import { Signer } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { callContract } from "../utils";
import abi from "../abis/debtMarket.json";

export const cancelDebtListing = async (
  signer: Signer,
  collection: string,
  tokenId: number
): Promise<string> => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.debtMarket,
    abi,
    signer,
    "cancelDebtListing",
    [collection, tokenId]
  );

  return transaction.hash;
};
