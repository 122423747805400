import { calculateApyBorrow } from "../utils";
import lendpoolAbi from "../abis/lendpoolUnlockd.json";
import dataProviderAbi from "../abis/unlockdProtocolDataProvider.json";
import { SingleCall } from "../types";
import { multicall } from "../utils/multicall";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const getLoanDataToRepay = async (
  collectionAddress: string,
  tokenId: number
): Promise<{
  apy: number;
  liquidationThreshold: string;
}> => {
  const collateralDataCalls = new SingleCall(
    CONTRACT_ADDRESSES.lendpool,
    lendpoolAbi,
    "getNftCollateralData",
    [collectionAddress, tokenId, CONTRACT_ADDRESSES.weth]
  );

  const getReserveDataCall = new SingleCall(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    dataProviderAbi,
    "getReserveData",
    [CONTRACT_ADDRESSES.weth]
  );

  const [collateralData, reserveDataCalls] = await multicall(
    collateralDataCalls,
    getReserveDataCall
  );

  const apy = calculateApyBorrow(
    // @ts-ignore
    parseInt(reserveDataCalls.variableBorrowRate)
  );

  // @ts-ignore
  const liquidationThreshold = collateralData.liquidationThreshold;

  return {
    apy,
    liquidationThreshold: liquidationThreshold.toString(),
  };
};
