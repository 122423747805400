import {type FC, useState} from "react";

interface Props {
  className?: string;
}

type Controller = (props: Props) => {
  className?: string;
  percentage?: number;
  handleSelectPercentage: (percentageValue?: number) => void;
};

const useController: Controller = (props) => {
  const [percentage, setPercentage] = useState<number>();

  const handleSelectPercentage = (percentageValue?: number) => {
    setPercentage(percentageValue);
  };

  return {
    percentage,
    handleSelectPercentage,
    ...props,
  };
};

export const Line: FC<Props> = (props) => {
  const { 
    className,
    percentage,
    handleSelectPercentage
   } = useController(props);

  return (
    <>
      <div className="">
        <ol className="sm:flex grid-cols-3 max-w-xl justify-center mb-4">
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <button
                className="flex z-10 cursor-pointer justify-center items-center w-8 h-8 border-2 bg-transparent border-white rounded-full shrink-0 focus:outline-none focus:bg-blue-500"
                onClick={() => handleSelectPercentage(0)}
              >
              </button>
              <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
            </div>
            <div className="mt-3 sm:pr-24">
              <h3 className="text-sm font-semibold text-white">0 %</h3>
            </div>
          </li>
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <button 
                className="flex z-10 cursor-pointer justify-center items-center w-8 h-8 border-2 bg-transparent border-white rounded-full shrink-0 focus:outline-none focus:bg-blue-500"
                onClick={() => handleSelectPercentage(25)}
              >
              </button>
              <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
            </div>
            <div className="mt-3 sm:pr-24">
              <h3 className="text-sm font-semibold text-white">25 %</h3>
            </div>
          </li>
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <button 
                className="flex z-10 cursor-pointer justify-center items-center w-8 h-8 border-2 bg-transparent border-white rounded-full shrink-0 focus:outline-none focus:bg-blue-500"
                onClick={() => handleSelectPercentage(50)}
              >
              </button>
              <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
            </div>
            <div className="mt-3 sm:pr-24">
              <h5 className="text-sm font-semibold text-white">50 %</h5>
            </div>
          </li>
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <button 
                className="flex z-10 cursor-pointer justify-center items-center w-8 h-8 border-2 bg-transparent border-white rounded-full shrink-0 focus:outline-none focus:bg-blue-500"
                onClick={() => handleSelectPercentage(75)}
              >
              </button>
              <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
            </div>
            <div className="mt-3 sm:pr-24">
              <h5 className="text-sm font-semibold text-white">75 %</h5>
            </div>
          </li>
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <button 
                className="flex z-10 cursor-pointer justify-center items-center w-8 h-8 border-2 bg-transparent border-white rounded-full shrink-0 focus:outline-none focus:bg-blue-500"
                onClick={() => handleSelectPercentage(100)}
              >
              </button>
            </div>
            <div className="mt-3">
              <h5 className="text-sm font-semibold text-white">100 %</h5>
            </div>
          </li>
        </ol>
      </div>
    </>
  );
};
