import { callContract } from "../utils";
import { BigNumber, Signer } from "ethers";
import abi from "../abis/debtMarket.json";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const listLoan = async (
  signer: Signer,
  collectionAddress: string,
  tokenId: number,
  walletAddress: string,
  sellPrice: BigNumber,
  startBiddingPrice?: BigNumber,
  auctionEndTimestamp?: number
) => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.debtMarket,
    abi,
    signer,
    "createDebtListing",
    [
      collectionAddress,
      tokenId,
      sellPrice,
      walletAddress,
      startBiddingPrice || 0,
      auctionEndTimestamp || 0,
    ]
  );

  return transaction.hash;
};
