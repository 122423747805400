import { useSession } from "next-auth/react";
import { useState } from "react";
import { useToggle, useUpdateEffect } from "react-use";
import { useAccount, useNetwork } from "wagmi";
import type { PortableText } from "../../../../types/elements";
import {
  useAlertNotification,
  useLockeysHolderType,
  useModalConnectWallets,
  useModalSign,
  useModalSwitchNetwork,
} from "../../../providers";
import { isWeb3InteractionsAvailableForUser } from "../../../../logic";
import { useWeb3ModalGuardStatics } from "../../../statics";

type Hook = (
  isOpen?: boolean,
  toggleModal?: (nextValue?: boolean) => void,
  options?: {
    validators?: {
      validator: () => boolean;
      notification: {
        type: "error" | "success" | "warning" | "loading";
        content: PortableText;
        timeout: number;
      };
    }[];
  }
) => () => void;

export const useWeb3ModalGuard: Hook = (isOpen, toggleModal, options = {}) => {
  const { validators } = options;
  const lockeysHolderType = useLockeysHolderType();
  const { feedbacks } = useWeb3ModalGuardStatics();
  const [process, setProcess] = useState<
    | "start"
    | "connectWallet"
    | "switchNetwork"
    | "authentication"
    | "open"
    | null
  >(null);
  const [on, launcher] = useToggle(false);

  const [isModalConnectWalletsOpen, toggleModalConnectWallets] =
    useModalConnectWallets();
  const [isModalSignOpen, toggleModalSign] = useModalSign();
  const [isModalSwitchNetworkOpen, toggleModalSwitchNetwork] =
    useModalSwitchNetwork();

  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();

  const { isDisconnected, address } = useAccount();
  const { data, status } = useSession();
  const { chain, chains } = useNetwork();

  const launcherModal = () => {
    setProcess("start");
    launcher();
  };

  useUpdateEffect(() => {
    if (
      !(
        process === "start" ||
        process === "connectWallet" ||
        process === "switchNetwork" ||
        process === "authentication"
      )
    )
      return;
    if (isDisconnected) {
      if (process === "connectWallet") {
        return setProcess(null);
      }
      return toggleModalConnectWallets(true);
    }

    // CODE COMMENTED TO TEST FLOW WITHOUT DATE-HOLDERTYPE VALIDATION
    // HAS TO BE REMOVED ON NEXT COMMITS

    if (
      lockeysHolderType == undefined ||
      !isWeb3InteractionsAvailableForUser(lockeysHolderType)
    ) {
      setProcess(null);

      openAlertNotification(
        "warning",
        feedbacks[`dappNotAvailable${lockeysHolderType!}`],
        2000
      );

      return;
    }

    if (chain?.id !== chains[0]?.id) {
      if (process === "switchNetwork") {
        return setProcess(null);
      }
      return toggleModalSwitchNetwork(true);
    }

    if (status !== "authenticated" /*|| data.address !== address*/) {
      // TODO confirm data.address is not required
      if (process === "authentication") {
        return setProcess(null);
      }
      return toggleModalSign(true);
    }
    if (validators) {
      let someValidateFail = false;
      let i = 0;
      do {
        if (!validators[i].validator()) someValidateFail = true;
        else i++;
      } while (!someValidateFail && i < validators.length);

      if (someValidateFail) {
        const { notification } = validators[i];
        setProcess(null);
        openAlertNotification(
          notification.type,
          notification.content,
          notification.timeout
        );
        return;
      }
    }
    setProcess("open");
    toggleModal?.(true);
  }, [on]);

  useUpdateEffect(() => {
    if (!process) return;
    if (!isModalConnectWalletsOpen) {
      const timer = setTimeout(() => {
        setProcess("connectWallet");
        launcher();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isModalConnectWalletsOpen]);

  useUpdateEffect(() => {
    if (!process) return;
    if (!isModalSignOpen) {
      const timer = setTimeout(() => {
        setProcess("authentication");
        launcher();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isModalSignOpen]);

  useUpdateEffect(() => {
    if (!process) return;
    if (!isModalSwitchNetworkOpen) {
      const timer = setTimeout(() => {
        setProcess("switchNetwork");
        launcher();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isModalSwitchNetworkOpen]);

  useUpdateEffect(() => {
    if (!(process === "open")) return;
    if (chain && chain?.id !== chains[0]?.id) {
      toggleModal?.(false);
      setProcess(null);
      const timer = setTimeout(() => {
        openAlertNotification(
          "warning",
          feedbacks.unxpectedChangeNetwork,
          10_000
        );
      }, 300);
      return () => clearTimeout(timer);
    }
    toggleModal?.(false);
    setProcess(null);
    const timer = setTimeout(() => {
      openAlertNotification(
        "warning",
        feedbacks.unexpectedChangeAddress,
        10_000
      );
    }, 300);
    return () => clearTimeout(timer);
  }, [address, chain]);

  useUpdateEffect(() => {
    if (!(process === "open")) return;
    if (!isOpen) setProcess(null);
  }, [isOpen]);

  return launcherModal;
};
