import { sign } from "@pushprotocol/restapi/src/lib/chat/helpers";
import Image from "next/image";
import Link from "next/link";
import React, { FC, useState } from "react";
import { IoMdClose } from "react-icons/io";
// import { PortableText } from "../../../types/elements/PortableText";
// import { useAlertNotification } from "../../providers";
import { CtaPrimary, CtaSecondary } from "../../ui";
import { withModal } from "./shared";

interface Props {
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
}

enum Status {
  APPROVE,
  LOADING,
  CLAIM,
  DONE,
}

const ModalClaim: FC<Props> = withModal((props) => {
  const { toggleModal } = props;
  //   const [ctaPrimaryText, setCtaPrimaryText] = useState<string>('Approve');
  //   const [isLoading, setIsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<Status>(Status.APPROVE);
  const [text, setText] = useState<string>(
    "In order to claim your cryptopunk you need to approve the contract first"
  );

  /* const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();
  const transactionErrorMessage: PortableText = [
    {
      _key: "block-0",
      _type: "block",
      children: [
        {
          _key: "child-0",
          _type: "span",
          text: "You've successfully claimed",
        },
      ],
    },
  ];
  useEffect(() => {
    openAlertNotification("success", transactionErrorMessage, 5000);
  }, []); */
  const claimNft = () => {
    // alert('Claim NFT button clicked')
    setStatus(Status.LOADING);
    setText("Your bid should be confirmed in a Blockchain shortly.");
    setTimeout(() => {
      setStatus(Status.DONE);
      setText("Transaction submitted");
    }, 2000);
  };
  const approveNft = () => {
    setStatus(Status.LOADING);
    // useTimeout(2000);
    // Mocking Metamask click
    setTimeout(() => {
      setStatus(Status.CLAIM);
      setText("Now you can claim it!");
    }, 2000);
  };
  const handleClickPrimaryButton = () => {
    if (status === Status.APPROVE) approveNft();
    if (status === Status.CLAIM) claimNft();
    /* if(status === Status.APPROVE) claimNft()
    if(status === Status.CLAIM) claimNft() */
  };

  return (
    <div className="bg-secondary relative border-glow-sm max-w-xl border-2 rounded-3xl flex flex-col items-center justify-center xs:px-4 px-3 xs:pb-8">
      <button
        className="w-10 h-10 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5 text-white"
        onClick={() => toggleModal(false)}
      >
        <IoMdClose className="w-full h-full" />
      </button>
      <h2 className="text-2xl font-bold mx-44 whitespace-nowrap mt-11">
        Claim your Cryptopunk
      </h2>
      <div className="my-11">
        {status === Status.LOADING && (
          <svg
            className="animate-spin"
            width="102"
            height="101"
            viewBox="0 0 102 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.2285 3.23633V19.0052M51.2285 82.0807V97.8496M19.6908 50.543H3.92188M98.5352 50.543H82.7663M17.7812 17.0957L28.9406 28.2551M73.5267 72.8411L84.6758 83.9902M84.6861 17.0854L73.537 28.2345M28.9303 72.8411L17.771 84.0005"
              stroke="white"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {status === Status.APPROVE && (
          <Image
            className="rounded-lg"
            src="https://ipfs.io/ipfs/QmcTw81RwapeZ8G9FhtuxMbMpWhMmib4iYLzxwijy7qpCz"
            height="138px"
            width="138px"
          />
        )}
        {(status === Status.CLAIM || status === Status.DONE) && (
          <svg
            width="108"
            height="108"
            viewBox="0 0 108 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="54.1597"
              cy="53.687"
              r="51.0972"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M54.25 80.6562V30.0117"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M31.0781 49.7383L54.0978 26.7188L77.1174 49.7383"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </div>

      <div className="w-80 text-center mb-20 ">
        {text}
        {status === Status.DONE && (
          <p className="mt-2">
            View on Etherscan:{" "}
            <Link legacyBehavior href={""}><span className="text-[#528dff] font-semibold">https://www.etherscan....3s3c9</span></Link>
          </p>
        )}
      </div>

      <div className="flex">
        {status === Status.DONE ? (
          <div className="flex justify-center gap-5 w-[350px]">
            <CtaPrimary
              onClick={() => toggleModal(false)}
              className="w-full"
              type="button"
              size="lg"
            >
              Close
            </CtaPrimary>
          </div>
        ) : (
          <>
            <CtaSecondary
              className="px-14"
              onClick={() => toggleModal(false)}
              size="lg"
            >
              Cancel
            </CtaSecondary>
            <CtaPrimary
              size="lg"
              className="ml-5 px-14"
              disabled={status === Status.LOADING}
              onClick={handleClickPrimaryButton}
            >
              {/* {ctaPrimaryText} */}
              {/* {status === Status.LOADING ? "Loading..." : "Approve"} */}
              {status === Status.LOADING && "Loading..."}
              {status === Status.APPROVE && "Approve"}
              {status === Status.CLAIM && "Claim"}
            </CtaPrimary>
          </>
        )}
      </div>
    </div>
  );
});

export default ModalClaim;
