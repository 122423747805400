import type {TransactionsStatics} from "../../types";

export const useTransactionsStatics = () => {
  const data: TransactionsStatics = {
    id: "transactions",
    content: {
      transactionTitle: "Your transactions",
      statusTitle: "Status",
      timeTitle: "Time",
    },
    feedbacks: {
      unexpectedError: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "child-0",
              _type: "span",
              text: "unexpected error",
            },
          ],
        },
      ],
    },
  };

  return data;
};
