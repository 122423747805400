import classNames from "classnames";
import { type FC, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useAlertNotification } from "../../../providers";
import { useAmountPanelStatics } from "../../../statics/amount-panel";
import {
  calculateHealthFactorBorrow,
  formatWeiToEth,
  getBalanceOfAddress,
} from "../../../../utils";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
} from "../../../../errors/errors";
import { ErrorMessage, Input } from "../../modals/shared";
import { BigNumber } from "ethers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { handleErrors } from "../../../../logic/helpers";

interface Props {
  className?: string;
  nameInput: string;
  valuation: BigNumber;
  liquidationThreshold: string;
  availableToBorrow: BigNumber;
  currentBorrowAmount: BigNumber;
  amountSelectedToBorrow: BigNumber;
  onSelectedAmountChange: (amount: BigNumber) => void;
}

export const AmountPanel: FC<Props> = (props) => {
  const {
    className,
    liquidationThreshold,
    valuation,
    availableToBorrow,
    currentBorrowAmount,
    onSelectedAmountChange,
  } = props;
  const statics = useAmountPanelStatics();
  const [amountToBorrow, setAmountToBorrow] = useState(BigNumber.from(0));
  const [balanceOfAddress, setBalanceOfAddress] = useState<BigNumber>();
  const [healthFactor, setHealthFactor] = useState<number>();
  const [error, setError] = useState<Error | null>(null);
  const { address } = useAccount();
  const [isOpenAlertNotification, openAlertNotification] =
    useAlertNotification();
  const {
    content: { healthFactorTitle },
  } = statics;
  //Handle Errors
  useEffect(() => {
    if (
      error &&
      !isOpenAlertNotification &&
      !(
        error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError
      )
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  //Get User Balance
  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const balance = await getBalanceOfAddress(address);
          setBalanceOfAddress(balance);
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    } else {
      setBalanceOfAddress(undefined);
    }
  }, [address]);

  //Calculate current Health Factor
  useEffect(() => {
    if (
      liquidationThreshold !== undefined &&
      amountToBorrow !== undefined &&
      currentBorrowAmount !== undefined
    ) {
      handleHealthFactorBorrowCalculation(
        currentBorrowAmount,
        BigNumber.from(liquidationThreshold),
        amountToBorrow
      );
    }
  }, [amountToBorrow]);

  const handleAmountChange = (amountSelected: BigNumber): void => {
    setAmountToBorrow(amountSelected);
    if (amountSelected.gt(availableToBorrow)) {
      setError(
        new LimitMaxError(
          `Amount ${formatWeiToEth(
            amountSelected
          )} is greater than available amount to borrow: ${formatWeiToEth(
            availableToBorrow
          )}`
        )
      );
    } else if (amountSelected.lt(BigNumber.from(0))) {
      setError(
        new LimitMinError(`Amount to borrow must be greater than than zero`)
      );
    } else {
      if (error) setError(null);
      onSelectedAmountChange(amountSelected);
    }
  };

  const handleHealthFactorBorrowCalculation = (
    currentBorrowAmount: BigNumber,
    liquidationThreshold: BigNumber,
    amountToBorrow: BigNumber
  ): void => {
    const healthFactor = calculateHealthFactorBorrow(
      valuation,
      currentBorrowAmount,
      liquidationThreshold,
      amountToBorrow
    );
    setHealthFactor(healthFactor);
  };

  return (
    <>
      <div className={classNames(`max-w-2xl`, [className])}>
        <Input
          onChange={handleAmountChange}
          max={availableToBorrow}
          className="xs:mb-8 mb-5"
          nameInput="totalSupply"
          balance={balanceOfAddress}
        />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <div className="flex mx-auto mb-2">
          <span className="flex text-lg max-3xs:hidden mx-auto">
            <div className="flex items-center">
              {healthFactorTitle}
              <div className="w-2"></div>
              {healthFactor != undefined && healthFactor == 0 && !error && "-"}
              {healthFactor != undefined &&
                healthFactor > 0 &&
                !error &&
                healthFactor.toFixed(2)}
              <div className="w-1"></div>
              {healthFactor != undefined && healthFactor >= 2 && !error && (
                <div className={`bg-green-400 w-3.5 h-3.5 rounded-full`}></div>
              )}
              {healthFactor != undefined &&
                healthFactor >= 1.5 &&
                healthFactor < 2 &&
                !error && (
                  <div
                    className={`bg-yellow-400 w-3.5 h-3.5 rounded-full`}
                  ></div>
                )}
              {healthFactor != undefined &&
                healthFactor >= 1 &&
                healthFactor < 1.5 &&
                !error && (
                  <div
                    className={`bg-orange-400 w-3.5 h-3.5 rounded-full`}
                  ></div>
                )}
              {healthFactor != undefined &&
                healthFactor < 1 &&
                healthFactor > 0 &&
                !error && (
                  <div className={`bg-red-400 w-3.5 h-3.5 rounded-full`}></div>
                )}
            </div>

            <div className="relative py-3 sm:max-w-xl sm:mx-auto mt-2">
              <div className="group cursor-pointer relative inline-block border-gray-400 text-center">
                <div className="text-2xl ml-2">
                  <AiOutlineQuestionCircle />
                </div>
                <div className="opacity-0 w-[400px] -ml-40 mb-2 bg-secondary text-white border border-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 px-3 pointer-events-none">
                The health factor is the numeric representation of the safety of your deposited NFTs against the borrowed assets and its underlying value. The higher the value is, the safer the state of your funds are against a liquidation scenario. When it reaches 1 or below, the position may be liquidated to maintain solvency.
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
