import { type FC, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IconEthereum } from "../ui/icons";
import { retrieveHealthFactorList } from "../../logic";
import { HealthFactorItem } from "../../types";
import { HealthFactorCircle } from "../commons/HealthFactorCircle";
import { CtaSecondary } from "../ui";
import { utils } from "ethers";
import { truncate } from "../../utils";
import { useAlertNotification } from "../providers";
import { HealthFactorEmptyItem } from "./HealthFactorEmptyItem";
import { handleErrors, retrieveImagesForNftsList } from "../../logic/helpers";
import Image from "next/image";
import { Info } from "../commons";
import { healthFactorDescription } from "../../literals";

const LIMIT = 10;

export const HealthFactorList: FC = () => {
  const [items, setItems] = useState<HealthFactorItem[]>([]);
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [more, setMore] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { items: freshItems, pagination } =
          await retrieveHealthFactorList(page * LIMIT, LIMIT);

        setItems([...items, ...freshItems]);
        setMore(pagination.offset + LIMIT < pagination.total);
        setLoading(false);
        await loadImages(freshItems);
      } catch (err) {
        setError(handleErrors(err));
      }
    })();
  }, [page]);

  useEffect(() => {
    if (error && !isAlertNotificationOpen) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  const handleSeeMore = () => {
    setPage(page + 1);
    setLoading(true);
  };

  const loadImages = async (items: Array<HealthFactorItem>) => {
    const itemsToRequestImage: Array<{
      collectionAddress: string;
      tokenId: string;
    }> = [];

    for (let i = 0; i < items.length; i++) {
      const { collectionAddress, tokenId } = items[i];

      itemsToRequestImage.push({ collectionAddress, tokenId });
    }

    const images = await retrieveImagesForNftsList(itemsToRequestImage);

    setItems((items) => {
      const itemsCopy = [...items];

      const from = page * LIMIT;

      for (let i = from; i < items.length; i++) {
        const item = itemsCopy[i];

        const itemCopy = { ...item };

        itemCopy.image = images[i - from];

        itemsCopy[i] = itemCopy;
      }

      return itemsCopy;
    });
  };

  return (
    <div className="overflow-x-auto max-w-max mx-auto p-4 relative bg-secondary rounded-xl border-4 border-transparent hover:border-indigo-600 flex flex-col items-center">
      <table className="w-full text-base text-left text-white">
        <thead className="text-md">
          <tr>
            <th scope="col" className="py-3 px-8">
              Collection
            </th>
            <th scope="col" className="py-3 px-8">
              Token ID
            </th>
            <th scope="col" className="py-3 px-8">
              Valuation
            </th>
            <th scope="col" className="py-3 px-8">
              Debt
            </th>
            <th scope="col" className="py-3 px-8 flex items-center gap-2">
              Health Factor
              <Info description={healthFactorDescription} direction="right" />
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map(
            (
              {
                collectionAddress,
                image,
                name,
                tokenId,
                valuation,
                currentBorrowAmount: debt,
                healthFactor,
              },
              index
            ) => (
              <tr
                key={collectionAddress + tokenId}
                className={`bg-secondary hover:bg-primary/10 hover:bg-opacity-50 text-base font-medium ${index < items.length - 1 ? "border-b" : ""
                  }`}
              >
                <td scope="row" className="flex py-6 px-8 font-medium">
                  <div className="flex gap-3 items-center w-64">
                    {image ? (
                      <Image
                        className="rounded-xl"
                        src={image!}
                        width={56}
                        height={56}
                      />
                    ) : (
                      <div className="w-14"></div>
                    )}
                    <p className="font-bold flex">{name}</p>
                  </div>
                </td>
                <td className="py-4 px-8">#{tokenId}</td>
                <td className="py-4 px-8">
                  <div className="flex gap-3 items-center">
                    {truncate(utils.formatEther(valuation), 4)}
                    <div className="w-5 h-5 bg-white rounded-full p-0.5 mr-2">
                      <IconEthereum />
                    </div>
                  </div>
                </td>
                <td className="py-4 px-8">
                  <div className="flex gap-3 items-center">
                    {truncate(utils.formatEther(debt), 4)}
                    <div className="w-5 h-5 bg-white rounded-full p-0.5 mr-2">
                      <IconEthereum />
                    </div>
                  </div>
                </td>
                <td className="py-4 px-8">
                  <div className="flex items-center gap-3">
                    {healthFactor?.toFixed(2)}{" "}
                    {healthFactor && (
                      <HealthFactorCircle healthFactor={healthFactor} />
                    )}
                  </div>
                </td>
              </tr>
            )
          )}
          {items.length === 0 && !isLoading && <HealthFactorEmptyItem />}
        </tbody>
      </table>
      {!isLoading && more && (
        <CtaSecondary className="mt-10 mb-8 w-48" onClick={handleSeeMore}>
          See more
        </CtaSecondary>
      )}
      {isLoading && (
        <div className="w-full flex mx-auto mt-10 mb-8 justify-center items-center">
          <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
        </div>
      )}
    </div>
  );
};
