import { callContract } from "../utils";
import abi from "../abis/customERC721.json";
import { PUNKS_COLLECTION, CONTRACT_ADDRESSES } from "../../app.config";

export const isOwnerAndPunkGatewayAprovedForAllInWrappedPunks = async (
  owner: string
): Promise<boolean> => {
  const isCollectionApproved = await callContract(
    PUNKS_COLLECTION.wrapAddress!,
    abi,
    null,
    "isApprovedForAll",
    [owner, CONTRACT_ADDRESSES.punkGateway]
  );

  return isCollectionApproved;
};
