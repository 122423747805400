import type {ModalMintNftsStatics} from "../../../../types";

export const modalMintNftsStatics = () => {
  const data: ModalMintNftsStatics = {
    title: "NFT Minting",
    legalText: [
      {
        _key: "block-0",
        _type: "block",
        children: [
          {
            _key: "child-0",
            _type: "span",
            text: "Select how many NFTs you want to mint",
          },
        ],
      },
    ],
    mintButton: "MINT",
    feedbacks: {
      limitMinError: "Invalid amount, please select 1 or more",
      limitMaxError: "Invalid amount",
      notEnoughEthForGas: "User has not enough eth for gas",
      userDeniedTransactionSignatureMetamaskError: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "child-0",
              _type: "span",
              text: "User denied transaction signature",
            },
          ],
        },
      ],
      unexpectedError: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "child-0",
              _type: "span",
              text: "Unexpected error on mint faucet signature",
            },
          ],
        },
      ],
    },
    selector: {
      labelAmount: "Amount",
      labelAvailableNfts: "NFTs available to mint",
      maxButton: "max",
    },
  };

  return data;
};
