import { BigNumber, ContractInterface } from "ethers";

export type Call = {
  target: string;
  callData: string;
};

export type _CallsGroupedByMethod = {
  abi: ContractInterface;
  contractAddress: string;
  method: string;
  callsArguments: Array<Array<
    string | number | BigNumber | Array<string | number | BigNumber>
  > | null>;
};

export class CallsGroupedByMethod {
  abi: ContractInterface;
  contractAddress: string;
  method: string;
  callsArguments: Array<
    Array<string | number | BigNumber | Array<string | number | BigNumber>>
  >;

  constructor(
    contractAddress: string,
    abi: ContractInterface,
    method: string,
    callsArguments: Array<
      Array<string | number | BigNumber | Array<string | number | BigNumber>>
    >
  ) {
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.method = method;
    this.callsArguments = callsArguments;
  }
}

export type _SingleCall = {
  abi: ContractInterface;
  contractAddress: string;
  method: string;
  args: Array<string | number | BigNumber | Array<string | number | BigNumber>>;
};

export class SingleCall {
  contractAddress: string;
  abi: ContractInterface;
  method: string;
  args: Array<string | number | BigNumber | Array<string | number | BigNumber>>;

  constructor(
    contractAddress: string,
    abi: ContractInterface,
    method: string,
    args: Array<
      string | number | BigNumber | Array<string | number | BigNumber>
    >
  ) {
    this.abi = abi;
    this.contractAddress = contractAddress;
    this.method = method;
    this.args = args;
  }
}
