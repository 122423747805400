import {BigNumber, ethers} from "ethers";
import {percentMul, wadDiv} from "./DSMath";

export const calculateHealthFactorBorrow = (
  nftValuation: BigNumber,
  currentBorrowAmount: BigNumber,
  liquidationThreshold: BigNumber,
  amountToBorrow: BigNumber
): number => {
  if (amountToBorrow.eq(0)) return 0;
  const nftValuationThreshold: BigNumber = getNFTValuationThreshold(
    nftValuation, 
    liquidationThreshold
  );

  return parseFloat(
    ethers.utils.formatEther(
      wadDiv(
        nftValuationThreshold,
        amountToBorrow.add(currentBorrowAmount.toString())
      )
    )
  );
};

const getNFTValuationThreshold = (
  valuation: BigNumber,
  liquidationThreshold: BigNumber
): BigNumber => {
  return percentMul(valuation, liquidationThreshold);
};
