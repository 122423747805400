import { BigNumber } from "ethers";
import { RAY, SECONDS_PER_YEAR } from "../constants";

export const calculateApyEarnGoerli = (liquidityRate: BigNumber): number => {
  //@ts-ignore
  const aprNumber = Number(liquidityRate) / RAY;

  return (
    (aprNumber / parseInt(SECONDS_PER_YEAR) + 1) ** parseInt(SECONDS_PER_YEAR) -
    1
  );
};
