import {LimitMaxError, LimitMinError} from "../../errors/errors";

export const validateRange = (
  number: number,
  min: number,
  max: number,
  explain?: string
) => {
  if (number < min) {
    throw new LimitMinError(`${explain} is lower than ${min}`);
  } else if (number > max) {
    throw new LimitMaxError(`${explain} is higher than ${max}`);
  }
};
