import classNames from "classnames";
import { type FC } from "react";

interface Props {
  heading?: string;
  className?: string;
}

export const CommonSubHeader: FC<Props> = (props) => {
  const { heading } = props;
  const { className } = props;

  return (
    <>
      <section className={classNames(`mt-14 mb-8`, [className])}>
        <h2 className="text-center w-full text-xl font-semibold md:text-3xl xs:text-2xl mt-b xs:mb-3">
          {heading}
        </h2>
      </section>
    </>
  );
};
