import { type FC, FormEvent, useEffect, useState, ChangeEvent } from "react";
import { ErrorMessage, Input, withModal } from "./shared";
import { IoMdClose } from "react-icons/io";
import { CtaPrimary } from "../../ui";
import { useAccount, useSigner } from "wagmi";
import { useAlertNotification } from "../../providers";
import { waitForTransactionReceipt } from "../../../utils";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  TransactionError,
  transactionErrorMessage,
} from "../../../errors/errors";
import { listLoan } from "../../../logic";
import { AiOutlineCheck, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useUpdateEffect } from "react-use";
import { BigNumber } from "ethers";
import {
  calculateAuctionEndTimestamp,
  changeToWrapIfCrypropunks,
  handleErrors,
} from "../../../logic/helpers";
import { approveInWalletLiteral } from "../../../literals";

enum Status {
  TO_LIST,
  LOADING,
  SUCCESS,
}

type Props = {
  className?: string;
  isOpen: boolean;
  toggleModal: (nextValue: boolean) => void;
  collectionAddress: string;
  tokenId: number;
  onListed: () => void;
};

const TIME_OPTIONS = [
  { value: "1d", text: "1 Day" },
  { value: "3d", text: "3 Days" },
  { value: "7d", text: "7 Days" },
  { value: "28d", text: "28 Days" },
  { value: "1m", text: "1 Month" },
  { value: "3m", text: "3 Months" },
  { value: "6m", text: "6 Months" },
];

export const ModalList: FC<Props> = withModal((props) => {
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const { toggleModal, collectionAddress, tokenId, onListed } = props;
  const [status, setStatus] = useState<Status>(Status.TO_LIST);
  const [error, setError] = useState<Error | null>(null);
  const [errorFixedPrice, setErrorFixedPrice] = useState<Error | null>(null);
  const [errorAuctionPrice, setErrorAuctionPrice] = useState<Error | null>(
    null
  );
  const [isFixedPriceSelected, setFixedPrice] = useState<boolean>(false);
  const [isAuctionSelected, setAuctionSelected] = useState<boolean>(false);
  const [fixedPriceAmount, setFixedPriceAmount] = useState<BigNumber>(
    BigNumber.from("0")
  );
  const [auctionAmount, setAuctionAmount] = useState<BigNumber>(
    BigNumber.from("0")
  );
  const [isListButtonDisabled, setListButtonDisabled] = useState<boolean>(true);
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();
  const [timeSelected, setTimeSelected] = useState<string>("28d");

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(
        error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError ||
        error instanceof TransactionError
      )
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    } else if (error instanceof TransactionError) {
      openAlertNotification("error", transactionErrorMessage, 5000);
    }
  }, [error]);

  // HANDLE BUTTON LIST STATE
  useEffect(() => {
    if (
      (isFixedPriceSelected || isAuctionSelected) &&
      status === Status.TO_LIST
    ) {
      setListButtonDisabled(false);
    } else {
      setListButtonDisabled(true);
    }
  }, [isFixedPriceSelected, isAuctionSelected, status]);

  useEffect(() => {
    if (!isAuctionSelected) {
      setAuctionAmount(BigNumber.from("0"));
    }
  }, [isAuctionSelected]);

  useUpdateEffect(() => {
    if (!isFixedPriceSelected) {
      setFixedPriceAmount(BigNumber.from("0"));
    }
  }, [isFixedPriceSelected]);

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const handleSellCheckboxChange = () => {
    setErrorFixedPrice(null);
    setFixedPrice(!isFixedPriceSelected);
  };

  const handleAuctionCheckboxChange = () => {
    setErrorAuctionPrice(null);
    setAuctionSelected(!isAuctionSelected);
  };

  const handleChangeFixedPrice = (amount: BigNumber) => {
    if (!amount || amount.isZero() || amount.isNegative()) {
      setFixedPriceAmount(BigNumber.from("0"));
      setFixedPrice(false);
    } else {
      setErrorFixedPrice(null);
      setFixedPriceAmount(amount);
      setFixedPrice(true);
    }
  };

  const handleChangeAuctionPrice = (amount: BigNumber) => {
    if (!amount || amount.isZero() || amount.isNegative()) {
      setAuctionAmount(BigNumber.from("0"));
      setAuctionSelected(false);
    } else {
      setErrorAuctionPrice(null);
      setAuctionSelected(true);
      setAuctionAmount(amount);
    }
  };

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAuctionSelected(true);
    setTimeSelected(event.target.value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (
      isFixedPriceSelected &&
      (fixedPriceAmount.isZero() || fixedPriceAmount.isNegative())
    ) {
      return setErrorFixedPrice(new LimitMinError("you must select a price"));
    } else if (
      isAuctionSelected &&
      (auctionAmount.isZero() || auctionAmount.isNegative())
    ) {
      return setErrorAuctionPrice(new LimitMinError("you must select a price"));
    }

    try {
      openAlertNotification("info", approveInWalletLiteral, 50000);
      setStatus(Status.LOADING);

      const txHash = await listLoan(
        signer!,
        changeToWrapIfCrypropunks(collectionAddress),
        tokenId,
        address!,
        fixedPriceAmount,
        isAuctionSelected ? auctionAmount : undefined,
        isAuctionSelected
          ? calculateAuctionEndTimestamp(timeSelected)
          : undefined
      );

      closeAlertNotification();

      waitForTransactionReceipt(txHash, 5000, (receipt) => {
        if (receipt.status == 1) {
          setStatus(Status.SUCCESS);

          onListed();
        } else if (receipt.status === 0) {
          setError(
            new TransactionError(
              `transaction with hash ${receipt.transactionHash} failed`
            )
          );
        }
      });
    } catch (err) {
      closeAlertNotification();
      setError(handleErrors(err));
      setStatus(Status.TO_LIST);
    }
  };

  return (
    <div className="bg-secondary relative border-glow-sm max-w-2xl border-2 rounded-3xl flex items-center justify-center xs:px-4 px-3 pb-6 pt-10 xs:pb-8">
      <form
        className="overflow-y-auto scrollbar max-h-[650px] xs:px-8 px-7 text-white flex flex-col items-center"
        onSubmit={handleSubmit}
      >
        <button
          type="button"
          className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
          onClick={handleCloseModal}
        >
          <IoMdClose className="w-full h-full" />
        </button>
        <div className="w-full flex flex-col gap-14">
          <div className="w-full flex flex-col gap-12">
            <div className="w-full flex flex-col gap-6">
              {status !== Status.SUCCESS && (
                <h2 className="text-2xl font-bold text-center">
                  You are going to list your loan
                  <br />
                  in a marketplace.
                </h2>
              )}
              {status === Status.TO_LIST && (
                <p className="text-center">
                  Loans are composed by:
                  <br />
                  Selling price + Debt
                  <br />
                  <br />
                  The buyer will take/pay the total position:
                  <br />
                  NFT + Debt
                </p>
              )}
            </div>
            {status === Status.TO_LIST && (
              <div className="w-full flex flex-col gap-8">
                <div className="w-full flex flex-col gap-5">
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full border-b-2 border-white pb-3 flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <input
                          className="w-3 h-3 border border-white rounded-sm checked:bg-primary"
                          id="sell"
                          type="checkbox"
                          value="sell"
                          checked={isFixedPriceSelected}
                          onChange={handleSellCheckboxChange}
                        />
                        <label className="font-bold" htmlFor="sell">
                          Fixed price
                        </label>
                      </div>
                    </div>
                    <p className="px-4">
                      Set a price. The first user that pays the price gets your
                      loan, and you receive the selling price.
                    </p>
                  </div>
                  <Input
                    nameInput="sellAmount"
                    maxButtonHidden={true}
                    walletBalanceHidden={true}
                    onChange={handleChangeFixedPrice}
                    value={fixedPriceAmount}
                  />
                  {errorFixedPrice && (
                    <ErrorMessage>{errorFixedPrice.message}</ErrorMessage>
                  )}
                </div>
                <div className="w-full flex flex-col gap-5">
                  <div className="w-full flex flex-col gap-2">
                    <div className="w-full border-b-2 border-white pb-3 flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <input
                          className="w-3 h-3 border border-white rounded-sm checked:bg-primary"
                          id="put-in-auction"
                          type="checkbox"
                          value="put-in-auction"
                          checked={isAuctionSelected}
                          onChange={handleAuctionCheckboxChange}
                        />
                        <label className="font-bold" htmlFor="put-in-auction">
                          Timed Auction
                        </label>
                      </div>
                    </div>
                    <p className="px-4">
                      Set your minimum price and any user can set a bid for your
                      loan. After the time limit expires, the highest bid gets
                      your loan and you receive the highest bid.{" "}
                    </p>
                  </div>
                  <div className="flex flex-col gap-5">
                    <Input
                      nameInput="auctionAmount"
                      maxButtonHidden={true}
                      walletBalanceHidden={true}
                      onChange={handleChangeAuctionPrice}
                      value={auctionAmount}
                    />
                    {errorAuctionPrice && (
                      <ErrorMessage>{errorAuctionPrice.message}</ErrorMessage>
                    )}
                    <div className="flex flex-col gap-3">
                      <legend className="font-bold text-xs">Time limit</legend>
                      <div className="w-full grid grid-cols-4 grid-rows-2">
                        {TIME_OPTIONS.map(({ value, text }) => (
                          <div className="flex items-center gap-2">
                            <input
                              className="w-3 h-3 border border-white rounded-full checked:bg-primary"
                              type="radio"
                              id={value}
                              name="time"
                              value={value}
                              checked={
                                isAuctionSelected && timeSelected === value
                              }
                              onChange={handleTimeChange}
                            />
                            <label htmlFor={value}>{text}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {status === Status.LOADING && (
            <>
              <div className="w-full flex flex-col items-center">
                <p className="mt-3">Your transaction should be confirmed</p>
                <p>in the blockchain shortly.</p>
              </div>
              <div className="flex justify-center items-center my-10">
                <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
              </div>
            </>
          )}

          {status === Status.SUCCESS && (
            <>
              <div className="flex w-96 flex-col items-center justify-center mx-auto xs:mb-8">
                <div className="border-white border-2 rounded-full mb-4">
                  <AiOutlineCheck className="w-8 h-8 m-4" />
                </div>
                <span className="mb-6">Transaction submitted</span>
                {/* <CtaSecondary>
              <Anchor
                href={`https://${
                  processs.env.NEXT_PUBLIC_CHAIN === "goerli" ? "Goerli" + "." : ""
                }etherscan.io/tx/${transaction}`}
                target="_blank"
                title="Etherscan"
                rel="noopener noreferrer"
                className="font-bold px-5"
              >
                See your transaction on Etherscan
              </Anchor>
            </CtaSecondary> */}
              </div>
              <div className="flex justify-center gap-5 w-full">
                <CtaPrimary
                  onClick={handleCloseModal}
                  className="w-full"
                  type="button"
                >
                  Close
                </CtaPrimary>
              </div>
            </>
          )}

          {status !== Status.SUCCESS && (
            <div className="w-full flex justify-center">
              <CtaPrimary
                className="w-48"
                type="submit"
                disabled={isListButtonDisabled}
              >
                List
              </CtaPrimary>
            </div>
          )}
        </div>
      </form>
    </div>
  );
});
