import { callContract } from "../utils";
import abi from "../abis/unlockdProtocolDataProvider.json";
import { NFTConfigurationDataType } from "../types";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const getNftConfigurationDataByTokenId = async (
  collectionAddress: string,
  tokenId: number
): Promise<NFTConfigurationDataType> => {
  const result: NFTConfigurationDataType = await callContract(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    abi,
    null,
    "getNftConfigurationDataByTokenId",
    [collectionAddress, tokenId]
  );

  return result;
};
