import classNames from "classnames";
import {type FC} from "react";

interface Props {
  className?: string;
}

type Controller = (props: Props) => Props;

const useController: Controller = (props) => {
  return {
    ...props,
  };
};

export const SubHeader: FC<Props> = (props) => {
  const { className } = useController(props);

  return (
    <>
      <section className={classNames(`mt-14 mb-8`, [className])}>
        <h1 className="text-center w-full text-xl font-semibold md:text-3xl xs:text-2xl mt-b xs:mb-3">
          Dashboard
        </h1>
      </section>
    </>
  );
};
