import { type FC } from "react";
import classNames from "classnames";
import { IconEthereum } from "../../ui/icons";
import { BigNumber } from "ethers";
import { formatWeiToEth } from "../../../utils";

interface Props {
  className?: string;
  valueLocked?: BigNumber;
  utilizationRate?: string;
  nftsDeposited?: number;
  totalNftsValue?: BigNumber;
}

export const ProtocolMetrics: FC<Props> = (props) => {
  const {
    className,
    valueLocked,
    nftsDeposited,
    utilizationRate,
    totalNftsValue,
  } = props;

  return (
    <>
      <div
        className={classNames(
          "mx-auto py-6 px-12 bg-secondary rounded-xl shadow-lighting",
          [className]
        )}
      >
        <h3 className="mb-6 font-bold text-xl">Protocol Metrics</h3>
        <div className="flex justify-between">
          <div className="flex max-w-max">
            <div className="flex flex-col items-center gap-1">
              <span className="font-light text-sm">
                Total Supplied Liquidity
              </span>
              <div className="flex items-center gap-x-1">
                <p className="font-bold text-xl">
                  {valueLocked && formatWeiToEth(valueLocked, 2)}
                </p>
                <div className="w-4 h-4 bg-white rounded-full">
                  <IconEthereum />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white w-0.5 h-10"></div>

          <div className="flex">
            <div className="flex flex-col items-center justify-center">
              <span className="font-light text-sm">Total NFTs Deposited</span>
              <p className="font-bold text-xl">{nftsDeposited || "-"}</p>
            </div>
          </div>
          <div className="bg-white w-0.5 h-10"></div>

          <div className="flex">
            <div className="flex flex-col items-center justify-center">
              <span className="font-light text-sm">Total NFT Value</span>
              <div className="flex items-center gap-x-1">
                <p className="font-bold text-xl">
                  {totalNftsValue ? formatWeiToEth(totalNftsValue, 2) : "-"}
                </p>
                <div className="w-4 h-4 bg-white rounded-full">
                  <IconEthereum />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white w-0.5 h-10"></div>

          <div className="flex flex-col items-center justify-center">
            <span className="font-light text-sm">Utilization Rate</span>
            <p className="font-bold text-xl">
              {utilizationRate && utilizationRate.toString()} %
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
