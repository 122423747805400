import { CONTRACT_ADDRESSES } from "../../app.config";
import { callContract } from "../utils";
import abi from "../abis/weth.json";
import { BigNumber } from "ethers";

export const getLendpoolAllowanceOnWeth = async (
  address: string
): Promise<BigNumber> => {
  const allowance = await callContract(
    CONTRACT_ADDRESSES.weth,
    abi,
    null,
    "allowance",
    [address, CONTRACT_ADDRESSES.lendpool]
  );

  return allowance;
};
