import { type FC, type FormEvent, useEffect, useState } from "react";
import { useFormStatics, useGlobalProviderStatics } from "../../statics";
import { getAvailableNftsForAddress } from "../../../logic";
import { CtaPrimary, CtaSecondary, RichText, WithCSR } from "../../ui";
import { ListElement } from "./ListElement";
import classNames from "classnames";
import { useAccount } from "wagmi";
import { useToggle } from "react-use";
import { ModalMintNfts } from "../../commons/modals";
import { useWeb3ModalGuard } from "../../commons/modals/shared";
import { useAlertNotification, useLockeysHolderType } from "../../providers";
import { Storage } from "../../../utils/Storage";
import { handleErrors } from "../../../logic/helpers";

interface Props {
  className?: string;
  onNewTransaction: (txHash: string) => void;
}

export const Form: FC<Props> = (props) => {
  const { className, onNewTransaction } = props;
  const statics = useFormStatics();
  const { feedbacks: globalFeedbacks } = useGlobalProviderStatics();
  const {
    content: { feedbacks },
  } = statics;
  const {
    content: {
      faucetLinks,
      copyButtonText,
      submitButtonText,
      copyButtonExplainText,
      faucetExplainText,
      inputAddressPlaceholder,
    },
  } = statics;
  const [isModalMintOpen, toggleModalMint] = useToggle(false);
  const { address } = useAccount();
  const [, openAlertNotification] = useAlertNotification();
  const [availableNfts, setAvailableNfts] = useState<number>();
  const [hasAvailableNftsToMint, setHasAvailableNftsToMint] =
    useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const lockeysHolderType = useLockeysHolderType();

  useEffect(() => {
    if (error) {
      openAlertNotification("error", feedbacks.unexpectedError, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const {
            availableNfts: availableNftsResult,
            hasAvailableNftsToMint: hasAvailableNftsToMintResult,
          } = await getAvailableNftsForAddress(address);

          setAvailableNfts(availableNftsResult);
          setHasAvailableNftsToMint(hasAvailableNftsToMintResult);
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  const launcherModal = useWeb3ModalGuard(isModalMintOpen, toggleModalMint, {
    validators: [
      {
        validator: () => lockeysHolderType != undefined,
        notification: {
          type: "error",
          content: globalFeedbacks.unexpectedError,
          timeout: 10_000,
        },
      },
      {
        validator: () => !error,
        notification: {
          type: "error",
          content: feedbacks.unexpectedError,
          timeout: 10_000,
        },
      },
      {
        validator: () => !!hasAvailableNftsToMint,
        notification: {
          type: "warning",
          content: feedbacks.allNftsMinted,
          timeout: 10_000,
        },
      },
      {
        validator: () => {
          const storedTransaction = Storage.LOCAL.get(
            `account.${address}.transactionPending`,
            ""
          );

          return !storedTransaction.length;
        },
        notification: {
          type: "warning",
          content: feedbacks.transactionPending,
          timeout: 10_000,
        },
      },
    ],
  });

  const handleSendNft = (event: FormEvent) => {
    event.preventDefault();
    launcherModal();
  };

  const handleCopyButton = () => {
    navigator.clipboard.writeText("0x180D4C4bF46F26e93C5817295944F54cFBdAa92B");
    openAlertNotification("success", feedbacks.addressCopied, 5000);
  };

  return (
    <>
      <section
        className={classNames(
          "max-w-4xl mx-auto mb-5 xs:px-6 px-4 pt-8 pb-8 shadow-lg rounded-t-xl bg-secondary element-shadow",
          [className]
        )}
      >
        <form className="mb-6 flex" onSubmit={handleSendNft}>
          <WithCSR>
            <input
              type="text"
              className="w-full bg-secondary text-xs md:text-sm px-3 max-3xs:px-2 py-2 border
              rounded-full text-whiteplaceholder:text-white disabled:text-white mr-3"
              placeholder={address ? `${address}` : inputAddressPlaceholder}
              disabled={true}
            />
          </WithCSR>
          <CtaPrimary type="submit" className="w-40">
            {submitButtonText}
          </CtaPrimary>
        </form>
        <div className="border-4 border-blue-600 rounded-xl max-w-max p-4 mb-4">
          <div className="text-sm">
            <span className="ml-3">
              During the Testnet period all NFTs valuation are going to be lower
              than it's real valuation in the market:
            </span>
          </div>
          <ul className="list-disc list-inside ml-5">
            <li>BAYC valuation divided by 100</li>
            <li>Azukis and Doodles valuation divided by 10</li>
          </ul>
        </div>

        <div className="mb-6 mx-7 text-sm">
          <RichText value={faucetExplainText} />
        </div>
        <ul className="list-disc list-inside mb-11 font-semibold ml-9">
          {faucetLinks.map((item, i) => (
            <ListElement
              key={item.id + i.toString()}
              href={item.href}
              target={item.target}
              rel={item.rel}
              title={item.title}
              className=""
            >
              {item.children}
            </ListElement>
          ))}
        </ul>
        <div className="mb-3 mx-7 text-sm">
          <RichText value={copyButtonExplainText} />
        </div>
        <CtaSecondary
          type="button"
          className="mx-7 px-10"
          onClick={handleCopyButton}
        >
          {copyButtonText}
        </CtaSecondary>
      </section>

      <ModalMintNfts
        onNewTransaction={onNewTransaction}
        isOpen={isModalMintOpen}
        toggleModal={toggleModalMint}
        availableNfts={availableNfts!}
      />
    </>
  );
};
