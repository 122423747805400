import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import "../styles/global.css";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";
import { GlobalProvider } from "../components/providers";
import { WagmiSetup } from "../clients";
import { ScreenMessage } from "../components/screen-message/ScreenMessage";
import { getSelectorsByUserAgent } from "react-device-detect";
import RaleonScript from "../components/commons/RaleonScript";
import { CHAIN } from "../../app.config";
import { WithCSR } from "../components/ui";

interface Props extends AppProps { }

export default function DApp({ Component, pageProps }: Props): JSX.Element {
  return (
    <WithCSR>
      <Head>
        <title>Unlockd</title>
      </Head>
      {/* <Maintenance /> */}
      {pageProps.isMobileView ? (
        <ScreenMessage />
      ) : (
        <WagmiSetup>
          <SessionProvider session={pageProps.session} refetchInterval={0}>
            <GlobalProvider>
              {CHAIN === "mainnet" && <RaleonScript />}
              <div className="relative top-0 min-h-screen w-full">
                <BackgroundApp />
                <Component {...pageProps} />
              </div>
            </GlobalProvider>
          </SessionProvider>
        </WagmiSetup>
      )}
    </WithCSR>
  );
}

DApp.getInitialProps = async (context: AppContext) => {
  const props = await App.getInitialProps(context);

  if (context.ctx.req) {
    const userAgent: string = context.ctx.req!.headers["user-agent"] as string;

    const { isMobile } = getSelectorsByUserAgent(userAgent);

    const newProps = { ...props };

    newProps.pageProps = { ...props.pageProps, isMobileView: isMobile };

    return newProps;
  }
  return props;
};

const BackgroundApp = () => {
  return (
    <div className="w-screen h-screen fixed translate-y-[1px] bg-[#2b2653]">
      <div
        className="bg-[rgb(8,132,248)] w-2/5 h-5/6 blur-3xl opacity-10 absolute
  translate-y-24 translate-x-12 rounded-full"
      ></div>
      <div
        className="bg-[rgb(252,16,163)] w-5/12 h-4/5 blur-3xl opacity-10 absolute
  translate-y-8 right-6 rounded-full"
      ></div>
    </div>
  );
};
