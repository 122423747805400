import {isHexString} from "ethers/lib/utils";
import {FormatError} from "../../errors/errors";

export const validateTransactionHash = (transactionHash: string): void => {
  if (
    !(
      transactionHash.startsWith("0x") &&
      transactionHash.length === 66 &&
      isHexString(transactionHash)
    )
  ) {
    throw new FormatError("invalid transaction hash");
  }
};
