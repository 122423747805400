import { callContract, validateAddress } from "../utils";
import abi from "../abis/customERC721.json";
import { MAX_NFTS_FOR_A_USER, MILADY_COLLECTION } from "../../app.config";

export const getAvailableNftsForAddress = async (
  address: string
): Promise<{ availableNfts: number; hasAvailableNftsToMint: boolean }> => {
  validateAddress(address);
  const [balanceOfAddress, MAX_SUPPLY, totalSupply] = await Promise.all([
    callContract(MILADY_COLLECTION.address, abi, null, "balanceOf", [address]),
    callContract(MILADY_COLLECTION.address, abi, null, "MAX_SUPPLY"),
    callContract(MILADY_COLLECTION.address, abi, null, "totalSupply"),
  ]);

  const availableNftsForUser =
    (MAX_NFTS_FOR_A_USER as number) - balanceOfAddress;

  const availableNftsInContract = MAX_SUPPLY - totalSupply;

  const availableNfts =
    availableNftsInContract > availableNftsForUser
      ? availableNftsForUser
      : availableNftsInContract;

  return {
    availableNfts,
    hasAvailableNftsToMint: availableNfts > 0,
  };
};
