import { Signer } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/customERC721.json";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { isCryptopunks } from "./helpers";

export const approveCollection = async (
  collectionAddress: string,
  signer: Signer
): Promise<string> => {
  const transaction = await callContract(
    collectionAddress,
    abi,
    signer,
    "setApprovalForAll",
    [
      isCryptopunks(collectionAddress)
        ? CONTRACT_ADDRESSES.punkGateway
        : CONTRACT_ADDRESSES.wethGateway,
    ]
  );

  return transaction.hash;
};
