import { COLLECTIONS, CONTRACT_ADDRESSES } from "../../app.config";
import {
  DashboardUserData,
  MyActivityItemStatus,
  CallsGroupedByMethod,
  SingleCall,
} from "../types";
import { retrieveOwnerNfts } from "../utils";
import lendpoolAbi from "../abis/lendpoolUnlockd.json";
import { getNftCollectionAddressFromUBound } from "./helpers";
import { BigNumber } from "ethers";
import uTokenAbi from "../abis/uToken.json";
import { retrieveMyActivity } from "./retrieveMyActivity";
import { multicall } from "../utils/multicall";

const uBoundAddresses = COLLECTIONS.map(({ uBoundAddress }) => uBoundAddress);

export const getDashboardUserData = async (
  address: string
): Promise<DashboardUserData> => {
  const nftsList = await retrieveOwnerNfts(
    address,
    {
      contractAddresses: uBoundAddresses,
      pageSize: 100,
      omitMetadata: true,
    },
    true
  );

  const nftsDebtDataCalls = new CallsGroupedByMethod(
    CONTRACT_ADDRESSES.lendpool,
    lendpoolAbi,
    "getNftDebtData",
    []
  );

  const depositBalanceCall = new SingleCall(
    CONTRACT_ADDRESSES.uWeth,
    uTokenAbi,
    "balanceOf",
    [address]
  );

  const scaledBalanceOfCall = new SingleCall(
    CONTRACT_ADDRESSES.uWeth,
    uTokenAbi,
    "scaledBalanceOf",
    [address]
  );

  nftsList.forEach((nft) => {
    nftsDebtDataCalls.callsArguments.push([
      getNftCollectionAddressFromUBound(nft.contract.address),
      nft.tokenId,
    ]);
  });

  // @ts-ignore
  const [
    [nftsDebt, [totalSupplied], [scaledBalanceOf]],
    { items: myAuctions },
  ] = await Promise.all([
    multicall(nftsDebtDataCalls, depositBalanceCall, scaledBalanceOfCall),
    retrieveMyActivity(address, 0, 100),
  ]);

  // @ts-ignore
  const totalDebt = nftsDebt!.reduce((a, b) => {
    return a.add(b.totalDebt);
  }, BigNumber.from(0));

  let claimedAuctions: number = 0,
    onGoingAuctions: number = 0;

  myAuctions.forEach((auction) => {
    if (auction.status === MyActivityItemStatus.CLAIMED) {
      claimedAuctions += 1;
    } else if (auction.status === MyActivityItemStatus.ONGOING) {
      onGoingAuctions += 1;
    }
  });

  return {
    nftsDeposited: nftsList.length,
    totalDebt,
    totalSupplied,
    interestEarned: totalSupplied.sub(scaledBalanceOf),
    claimedAuctions,
    onGoingAuctions,
  };
};
