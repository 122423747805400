import {useSession} from "next-auth/react";
import {useAccount, useNetwork} from "wagmi";

export const useUnlockdSession = (): {
  isWalletConnected: boolean;
  isNetworkConnected: boolean;
  isLoggedIn: boolean;
  isReady: boolean;
  walletAddress: string;
} => {
  const { address } = useAccount();
  const { chain, chains } = useNetwork();
  const { data, status } = useSession();

  const isNetworkConnected = chains.some((_chain) => _chain.id === chain?.id)
    ? true
    : false;

  const isLoggedIn =
    status !== "authenticated" || data.address !== address ? false : true;

  return {
    isWalletConnected: address ? true : false,
    isNetworkConnected,
    isLoggedIn,
    isReady: isNetworkConnected && isLoggedIn ? true : false,
    walletAddress: address ?? "",
  };
};
