import { type FC, FormEvent, useEffect, useState } from "react";
import { MarketItem, MarketItemType, PortableText } from "../../../types";
import { ErrorMessage, Input, withModal } from "./shared";
import { IoMdClose } from "react-icons/io";
import { IconEthereum } from "../../ui/icons";
import { Anchor, CtaPrimary, CtaSecondary } from "../../ui";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { useAlertNotification, useLockeysHolderType } from "../../providers";
import {
  formatWeiToEth,
  getBalanceOfAddress,
  waitForTransactionReceipt,
} from "../../../utils";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  TransactionError,
  transactionErrorMessage,
} from "../../../errors/errors";
import {
  AiFillExclamationCircle,
  AiOutlineCheck,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { useUpdateEffect } from "react-use";
import { BigNumber } from "ethers";
import {
  changeToWrapIfCrypropunks,
  handleErrors,
} from "../../../logic/helpers";
import {
  approveLendpoolOnWeth,
  buyLoanAuctioned,
  buyLoanListed,
  getLendpoolAllowanceOnWeth,
} from "../../../logic";
import { approveInWalletLiteral } from "../../../literals";

type Props = {
  className?: string;
  marketItem: MarketItem;
  isOpen: boolean;
  toggleModal: (nextValue: boolean) => void;
  isRemoved: boolean;
  buyNowPrice: BigNumber;
  discount: number;
};

enum Status {
  TO_APPROVE,
  LOADING_APPROVE,
  TO_BUY,
  LOADING_BUY,
  SUCCESS,
  REDEEMED,
}

export const ModalBuyNow: FC<Props> = withModal((props) => {
  const {
    marketItem: {
      nfts: [{ tokenId, collection, name, image }],
      type,
    },
    toggleModal,
    isRemoved,
    buyNowPrice,
    discount,
  } = props;
  const { address } = useAccount();
  const [balance, setBalance] = useState<BigNumber>();
  const [error, setError] = useState<Error | null>(null);
  const { chains } = useNetwork();
  const chainName = chains[0]?.name;
  const [transaction, setTransaction] = useState<any>();
  const [status, setStatus] = useState<Status>(
    type === MarketItemType.LOAN_AUCTIONED ? Status.TO_APPROVE : Status.TO_BUY
  );
  const [ctaPrimaryText, setCtaPrimaryText] = useState<string>(
    type === MarketItemType.LOAN_AUCTIONED ? "Approve" : "Buy now"
  );
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();
  const [allowance, setAllowance] = useState<BigNumber>();
  const [isPrimaryButtonDisabled, setPrimaryButtonDisabled] = useState<boolean>(
    type === MarketItemType.LOAN_AUCTIONED
  );
  const { data: signer } = useSigner();

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(
        error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError ||
        error instanceof TransactionError
      )
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    } else if (error instanceof TransactionError) {
      openAlertNotification("error", transactionErrorMessage, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const balanceRetrieved = await getBalanceOfAddress(address);
          const allowance = await getLendpoolAllowanceOnWeth(address);

          setAllowance(allowance);
          setBalance(balanceRetrieved);
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  useEffect(() => {
    if (allowance && allowance.gte(buyNowPrice)) {
      setStatus(Status.TO_BUY);
    } else {
      setStatus(Status.TO_APPROVE);
    }
  }, [allowance]);

  useEffect(() => {
    if (status === Status.TO_APPROVE) {
      setCtaPrimaryText("Approve");
      setPrimaryButtonDisabled(false);
    } else if (status === Status.LOADING_APPROVE) {
      setPrimaryButtonDisabled(true);
    } else if (status === Status.TO_BUY) {
      setCtaPrimaryText("Buy now");
      setPrimaryButtonDisabled(false);
    } else if (status === Status.LOADING_BUY) {
      setPrimaryButtonDisabled(true);
    } else {
      setCtaPrimaryText("Loading...");
    }
  }, [status]);

  useUpdateEffect(() => {
    if (isRemoved && status === Status.TO_BUY) {
      openAlertNotification("info", removedLoanLiteral, 5000);

      setStatus(Status.REDEEMED);
    }
  }, [isRemoved]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (signer && address) {
      try {
        let txHash: string;

        openAlertNotification("info", approveInWalletLiteral, 50000);

        if (status === Status.TO_APPROVE) {
          await handleApprove();
        } else {
          if (type === MarketItemType.LOAN_LISTED) {
            txHash = await buyLoanListed(
              signer,
              changeToWrapIfCrypropunks(collection),
              tokenId,
              address,
              buyNowPrice
            );
          } else {
            txHash = await buyLoanAuctioned(
              signer,
              changeToWrapIfCrypropunks(collection),
              tokenId,
              buyNowPrice,
              address
            );
          }

          closeAlertNotification();
          setStatus(Status.LOADING_BUY);

          waitForTransactionReceipt(txHash, 2000, (receipt) => {
            if (receipt.status == 1) {
              openAlertNotification("success", succeedOnBuyLiteral, 5000);
              setStatus(Status.SUCCESS);
              setTransaction(txHash);
            } else if (receipt.status === 0) {
              setError(
                new TransactionError(
                  `transaction with hash ${receipt.transactionHash} failed`
                )
              );
              setStatus(Status.TO_BUY);
            }
          });
        }
      } catch (err) {
        closeAlertNotification();
        setError(handleErrors(err));
      }
    }
  };

  const handleApprove = async () => {
    const txHash = await approveLendpoolOnWeth(signer!, buyNowPrice);

    closeAlertNotification();
    setStatus(Status.LOADING_APPROVE);

    waitForTransactionReceipt(txHash, 2000, (receipt) => {
      if (receipt.status == 1) {
        openAlertNotification("success", succeedOnBuyLiteral, 5000);
        setAllowance(buyNowPrice);
      } else if (receipt.status === 0) {
        setError(
          new TransactionError(
            `transaction with hash ${receipt.transactionHash} failed`
          )
        );
        setStatus(Status.TO_BUY);
      }
    });
  };

  const handleCloseModal = () => {
    toggleModal(false);
  };

  return (
    <form
      className="bg-secondary relative border-glow-sm max-w-2xl overflow-hidden border-2 rounded-3xl xs:px-12 px-10 pb-6 pt-10 xs:pb-8 text-white flex flex-col items-center"
      onSubmit={handleSubmit}
    >
      <button
        type="button"
        className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
        onClick={handleCloseModal}
      >
        <IoMdClose className="w-full h-full" />
      </button>
      {status !== Status.SUCCESS && (
        <>
          <div className="w-full flex items-center">
            <div className="w-20 h-20 rounded-xl">
              <img className="rounded-xl" src={image} />
            </div>
            <h2 className="ml-4 font-bold text-2xl">
              {name}
              <br />#{tokenId}
            </h2>
          </div>
          <div className="mt-5 w-full border-t border-white"></div>
        </>
      )}

      {(status === Status.TO_BUY || status === Status.TO_APPROVE) && (
        <>
          <div className="mt-5 w-full flex justify-between">
            <div className="flex flex-col gap-8">
              <div className="flex items-center">
                <h3 className="text-2xl">Price</h3>&nbsp;
                <div className="flex items-center font-bold text-3xl">
                  <p>{formatWeiToEth(buyNowPrice)}</p>
                  <div className="bg-white ml-2 w-8 h-8 rounded-full">
                    <IconEthereum />
                  </div>
                </div>
              </div>
              {discount ? (
                <div className="flex gap-1">
                  <p className="text-xs">
                    Discount applied:{" "}
                    <span className="text-base text-[#8DF85B] font-bold">
                      - {discount}%
                    </span>
                  </p>
                  {/* <Info
                    direction="right"
                    description="You are currently a The Lockeys holder. Enjoy your 3% discount in all buyouts, among many other benefits!"
                  /> */}
                </div>
              ) : (
                <div className="flex gap-1">
                  <p className="text-xs">
                    Discount applied:{" "}
                    <span className="text-base text-[#FF4A3F] font-bold">
                      Eligible{" "}
                    </span>
                  </p>
                  {/* <Info
                    direction="right"
                    description="Holders of our The Lockeys collection have a 3% discount in all buyouts. Get yours now at https://www.gem.xyz/collection/the-lockeys/ and start enjoying multiple holders-only benefits!"
                  /> */}
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 w-full border-t border-white"></div>
          <Input
            className="mt-5 mb-10 w-[560px]"
            balance={balance}
            nameInput="buy"
            defaultValue={buyNowPrice}
            maxButtonHidden={true}
            readOnly={true}
          />
        </>
      )}

      {(status === Status.LOADING_BUY || status === Status.LOADING_APPROVE) && (
        <>
          <div className="w-full flex flex-col items-center">
            <p className="mt-5 font-bold">Don't close this screen.</p>
            <p className="mt-3">
              Your {status === Status.LOADING_BUY ? "purchase" : "approval"}{" "}
              should be confirmed
            </p>
            <p>in the blockchain shortly.</p>
          </div>
          <div className="flex justify-center items-center my-10">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        </>
      )}

      {(error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError) && (
        <ErrorMessage className="px-4">{error.message}</ErrorMessage>
      )}

      {status !== Status.SUCCESS && status !== Status.REDEEMED && (
        <div className="flex justify-between gap-4">
          <CtaSecondary className="w-40" onClick={handleCloseModal}>
            Back
          </CtaSecondary>
          <CtaPrimary className="w-40" disabled={isPrimaryButtonDisabled}>
            {ctaPrimaryText}
          </CtaPrimary>
        </div>
      )}

      {status === Status.SUCCESS && (
        <>
          <div className="mt-5 flex w-96 flex-col items-center justify-center mx-auto xs:mb-8 mb-5">
            <div className="border-white border-2 rounded-full mb-4">
              <AiOutlineCheck className="w-8 h-8 m-4" />
            </div>
            <span className="mb-6">Transaction submitted</span>
            <CtaSecondary>
              <Anchor
                href={`https://${
                  chainName === "Goerli" ? chainName + "." : ""
                }etherscan.io/tx/${transaction}`}
                target="_blank"
                title="Etherscan"
                rel="noopener noreferrer"
                className="font-bold px-5"
              >
                See your transaction on Etherscan
              </Anchor>
            </CtaSecondary>
          </div>
          <div className="flex justify-center gap-5 w-full">
            <CtaPrimary
              onClick={handleCloseModal}
              className="w-full"
              type="button"
            >
              Close
            </CtaPrimary>
          </div>
        </>
      )}
      {status === Status.REDEEMED && (
        <>
          <div className="mt-5 flex w-96 flex-col items-center justify-center mx-auto xs:mb-8 mb-5">
            <AiFillExclamationCircle className="w-16 h-16 m-4" />
            <span>This auction was just redeemed by its owner</span>
          </div>
          <div className="flex justify-center gap-5 w-full">
            <CtaPrimary
              onClick={handleCloseModal}
              className="w-full"
              type="button"
            >
              Close
            </CtaPrimary>
          </div>
        </>
      )}
    </form>
  );
});

const removedLoanLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "This loan was just recovered from their owner or bought by someone else",
      },
    ],
  },
];

const succeedOnBuyLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "You transaction was successfully completed",
      },
    ],
  },
];
