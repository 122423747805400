import { CONTRACT_ADDRESSES, YEARN } from "../../../app.config";
import { ConnectionError } from "../../errors";
import { equalIgnoreCase } from "../../utils";

export const getYearnApy = async (): Promise<number> => {
  const yearnResponse = await fetch(
    `api/proxy/${YEARN.apiUrl}/vaults/all`
  );

  if (!yearnResponse.ok) {
    throw new ConnectionError("failed fetching apy data from yearn");
  }
  const yearn = await yearnResponse.json();

  const {
    apy: { net_apy: yearnApy },
    // @ts-ignore
  } = yearn.find(({ address }) =>
    equalIgnoreCase(address, CONTRACT_ADDRESSES.yearn)
  );

  return yearnApy as number;
};
