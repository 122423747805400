import classNames from "classnames";
import { FC } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

type Props = {
  className?: string;
  description: string;
  direction: "right" | "top";
};

export const Info: FC<Props> = (props) => {
  const { className, description, direction } = props;
  const directionStyles =
    direction === "right" ? "-mt-10 ml-7" : "-ml-40 mb-2 bottom-full -left-1/2";

  return (
    <div className="group cursor-pointer relative text-center">
      <div className="text-xl ml-1 text-white">
        <AiOutlineQuestionCircle />
      </div>
      <div
        className={classNames(
          "opacity-0 font-normal  bg-secondary text-white border border-white text-center text-xs rounded-lg py-2 fixed z-10 group-hover:opacity-100  px-3 pointer-events-none",
          directionStyles,
          className
        )}
      >
        {description}
      </div>
    </div>
  );
};
