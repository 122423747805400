import {type PanelWalletStatics} from "../../../../types";

export const usePanelWalletStatics = () => {
  const statics: PanelWalletStatics = {
    networkLabel: "Network",
    copyAddress: "Copy Address",
    viewExplorer: "View on explorer",
    discconectWallet: "Disconnect wallet",
  };

  return statics;
};
