import { CONTRACT_ADDRESSES } from "../../../app.config";
import { isCryptopunks } from "./isCryptopunks";

export const getContractAddressToInteractWith = (
  collectionAddress: string
): string => {
  if (isCryptopunks(collectionAddress)) {
    return CONTRACT_ADDRESSES.punkGateway;
  } else {
    return CONTRACT_ADDRESSES.wethGateway;
  }
};
