import {BytesLike} from "ethers";
import abi from "../../abis/lendpoolLoan.json";
import {decodeTransactionData} from "../../utils";

export const decodeLoanAuctionedLogData = (
  data: BytesLike,
  topics: string[]
) => {
  return decodeTransactionData(abi, "LoanAuctioned", data, topics);
};
