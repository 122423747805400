import {getTransactionReceipt} from "./getTransactionReceipt";
import {
    TransactionReceipt
} from ".pnpm/@ethersproject+abstract-provider@5.7.0/node_modules/@ethersproject/abstract-provider/src.ts";

export const waitForTransactionReceipt = async (
  transactionHash: string,
  ms: number,
  callback: (arg: TransactionReceipt) => void
): Promise<void> => {
  const receipt = await getTransactionReceipt(transactionHash);

  if (!receipt) {
    setTimeout(async () => {
      await waitForTransactionReceipt(transactionHash, ms, callback);
    }, ms);
  } else {
    callback(receipt);
  }
};
