import { ConnectionError } from "../errors/errors";
import { ALCHEMY, CHAIN, PUNKS_COLLECTION } from "../../app.config";
import { isCryptopunks } from "../logic/helpers";
import { equalIgnoreCase } from "./strings";

export type NftMetadata = {
  contract: {
    address: string;
  };
  id: {
    tokenId: string;
    tokenMetadata: {};
  };
  balance: string;
  title: string;
  description: string;
  tokenUri: {
    raw: string;
    gateway: string;
  };
  media: Array<{
    raw: string;
    gateway: string;
  }>;
  metadata: {
    image: string;
    name: string;
  };
};

export const getNftsMetadataBatch = async (
  tokens: Array<{ contractAddress: string; tokenId: number }>,
  options?: { maxRetries: number }
): Promise<Array<NftMetadata>> => {
  const url = `${ALCHEMY.apiUrl}/${
    typeof window ? ALCHEMY.apiKey : process.env.SERVER_ALCHEMY_KEY
  }/getNFTMetadataBatch`;

  if (CHAIN === "goerli") {
    tokens = tokens.map(({ contractAddress, tokenId }) => {
      if (isCryptopunks(contractAddress)) {
        return { contractAddress: PUNKS_COLLECTION.wrapAddress!, tokenId };
      } else {
        return { contractAddress, tokenId };
      }
    });
  }

  const body = {
    tokens,
    tokenUriTimeoutInMs: 500,
    refreshCache: true,
  };

  const data = await (async function call(
    retriesCount: number
  ): Promise<Array<any>> {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "content-type": "application/json",
      },
    });

    if (response.ok) {
      const data: Array<NftMetadata> = await response.json();

      return tokens.map(({ contractAddress, tokenId }) => {
        const item = data.find(
          (nft) =>
            equalIgnoreCase(nft.contract.address, contractAddress) &&
            tokenId === parseInt(nft.id.tokenId)
        );

        return item;
      });
    }

    if (response.status > 499 && retriesCount < (options?.maxRetries || 3)) {
      return call(retriesCount + 1);
    }

    throw new ConnectionError("failed fetching nfts metadata from alchemy");
  })(1);

  return data;
};
