import { PUNKS_COLLECTION } from "../../../app.config";
import { isCryptopunks } from "./isCryptopunks";

export const determineCollectionAddressToSetValuation = (
  collectionAddress: string,
  isDeposited: boolean
): string => {
  return isCryptopunks(collectionAddress) && isDeposited
    ? PUNKS_COLLECTION.wrapAddress!
    : collectionAddress;
};
