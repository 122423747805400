import classNames from "classnames";
import {type FC} from "react";

interface Props {
  className?: string;
}

export const ErrorMessage: FC<Props> = (props) => {
  const { className, children } = props;
  return (
    <div
      className={classNames(
        `bg-white py-1 xs:py-1 mx-auto rounded-lg
      border-2 text-center text-red-600 text-xs border-red-600`,
        [className]
      )}
    >
      <span className="">{children}</span>
    </div>
  );
};
