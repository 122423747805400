import { useEffect } from "react";
import { useUnlockdSession } from "../../logic";
import { RALEON_ORG_ID } from "../../../app.config"

declare var setupRaleonParameters: any;
declare var raleon: any;

const RaleonScript = () => {
  const { walletAddress } = useUnlockdSession();

  useEffect(() => {
    const script = document.createElement("script");
    script.onload = function () {
      setupRaleonParameters(RALEON_ORG_ID);

      handleReportWalletConnected();
    };
    script.src = "https://dqpqjbq51w8fz.cloudfront.net/raleon_snippet.min.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  useEffect(() => {
    handleReportWalletConnected();
  }, [walletAddress]);

  const handleReportWalletConnected = () => {
    if (typeof raleon !== "undefined" && walletAddress) {
      try {
        raleon.walletConnected(walletAddress);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return null;
};
export default RaleonScript;
