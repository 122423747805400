import {BigNumber} from "ethers";
import {percentMul, rayMul} from "../../utils";

export const calculateAvailableBorrow = (
  price: BigNumber,
  ltv: BigNumber,
  scaledAmount: BigNumber,
  normalizedDebt: BigNumber
): BigNumber => {
  const totalAvailable = percentMul(price, ltv);

  const totalDebtInReserve = rayMul(scaledAmount, normalizedDebt);

  const availableToBorrow = totalAvailable.sub(totalDebtInReserve);

  return availableToBorrow;
};
