import { type FC } from "react";
import classNames from "classnames";
import { NftBorrowData } from "../../../../types";
import { MyLoan } from "./MyLoan";
import { EmptyLoan } from "./EmptyLoan";
import {
  AiOutlineLoading3Quarters,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { healthFactorDescription } from "../../../../literals";

interface Props {
  className?: string;
  loans: Array<NftBorrowData>;
  onBorrowClick: (nft: NftBorrowData) => void;
  onModalOpen: (collectionAddres: string, tokenId: number) => void;
  onModalClose: () => void;
  isLoading: boolean;
}

export const MyLoans: FC<Props> = (props) => {
  const {
    className,
    onBorrowClick,
    isLoading,
    loans,
    onModalClose,
    onModalOpen,
  } = props;

  return (
    <>
      <section
        className={classNames(
          "overflow-x-auto max-w-max mx-auto p-4 relative bg-secondary rounded-xl shadow-lighting",
          [className]
        )}
      >
        <div className="flex mb-8">
          <h2 className="flex pt-2 text-xl ml-2 font-bold">My Loans</h2>
        </div>

        <table className="w-full text-base text-left text-white">
          <thead className="text-md">
            <tr>
              <th scope="col" className="py-3 px-3">
                Deposited NFT
              </th>
              <th scope="col" className="py-3 px-3">
                Valuation
              </th>
              <th scope="col" className="py-3 px-3">
                <span className="block">Liquidation</span>
                <span>price</span>
              </th>
              <th scope="col" className="py-3 flex flex-col px-3 pt-3">
                <span>Health</span>
                <div className="flex">
                  <span>factor</span>
                  <div className="group cursor-pointer relative text-center">
                    <div className="text-xl ml-1 text-white">
                      <AiOutlineQuestionCircle />
                    </div>
                    <div className="opacity-0 w-[400px] font-normal -ml-40 mb-2 bg-secondary text-white border border-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 px-3 pointer-events-none">
                      {healthFactorDescription}
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" className="py-3 px-3">
                <span className="block">Interest</span>
                <span>rate</span>
              </th>
              <th scope="col" className="py-3 px-3">
                <span className="block">Borrowed</span>
                <span>amount</span>
              </th>
              <th scope="col" className="py-3 px-3">
                <span className="block">Available to </span>
                <span>borrow</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loans.length ? (
              loans.map((loan) => (
                <MyLoan
                  key={loan.collection + loan.tokenId}
                  loan={loan}
                  onBorrowClick={onBorrowClick}
                  onModalOpen={onModalOpen}
                  onModalClose={onModalClose}
                />
              ))
            ) : (
              <EmptyLoan />
            )}
          </tbody>
        </table>
        {isLoading && (
          <div className="w-full flex justify-center py-4">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        )}
      </section>
    </>
  );
};
