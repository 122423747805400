import { type FC, type FormEvent, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { CtaPrimary, RichText } from "../../../ui";
import { Selector } from "./Selector";
import { useAccount, useSigner } from "wagmi";
import { mintFaucet } from "../../../../logic";
import { modalMintNftsStatics } from "../../../statics";
import { useUpdateEffect } from "react-use";
import { ErrorMessage, withModal } from "../shared";
import { useAlertNotification } from "../../../providers";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  UnexpectedError,
  UserDeniedTransactionSignatureError,
} from "../../../../errors/errors";
import { approveInWalletLiteral } from "../../../../literals";

interface Props {
  isOpen?: boolean;
  availableNfts: number;
  toggleModal: (nextValue?: boolean) => void;
  onNewTransaction: (txHash: string) => void;
}

export const ModalMintNfts: FC<Props> = withModal(
  ({ toggleModal, onNewTransaction, availableNfts }) => {
    const { feedbacks, ...restStatics } = modalMintNftsStatics();

    const [amount, setAmount] = useState<number | null>(null);
    const [txHash, setTxHash] = useState<string | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const { address } = useAccount();

    const { data: signer } = useSigner();

    const [
      isAlertNotificationOpen,
      openAlertNotification,
      closeAlertNotification,
    ] = useAlertNotification();

    const handleChangeSelector = (amountSelected: number): void => {
      setAmount(amountSelected);

      if (error instanceof LimitMinError && amountSelected > 0) {
        setError(null);
      }
    };

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault();

      if (address && amount) {
        (async () => {
          try {
            openAlertNotification("info", approveInWalletLiteral, 50000);

            // @ts-ignore signer provided by wagmi does not matches with ethers type
            const hash = await mintFaucet(signer, address, amount);

            closeAlertNotification();
            setTxHash(hash);
            setAmount(null);
          } catch (err) {
            closeAlertNotification();
            if (err instanceof LimitMaxError) {
              err.message = feedbacks.limitMinError;

              setError(err);
            } else if (err instanceof LimitMaxError) {
              err.message = feedbacks.limitMaxError;

              setError(err);
            } else if (err instanceof GasLimitError) {
              err.message = feedbacks.notEnoughEthForGas;

              setError(err);

              // @ts-ignore
            } else if (err.code === 4001 || err.code === -32000) {
              setError(
                new UserDeniedTransactionSignatureError(
                  "user denied transaction signature"
                )
              );
            } else {
              setError(
                new UnexpectedError("mint faucet signature unexpected error")
              );
            }
          }
        })();
      } else if (amount === 0) {
        setError(new LimitMinError(feedbacks.limitMinError));
      }
    };

    useUpdateEffect(() => {
      if (txHash) {
        onNewTransaction(txHash);

        toggleModal(false);
      }
    }, [txHash]);

    useUpdateEffect(() => {
      setTxHash(null);
      setError(null);
    }, [address]);

    useUpdateEffect(() => {
      if (error && !isAlertNotificationOpen) {
        if (error instanceof UserDeniedTransactionSignatureError) {
          openAlertNotification(
            "error",
            feedbacks.userDeniedTransactionSignatureMetamaskError,
            5000
          );
        } else if (
          !(
            error instanceof LimitMinError ||
            error instanceof LimitMaxError ||
            error instanceof GasLimitError
          )
        ) {
          openAlertNotification("error", feedbacks.unexpectedError, 5000);
        }
      }
    }, [error]);

    const { legalText, mintButton, title, selector } = restStatics;

    return (
      <>
        <div
          className="bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] overflow-hidden
           border-2 rounded-3xl xs:px-12 px-8 pb-6 pt-8 xs:pb-8 w-96 text-white"
        >
          <button
            className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
            onClick={() => toggleModal(false)}
          >
            <IoMdClose className="w-full h-full" />
          </button>
          <h5 className="xs:mb-5 mb-3 text-center text-base xs:text-lg font-bold">
            {title}
          </h5>
          <div className="w-80 text-center text-xs xs:text-sm max-w-full xs:mb-5 mb-3">
            <RichText value={legalText} />
          </div>
          <form onSubmit={handleSubmit}>
            <Selector
              onChange={handleChangeSelector}
              max={availableNfts}
              className="xs:mb-8 mb-5"
              nameInput="totalMint"
              statics={selector}
            />
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            <CtaPrimary className="w-full" type="submit">
              {mintButton}
            </CtaPrimary>
          </form>
        </div>
      </>
    );
  }
);
