import { callContract } from "../utils";
import abiDataProvider from "../abis/unlockdProtocolDataProvider.json";
import abiLendPool from "../abis/lendpoolUnlockd.json";
import { NFTConfigurationDataType } from "../types";
import { isCryptopunks } from "./helpers";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { getCryptopunksWrapAddress } from "./helpers/getCryptopunksWrapAddress";

export const getIsValuationApproved = async (
  collectionAddress: string,
  tokenId: number
): Promise<boolean> => {
  const collectionAddressCorrected = isCryptopunks(collectionAddress)
    ? getCryptopunksWrapAddress()
    : collectionAddress;

  const nftConfigurationData: NFTConfigurationDataType = await callContract(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    abiDataProvider,
    null,
    "getNftConfigurationDataByTokenId",
    [collectionAddressCorrected, tokenId]
  );

  const timeframe = await callContract(
    CONTRACT_ADDRESSES.lendpool,
    abiLendPool,
    null,
    "getTimeframe",
    []
  );
  const currentTimestamp = Math.floor(Date.now() / 1000); //we want to compare it in seconds!!

  if (
    nftConfigurationData.configTimestamp.add(timeframe).lt(currentTimestamp)
  ) {
    return false;
  }

  return true;
};
