import { equalIgnoreCase } from "../../utils";
import { COLLECTIONS } from "../../../app.config";

export const getTotalSupplyFromCollection = (
  collectionAddress: string
): number => {
  const collection = COLLECTIONS.find(({ address }) =>
    equalIgnoreCase(address, collectionAddress)
  );

  if (!collection) {
    throw new Error("collection not supported");
  }

  return collection.totalSupply;
};
