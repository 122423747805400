import type { HeaderStatics } from "../../types/HeaderStatics";
import { FUNCTIONALITIES } from "../../../app.config";

export const useHeaderStatics = () => {
  const data: HeaderStatics = {
    id: "header",
    logo: {
      href: "/dashboard",
      target: "_self",
      title: "Unlockd",
      rel: "bookmark",
      children: {
        asset: "/assets/images/logo-unlockd.png",
        alt: "Unlockd",
      },
    },
    navigationElements: [
      {
        id: "dashboard",
        href: "/dashboard",
        target: "_self",
        title: "dashboard",
        rel: "bookmark",
        children: "Dashboard",
      },
      {
        id: "earn",
        href: "/earn",
        target: "_self",
        title: "earn",
        rel: "bookmark",
        children: "Earn",
      },
      {
        id: "borrow",
        href: "/borrow",
        target: "_self",
        title: "borrow",
        rel: "bookmark",
        children: "Borrow",
      },
      {
        id: "marketplace",
        href: "/marketplace",
        target: "_self",
        title: "marketplace",
        rel: "bookmark",
        children: "Marketplace",
      },
    ],
  };

  if (FUNCTIONALITIES.some(({ id, enabled }) => id === "faucet" && enabled))
    data.navigationElements.push({
      id: "faucet",
      href: "/faucet",
      target: "_self",
      title: "faucet",
      rel: "bookmark",
      children: "Faucet",
    });

  return data;
};
