import { BigNumber } from "ethers";
import { RAY /* SECONDS_PER_YEAR */ } from "../constants";

export const calculateApyEarn = (
  liquidityRate: BigNumber,
  utilizationRate: number,
  yearnApy: number,
  borrowApy: number
): number => {
  //@ts-ignore
  const aprNumber = Number(liquidityRate) / RAY;
  /* const unlockdApy =
    (aprNumber / parseInt(SECONDS_PER_YEAR) + 1) ** parseInt(SECONDS_PER_YEAR) -
    1; */
  return yearnApy * (1 - utilizationRate) + borrowApy * utilizationRate;
};
