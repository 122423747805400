import { FC } from "react";
import { CtaPrimary, CtaSecondary } from "../../../ui";
import { IconEthereum } from "../../../ui/icons";

type Props = {
  className?: string;
};

export const EmptyLoan: FC<Props> = () => {
  return (
    <tr className="bg-secondary text-base font-medium px-8">
      <td scope="row" className="flex py-6 px-8 font-medium">
        <div className="flex gap-3 items-center">
          <div className="w-14 h-14 border-2 border-dashed rounded-xl"></div>
        </div>
      </td>
      <td className="py-4 px-8">
        <div className="flex gap-x-2 items-center">
          ----
          <div className="w-5 h-5 bg-white rounded-full">
            <IconEthereum />
          </div>
        </div>
      </td>
      <td className="py-4 px-8">
        <div className="flex gap-x-2 items-center">
          ----
          <div className="w-5 h-5 bg-white rounded-full">
            <IconEthereum />
          </div>
        </div>
      </td>
      <td className="py-4 px-8">---</td>
      <td className="py-4 px-8">---- %</td>
      <td className="py-4 px-8">
        <div className="flex gap-x-2 items-center">
          ----
          <div className="w-5 h-5 bg-white rounded-full">
            <IconEthereum />
          </div>
        </div>
      </td>
      <td className="py-4 px-8">
        <div className="flex gap-x-2 items-center">
          ----
          <div className="w-5 h-5 bg-white rounded-full">
            <IconEthereum />
          </div>
        </div>
      </td>
      <td className="px-8">
        <div className="flex gap-x-5">
          <CtaSecondary className="w-32" disabled={true}>
            List for Sale
          </CtaSecondary>
          <CtaSecondary className="w-32" disabled={true}>
            Borrow
          </CtaSecondary>
          <CtaPrimary className="w-32" disabled={true}>
            Repay
          </CtaPrimary>
        </div>
      </td>
    </tr>
  );
};
