import classNames from "classnames";
import {ComponentProps, type ElementType} from "react";
import {AiOutlineCheck, AiOutlineLoading3Quarters} from "react-icons/ai";

type Component = <As extends ElementType = "button">(
  props: ComponentProps<As> & {
    as?: As;
    size?: "xs" | "sm" | "lg";
    isApproving: boolean;
  }
) => JSX.Element;

export const Approved: Component = (props) => {
  const {
    children,
    as,
    className,
    size = "xs",
    isApproving,
    ...restProps
  } = props;
  const Component = as ?? "div";

  return (
    <Component
      {...restProps}
      className={classNames(
        `bg-[rgba(30,20,60,0.8)] border-solid border-2 border-primary ${
          isApproving
            ? "border-blue-400 focus-visible:border-blue"
            : "border-green-400 focus-visible:border-green"
        } 
        font-bold rounded-full active:contrast-125
        box-border block text-center break-words transition-colors duration-75 disabled`,
        {
          "text-2xs md:text-xs px-3 py-1.5": size === "xs",
          "text-xs md:text-sm px-3 max-3xs:px-2 py-2": size === "sm",
          "text-sm md:text-base px-3 max-3xs:px-2 py-2": size === "lg",
        },
        [className]
      )}
    >
      {isApproving ? (
        <div className="flex justify-center items-center max-w-max">
          <AiOutlineLoading3Quarters className="animate-spin w-5 h-5 mr-3" />
          {children}
        </div>
      ) : (
        <div className="flex justify-center">
          <AiOutlineCheck className="mr-3 text-lg fill-green-400" />
          {children}
        </div>
      )}
    </Component>
  );
};
