import { Log } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEventV2,
} from "../../utils";
import abi from "../../abis/gnosisSafe.json";
import { BigNumber } from "ethers";

type SafeMultiSigTxData = {
  additionalInfo: string;
  baseGas: BigNumber;
  data: string;
  gasPrice: BigNumber;
  gasToken: string;
  operation: number;
  refundReceiver: string;
  safeTxGas: BigNumber;
  signatures: string;
  to: string;
  value: BigNumber;
};

export const setSafeMultiSigTxListener = async (
  safeSmartAccountAddress: string,
  callback: (data: SafeMultiSigTxData) => void
): Promise<() => void> => {
  const unlistener = await listenContractEventV2(
    // @ts-ignore
    getEventSignature(abi, "SafeMultiSigTransaction"),
    safeSmartAccountAddress,
    [],
    ({ data, topics }: Log) => {
      //@ts-ignore
      const dataDecoded = decodeTransactionData(
        abi,
        "SafeMultiSigTransaction",
        data,
        topics
      );

      callback(dataDecoded as unknown as SafeMultiSigTxData);
    }
  );

  return unlistener;
};
