import { BigNumber, Signer } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/wethGateway.json";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const withdraw = async (
  signer: Signer,
  amount: BigNumber,
  address: string
) => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.wethGateway,
    abi,
    signer,
    "withdrawETH",
    [amount, address]
  );

  return transaction.hash;
};
