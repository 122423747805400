import classNames from "classnames";
import { type FC, useEffect, useState } from "react";
import { useAccount, useSigner } from "wagmi";
import { useAlertNotification } from "../providers";
import {
  approvePunkGatewayForAllInWrappedPunks,
  claimAuctionWon,
  claimLoanListedAuction,
} from "../../logic";
import {
  MarketItemType,
  MyActivityItem,
  MyActivityItemStatus,
  PortableText,
} from "../../types";
import { CtaPrimary } from "../ui";
import { IconEthereum } from "../ui/icons";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  TransactionError,
  transactionErrorMessage,
} from "../../errors/errors";
import {
  equalIgnoreCase,
  formatWeiToEth,
  parseCompleteDateToRender,
  parseMsToRender,
  parseMsToRenderMinAndSec,
  waitForTransactionReceipt,
} from "../../utils";
import { useWeb3ModalGuard } from "../commons/modals/shared";
import {
  changeToWrapIfCrypropunks,
  handleErrors,
  isCryptopunks,
} from "../../logic/helpers";
import { approveInWalletLiteral } from "../../literals";
import { setLiquidatedReservoirListener } from "../../logic/listeners/setLiquidatedReservoirListener";

enum PunkStatus {
  TO_APPROVE,
  LOADING_APPROVE,
  APPROVED,
}

type Props = {
  className?: string;
  item: MyActivityItem;
  isWrapPunkApproved?: boolean;
};

export const MyActiviyItemComponent: FC<Props> = (props) => {
  const {
    className,
    isWrapPunkApproved,
    item: {
      nfts: [{ image, name, collection, tokenId }],
      soldPrice,
      wasSold,
      latestBid,
      date,
      currentAmount,
      status: statusProp,
      type,
    },
  } = props;
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<MyActivityItemStatus>(statusProp);
  const [claimButtonText, setClaimButtonText] = useState<string>();
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();
  const [intervalId, setIntervalId] = useState<any>();
  const [time, setTime] = useState<number | null>();
  const [claimTime, setClaimTime] = useState<number | null>();
  const [claimIntervalId, setClaimIntervalId] = useState<any>();
  const [punkStatus, setPunkStatus] = useState<PunkStatus>();
  const launcherClaim = useWeb3ModalGuard(undefined, handleClaim);

  const priceToShow = wasSold ? soldPrice : latestBid;

  const stylesStatus =
    status === MyActivityItemStatus.ONGOING
      ? "text-[#27E723] font-bold"
      : status === MyActivityItemStatus.BID_FAILED
      ? "text-[#FF9B3F]"
      : status === MyActivityItemStatus.LOST ||
        status === MyActivityItemStatus.REDEEMED ||
        status === MyActivityItemStatus.CANCELLED
      ? "text-[#FF4A3F]"
      : "text-white";

  const statusText =
    status === MyActivityItemStatus.ONGOING
      ? "Winning"
      : status === MyActivityItemStatus.BID_FAILED
      ? "Failed bid"
      : status === MyActivityItemStatus.LOST
      ? "Auction lost"
      : status === MyActivityItemStatus.SOLD
      ? "Sold"
      : status === MyActivityItemStatus.REDEEMED
      ? "Redeemed"
      : status === MyActivityItemStatus.CANCELLED
      ? "Cancelled"
      : "Claimed";

  const cardTypeTitle =
    type === MarketItemType.LOAN_AUCTIONED ? "NFT" : "NFT with Debt";

  const cardTitleBorder =
    type === MarketItemType.LOAN_AUCTIONED
      ? "border-tertiary"
      : "border-quaternary";

  const dateToRender = date ? parseCompleteDateToRender(date) : "--/--/----";

  const claimTimeToRender = claimTime && parseMsToRenderMinAndSec(claimTime);

  const timeToFinish = time
    ? parseMsToRender(time)
    : time === null
    ? "Finished"
    : "";

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(
        error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError ||
        error instanceof TransactionError
      )
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    } else if (error instanceof TransactionError) {
      // @ts-ignore
      openAlertNotification("error", transactionErrorMessage, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (
      status === MyActivityItemStatus.CLAIM ||
      status === MyActivityItemStatus.LOADING ||
      status === MyActivityItemStatus.CLAIMED ||
      status === MyActivityItemStatus.WAITING_FOR_BOT_TIME
    ) {
      if (punkStatus !== undefined) {
        setClaimButtonText(
          punkStatus === PunkStatus.TO_APPROVE
            ? "Approve to claim"
            : punkStatus === PunkStatus.LOADING_APPROVE ||
              status === MyActivityItemStatus.LOADING
            ? "Loading..."
            : "Claim"
        );
      } else {
        setClaimButtonText(
          status === MyActivityItemStatus.CLAIM ||
            status === MyActivityItemStatus.WAITING_FOR_BOT_TIME
            ? "Claim"
            : "Loading..."
        );

        if (status === MyActivityItemStatus.WAITING_FOR_BOT_TIME) {
          setClaimIntervalProcess();
        }
      }
    }
  }, [punkStatus, status]);

  useEffect(() => {
    if (isWrapPunkApproved !== undefined) {
      setPunkStatus(
        isWrapPunkApproved ? PunkStatus.APPROVED : PunkStatus.TO_APPROVE
      );
    }
  }, [isWrapPunkApproved]);

  useEffect(() => {
    if (
      (status === MyActivityItemStatus.BID_FAILED ||
        status === MyActivityItemStatus.ONGOING) &&
      date
    ) {
      clearInterval(intervalId);

      const id = setInterval(() => {
        setTime((time) => {
          if (time !== undefined && time! < 1000) {
            clearInterval(id);
            return null;
          } else if (time === undefined || time! >= 1000) {
            if (date.getTime() - Date.now() > 1000) {
              return date.getTime() - Date.now();
            } else {
              return null;
            }
          }
        });
      }, 1000);

      setIntervalId(id);
    }

    let reservoirUnlistener: Promise<() => void>;

    if (status === MyActivityItemStatus.WAITING_FOR_BOT_TIME) {
      const { id, reservoirUnlistener: _reservoirUnlistener } =
        setClaimIntervalProcess();

      setClaimIntervalId(id);
      reservoirUnlistener = _reservoirUnlistener;
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(claimIntervalId);

      if (reservoirUnlistener) {
        (async () => {
          const unlistener = await reservoirUnlistener;

          unlistener();
        })();
      }
    };
  }, [address]);

  async function handleClaim() {
    if (signer) {
      try {
        let txHash: string;

        openAlertNotification("info", approveInWalletLiteral, 50000);

        if (type === MarketItemType.LOAN_AUCTIONED) {
          txHash = await claimAuctionWon(
            signer,
            changeToWrapIfCrypropunks(collection),
            tokenId,
            currentAmount!,
            priceToShow!
          );
        } else {
          txHash = await claimLoanListedAuction(
            signer,
            changeToWrapIfCrypropunks(collection),
            tokenId,
            address!
          );
        }

        closeAlertNotification();
        setStatus(MyActivityItemStatus.LOADING);

        if (!isAlertNotificationOpen) {
          openAlertNotification("loading", feedbackLoading, 3000);
        }

        waitForTransactionReceipt(txHash, 5000, (receipt) => {
          if (receipt.status == 1) {
            setStatus(MyActivityItemStatus.CLAIMED);

            if (!isAlertNotificationOpen) {
              openAlertNotification("success", feedbackClaimed, 3000);
            }
          } else if (receipt.status === 0) {
            setError(
              new TransactionError(
                `transaction with hash ${receipt.transactionHash} failed`
              )
            );

            setStatus(MyActivityItemStatus.CLAIM);
          }
        });
      } catch (err) {
        closeAlertNotification();
        setError(handleErrors(err));
      }
    }
  }

  const setClaimIntervalProcess = () => {
    const reservoirUnlistener = setLiquidatedReservoirListener(
      ({ nftAsset, tokenId: _tokenId }) => {
        if (equalIgnoreCase(nftAsset, collection) && tokenId === tokenId) {
          setStatus(MyActivityItemStatus.LOST);
        }
      }
    );

    const id = setInterval(() => {
      setClaimTime((claimTime: any) => {
        if (claimTime !== undefined && claimTime! < 1000) {
          clearInterval(id);
          setStatus((currentStatus) =>
            currentStatus !== MyActivityItemStatus.LOST
              ? MyActivityItemStatus.CLAIM
              : currentStatus
          );

          (async () => {
            const unlistener = await reservoirUnlistener;
            unlistener();
          })();
        } else if (claimTime === undefined || claimTime! >= 1000) {
          const claimTimeEnd = date!.getTime() + 1000 * 60 * 20;

          if (claimTimeEnd - Date.now() > 1000) {
            return claimTimeEnd - Date.now();
          } else {
            return null;
          }
        }
      });
    }, 1000);
    setClaimIntervalId(id);

    return { id, reservoirUnlistener };
  };

  const handleApprovePunk = async (): Promise<void> => {
    try {
      openAlertNotification("info", approveInWalletLiteral, 50000);

      const transactionHash: string =
        await approvePunkGatewayForAllInWrappedPunks(signer!);

      closeAlertNotification();
      setPunkStatus(PunkStatus.LOADING_APPROVE);

      openAlertNotification("loading", richTextValueLoadingApprove, 5000);

      waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
        if (receipt.status == 1) {
          openAlertNotification("success", richTextApprovedAll, 5000);

          setPunkStatus(PunkStatus.APPROVED);
        } else if (receipt.status === 0) {
          setError(
            new TransactionError(
              `transaction with hash ${receipt.transactionHash} failed`
            )
          );

          setPunkStatus(PunkStatus.TO_APPROVE);
        }
      });
    } catch (err) {
      closeAlertNotification();
      setError(handleErrors(err));
    }
  };

  const handleClaimOrApproveButton = () => {
    if (isCryptopunks(collection) && punkStatus !== PunkStatus.APPROVED) {
      handleApprovePunk();
    } else {
      launcherClaim();
    }
  };

  return (
    <tr
      key={tokenId}
      className={classNames(
        "py-2 bg-secondary hover:bg-white hover:bg-opacity-50 hover:text-black text-base font-medium",
        className
      )}
    >
      <td scope="row" className="flex py-3 px-8 font-medium">
        <div className="flex w-fit gap-3 items-center">
          <div className="flex w-14 items-center">
            <img className="rounded-xl" src={image} alt="" />
          </div>
          <div className="flex flex-col gap-3">
            <p className="font-bold">{name}</p>
            <div
              className={classNames(
                "font-bold text-xs min-w-[70px] w-fit px-3 py-0.5 border-2 rounded-full bg-[#312958] flex justify-center items-center",
                cardTitleBorder
              )}
            >
              {cardTypeTitle}
            </div>
          </div>
        </div>
      </td>
      <td className="py-4 px-8 font-normal">#{tokenId}</td>
      <td className="py-4 px-8 font-normal">
        <div className="flex gap-2 items-center">
          {priceToShow ? formatWeiToEth(priceToShow, 3) : "0.00"}
          <div className="w-5 h-5 bg-white rounded-full p-0.5 mr-2">
            <IconEthereum />
          </div>
        </div>
      </td>
      <td className="py-4 px-8 font-normal">{dateToRender}</td>
      <td className={classNames("py-4 px-8", stylesStatus)}>
        {status === MyActivityItemStatus.WAITING_FOR_BOT_TIME && (
          <p className="mb-1 text-2xs">
            Validating winner in:{" "}
            <span className="font-bold text-xs">{claimTimeToRender}</span>
          </p>
        )}
        {status === MyActivityItemStatus.CLAIM ||
        status === MyActivityItemStatus.LOADING ||
        status === MyActivityItemStatus.WAITING_FOR_BOT_TIME ? (
          <CtaPrimary
            className="w-40"
            onClick={handleClaimOrApproveButton}
            disabled={
              status === MyActivityItemStatus.LOADING ||
              punkStatus === PunkStatus.LOADING_APPROVE ||
              status === MyActivityItemStatus.WAITING_FOR_BOT_TIME
            }
          >
            {claimButtonText}
          </CtaPrimary>
        ) : (
          <>
            {statusText}
            {(status === MyActivityItemStatus.BID_FAILED ||
              status === MyActivityItemStatus.ONGOING) && (
              <>
                <br />
                <span className="font-normal text-white">
                  Auction ends in: {timeToFinish}
                </span>
              </>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

// THIS VARIABLES ARE DECLARE ALSO IN AUCTION CARD
// IF HAVE TO CHANGE IT, ALSO DO IT ON THAT FILE
const feedbackLoading: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your claim transaction is being processed. Please wait.",
      },
    ],
  },
];

const feedbackClaimed: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "NFT claimed successfully. You can check it in your wallet.",
      },
    ],
  },
];

const richTextValueLoadingApprove: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your petition is being processed. Please wait.",
      },
    ],
  },
];

const richTextApprovedAll: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "NFT approved. You can now proceed with claiming.",
      },
    ],
  },
];
