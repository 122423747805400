import { useAccount, useNetwork, useSignMessage } from "wagmi";
import { IoMdClose } from "react-icons/io";
import { type FC, useState } from "react";
import Image from "next/image";
import { SiweMessage } from "siwe";
import { getCsrfToken, signIn } from "next-auth/react";
import { Anchor, CtaPrimary, CtaSecondary } from "../../ui";
import { withModal } from "./shared/withModal";
import { ErrorMessage } from "./shared";

interface Props {
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
}

type Controller = (props: Props) => Props & {
  sign: () => void;
  errorMessage?: string;
};

const useController: Controller = (props) => {
  const { toggleModal, ...restProps } = props;
  const [errorMessage, setErrorMessage] = useState<string>();
  const { signMessageAsync } = useSignMessage();
  const { chain } = useNetwork();
  const { address } = useAccount();

  const sign = async () => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement:
          "Click to sign in and accept the Unlockd Terms of Service: https://unlockd.finance/legal/terms-of-use. This request will not trigger a blockchain transaction or cost any gas fees",
        uri: window.location.origin,
        version: "1",
        chainId: chain?.id,
        nonce: await getCsrfToken(),
      });
      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      });
      const res = await signIn("credentials", {
        message: JSON.stringify(message),
        signature,
        redirect: false,
      });
      if (res?.status === 200) {
        toggleModal(false);
        return;
      }
      // @ts-ignore
    } catch (error: Error) {
      if (error.code === -32000)
        return setErrorMessage("Multisig wallets are not sopported");
      const { message = "Unknown error. Please try again" } = error;
      setErrorMessage(message);
    }
  };

  return {
    sign,
    errorMessage,
    toggleModal,
    ...restProps,
  };
};

export const ModalSign: FC<Props> = withModal((props: Props) => {
  const { toggleModal, sign, errorMessage } = useController(props);

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen grid place-content-center z-50">
        <div
          onClick={() => toggleModal(false)}
          className="absolute w-full h-full -z-10 bg-drawer cursor-default"
        ></div>
        <div
          className="bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] overflow-hidden
           border-2 rounded-3xl xs:px-6 px-5 pb-6 pt-8 xs:pb-8 w-96"
        >
          <button
            className="w-10 h-10 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5 text-white"
            onClick={() => toggleModal(false)}
          >
            <IoMdClose className="w-full h-full" />
          </button>
          <h5 className="xs:mb-8 mb-5 text-center text-base xs:text-lg font-bold text-white">
            Welcome to Unlockd
          </h5>
          <div className="xs:mb-8 mb-5 grid place-content-center">
            <div className="relative w-44 xs:w-64">
              <Image
                height={300}
                width={1400}
                layout="intrinsic"
                src="/assets/images/logo-unlockd.png"
              />
            </div>
          </div>
          <div className="w-80 text-center text-2xs xs:text-xs max-w-full xs:mb-8 mb-5 text-white">
            <span>
              By connecting your wallet and using Unlockd you agree to our{" "}
              <Anchor
                href="https://www.unlockd.finance/terms-of-use"
                target="_blank"
                className="underline text-blue-600"
              >
                Terms and Conditons
              </Anchor>{" "}
              and{" "}
              <Anchor
                href="https://www.unlockd.finance/privacy-policy"
                target="_blank"
                className="underline text-blue-600"
              >
                Privacy Policy
              </Anchor>
            </span>
          </div>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <div className="max-w-full xs:flex xs:flex-row-reverse justify-center">
            <CtaPrimary className="max-xs:w-full max-xs:mb-4" onClick={sign}>
              Accept and Sign
            </CtaPrimary>
            <CtaSecondary
              className="xs:mr-4 max-xs:w-full"
              onClick={() => toggleModal(false)}
            >
              Cancel
            </CtaSecondary>
          </div>
        </div>
      </div>
    </>
  );
});
