import { BigNumber } from "ethers";
import { isCryptopunks } from "./isCryptopunks";

export const getRedeemArguments = (
  collectionAddress: string,
  tokenId: number,
  amount: BigNumber,
  bidFine: BigNumber
): Array<any> => {
  if (isCryptopunks(collectionAddress)) {
    return [tokenId, amount, bidFine];
  } else {
    return [collectionAddress, tokenId, amount, bidFine];
  }
};
