import classNames from "classnames";
import {type FC, useEffect, useState} from "react";

interface Props {
  className?: string;
  percentage: number;
}

export const OfLimit: FC<Props> = ({ className, percentage }) => {
  const [percentageStyle, setPercentageStyle] = useState<string>();

  useEffect(() => {
    if (percentage > 99) {
      setPercentageStyle("w-[100%]");
    } else if (percentage > 89) {
      setPercentageStyle("w-[90%]");
    } else if (percentage > 84) {
      setPercentageStyle("w-[85%]");
    } else if (percentage > 79) {
      setPercentageStyle("w-[80%]");
    } else if (percentage > 74) {
      setPercentageStyle("w-[75%]");
    } else if (percentage > 69) {
      setPercentageStyle("w-[70%]");
    } else if (percentage > 64) {
      setPercentageStyle("w-[65%]");
    } else if (percentage > 59) {
      setPercentageStyle("w-[60%]");
    } else if (percentage > 54) {
      setPercentageStyle("w-[55%]");
    } else if (percentage > 49) {
      setPercentageStyle("w-[50%]");
    } else if (percentage > 44) {
      setPercentageStyle("w-[45%]");
    } else if (percentage > 39) {
      setPercentageStyle("w-[40%]");
    } else if (percentage > 34) {
      setPercentageStyle("w-[35%]");
    } else if (percentage > 29) {
      setPercentageStyle("w-[30%]");
    } else if (percentage > 24) {
      setPercentageStyle("w-[25%]");
    } else if (percentage > 19) {
      setPercentageStyle("w-[20%]");
    } else if (percentage > 14) {
      setPercentageStyle("w-[15%]");
    } else if (percentage > 9) {
      setPercentageStyle("w-[10%]");
    } else if (percentage > 4) {
      setPercentageStyle("w-[5%]");
    }
  }, [percentage]);

  return (
    <>
      <div className={classNames(`max-w-lg w-full`, [className])}>
        <div className="h-6 rounded-full w-full border mb-1 xs:mb-1 overflow-hidden relative">
          <div
            className={classNames(
              `absolute h-full bg-white border-2 rounded-full`,
              percentageStyle
            )}
          ></div>
          <div className="max-w-full h-full w-full absolute rounded-full"></div>
        </div>
      </div>
    </>
  );
};
