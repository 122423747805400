import { equalIgnoreCase } from "../../utils";
import { COLLECTIONS } from "../../../app.config";

export const getCollectionNameByContractAddress = (
  collectionAddress: string
): string => {
  const collection = COLLECTIONS.find(
    ({ address, uBoundAddress, wrapAddress }) => {
      return (
        equalIgnoreCase(address, collectionAddress) ||
        equalIgnoreCase(uBoundAddress, collectionAddress) ||
        (wrapAddress && equalIgnoreCase(wrapAddress, collectionAddress))
      );
    }
  );

  if (!collection) {
    throw new Error(
      "failed on getting collection address name. Contract not supported"
    );
  }

  return collection.name;
};
