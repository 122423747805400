import { type FC } from "react";
import { CollectionData } from "../../../types";
import classNames from "classnames";
import { formatWeiToEth } from "../../../utils";
import { IconEthereum } from "../../ui/icons";

interface Props {
  className?: string;
  index: number;
  collectionData: CollectionData;
  isLastOne: boolean;
}

export const CollectionItem: FC<Props> = (props) => {
  const {
    className,
    index,
    collectionData: {
      totalNftsLocked,
      name,
      image,
      tvl,
      minimumVal,
      maximumVal,
    },
    isLastOne,
  } = props;

  return (
    <>
      <tr
        className={classNames(
          className || "",
          !isLastOne && "border-b border-b-[#FFF7F7]"
        )}
      >
        <td className="pb-3 pt-2 pr-4 flex gap-3">
          <div className="w-12 h-12 flex items-center justify-center">
            <img className="rounded-lg h-full" src={image} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-xs">{index}</p>
            <p className="font-bold">{name.toUpperCase()}</p>
          </div>
        </td>
        <td className="text-center">{totalNftsLocked}</td>
        <td className="text-center">
          <div className="flex justify-center items-center gap-1">
            <span>{tvl !== "0" ? formatWeiToEth(tvl, 2) : "0"}</span>
            <div className="w-4 h-4 bg-white rounded-full -mt-0.5">
              <IconEthereum />
            </div>
          </div>
        </td>
        <td className="text-center">
          <div className="flex justify-center items-center gap-1">
            <span>
              {minimumVal !== "0" ? formatWeiToEth(minimumVal, 2) : "0"} -{" "}
              {maximumVal !== "0" ? formatWeiToEth(maximumVal, 2) : "0"}
            </span>
            <div className="w-4 h-4 bg-white rounded-full -mt-0.5">
              <IconEthereum />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
