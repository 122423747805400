import type {FormStatics} from "../../types";

export const useFormStatics = () => {
  const data: FormStatics = {
    id: "form",
    content: {
      inputAddressPlaceholder:
        "Connect your wallet and this field will be auto-completed",
      faucetExplainText: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "child-0",
              _type: "span",
              text: "Order Ethereum from the Goerli network to be able to use the protocol from any of these links:",
            },
          ],
        },
      ],
      faucetLinks: [
        {
          id: "link_0",
          href: "https://goerlifaucet.com/",
          target: "_blank",
          title: "firstlink",
          rel: "fisrtlink",
          children: "https://goerlifaucet.com/",
        },
        {
          id: "link_0",
          href: "https://goerli-faucet.mudit.blog/",
          target: "_blank",
          title: "firstlink",
          rel: "fisrtlink",
          children: "https://goerli-faucet.mudit.blog/",
        },
        {
          id: "link_0",
          href: "https://faucet.paradigm.xyz/",
          target: "_blank",
          title: "firstlink",
          rel: "fisrtlink",
          children: "https://faucet.paradigm.xyz/",
        },
      ],
      copyButtonExplainText: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "child-0",
              _type: "span",
              text: "Click on the button to copy the test nfts contract to the clipboard:",
            },
          ],
        },
      ],
      submitButtonText: "Send me NFTs",
      copyButtonText: "Copy contract address",
      feedbacks: {
        allNftsMinted: [
          {
            _key: "1",
            _type: "block",
            children: [
              {
                _key: "1",
                _type: "span",
                text: "not available NFTs to Mint",
              },
            ],
          },
        ],
        transactionPending: [
          {
            _key: "1",
            _type: "block",
            children: [
              {
                _key: "1",
                _type: "span",
                text: "there is a pending transaction",
              },
            ],
          },
        ],
        addressCopied: [
          {
            _key: "block-0",
            _type: "block",
            children: [
              { _key: "children-0", _type: "span", text: "copy nft address" },
            ],
          },
        ],
        unexpectedError: [
          {
            _key: "block-0",
            _type: "block",
            children: [
              { _key: "children-0", _type: "span", text: "unexpected error" },
            ],
          },
        ],
      },
    },
  };

  return data;
};
