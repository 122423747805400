import { Signer } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/customERC721.json";
import { PUNKS_COLLECTION, CONTRACT_ADDRESSES } from "../../app.config";

export const approvePunkGatewayForAllInWrappedPunks = async (
  signer: Signer
): Promise<string> => {
  const transaction = await callContract(
    PUNKS_COLLECTION.wrapAddress!,
    abi,
    signer,
    "setApprovalForAll",
    [CONTRACT_ADDRESSES.punkGateway]
  );

  return transaction.hash;
};
