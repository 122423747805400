import { CONTRACT_ADDRESSES } from "../../app.config";
import abi from "../abis/lendpoolUnlockd.json";
import { callContract } from "../utils";

export const getConfigFee = async (): Promise<number> => {
  const configFee = await callContract(
    CONTRACT_ADDRESSES.lendpool,
    abi,
    null,
    "getConfigFee",
    []
  );

  return Number(configFee);
};
