import {BigNumber, Signer} from "ethers";
import {callContract} from "../utils";
import {getAbiToInteractWith, getContractAddressToInteractWith, getRedeemArguments,} from "./helpers";

export const redeem = async (
  signer: Signer,
  collectionAddress: string,
  tokenId: number,
  amount: BigNumber,
  bidFine: BigNumber
) => {
  const contractAddressToInteractWith = getContractAddressToInteractWith(collectionAddress)
  const abiToInteractWith = getAbiToInteractWith(collectionAddress)
  const callArguments = getRedeemArguments(collectionAddress, tokenId, amount, bidFine)

  //console.log('redeem', contractAddressToInteractWith, abiToInteractWith, callArguments)

  const transaction = await callContract(
    contractAddressToInteractWith,
    abiToInteractWith,
    signer,
    "redeemETH",
    callArguments,
    { value: amount.add(bidFine) }
  );

  return transaction.hash;
};
