import React, { type FC, FormEvent, useEffect, useState, useMemo } from "react";
import { IoMdClose } from "react-icons/io";
import { Anchor, CtaPrimary, CtaSecondary, RichText } from "../../ui";
import { ErrorMessage, Input, withModal } from "./shared";
import { IconEthereum } from "../../ui/icons";
import { AiOutlineCheck, AiOutlineLoading3Quarters } from "react-icons/ai";
import { PortableText } from "../../../types/elements";
import { useAccount, useNetwork, useSigner } from "wagmi";
import {
  approveUnlockdToManageTokens,
  getAmountOfTokensApprovedToManage,
  registerRaleonEvent,
  supply,
} from "../../../logic";
import { useAlertNotification } from "../../providers";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  TransactionError,
} from "../../../errors/errors";
import { handleErrors } from "../../../logic/helpers";
import { formatWeiToEth, waitForTransactionReceipt } from "../../../utils";
import { BigNumber } from "ethers";
import { approveInWalletLiteral } from "../../../literals";

interface Props {
  className?: string;
  balance?: BigNumber;
  apy?: number;
  deposit: BigNumber;
  toggleModal: (nextValue?: boolean) => void;
  isOpen: boolean;
  onApproved: () => void;
  onSupplied: () => void;
}

enum Status {
  TO_APPROVE,
  LOADING_APPROVE,
  TO_SUPPLY,
  LOADING_SUPPLY,
  SUCCESS,
}

export const ModalSupply: FC<Props> = withModal((props) => {
  const { toggleModal, apy, balance, deposit, onApproved, onSupplied } = props;

  const [error, setError] = useState<Error | null | undefined>(null);
  const [amount, setAmount] = useState<BigNumber>();
  const { chains } = useNetwork();
  const chainName = chains[0]?.name;
  const [transaction, setTransaction] = useState<any>();
  const [amountApproved, setAmountApproved] = useState<BigNumber>();
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const [status, setStatus] = useState<Status>();
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();

  const { primaryButtonText, isPrimaryButtonDisabled } = useMemo(() => {
    if (status !== undefined) {
      if (status === Status.TO_APPROVE) {
        return {
          primaryButtonText: "Approve",
          isPrimaryButtonDisabled: false,
        };
      } else if (
        status === Status.LOADING_APPROVE ||
        status === Status.LOADING_SUPPLY
      ) {
        return {
          primaryButtonText: "Loading...",
          isPrimaryButtonDisabled: true,
        };
      } else if (status === Status.TO_SUPPLY) {
        return {
          primaryButtonText: "Supply",
          isPrimaryButtonDisabled: false,
        };
      }
    }
    return {
      primaryButtonText: "Approve",
      isPrimaryButtonDisabled: true,
    };
  }, [status]);

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(
        error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError
      )
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  useEffect(() => {
    if (
      (!amount && amountApproved && !amountApproved.isZero()) ||
      (amount &&
        amountApproved &&
        ((!amount.isZero() && amount.add(deposit).lte(amountApproved)) ||
          amount.isZero()))
    ) {
      setStatus(Status.TO_SUPPLY);
    } else {
      setStatus(Status.TO_APPROVE);
    }
  }, [amountApproved, amount]);

  useEffect(() => {
    if (address) {
      callGetAmountOfTokensApprovedToManage();
    }
  }, [address]);

  const callGetAmountOfTokensApprovedToManage = async (): Promise<void> => {
    try {
      setAmountApproved(await getAmountOfTokensApprovedToManage(address!));
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const handleAmountChange = (amountSelected: BigNumber): void => {
    setAmount(amountSelected);

    if (amountSelected.gt(BigNumber.from(balance)!)) {
      setError(
        new LimitMaxError(
          `amount ${formatWeiToEth(
            amountSelected
          )} is greater than balance of address: ${formatWeiToEth(balance!)}`
        )
      );
    } else if (amountSelected.lt(BigNumber.from(0))) {
      setError(
        new LimitMinError(`amount ${amountSelected} is lower than zero`)
      );
    } else {
      if (error) setError(null);
    }
  };

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    if (status === Status.TO_SUPPLY) {
      registerRaleonEvent("supply-clicked", address!);

      if (signer && amount && address) {
        // @ts-ignore
        try {
          openAlertNotification("info", approveInWalletLiteral, 50000);

          const transactionHash = await supply(address, signer, amount);

          closeAlertNotification();

          setStatus(Status.LOADING_SUPPLY);

          waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
            if (receipt.status == 1) {
              setStatus(Status.SUCCESS);

              onSupplied();
              setTransaction(transactionHash);
            } else if (receipt.status === 0) {
              setError(
                new TransactionError(
                  `transaction with hash ${receipt.transactionHash} failed`
                )
              );
              setStatus(Status.TO_SUPPLY);
            }
          });
        } catch (err) {
          closeAlertNotification();
          setError(handleErrors(err));
        }
      }
    } else if (status === Status.TO_APPROVE) {
      registerRaleonEvent("earn-approve-clicked", address!);
      if (signer) {
        // @ts-ignore
        try {
          openAlertNotification("info", approveInWalletLiteral, 50000);

          const transactionHash = await approveUnlockdToManageTokens(signer);

          closeAlertNotification();

          setStatus(Status.LOADING_APPROVE);

          waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
            if (receipt.status == 1) {
              openAlertNotification("success", richTextApproved, 5000);

              onApproved();

              callGetAmountOfTokensApprovedToManage();
            } else if (receipt.status === 0) {
              setError(
                new TransactionError(
                  `transaction with hash ${receipt.transactionHash} failed`
                )
              );
              setStatus(Status.TO_APPROVE);
            }
          });
        } catch (err) {
          closeAlertNotification();
          setError(handleErrors(err));
        }
      }
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="bg-secondary relative border-glow-sm max-w-5xl overflow-hidden
           border-2 rounded-3xl xs:px-12 px-8 pb-6 pt-8 xs:pb-8 text-white"
      >
        <button
          type="button"
          className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
          onClick={() => toggleModal(false)}
        >
          <IoMdClose className="w-full h-full" />
        </button>
        <div className="flex">
          <h5 className="xs:mb-5 text-xl text-center xs:text-2xl font-bold">
            Supply ETH
          </h5>
          <div className="w-6 h-6 mt-1 bg-white rounded-full ml-2">
            <IconEthereum />
          </div>
        </div>
        <div className="text-xs xs:text-sm xs:mb-8 mb-3">
          {(status === Status.TO_APPROVE || status === Status.TO_SUPPLY) && (
            <>
              <RichText className="text-lg mb-5" value={richTextValuePending} />
              <p className="text-xl">
                APY:
                <span className="font-bold ml-3">
                  {apy ? `${(apy * 100).toFixed(4)}%` : "0%"}
                </span>
              </p>
            </>
          )}
          {status === Status.LOADING_APPROVE && (
            <div>
              <p className="flex mb-4 justify-center">
                Don't close this screen.
              </p>
              <p className="flex justify-centermb-4">
                Your approval should be confirmed in the blockchain shortly.
              </p>
            </div>
          )}
          {status === Status.LOADING_SUPPLY && (
            <div>
              <p className="flex mb-4 justify-center">
                Don't close this screen.
              </p>
              <p className="flex mb-4 justify-center">
                Your supply transaction is being processed. Please wait.
              </p>
            </div>
          )}
        </div>
        {(status === Status.TO_APPROVE || status === Status.TO_SUPPLY) && (
          <Input
            onChange={handleAmountChange}
            max={BigNumber.from(balance) || BigNumber.from(0)}
            className="xs:mb-8 mb-10"
            nameInput="totalSupply"
            balance={balance!}
            defaultValue={amount}
          />
        )}
        {(status === Status.LOADING_APPROVE ||
          status === Status.LOADING_SUPPLY) && (
          <div className="flex justify-center items-center py-4">
            <AiOutlineLoading3Quarters className="animate-spin w-8 h-8" />
          </div>
        )}
        {status === Status.SUCCESS && (
          <div className="flex w-96 flex-col items-center justify-center mx-auto xs:mb-8 mb-5">
            <div className="border-white border-4 rounded-full mb-4">
              <AiOutlineCheck className="w-16 h-16 m-4" />
            </div>
            <span className="mb-6">Transaction submitted</span>
            <CtaSecondary>
              <Anchor
                href={`https://${
                  chainName === "Goerli" ? chainName + "." : ""
                }etherscan.io/tx/${transaction}`}
                target="_blank"
                title="Etherscan"
                rel="noopener noreferrer"
                className="font-bold px-5"
              >
                See your transaction on Etherscan
              </Anchor>
            </CtaSecondary>
          </div>
        )}

        {(error instanceof LimitMinError ||
          error instanceof LimitMaxError ||
          error instanceof GasLimitError) && (
          <ErrorMessage>{error.message}</ErrorMessage>
        )}

        {status === Status.SUCCESS ? (
          <div className="flex justify-center gap-5 w-full mt-6">
            <CtaPrimary
              onClick={() => toggleModal(false)}
              className="w-full"
              type="button"
            >
              Close
            </CtaPrimary>
          </div>
        ) : (
          <div className="flex justify-center gap-5 w-full pt-4 mt-6">
            <CtaSecondary
              onClick={() => toggleModal(false)}
              className="px-11"
              type="button"
            >
              Cancel
            </CtaSecondary>
            <CtaPrimary
              className="px-11"
              type="submit"
              disabled={isPrimaryButtonDisabled}
            >
              {primaryButtonText}
            </CtaPrimary>
          </div>
        )}
      </form>
    </>
  );
});

const richTextValuePending: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Deposit your tokens to supply the liquidity pool and Earn an APY.",
      },
    ],
  },
];

const richTextApproved: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Token approved. You can now supply to the pool.",
      },
    ],
  },
];
