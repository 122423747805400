import classNames from "classnames";
import {type FC} from "react";
import {useModalConnectWallets} from "../../providers";

import type {ButtonConnectWalletStatics} from "../../../types";
import {CtaPrimary} from "../../ui";
import {useButtonConnectWalletStatics} from "../../statics";

interface Props {
  className?: string;
}

type Controller = (props: Props) => Props & {
  statics: ButtonConnectWalletStatics;
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
};

const useController: Controller = (props) => {
  const statics = useButtonConnectWalletStatics();
  const [isOpen, toggleModal] = useModalConnectWallets();

  return { statics, isOpen, toggleModal, ...props };
};

export const ButtonConnectWallet: FC<Props> = (props) => {
  const { className, toggleModal, statics } = useController(props);
  const { children } = statics;

  return (
    <>
      <CtaPrimary
        className={classNames("break-normal min-w-fit", [className])}
        onClick={() => toggleModal()}
      >
        {children}
      </CtaPrimary>
    </>
  );
};
