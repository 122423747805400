import classNames from "classnames";
import {type FC, type ForwardedRef, forwardRef} from "react";
import {SimpleLink} from "../../../types/structures";
import {Anchor} from "../../ui";

interface Props extends SimpleLink<string> {
  className?: string;
}

export const ListElement: FC<Props> = forwardRef(function AnchorItem(
  props,
  ref: ForwardedRef<HTMLLIElement>
) {
  const { id, children, href, target, rel, title, className } = props;
  return (
    <li className={classNames(``, [className])} ref={ref}>
      <Anchor
        className="underline-offset-4 hover:underline cursor-pointer"
        id={id}
        href={href}
        target={target}
        title={title}
        rel={rel}
      >
        {children}
      </Anchor>
    </li>
  );
});
