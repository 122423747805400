import { MOONBIRDS_COLLECTION } from "../../../app.config";
import { ConnectionError } from "../../errors";

const DEFAULT_IMAGE =
  "https://i.seadn.io/gae/9ZLCKS7w0XtwLpq7QVMRgY4Eakg5CQaLa52HFcf4HKksqOxRKc_ybis58FWnjHcNKf5MHc_Iw_JAP_7WPMJFLBLOkhFhBd4HU0InJw";

export const getMoonbirdImage = async (tokenId: number): Promise<string> => {
  try {
    const url = `api/proxy/${MOONBIRDS_COLLECTION.baseTokenUri!}/${tokenId}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new ConnectionError("failed on fetching moonbird image");
    }

    const { image } = await response.json();

    return image;
  } catch (error) {
    return DEFAULT_IMAGE;
  }
};
