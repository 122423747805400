import { callContract } from ".";
import { CONTRACT_ADDRESSES } from "../../app.config";
import multicallAbi from "../abis/multicallAbi.json";
import { Call } from "../types";

export const aggregateCallContract = async (
  calls: Array<Call>
): Promise<any> => {
  const result = await callContract(
    CONTRACT_ADDRESSES.multicall,
    multicallAbi,
    null,
    "aggregate",
    [calls]
  );

  return result;
};
