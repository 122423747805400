import {RAY, SECONDS_PER_YEAR} from "../constants";

export const calculateApyBorrow = (variableBorrowRate: number): number => {
  //@ts-ignore
  const apr = variableBorrowRate / RAY;

  const base = apr / parseInt(SECONDS_PER_YEAR) + 1;
  const apy = base ** parseInt(SECONDS_PER_YEAR) - 1;

  return apy;
};
