import wethGateWayAbi from "../../abis/wethGateway.json";
import punkGatewayAbi from "../../abis/punkGateway.json";
import { ContractInterface } from "ethers";
import { isCryptopunks } from "./isCryptopunks";

export const getAbiToInteractWith = (
  collectionAddress: string
): ContractInterface => {
  if (isCryptopunks(collectionAddress)) {
    return punkGatewayAbi;
  } else {
    return wethGateWayAbi;
  }
};
