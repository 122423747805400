import type {ModalConnectWalletsStatics} from "../../../../types";

export const useModalConnectWalletsStatics = () => {
  const data: ModalConnectWalletsStatics = {
    title: "Connect a Wallet",
    legalText: [
      {
        _key: "legal-text-0",
        _type: "block",
        children: [
          {
            _key: "legal-text-0-1",
            _type: "span",
            text: "Wallets are provided by External Providers and by selecting you agree to Terms of those Providers. Your access to the wallet might be reliant on the External Provider being operational.",
          },
        ],
      },
    ],
    feedbacks: {
      connectorNotFound: "MetaMask is not installed",
      userRejectRequest: "User rejected request",
      unexpectedErrorConnecting: "unexpected error while connecting",
    },
  };

  return data;
};
