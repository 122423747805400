import { keccak256 } from "ethers/lib/utils";
import { UnexpectedError } from "../errors";

export const getEventSignature = (
  abi: Array<{ name?: string; inputs: Array<{ type: string }> }>,
  eventName: string
): string => {
  const eventObject = abi.find(({ name }) => name === eventName);

  if (!eventObject)
    throw new UnexpectedError(`eventName ${eventName} not found on abi ${abi}`);

  let eventString = eventObject!.name + "(";

  eventObject!.inputs.forEach(
    ({ type }, index) =>
      (eventString +=
        type + (index !== eventObject!.inputs.length - 1 ? "," : ""))
  );

  eventString += ")";

  return keccak256(Buffer.from(eventString));
};
