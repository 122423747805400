import {toMin2Digits} from './numbers'

export function toYearForSlashMonthForSlashDaySpaceHourColonMinute(date: Date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()

    return `${year}/${toMin2Digits(month)}/${toMin2Digits(day)} ${toMin2Digits(hour)}:${toMin2Digits(minute)}`
}