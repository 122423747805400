import type { ModalSwitchNetworkStatics } from "../../../../types";

export const useModalSwitchNetworkStatics = () => {
  const data: ModalSwitchNetworkStatics = {
    title: "Change Network",
    successButton: "Change network",
    dismissButton: "Cancel",
    feedbacks: {
      userRejectedRequestError: "User rejected request",
      unknownError: "Unknown error switching network, please try again",
      pendingSwitchTransactionError: "already pending request",
    },
  };

  return data;
};
