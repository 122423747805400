import { BuyoutEventData, BuyoutEventDataParsed, Log } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import abi from "../../abis/lendpoolUnlockd.json";
import { CONTRACT_ADDRESSES } from "../../../app.config";

export const setBuyLoanAuctionedListener = (
  callback: (data: BuyoutEventDataParsed) => void
): (() => void) => {
  const unlistener = listenContractEvent(
    // @ts-ignore
    getEventSignature(abi, "Buyout"),
    CONTRACT_ADDRESSES.lendpool,
    [],
    ({ data, topics }: Log) => {
      // @ts-ignore
      const dataDecoded: BuyoutEventData = decodeTransactionData(
        abi,
        "Buyout",
        data,
        topics
      );

      const dataParsed: BuyoutEventDataParsed = {
        ...dataDecoded,
        nftTokenId: Number(dataDecoded.nftTokenId),
        loanId: Number(dataDecoded.loanId),
      };

      callback(dataParsed);
    },
    false
  );

  return unlistener;
};
