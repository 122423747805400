import { getCollectionNameByContractAddress } from "./getCollectionNameByContractAddress";
import { retrieveNftsData } from "./retrieveNftsData";
import { alchemy } from "../../clients";
import { NftBorrowData } from "../../types";
import { isCryptopunks } from "./isCryptopunks";
import { CHAIN } from "../../../app.config";

export const retrieveNftData = async (
  collectionAddress: string,
  tokenId: number,
  isDeposited: boolean
): Promise<NftBorrowData> => {
  const metadata = await alchemy().nft.getNftMetadata(collectionAddress, tokenId);

  const response = await retrieveNftsData([
    {
      collectionAddress,
      tokenId,
      isDeposited,
      collectionName: getCollectionNameByContractAddress(collectionAddress),
      tokenUri:
        isCryptopunks(collectionAddress) && CHAIN === "goerli"
          ? `https://images.wrappedpunks.com/images/punks/${tokenId}.png`
          : metadata.tokenUri?.gateway!,
      image:
        isCryptopunks(collectionAddress) && CHAIN === "goerli"
          ? `https://images.wrappedpunks.com/images/punks/${tokenId}.png`
          : metadata.media?.at(0)?.gateway,
    },
  ]);

  return response[0];
};
