import {type FC} from "react";
import {IconEthereum} from "../ui/icons";

export const AuctionHistoryEmptyItem: FC = () => {
  return (
    <>
      <tr
        className={`bg-secondary hover:bg-primary/10 hover:text-black text-base font-medium`}
      >
        <td scope="row" className="flex py-6 px-8 font-medium">
          <div className="flex gap-3 items-center w-40 mr-44">
            <div className="w-14 h-14 border border-dashed rounded-xl"></div>
            <p className="font-bold flex">----</p>
          </div>
        </td>
        <td className="py-4 px-8"># ----</td>
        <td className="py-4 px-8">
          <div className="flex gap-3 items-center">
            ----
            <div className="w-5 h-5 bg-white rounded-full p-0.5 mr-2">
              <IconEthereum />
            </div>
          </div>
        </td>
        <td className="py-4 px-8">----</td>
        <td className="py-4 px-8">--/--/--</td>
      </tr>
    </>
  );
};
