import {PortableText} from "../../types/elements";

type Data = {
  title: string;
  feedbacks: { unexpectedError: PortableText };
};

export const useGlobalProviderStatics = (): Data => {
  const data: Data = {
    title: "Global Provider",
    feedbacks: {
      unexpectedError: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "child-0",
              _type: "span",
              text: "Web application not available. Please try again later",
            },
          ],
        },
      ],
    },
  };

  return data;
};
