import { EventFilter } from "ethers";
import { lookUpProvider } from "../logic/helpers";
import { Log } from "../types";
import { parseArgHexWithZeroPad } from "./parseArgHexWithZeroPad";
import { unlistenContractEvent } from "./unlistenContractEvent";

// TODO decode data directly for here

export const listenContractEvent = (
  eventSignature: string,
  contractAddress: string,
  args: Array<string | number | undefined>,
  callback: (log: Log) => void,
  once: boolean = true
): (() => void) => {
  const params = args.map((element: string | number | undefined) => {
    if (element) {
      return parseArgHexWithZeroPad(element);
    } else {
      return;
    }
  });

  const provider = lookUpProvider();

  const event: EventFilter = {
    address: contractAddress,
    // @ts-ignore
    topics: [eventSignature, ...params],
  };

  provider[once ? "once" : "on"](event, (data) => callback(data));

  // console.log("listener on", eventTopic, contractAddress, args);

  return () => {
    // console.log("listener off", eventTopic, Date.now);
    unlistenContractEvent(event, provider);
  };
};
