import { type FC, useEffect, useState } from "react";
import { IconEthereum } from "../ui/icons";
import { retrieveTransactionsHistory } from "../../logic";
import { TransactionHistoryItem } from "../../types";
import {
  to13Chars,
  toYearForSlashMonthForSlashDaySpaceHourColonMinute,
  truncate,
} from "../../utils";
import { CtaSecondary } from "../ui";
import { utils } from "ethers";
import { useAlertNotification } from "../providers";
import { AuctionHistoryEmptyItem } from "./AuctionHistoryEmptyItem";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { handleErrors } from "../../logic/helpers";

const LIMIT = 100;

export const TransactionsHistory: FC = () => {
  const [items, setItems] = useState<Array<TransactionHistoryItem>>([]);
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    try {
      handleRetrieveTransactionsHistory();
    } catch (error) {
      setError(handleErrors(error));
    }
  }, []);

  const handleRetrieveTransactionsHistory = async () => {
    try {
      const { items: itemsFromLogic, pagination } =
        await retrieveTransactionsHistory(page * LIMIT, LIMIT);

      setTotal(pagination.total);
      setItems((items) => {
        const itemsCopy = [...items];

        return itemsCopy.concat(itemsFromLogic);
      });
      pagination.offset + LIMIT < pagination.total && setPage(page + 1);
      setLoading(false);
    } catch (error) {
      setError(handleErrors(error));
    }
  };

  useEffect(() => {
    if (error && !isAlertNotificationOpen) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  const handleSeeMore = () => {
    setLoading(true);

    try {
      handleRetrieveTransactionsHistory();
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  return (
    <div className="overflow-x-auto max-w-max mx-auto p-4 relative bg-secondary rounded-xl border-4 border-transparent hover:border-indigo-600 flex flex-col items-center">
      <table className="w-full text-base text-left text-white">
        <thead className="text-md">
          <tr>
            <th scope="col" className="py-3 px-8">
              Collection
            </th>
            <th scope="col" className="py-3 px-8">
              Token ID
            </th>
            <th scope="col" className="py-3 px-8">
              Price
            </th>
            <th scope="col" className="py-3 px-8">
              Wallet address
            </th>
            <th scope="col" className="py-3 px-8">
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map(
            (
              {
                nfts: [{ collection, image, name, tokenId }],
                id,
                price,
                buyer,
                date,
              },
              index
            ) => (
              <tr
                key={collection + tokenId + id}
                className={`bg-secondary hover:bg-primary/10 text-base font-medium ${
                  index < items.length - 1 ? "border-b" : ""
                }`}
              >
                <td scope="row" className="flex py-6 px-8 font-medium">
                  <div className="flex gap-3 items-center w-64">
                    <img className="rounded-xl w-14" src={image} alt="" />
                    <p className="font-bold flex">{name}</p>
                  </div>
                </td>
                <td className="py-4 px-8">#{tokenId}</td>
                <td className="py-4 px-8">
                  <div className="flex gap-3 items-center">
                    {price ? truncate(utils.formatEther(price), 4) : "0"}
                    <div className="w-5 h-5 bg-white rounded-full p-0.5 mr-2">
                      <IconEthereum />
                    </div>
                  </div>
                </td>
                <td className="py-4 px-8">
                  {buyer ? to13Chars(buyer) : "0x0000...0000"}
                </td>
                <td className="py-4 px-8">
                  {date
                    ? toYearForSlashMonthForSlashDaySpaceHourColonMinute(date)
                    : "--/--/----"}
                </td>
              </tr>
            )
          )}
          {items.length === 0 && !isLoading && <AuctionHistoryEmptyItem />}
        </tbody>
      </table>
      {!isLoading && !!total && total > items.length && (
        <CtaSecondary className="mt-10 mb-8 w-48" onClick={handleSeeMore}>
          See more
        </CtaSecondary>
      )}
      {isLoading && (
        <div className="w-full flex mx-auto mt-10 mb-8 justify-center items-center">
          <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
        </div>
      )}
    </div>
  );
};
