import { SAFE_BASE_URL } from "../../app.config";
import { UnexpectedError } from "../errors";

type SafeAddressResponse = {
  address: string;
  nonce: number;
  threshold: number;
  owners: Array<string>;
  masterCopy: string;
  modules: Array<string>;
  fallbackHandler: string;
  guard: string;
  version?: string;
};

export const getSafeAddressData = async (
  safeAddress: string
): Promise<SafeAddressResponse> => {
  const gnosisAddressResponse = await fetch(
    `${SAFE_BASE_URL}/v1/safes/${safeAddress}`
  );

  if (!gnosisAddressResponse.ok) {
    throw new UnexpectedError("failed fetching data from safe gnosis");
  }

  const data: SafeAddressResponse = await gnosisAddressResponse.json();

  return data;
};
