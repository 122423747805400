import { type FC } from "react";
import classNames from "classnames";
import { CtaSecondary } from "../../ui";
import Link from "next/link";

interface Props {
  className?: string;
  claimedAuctions?: number;
  onGoingAuctions?: number;
}

export const AuctionsBox: FC<Props> = (props) => {
  const { className, onGoingAuctions, claimedAuctions } = props;

  return (
    <>
      <div
        className={classNames(
          "flex flex-col justify-between gap-9 p-8 bg-secondary rounded-xl shadow-lighting ",
          [[className]]
        )}
      >
        <div className="flex flex-col gap-7">
          <h3 className="font-bold text-xl">My Auctions</h3>
          <div className="h-full flex flex-col gap-5">
            <div className="flex justify-between w-full">
              <span>Ongoing</span>
              <p>{onGoingAuctions}</p>
            </div>
            <div className="flex justify-between w-full">
              <span>Claimed</span>
              <p>{claimedAuctions}</p>
            </div>
          </div>
        </div>

        <Link legacyBehavior href="/marketplace">
          <a>
            <CtaSecondary className="mx-auto w-40">Marketplace</CtaSecondary>
          </a>
        </Link>
      </div>
    </>
  );
};
