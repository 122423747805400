import {Disclosure, Transition} from "@headlessui/react";
import classNames from "classnames";
import {type FC, Fragment} from "react";
import type {FaqsStatics} from "../../types";
import {RichText} from "../ui";
import {useFaqsStatics} from "../statics";
import {FaChevronDown} from "react-icons/fa";

interface Props {
  className?: string;
}

type Controller = (props: Props) => {
  className?: string;
  statics: FaqsStatics;
};

const useController: Controller = (props) => {
  const statics = useFaqsStatics();

  return {
    statics,
    ...props,
  };
};

export const Faqs: FC<Props> = (props) => {
  const { statics, className } = useController(props);
  const { id, title, content } = statics;

  return (
    <>
      <section
        className={classNames(
          `max-w-4xl md:px-9 xs:px-6 px-4 pt-8 pb-5 bg-secondary rounded-xl element-shadow`,
          [className]
        )}
      >
        <h2 className="mb-3 pl-2 text-white cursor-default md:text-3xl xs:text-2xl text-xl font-bold">
          {title}
        </h2>
        <dl className="">
          {content.elements.map((element, i) => (
            <Disclosure key={element.id + i.toString()} as={Fragment}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    as="dt"
                    tabIndex={0}
                    role="button"
                    aria-pressed="false"
                    className={classNames(
                      `mb-3 flex w-full items-center text-black justify-between gap-x-2 rounded-2xl border border-solid
                      border-white bg-secondary px-4 py-2`,
                      { "border-glow-sm": open }
                    )}
                  >
                    <span className="text-sm font-semibold text-white xs:text-base max-2xs:text-xs">
                      {element.definitionTitle}
                    </span>
                    <div
                      className={classNames("w-4 h-4 text-white shrink-0", {
                        "rotate-180 transform": open,
                      })}
                    >
                      <FaChevronDown className="w-full h-full" />
                    </div>
                  </Disclosure.Button>
                  <Transition
                    unmount={false}
                    className="!block h-full max-h-0 overflow-hidden"
                    enter="transition-all duration-75 ease-in"
                    enterFrom="max-h-[0px]"
                    enterTo="max-h-[500px]"
                    leave="transition-all duration-75 ease-out"
                    leaveFrom="max-h-[500px]"
                    leaveTo="max-h-[0px]"
                  >
                    <Disclosure.Panel
                      as="div"
                      static
                      className="h-fit w-full px-3 pt-1 pb-7 text-sm font-normal text-white max-2xs:text-2xs"
                    >
                      <RichText value={element.definitionDescription} />
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </section>
    </>
  );
};
