import { FC, useEffect, useState } from "react";
import { useToggle, useUpdateEffect } from "react-use";
import { LoanStatus, NftBorrowData, PortableText } from "../../../../types";
import { CtaPrimary, CtaRed, CtaSecondary } from "../../../ui";
import { IconEthereum } from "../../../ui/icons";
import { HealthFactorCircle } from "../../HealthFactorCircle";
import { useWeb3ModalGuard } from "../../modals/shared";
import { ModalList, ModalRedeem, ModalRepay } from "../../modals";
import { formatWeiToEth, waitForTransactionReceipt } from "../../../../utils";
import { cancelDebtListing } from "../../../../logic";
import { useSigner } from "wagmi";
import { useAlertNotification } from "../../../providers";
import { TransactionError, transactionErrorMessage } from "../../../../errors";
import {
  changeToWrapIfCrypropunks,
  handleErrors,
} from "../../../../logic/helpers";
import { approveInWalletLiteral } from "../../../../literals";

type Props = {
  loan: NftBorrowData;
  onBorrowClick: (nft: NftBorrowData) => void;
  onModalClose: () => void;
  onModalOpen: (collectionAddres: string, tokenId: number) => void;
};

export const MyLoan: FC<Props> = (props) => {
  const {
    loan,
    loan: {
      collection,
      tokenUri,
      name,
      tokenId,
      healthFactor,
      currentBorrowAmount,
      apy,
      status,
      valuation,
      availableToBorrow,
      liquidationPrice,
      liquidationThreshold,
      isListed: isListedFromProps = false,
    },
    onBorrowClick,
    onModalClose,
    onModalOpen,
  } = props;
  const [isListed, setListed] = useState<boolean>(isListedFromProps);
  const [isListButtonDisabled, setListButtonDisabled] = useState<boolean>(
    status === LoanStatus.TO_REDEEM || status === LoanStatus.TO_REPAY
  );
  const [isModalRepayOpen, toggleModalRepay] = useToggle(false);
  const [isModalRedeemOpen, toggleModalRedeem] = useToggle(false);
  const [isModalListOpen, toggleModalList] = useToggle(false);
  const [error, setError] = useState<Error | null>(null);
  const launcherModalRepay = useWeb3ModalGuard(
    isModalRepayOpen,
    toggleModalRepay
  );
  const launcherModalRedeem = useWeb3ModalGuard(
    isModalRedeemOpen,
    toggleModalRedeem
  );
  const launcherModalList = useWeb3ModalGuard(isModalListOpen, toggleModalList);
  const { data: signer } = useSigner();
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();

  const isBorrowDisabled = status !== LoanStatus.TO_BORROW;

  const ctaPrimaryText = status === LoanStatus.TO_REDEEM ? "Redeem" : "Repay";

  const isCtaPrimaryDisabled = status === LoanStatus.NOT_AVAILABLE;

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(error instanceof TransactionError)
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    } else if (error instanceof TransactionError) {
      openAlertNotification("error", transactionErrorMessage, 5000);
    }
  }, [error]);

  useUpdateEffect(() => {
    if (!isModalRepayOpen) {
      onModalClose?.();
    }
  }, [isModalRepayOpen]);

  useUpdateEffect(() => {
    if (!isModalRedeemOpen) {
      onModalClose?.();
    }
  }, [isModalRedeemOpen]);

  // useUpdateEffect(() => {
  //   if (!isModalListOpen) {
  //     onModalClose?.();
  //   }
  // }, [isModalListOpen]);

  const handleRepayOrRedeemButton = () => {
    onModalOpen?.(collection, tokenId);

    if (status !== LoanStatus.TO_REDEEM) {
      launcherModalRepay();
    } else {
      launcherModalRedeem();
    }
  };

  const handleListButton = async () => {
    if (signer) {
      try {
        if (isListed) {
          openAlertNotification("info", approveInWalletLiteral, 50000);

          const txHash = await cancelDebtListing(
            signer,
            changeToWrapIfCrypropunks(collection),
            tokenId
          );

          closeAlertNotification();
          setListButtonDisabled(true);

          openAlertNotification("loading", cancelListLoadingLiteral, 45000);

          waitForTransactionReceipt(txHash, 5000, (receipt) => {
            setListButtonDisabled(false);

            if (receipt.status == 1) {
              setListed(false);

              closeAlertNotification();

              openAlertNotification(
                "success",
                cancelListSucceededLiteral,
                5000
              );
            } else if (receipt.status === 0) {
              setError(
                new TransactionError(
                  `transaction with hash ${receipt.transactionHash} failed`
                )
              );
            }
          });
        } else {
          launcherModalList();
        }
      } catch (err) {
        closeAlertNotification();
        setError(handleErrors(err));
      }
    }
  };

  const handleOnListed = () => setListed(true);

  return (
    <>
      <tr className="bg-secondary hover:bg-primary/10 text-base font-medium">
        <td
          scope="row"
          className="flex items-center gap-3 py-4 px-3 font-medium"
        >
          <div className="h-16 w-16 flex items-center justify-center">
            <img className="h-16 max-w-[64px]" src={tokenUri || ""} alt="" />
          </div>
          <div className="flex items-center gap-x-2">
            <p className="">{name}</p>
            <p className="">#{tokenId}</p>
          </div>
        </td>
        <td className="px-3 items-center">
          <div className="flex gap-x-2">
            {formatWeiToEth(valuation!, 4)}
            <div className="bg-white w-5 h-5 rounded-full mt-0.3">
              <IconEthereum />
            </div>
          </div>
        </td>
        <td className="px-3">
          <div className="flex gap-x-2">
            {formatWeiToEth(liquidationPrice!, 4)}
            <div className="bg-white w-5 h-5 rounded-full">
              <IconEthereum />
            </div>
          </div>
        </td>
        <td className="py-4 px-3">
          <div className="flex items-center gap-1">
            <p>{healthFactor ? healthFactor.toFixed(2) : ""}</p>
            {!!healthFactor && (
              <HealthFactorCircle healthFactor={healthFactor} />
            )}
          </div>
        </td>
        <td className="py-4 px-3">
          {apy ? Number(apy * 100).toFixed(4) : ""}%
        </td>
        <td className="py-4 px-3">
          <div className="relative sm:max-w-xl sm:mx-auto mt-0.5">
            <div className="group flex relative border-gray-400 text-center">
              <div className="flex gap-x-2">
                {formatWeiToEth(currentBorrowAmount!)}
                <div className="bg-white w-5 h-5 rounded-full">
                  <IconEthereum />
                </div>
              </div>
              <div className="opacity-0 max-w-max ml-14 mb-2 bg-secondary text-white border border-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 px-3 pointer-events-none">
                {formatWeiToEth(currentBorrowAmount!, 8)}
              </div>
            </div>
          </div>
        </td>
        <td className="px-3">
          <div className="flex gap-x-2">
            {availableToBorrow!.isNegative()
              ? "0.0000"
              : formatWeiToEth(availableToBorrow!, 4)}
            <div className="bg-white w-5 h-5 rounded-full">
              <IconEthereum />
            </div>
          </div>
        </td>
        <td className="px-3">
          <div className="flex gap-x-5">
            <CtaSecondary
              className="w-32"
              onClick={handleListButton}
              disabled={isListButtonDisabled}
            >
              {isListed ? "Cancel list" : "List for Sale"}
            </CtaSecondary>

            <CtaSecondary
              className="w-32"
              disabled={isBorrowDisabled}
              onClick={() => {
                onBorrowClick(loan);
              }}
            >
              Borrow
            </CtaSecondary>

            {(status === LoanStatus.TO_REDEEM ||
              status === LoanStatus.TO_REPAY) && (
                <CtaRed
                  className="w-32"
                  disabled={isCtaPrimaryDisabled}
                  onClick={handleRepayOrRedeemButton}
                >
                  {ctaPrimaryText}
                </CtaRed>
              )}

            {(status === LoanStatus.ONLY_REPAY ||
              status === LoanStatus.TO_BORROW) && (
                <CtaPrimary className="w-32" onClick={handleRepayOrRedeemButton}>
                  {ctaPrimaryText}
                </CtaPrimary>
              )}
          </div>
        </td>

        {isModalRepayOpen && (
          <ModalRepay
            isOpen={isModalRepayOpen}
            toggleModal={toggleModalRepay}
            tokenId={tokenId}
            collectionAddress={collection}
            currentBorrowAmount={currentBorrowAmount!}
            apy={apy}
            valuation={valuation!}
            liquidationThreshold={liquidationThreshold}
          />
        )}
        {isModalRedeemOpen && (
          <ModalRedeem
            isOpen={isModalRedeemOpen}
            collectionAddress={collection}
            debt={currentBorrowAmount!}
            tokenId={tokenId}
            toggleModal={toggleModalRedeem}
            valuation={valuation!}
          />
        )}
        {isModalListOpen && (
          <ModalList
            isOpen={isModalListOpen}
            toggleModal={toggleModalList}
            collectionAddress={collection}
            tokenId={tokenId}
            onListed={handleOnListed}
          />
        )}
      </tr>
    </>
  );
};

export const cancelListSucceededLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your debt was delist successfully",
      },
    ],
  },
];

export const cancelListLoadingLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your debt is being delist on chain. Please wait.",
      },
    ],
  },
];
