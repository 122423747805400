import { ITransaction } from "../../types";
import { alchemy } from "../../clients";
import { AssetTransfersCategory, AssetTransfersOrder } from "alchemy-sdk";
import { validateAddress } from "../../utils";
import { MILADY_COLLECTION } from "../../../app.config";

export const retrieveMintHistoryOfAddress = async (
  address: string
): Promise<ITransaction[]> => {
  validateAddress(address);

  const res = await alchemy().core.getAssetTransfers({
    fromBlock: "0x0",
    fromAddress: "0x0000000000000000000000000000000000000000",
    toAddress: address,
    contractAddresses: [MILADY_COLLECTION.address],
    excludeZeroValue: true,
    category: [AssetTransfersCategory.ERC721],
    order: AssetTransfersOrder.DESCENDING,
    withMetadata: true,
  });

  const transfers = res.transfers.map((transfer) => {
    return {
      hash: transfer.hash,
      date: new Date(transfer.metadata.blockTimestamp),
      status: 1,
    };
  });

  return transfers.filter((transfer, index, array) => {
    return (
      array.findIndex((_transfer) => _transfer.hash === transfer.hash) === index
    );
  });
};
