import { BigNumber } from "ethers";
import { MarketItemType } from "./MarketItem";

export enum MyActivityItemStatus {
  // winning and still pending to finish
  ONGOING,
  // auction finished and lost
  LOST,
  // the user has placed a bid and someone else bid over, but still pending to finish
  BID_FAILED,
  SOLD,
  WAITING_FOR_BOT_TIME,
  CLAIM,
  LOADING,
  CLAIMED,
  REDEEMED,
  CANCELLED,
}

export enum MyActivityState {
  NEW = "new",
  ACTIVE = "active",
  AUCTIONED = "auctioned",
  LIQUIDATED = "liquidated",
  REDEEMED = "redeemed",
  SOLD = "sold",
  CANCELED = "canceled",
  BOUGHTOUT = "boughtOut",
}

export type MyActivityItem = {
  type: MarketItemType;
  nfts: Array<{
    collection: string;
    tokenId: number;
    image: string;
    name: string;
  }>;
  latestBid: BigNumber | null;
  date: Date | null;
  status: MyActivityItemStatus;
  soldTimestamp?: number | null;
  soldPrice?: BigNumber | null;
  wasSold: boolean;
  currentAmount?: BigNumber;
};

export type MyActivityItemDto = {
  buyer: string | null;
  soldTimestamp: string | null;
  category: MarketItemType;
  tokenId: number;
  collection: string;
  debtId: number;
  debtor: string;
  sellPrice: string;
  bidderAddress: Array<string>;
  bidPrice: Array<string>;
  state: MyActivityState;
  auctionEndTimestamp: string;
  redeemEndTimestamp: string | null;
  ltv: number;
  soldPrice: string;
  sellType: "fixed-price" | "auction" | "mixed";
  startBiddingPrice: string;
  valuation: string;
};
