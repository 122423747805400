import classNames from "classnames";
import { BigNumber } from "ethers";
import { type FC, useState } from "react";
import { useUpdateEffect } from "react-use";
import { CtaPrimary } from "../../../ui";
import { IconEthereum } from "../../../ui/icons";
import { formatWeiToEth } from "../../../../utils";
import { BigNumberInput } from "../../BigNumberInput";

interface Props {
  className?: string;
  nameInput: string;
  max?: BigNumber;
  onChange?: (amount: BigNumber) => void;
  onMax?: () => void;
  balance?: BigNumber;
  defaultValue?: BigNumber;
  decimals?: number;
  maxButtonHidden?: boolean;
  walletBalanceHidden?: boolean;
  required?: boolean;
  value?: BigNumber;
  readOnly?: boolean;
}

export const Input: FC<Props> = (props) => {
  const {
    className,
    balance,
    nameInput,
    max,
    onChange,
    defaultValue,
    decimals = 5,
    onMax = () => null,
    maxButtonHidden,
    walletBalanceHidden,
    value,
    readOnly = false,
    ...restProps
  } = props;

  const [weiValue, setWeiValue] = useState<BigNumber>(
    defaultValue ? BigNumber.from(defaultValue) : BigNumber.from(0)
  );

  useUpdateEffect(() => {
    onChange?.(weiValue);
  }, [weiValue]);

  useUpdateEffect(() => {
    if (!value?.eq(weiValue) && value) {
      setWeiValue(value);
    }
  }, [value]);

  const handleChange = (value: string) => {
    const weiVal = value ? BigNumber.from(value) : BigNumber.from(0);
    setWeiValue(weiVal);
  };

  const handleMaxButton = () => {
    if (max) {
      setWeiValue(max);
    }
    onMax();
  };
  return (
    <>
      <div className={classNames(``, [className])}>
        <label className="border-2 rounded-xl px-2.5 pt-2 pb-2 grid grid-cols-6 grid-rows-2">
          <span
            className={classNames(
              "text-md xs:text-md block max-3xs:hidden",
              walletBalanceHidden ? "col-[1_/_span_5]" : "col-[1_/_span_1]"
            )}
          >
            Amount
          </span>
          {!walletBalanceHidden && (
            <span className="col-[2_/_span_4] text-md xs:text-md flex justify-center gap-x-1">
              Wallet balance:{" "}
              <div className="flex">
                <p className="flex font-medium">
                  {balance && formatWeiToEth(balance!)}
                </p>
                <div className="bg-white ml-1 w-4 h-4 rounded-full flex justify-center mt-[3.5px]">
                  <IconEthereum />
                </div>
              </div>
            </span>
          )}

          <BigNumberInput
            decimals={decimals}
            name={nameInput}
            className={classNames(
              "col-[1_/_span_5] row-[2_/_span_1] bg-transparent w-full  !outline-none text-2xl xs:text-3xl xs:ml-2 ml-1.5",
              readOnly ? "text-[#9A9A9A] font-bold" : "text-white"
            )}
            onChange={handleChange}
            value={weiValue.toString()}
            readOnly={readOnly}
            {...restProps}
          />

          <div className="w-full h-full flex justify-center items-center">
            <div className="w-full h-5/6 flex border border-white rounded-full items-center">
              <div className="relative h-full aspect-square	 bg-white rounded-full">
                <IconEthereum />
              </div>
              <span className="ml-2 text-sm">ETH</span>
            </div>
          </div>
          {!maxButtonHidden && (
            <div className="w-full h-full flex justify-center items-center">
              <CtaPrimary
                onClick={handleMaxButton}
                className="w-full py-1"
                type="button"
                disabled={!max || readOnly}
              >
                MAX
              </CtaPrimary>
            </div>
          )}
        </label>
      </div>
    </>
  );
};
