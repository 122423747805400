import { PUNKS_COLLECTION } from "../../../app.config";
import { equalIgnoreCase } from "../../utils";
import { isCryptopunks } from "./isCryptopunks";

export const adjustAddressIfIsCryptopunks = (address: string): string => {
  if (isCryptopunks(address)) {
    if (equalIgnoreCase(address, PUNKS_COLLECTION.wrapAddress!)) {
      return PUNKS_COLLECTION.address;
    } else {
      return PUNKS_COLLECTION.wrapAddress!;
    }
  } else {
    return address;
  }
};
