import { isCryptopunks } from "./isCryptopunks";

export const getClaimArguments = (
  collectionAddress: string,
  tokenId: number
): Array<any> => {
  if (isCryptopunks(collectionAddress)) {
    return [tokenId];
  } else {
    return [collectionAddress, tokenId];
  }
};
