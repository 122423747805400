import {type FC} from "react";
import classNames from "classnames";

type Props = {
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const ButtonNavBar: FC<Props> = (props) => {
  const { children, selected, ...restProps } = props;

  return (
    <button className="">
      <div
        className={classNames(
          selected
            ? "py-3 px-0.5 rounded-xl bg-gradient-to-r from-purple-400 via-white to-primary"
            : ""
        )}
        {...restProps}
      >
        <span
          className={classNames(
            selected ? "font-bold" : "",
            "px-10 py-3 bg-secondary text-white rounded-xl"
          )}
        >
          {children}
        </span>
      </div>
    </button>
  );
};
