import Image from "next/image";
import { useEffect, useState, type FC } from "react";
import { IoMdClose } from "react-icons/io";
import { CtaPrimary, CtaRed, CtaSecondary } from "../../ui";
import { withModal } from "./shared";
import * as PushAPI from "@pushprotocol/restapi";
import { useAccount, useSigner } from "wagmi";
import { equalIgnoreCase } from "../../../utils";
import { handleErrors } from "../../../logic/helpers";
import { useAlertNotification } from "../../providers";
import { PortableText } from "../../../types";
import Link from "next/link";
import { CONTRACT_ADDRESSES, METAMASK } from "../../../../app.config";

type Props = {
  className?: string;
  isOpen: boolean;
  toggleModal: (nextValue: boolean) => void;
};

export const ModalNotifications: FC<Props> = withModal((props) => {
  const { toggleModal } = props;
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const [isSubscribed, setSuscribed] = useState<boolean>();
  const [error, setError] = useState<Error | null>(null);
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();
  const env =
    METAMASK.chainId === 5 ? "staging" : "prod";
  const channelAddress = `eip155:${METAMASK.chainId}:${CONTRACT_ADDRESSES.pushChannel}`;
  const eipPrefix = `eip155:${METAMASK.chainId}:`;

  useEffect(() => {
    if (error && !isAlertNotificationOpen) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const subscriptions: Array<{ channel: string }> =
            await PushAPI.user.getSubscriptions({
              user: address,
              env,
            });

          const isChannelInSubscriptions = subscriptions.some(({ channel }) =>
            equalIgnoreCase(channel, CONTRACT_ADDRESSES.pushChannel)
          );

          setSuscribed(isChannelInSubscriptions);
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  const handleSubscribe = async () => {
    if (signer && address) {
      try {
        await PushAPI.channels.subscribe({
          // @ts-ignore
          signer,
          channelAddress,
          userAddress: `${eipPrefix}${address}`,
          env,
        });

        setSuscribed(true);

        openAlertNotification("success", successfullySubscribedLiteral, 5000);
      } catch (err) {
        setError(handleErrors(err));
      }
    }
  };

  const handleUnsubscribe = async () => {
    if (signer && address) {
      try {
        await PushAPI.channels.unsubscribe({
          // @ts-ignore
          signer,
          channelAddress,
          userAddress: `${eipPrefix}${address}`,
          env,
        });

        setSuscribed(false);

        openAlertNotification("success", successfullyunsubscribedLiteral, 5000);
      } catch (err) {
        setError(handleErrors(err));
      }
    }
  };

  return (
    <div className="relative bg-secondary border-glow-sm border-2 rounded-2xl py-24 px-20 flex flex-col justify-center gap-7">
      <button
        type="button"
        className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
        onClick={() => toggleModal(false)}
      >
        <IoMdClose className="w-full h-full" />
      </button>
      <div className="flex flex-col justify-center items-center gap-5 text-white">
        <h2 className="text-white font-bold text-2xl">Notifications</h2>
        <p>
          By enabling push notifications, you'll be immediately notified when:
        </p>
        <ul className="list-disc">
          <li>
            Your NFT is up for auction due to liquidation of your position
          </li>
          <li>An auction in which you've placed a bid ends</li>
          <li>You are outbid by another bidder in an active auction</li>
          <li>A new auction becomes available for you to bid</li>
        </ul>
      </div>
      <div className="flex flex-col justify-center items-center gap-5">
        {!isSubscribed ? (
          <CtaPrimary
            className="w-72 flex items-center justify-center gap-2"
            onClick={handleSubscribe}
            disabled={isSubscribed === undefined}
          >
            <Image src="/assets/images/push-icon.svg" width="15" height="15" />
            Activate notifications
          </CtaPrimary>
        ) : (
          <CtaRed
            className="w-72 flex items-center justify-center gap-2"
            onClick={handleUnsubscribe}
          >
            <Image src="/assets/images/push-icon.svg" width="15" height="15" />
            Disable notifications
          </CtaRed>
        )}
        <CtaSecondary className="w-72 ">
          <Link legacyBehavior href="https://twitter.com/Unlockd_Finance">
            <a
              className="flex items-center justify-center gap-2"
              target="_blank"
            >
              <Image src="/assets/images/twitter.svg" width="15" height="15" />
              Follow us on Twitter
            </a>
          </Link>
        </CtaSecondary>
      </div>
    </div>
  );
});

const successfullySubscribedLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "You have activated the notifications correctly",
      },
    ],
  },
];

const successfullyunsubscribedLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "You have desactivated the notifications correctly",
      },
    ],
  },
];
