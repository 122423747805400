import { BigNumber } from "ethers";
import { LoanStatus } from "../../types";

export const determineLoanStatus = (
  statusFromServer: string,
  redeemEnd: number,
  healthFactor: number,
  availableToBorrow: BigNumber
): LoanStatus => {
  if (statusFromServer === "auctioned") {
    if (redeemEnd! > Date.now()) {
      return LoanStatus.TO_REDEEM;
    } else {
      return LoanStatus.NOT_AVAILABLE;
    }
  } else if (healthFactor! <= 1) {
    return LoanStatus.TO_REPAY;
    // } else if (statusFromServer === "active") {
    // this line is not correct and was put there because the server is not working properly
  } else if (statusFromServer === "active" || statusFromServer === null) {
    if (availableToBorrow.isZero() || availableToBorrow.isNegative()) {
      return LoanStatus.ONLY_REPAY;
    } else {
      return LoanStatus.TO_BORROW;
    }
  } else {
    return LoanStatus.NOT_AVAILABLE;
  }
};
