import { callContract } from "../utils/callContract";
import debtTokenAbi from "../abis/debtToken.json";
import wethAbi from "../abis/uToken.json";
import protocolDataProviderAbi from "../abis/unlockdProtocolDataProvider.json";
import { PEP, RAY, SECONDS_PER_YEAR } from "../constants";
import { SingleCall } from "../types";
import { multicall } from "../utils/multicall";
import { ConnectionError } from "../errors";
import {
  calculateApyBorrow,
  calculateApyEarn,
  equalIgnoreCase,
} from "../utils";
import { CHAIN, CONTRACT_ADDRESSES, YEARN } from "../../app.config";

export const getDepositApy = async (): Promise<number> => {
  if (CHAIN === "mainnet") {
    const totalSupplyUTokenCall = new SingleCall(
      CONTRACT_ADDRESSES.uWeth,
      wethAbi,
      "totalSupply",
      []
    );

    const totalSupplyDebtTokenCall = new SingleCall(
      CONTRACT_ADDRESSES.debtToken,
      debtTokenAbi,
      "totalSupply",
      []
    );

    const reserveDataCall = new SingleCall(
      CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
      protocolDataProviderAbi,
      "getReserveData",
      [CONTRACT_ADDRESSES.weth]
    );

    const [
      yearnResponse,
      [
        //@ts-ignore
        [totalSupplyUToken],
        //@ts-ignore
        [totalSupplyDebtToken],
        //@ts-ignore
        { liquidityRate, variableBorrowRate },
        //@ts-ignore
        // { liquidityRate },
        //@ts-ignore
      ],
    ] = await Promise.all([
      fetch(`api/proxy/${YEARN.apiUrl}/vaults/all`),
      multicall(
        totalSupplyUTokenCall,
        totalSupplyDebtTokenCall,
        reserveDataCall
      ),
    ]);

    if (!yearnResponse.ok) {
      throw new ConnectionError("failed fetching apy data from yearn");
    }

    const yearn = await yearnResponse.json();

    const {
      apy: { net_apy: yearnApy },
      // @ts-ignore
    } = yearn.find(({ address }) =>
      equalIgnoreCase(address, CONTRACT_ADDRESSES.yearn)
    );

    const utilizationRate =
      (totalSupplyDebtToken.div(PEP).toString() /
        totalSupplyUToken.div(PEP).toString()) *
      100;

    const borrowApy = calculateApyBorrow(Number(variableBorrowRate));

    return calculateApyEarn(
      liquidityRate,
      utilizationRate / 100,
      yearnApy,
      borrowApy
    );
  } else {
    const { liquidityRate } = await callContract(
      CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
      protocolDataProviderAbi,
      null,
      "getReserveData",
      [CONTRACT_ADDRESSES.weth]
    );

    //@ts-ignore
    const aprNumber = Number(liquidityRate) / RAY;

    const apyNumber =
      (aprNumber / parseInt(SECONDS_PER_YEAR) + 1) **
      parseInt(SECONDS_PER_YEAR) -
      1;

    return apyNumber;
  }
};
