import { Log } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEventV2,
} from "../../utils";
import abi from "../../abis/gnosisSafe.json";
import { BigNumber } from "ethers";

type SafeExSuccessData = {
  txHash: string;
  payment: BigNumber;
};

export const setSafeExSuccessListener = async (
  safeSmartAccountAddress: string,
  callback: (data: SafeExSuccessData) => void
): Promise<() => void> => {
  const unlistener = await listenContractEventV2(
    // @ts-ignore
    getEventSignature(abi, "ExecutionSuccess"),
    safeSmartAccountAddress,
    [],
    ({ data, topics }: Log) => {
      //@ts-ignore
      const dataDecoded = decodeTransactionData(
        abi,
        "ExecutionSuccess",
        data,
        topics
      );

      callback(dataDecoded as unknown as SafeExSuccessData);
    }
  );

  return unlistener;
};
