import { BigNumber } from "ethers";
import { UnexpectedError } from "../errors";
// import { UnexpectedError } from "../errors";

export const getReservoirData = async (
  collection: string,
  tokenId: number
): Promise<BigNumber | null> => {
  const url = `api/unlockd-api/protocol/marketplace/top-external-bid?collection=${collection}&tokenId=${tokenId}`;

  const response = await fetch(url);

  if (!response.ok) {
    throw new UnexpectedError("failed fetching data from reservoir");
  }
  const price = await response.text();

  return price ? BigNumber.from(price) : null;
};
