import { BigNumber } from "ethers";
import { ConnectionError } from "../errors/errors";
import { DEFAULT_LIQ_THRESHOLD_VALUE } from "../../app.config"
import { calculateAvailableToBorrow } from "./helpers/calculateAvailableToBorrow";
import { calculateLiquidationPrice } from "./helpers/calculateLiquidationPrice";
import { getNftDebtData } from "./helpers";
import { NftBorrowData } from "../types";

type ValuationResponse = {
  calculations: {
    [key: string]: {
      [key: string]: {
        priceInEth: string;
        latestVolatility: string;
        token: number;
        collection: string;
        status: string;
        ltv: number;
      };
    };
  };
  collections: {
    [key: string]: {
      historicalVolatility: Array<number>;
      count: string;
    };
  };
};

export const retrieveNftDataToBorrow = async (
  nft: NftBorrowData
): Promise<NftBorrowData> => {
  const { collection, tokenId, name, tokenUri, apy } = nft;

  const { totalDebt: currentBorrowAmount } = await getNftDebtData(
    collection,
    tokenId
  );

  const query: { nfts: Array<{ collection: string; tokenId: number }> } = {
    nfts: [{ collection, tokenId }],
  };

  const serverResponse = await fetch("api/unlockd-api/protocol/prices", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(query),
  });

  if (!serverResponse.ok) {
    throw new ConnectionError("failed on getting token info from server");
  }

  const { calculations }: ValuationResponse =
    await serverResponse.json();

  const { priceInEth, ltv } =
    calculations[collection.toLowerCase()][tokenId];

  const valuation: BigNumber = BigNumber.from(priceInEth);

  const availableToBorrow = calculateAvailableToBorrow(
    valuation,
    ltv,
    BigNumber.from(0)
  );

  const liquidationThreshold: string = DEFAULT_LIQ_THRESHOLD_VALUE.toString();

  const liquidationPrice = calculateLiquidationPrice(
    BigNumber.from(0),
    BigNumber.from(liquidationThreshold)
  );

  return {
    collection,
    tokenId,
    name,
    tokenUri,
    apy,
    isDeposited: false,
    amountSelectedToBorrow: BigNumber.from(0),
    valuation,
    availableToBorrow,
    liquidationPrice,
    ltv: ltv.toString(),
    liquidationThreshold,
    currentBorrowAmount,
  };
};
