import { BigNumber } from "ethers";
import {
  CONTRACT_ADDRESSES,
  DEFAULT_LIQ_THRESHOLD_VALUE,
} from "../../../app.config";
import { CallsGroupedByMethod, MarketItem, MarketItemType } from "../../types";
import { equalIgnoreCase } from "../../utils";
import { determineAuctionStatus } from "./determineAuctionStatus";
import { getCollectionNameByContractAddress } from "./getCollectionNameByContractAddress";
import { calculateAvailableToBorrow } from "./calculateAvailableToBorrow";
import { MarketItemPrePopulated } from "../retrieveMarketplaceItems";
import lendpoolAbi from "../../abis/lendpoolUnlockd.json";
import { adjustAddressIfIsCryptopunks } from "./adjustAddressIfIsCryptopunks";
import { multicall } from "../../utils/multicall";

export const populateLoansListed = async (
  items: Array<MarketItemPrePopulated>,
  address: string
): Promise<Array<MarketItem>> => {
  const nftsDebtDataCalls = new CallsGroupedByMethod(
    CONTRACT_ADDRESSES.lendpool,
    lendpoolAbi,
    "getNftDebtData",
    items.map(({ collection, tokenId }) => [
      adjustAddressIfIsCryptopunks(collection),
      tokenId,
    ])
  );

  const [nftsDebtData] = await multicall(nftsDebtDataCalls);

  const itemsToReturn: Array<MarketItem> = items.map(
    (
      {
        collection,
        tokenId,
        debtId,
        debtor,
        auctionEndTimestamp,
        bidPrice,
        bidderAddress,
        ltv,
        sellPrice,
        sellType,
        startBiddingPrice,
        category,
        image,
        valuation: _valuation,
        liquidationThreshold,
        reservoirBid,
        healthFactor,
      },
      index
    ) => {
      const valuation = BigNumber.from(_valuation);
      const debt = BigNumber.from(nftsDebtData![index].totalDebt);

      const bidder: string | undefined =
        bidderAddress && bidderAddress.length > 0
          ? bidderAddress.at(-1)
          : undefined;
      const biddingEnd =
        sellType !== "fixed-price" ? auctionEndTimestamp : undefined;

      let minBid: BigNumber | undefined;

      if (sellType !== "fixed-price") {
        if (bidPrice && bidPrice.length > 0) {
          minBid = BigNumber.from(bidPrice.at(-1)).mul(102).div(100);
        } else {
          minBid = BigNumber.from(startBiddingPrice);
        }
      }

      return {
        type: category,
        sellType,
        nfts: [
          {
            collection,
            tokenId,
            image: image!,
            name: getCollectionNameByContractAddress(collection),
          },
        ],
        id: debtId,
        owner: debtor,
        isBuyNowAvailable: sellType !== "auction",
        isAuctionAvailable: sellType !== "fixed-price",
        buyNowPrice:
          sellType !== "auction" ? BigNumber.from(sellPrice) : undefined,
        valuation,
        debt,
        availableToBorrow: calculateAvailableToBorrow(
          valuation,
          ltv ? ltv : DEFAULT_LIQ_THRESHOLD_VALUE,
          debt
        ),
        healthFactor: parseFloat(healthFactor),
        ltv: ltv || DEFAULT_LIQ_THRESHOLD_VALUE.toString(),
        biddingEnd,
        latestBid:
          sellType !== "fixed-price" && bidPrice && bidPrice.length > 0
            ? BigNumber.from(bidPrice.at(-1))
            : undefined,
        bidder: sellType !== "fixed-price" && bidder ? bidder : undefined,
        minBid,
        auctionStatus:
          sellType !== "fixed-price"
            ? determineAuctionStatus(
                MarketItemType.LOAN_LISTED,
                debtor,
                address,
                biddingEnd!,
                bidder,
                true
              )
            : undefined,
        isOwnDebt: equalIgnoreCase(debtor, address),
        reservoirBid,
      };
    }
  );

  return itemsToReturn;
};
