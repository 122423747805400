import { useEffect, useState, type FC } from "react";
import classNames from "classnames";
import { CtaSecondary } from "../../ui";
import { CollectionItem } from "./CollectionItem";
import { CollectionData } from "../../../types";
import { getCollectionsData } from "../../../logic";
import { COLLECTIONS } from "../../../../app.config";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface Props {
  className?: string;
}

const LIMIT = 5;

export const CollectionsPanel: FC<Props> = (props) => {
  const { className } = props;

  const [page, setPage] = useState<number>(0);
  const [collectionsData, setCollectionsData] = useState<Array<CollectionData>>(
    []
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const collectionsDataRetrieved = await getCollectionsData();

      setCollectionsData(collectionsDataRetrieved);

      setLoading(false);
    })();
  }, []);

  const handleSeeMore = () => {
    setPage(page + 1);
  };

  return (
    <>
      <section
        className={classNames(
          "mx-auto p-12 bg-secondary rounded-xl shadow-lighting",
          [className]
        )}
      >
        <h3 className="font-bold text-xl">Collections</h3>
        <table className="table-fixed w-full">
          <thead>
            <th className="font-normal w-[400px]"></th>
            <th className="font-normal">Total NFTs locked</th>
            <th className="font-normal">TVL</th>
            <th className="font-normal">Valuation range</th>
          </thead>
          <tbody>
            {collectionsData
              .slice(0, LIMIT * (page + 1))
              .map((collectionData, index) => (
                <CollectionItem
                  key={collectionData.address}
                  index={index + 1}
                  collectionData={collectionData}
                  isLastOne={index === collectionsData.length - 1}
                />
              ))}
          </tbody>
        </table>
        {!isLoading && COLLECTIONS.length > (page + 1) * LIMIT && (
          <div className="mt-10">
            <CtaSecondary className="mx-auto w-40" onClick={handleSeeMore}>
              See More
            </CtaSecondary>
          </div>
        )}
        {isLoading && (
          <div className="w-full flex mx-auto mt-10 justify-center items-center">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        )}
      </section>
    </>
  );
};
