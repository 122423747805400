import { BigNumber, Signer } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/debtToken.json";
import { UINT_256_MAX } from "../constants";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const approveDebtToken = async (signer: Signer): Promise<string> => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.debtToken,
    abi,
    signer,
    "approveDelegation",
    [CONTRACT_ADDRESSES.wethGateway, BigNumber.from(UINT_256_MAX)]
  );

  return transaction.hash;
};
