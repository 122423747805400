import {IoMdClose} from "react-icons/io";
import {type FC, useState} from "react";
import {ConnectorNotFoundError, useConnect, useNetwork} from "wagmi";
import {CtaPrimary, RichText} from "../../ui";
import type {ModalConnectWalletsStatics} from "../../../types";
import {IconMetamask, IconWalletConnect} from "../../ui/icons";
import {ErrorMessage, withModal} from "./shared";
import {useModalConnectWalletsStatics} from "../../statics";

interface Props {
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
}

interface Client {
  id: string;
  name: "MetaMask" | "WalletConnect";
  connect: Function;
}

type Controller = (props: Props) => Props & {
  statics: Omit<ModalConnectWalletsStatics, "feedbacks">;
  errorMessage?: string;
  clients: Client[];
};

const useController: Controller = (props) => {
  const { toggleModal, ...restProps } = props;
  const statics = useModalConnectWalletsStatics();
  const { feedbacks, ...restStatics } = statics;
  const { chains } = useNetwork();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { connect, connectors } = useConnect({
    onError(error) {
      if (error instanceof ConnectorNotFoundError)
        setErrorMessage(feedbacks.connectorNotFound);
      else if (error.code === 4001)
        return setErrorMessage(feedbacks.userRejectRequest);
      else if (error.code === -32002)
        return setErrorMessage(
          "Already waiting for user interaction, please check your wallet."
        );
      else setErrorMessage(feedbacks.unexpectedErrorConnecting);
    },
    onSuccess() {
      toggleModal(false);
    },
  });

  const clients: Client[] = connectors.map((connector) => ({
    id: connector.id,
    name: connector.name as any,
    connect() {
      connect({ connector, chainId: chains[0]?.id });
    },
  }));

  return {
    statics: restStatics,
    errorMessage,
    clients,
    toggleModal,
    ...restProps,
  };
};

export const ModalConnectWallets: FC<Props> = withModal((props: Props) => {
  const { statics, errorMessage, clients, toggleModal } = useController(props);
  const { title, legalText } = statics;

  return (
    <>
      <div
        className="bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] overflow-hidden
        border-2 rounded-3xl xs:px-6 px-5 pb-6 pt-8 xs:pb-8"
      >
        <button
          className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
          onClick={() => toggleModal(false)}
        >
          <IoMdClose className="w-full h-full text-white" />
        </button>
        <h5 className="xs:mb-8 mb-5 text-center text-base xs:text-lg font-bold text-white">
          {title}
        </h5>
        <div className="grid gap-4 xs:mb-8 mb-5 w-60 max-w-full mx-auto">
          {clients.map((client, i) => (
            <CtaPrimary
              key={client.id + i.toString()}
              className="w-full mx-auto !flex items-center justify-center"
              onClick={() => client.connect()}
            >
              <div className="relative w-6 h-6 mr-2 flex items-center justify-center">
                {client.name === "MetaMask" && <IconMetamask />}
                {client.name === "WalletConnect" && <IconWalletConnect />}
              </div>
              <span>{client.name}</span>
            </CtaPrimary>
          ))}
        </div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <div className="w-80 text-left text-2xs xs:text-xs max-w-full text-white">
          <RichText value={legalText} />
        </div>
      </div>
    </>
  );
});
