import { EventFilter } from "ethers";
import { lookUpProvider } from "../logic/helpers";
import { Log } from "../types";
import { parseArgHexWithZeroPad } from "./parseArgHexWithZeroPad";
import { unlistenContractEvent } from "./unlistenContractEvent";

// TODO decode data directly for here

export const listenContractEventV2 = async (
  eventSignature: string,
  contractAddress: string,
  args: Array<string | number | undefined>,
  callback: (log: Log) => void,
  once: boolean = true
): Promise<() => void> => {
  let off = { value: false };

  const params = args.map((element: string | number | undefined) => {
    if (element) {
      return parseArgHexWithZeroPad(element);
    } else {
      return;
    }
  });

  const provider = lookUpProvider();

  const event: EventFilter = {
    address: contractAddress,
    // @ts-ignore
    topics: [eventSignature, ...params],
  };

  const blockNumber = await provider.getBlockNumber();

  // console.log("blockNumber: " + blockNumber);

  (function setListener() {
    provider.on(event, (data) => {
      // console.log("blockFromEvent: " + data.blockNumber);
      if (data.blockNumber > blockNumber) {
        if (!off.value) {
          callback(data);
        }

        if (once) {
          // console.log("listener off", eventTopic, Date.now);

          unlistenContractEvent(event, provider);
        }
      }
    });

    // console.log("listener on", eventTopic, contractAddress, args);
  })();

  return () => {
    // console.log("listener off", eventTopic, Date.now);
    off.value = true;
    unlistenContractEvent(event, provider);
  };
};
