import { BigNumber, Signer } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { callContract } from "../utils";
import abi from "../abis/wethGateway.json";

export const buyLoanListed = async (
  signer: Signer,
  collection: string,
  tokenId: number,
  onBehalfOf: string,
  amount: BigNumber
) => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.wethGateway,
    abi,
    signer,
    "buyDebtETH",
    [collection, tokenId, onBehalfOf],
    { value: amount }
  );

  return transaction.hash;
};
