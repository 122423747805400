import { PortableText } from "../types";

export const approveInWalletLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Approve the transaction in your wallet.",
      },
    ],
  },
];
