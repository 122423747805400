import classNames from "classnames";
import { type FC } from "react";
import { useUnlockdSession } from "../../../logic";
import { WithCSR } from "../../ui";

interface Props {
  className?: string;
}

export const BorrowSubHeader: FC<Props> = (props) => {
  const { className } = props;
  const { isWalletConnected } = useUnlockdSession();

  return (
    <>
      <section className={classNames(`mt-14 mb-8 text-center`, [className])}>
        <h2 className="text-center w-full text-xl font-semibold md:text-3xl xs:text-2xl mt-b xs:mb-3">
          Borrow
        </h2>
        <WithCSR>
          {isWalletConnected && (
            <>
              <p className="text-sm">
                Borrow crypto against your NFTs from supported collections.
              </p>
              <p className="font-bold text-lg mt-4">Select an NFT to deposit</p>
            </>
          )}
        </WithCSR>
      </section>
    </>
  );
};
