import { LockeysHolderType } from "../types";
import { isSomeInRange } from "../utils/numbers";
import { parseEnvRanges } from "./parseEnvRanges";
import { alchemy } from "../clients";
import { validateAddress } from "../utils";
import {
  CONTRACT_ADDRESSES,
  TOKEN_ID_RANGES_TEAM,
  TOKEN_ID_RANGES_KEY_SUPPORTER,
  TOKEN_ID_RANGES_ONE_ON_ONE
} from "../../app.config";

const RANGES_ONE_ON_ONE = parseEnvRanges(
  TOKEN_ID_RANGES_ONE_ON_ONE!
);
const RANGES_TEAM = parseEnvRanges(
  TOKEN_ID_RANGES_TEAM!
);
const RANGES_KEY_SUPPORTER = parseEnvRanges(
  TOKEN_ID_RANGES_KEY_SUPPORTER!
);

type fun = (address: string) => Promise<LockeysHolderType>;

export const retrieveLockeysHolderType: fun = async (address) => {
  validateAddress(address);

  //@ts-ignore
  const nftsForOwner = await alchemy.TEMP_MAINNET().nft.getNftsForOwner(address, {
    contractAddresses: [CONTRACT_ADDRESSES.theLockeys],
  });

  //@ts-ignore
  const tokenIds = nftsForOwner.ownedNfts.map((nft) => parseInt(nft.tokenId));

  let lockeysHolderType: LockeysHolderType;

  if (isSomeInRange(tokenIds, RANGES_TEAM)) {
    lockeysHolderType = LockeysHolderType.TEAM;
  } else if (isSomeInRange(tokenIds, RANGES_ONE_ON_ONE)) {
    lockeysHolderType = LockeysHolderType.ONE_ON_ONE;
  } else if (isSomeInRange(tokenIds, RANGES_KEY_SUPPORTER)) {
    lockeysHolderType = LockeysHolderType.KEY_SUPPORTER;
  } else if (tokenIds.length > 0) {
    lockeysHolderType = LockeysHolderType.HOLDER;
  } else {
    lockeysHolderType = LockeysHolderType.NON_HOLDER;
  }

  return lockeysHolderType;
};
