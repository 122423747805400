import { CONTRACT_ADDRESSES } from "../../../app.config";
import { ListEventData, Log } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import abi from "../../abis/debtMarket.json";

export const setListListener = (
  callback: (dataDecoded: ListEventData) => void
): (() => void) => {
  const unlistener = listenContractEvent(
    getEventSignature(abi, "DebtListingCreated"),
    CONTRACT_ADDRESSES.debtMarket,
    [],
    ({ data, topics }: Log) => {
      const dataDecoded = decodeTransactionData(
        abi,
        "DebtListingCreated",
        data,
        topics
      );

      callback(dataDecoded as unknown as ListEventData);
    },
    false
  );

  return unlistener;
};
