import { callContract } from "../utils";
import abi from "../abis/unlockdProtocolDataProvider.json";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const getLoanDataByCollateral = async (
  collectionAddress: string,
  tokenId: number
) => {
  const data = await callContract(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    abi,
    null,
    "getLoanDataByCollateral",
    [collectionAddress, tokenId]
  );

  return data;
};
