import { BigNumber } from "ethers";
import { ConnectionError } from "../../errors/errors";
import { LoanStatus, NftBorrowData, CallsGroupedByMethod } from "../../types";
import { calculateApyBorrow, equalIgnoreCase, getImage } from "../../utils";
import { getCollectionNameByContractAddress } from "./getCollectionNameByContractAddress";
import lendpoolAbi from "../../abis/lendpoolUnlockd.json";
import debtMarketAbi from "../../abis/debtMarket.json";
import dataProviderAbi from "../../abis/unlockdProtocolDataProvider.json";
import { calculateHealthFactor } from "./calculateHealthFactor";
import { calculateAvailableToBorrow } from "./calculateAvailableToBorrow";
import { calculateLiquidationPrice } from "./calculateLiquidationPrice";
import { determineLoanStatus } from "./determineLoanStatus";
import { getReserveData } from "./getReserveData";
import { adjustAddressIfIsCryptopunks } from "./adjustAddressIfIsCryptopunks";
import { multicall } from "../../utils/multicall";
import {
  CHAIN,
  CONTRACT_ADDRESSES,
  DEFAULT_LIQ_THRESHOLD_VALUE,
  FUNCTIONALITIES,
  MOONBIRDS_COLLECTION,
} from "../../../app.config";
import { getMoonbirdImage } from "./getMoonbirdImage";

type NftData = {
  collectionAddress: string;
  tokenId: number;
  isDeposited: boolean;
  collectionName: string;
  tokenUri: string;
  image: string;
  currentBorrowAmount?: BigNumber;
  ltv?: string;
  liquidationThreshold?: number;
  redeemEnd?: number;
  isListed?: boolean;
};

type RetrieveNftData = (
  nftDatas: Array<NftData>
) => Promise<Array<NftBorrowData>>;

type Calculations = {
  [key: string]: {
    [key: string]: {
      priceInEth: string;
      latestVolatility: string;
      token: number;
      collection: string;
      status: string;
      ltv: number;
    };
  };
};

type Collections = {
  [key: string]: {
    historicalVolatility: Array<number>;
    count: string;
  };
};

type ValuationResponse = {
  calculations: Calculations;
  collections: Collections;
};

const isDebtMarketEnabled = !FUNCTIONALITIES.some(
  ({ id, enabled }) => id === "debt-market" && !enabled
);

export const retrieveNftsData: RetrieveNftData = async (
  nftDatas: Array<NftData>
): Promise<Array<NftBorrowData>> => {
  let calculations: Calculations;

  const imagesRetrievals = nftDatas.map(
    ({ image, tokenUri, collectionAddress, tokenId }) => {
      if (image) return image;
      if (tokenUri) return getImage(tokenUri!);
      if (
        CHAIN === "mainnet" &&
        equalIgnoreCase(collectionAddress, MOONBIRDS_COLLECTION.address)
      ) {
        return getMoonbirdImage(tokenId);
      }
    }
  );

  const [images, { variableBorrowRate }] = await Promise.all([
    Promise.all(imagesRetrievals),
    getReserveData(),
  ]);

  const depositedNfts = nftDatas.filter(({ isDeposited }) => isDeposited);

  if (depositedNfts.length) {
    const nftsDebtDataCalls = new CallsGroupedByMethod(
      CONTRACT_ADDRESSES.lendpool,
      lendpoolAbi,
      "getNftDebtData",
      []
    );

    const collateralDataCalls = new CallsGroupedByMethod(
      CONTRACT_ADDRESSES.lendpool,
      lendpoolAbi,
      "getNftCollateralData",
      []
    );

    const debtIdCalls = new CallsGroupedByMethod(
      CONTRACT_ADDRESSES.debtMarket,
      debtMarketAbi,
      "getDebtId",
      []
    );

    const query: { nfts: Array<{ collection: string; tokenId: number }> } = {
      nfts: [],
    };

    depositedNfts.forEach(({ collectionAddress, tokenId }) => {
      nftsDebtDataCalls.callsArguments.push([
        adjustAddressIfIsCryptopunks(collectionAddress),
        tokenId,
      ]);

      collateralDataCalls.callsArguments.push([
        adjustAddressIfIsCryptopunks(collectionAddress),
        tokenId,
        CONTRACT_ADDRESSES.weth,
      ]);

      debtIdCalls.callsArguments.push([
        adjustAddressIfIsCryptopunks(collectionAddress),
        tokenId,
      ]);

      query.nfts.push({ collection: collectionAddress, tokenId });
    });

    const [serverResponse, [nftsDebtData, collateralData, debtIds]] =
      await Promise.all([
        fetch("api/unlockd-api/protocol/prices", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(query),
        }),
        isDebtMarketEnabled
          ? multicall(nftsDebtDataCalls, collateralDataCalls, debtIdCalls)
          : multicall(nftsDebtDataCalls, collateralDataCalls),
      ]);

    if (!serverResponse.ok) {
      throw new ConnectionError("failed on getting token info from server");
    }

    const { calculations: calculationsFromServer }: ValuationResponse =
      await serverResponse.json();

    calculations = calculationsFromServer;

    const auctionedNfts = nftDatas.filter(({ collectionAddress, tokenId }) => {
      if (
        !calculations[collectionAddress.toLowerCase()] ||
        !calculations[collectionAddress.toLowerCase()][tokenId]
      ) {
        return false;
      }

      const nftFromServer =
        calculations[collectionAddress.toLowerCase()][tokenId]!;

      return nftFromServer.status === "auctioned";
    });

    const getNftConfigurationCalls = new CallsGroupedByMethod(
      CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
      dataProviderAbi,
      "getNftConfigurationDataByTokenId",
      []
    );

    auctionedNfts.forEach(({ collectionAddress, tokenId }) => {
      getNftConfigurationCalls.callsArguments.push([
        adjustAddressIfIsCryptopunks(collectionAddress),
        tokenId,
      ]);
    });

    const [nftConfigurationResults] = await multicall(getNftConfigurationCalls);

    auctionedNfts.forEach((auctionedNft, index) => {
      const { configTimestamp, redeemDuration } =
        nftConfigurationResults![index].configData;

      auctionedNft.redeemEnd =
        configTimestamp.toNumber() * 1000 +
        redeemDuration.toNumber() * 60 * 1000;
    });

    depositedNfts.forEach((depositedNft, index) => {
      depositedNft.currentBorrowAmount = nftsDebtData![index].totalDebt;
      depositedNft.liquidationThreshold =
        collateralData![index].liquidationThreshold;

      if (isDebtMarketEnabled) {
        depositedNft.isListed = !debtIds[index][0].isZero();
      }
    });
  }

  const apy = calculateApyBorrow(parseInt(variableBorrowRate));

  const nftBorrowDatas: NftBorrowData[] = nftDatas.reduce(
    (
      accum: NftBorrowData[],
      {
        tokenId,
        isDeposited,
        collectionAddress,
        collectionName,
        currentBorrowAmount,
        liquidationThreshold,
        redeemEnd,
        isListed,
      },
      index
    ) => {
      const tokenUri = images[index];

      if (
        !calculations ||
        !calculations[collectionAddress.toLowerCase()] ||
        !calculations[collectionAddress.toLowerCase()][tokenId]
      ) {
        accum.push({
          collection: collectionAddress,
          tokenId,
          name: isDeposited
            ? getCollectionNameByContractAddress(collectionAddress)
            : collectionName,
          isDeposited,
          tokenUri,
          amountSelectedToBorrow: BigNumber.from(0),
          apy,
        });

        return accum;
      }

      const {
        priceInEth,
        status: statusFromServer,
        ltv,
      } = calculations[collectionAddress.toLowerCase()][tokenId];

      const valuation = BigNumber.from(priceInEth);

      const availableToBorrow = calculateAvailableToBorrow(
        valuation,
        ltv,
        currentBorrowAmount
      );

      const healthFactor =
        currentBorrowAmount && !currentBorrowAmount.isZero()
          ? calculateHealthFactor(
              valuation,
              currentBorrowAmount,
              BigNumber.from(liquidationThreshold!)
            )
          : undefined;

      const liquidationThresholdCorrected =
        liquidationThreshold || DEFAULT_LIQ_THRESHOLD_VALUE;

      const liquidationPrice = calculateLiquidationPrice(
        currentBorrowAmount || BigNumber.from(0),
        BigNumber.from(liquidationThresholdCorrected)
      );

      let status: LoanStatus | undefined = undefined;

      if (currentBorrowAmount) {
        status = determineLoanStatus(
          statusFromServer,
          redeemEnd!,
          healthFactor!,
          availableToBorrow
        );
      }

      accum.push({
        collection: collectionAddress,
        tokenId,
        name: isDeposited
          ? getCollectionNameByContractAddress(collectionAddress)
          : collectionName,
        isDeposited,
        tokenUri,
        amountSelectedToBorrow: BigNumber.from(0),
        availableToBorrow,
        healthFactor,
        currentBorrowAmount,
        ltv: ltv.toString(),
        valuation,
        liquidationThreshold: liquidationThresholdCorrected,
        liquidationPrice,
        apy,
        status,
        isListed,
      });

      return accum;
    },
    []
  );

  return nftBorrowDatas.filter(
    ({ status, isDeposited, valuation }) =>
      status !== LoanStatus.NOT_AVAILABLE && !(isDeposited && !valuation)
  );
};
