import { BigNumber, Signer } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { callContract } from "../utils";
import abi from "../abis/wethGateway.json";

export const bidOnLoanListed = async (
  signer: Signer,
  collectionAddress: string,
  tokenId: number,
  bidPrice: BigNumber,
  onBehalfOf: string
) => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.wethGateway,
    abi,
    signer,
    "bidDebtETH",
    [collectionAddress, tokenId, onBehalfOf],
    { value: bidPrice }
  );

  return transaction.hash;
};
