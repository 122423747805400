import { ConnectionError } from "../errors/errors";
import {
  CallsGroupedByMethod,
  MarketItemType,
  MyActivityItem,
  MyActivityItemDto,
  MyActivityItemStatus,
  Pagination,
} from "../types";
import { equalIgnoreCase, getImage, getNftsMetadataBatch } from "../utils";
import protocolDataProviderAbi from "../abis/unlockdProtocolDataProvider.json";
import {
  changeToWrapIfCrypropunks,
  getCollectionNameByContractAddress,
} from "./helpers";
import { BigNumber } from "ethers";
import { multicall } from "../utils/multicall";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { determineMyActivityItemStatus } from "./helpers/determineMyActivityItemStatus";

type ServerResponse = {
  items: Array<MyActivityItemDto>;
  pagination: Pagination;
};

export const retrieveMyActivity = async (
  address: string,
  offset: number,
  limit: number
): Promise<{ items: Array<MyActivityItem>; pagination: Pagination }> => {
  const response = await fetch(
    `/api/unlockd-api/protocol/marketplace/${address.toLowerCase()}?offset=${offset}&limit=${limit}`
  );

  if (!response.ok) {
    throw new ConnectionError("failed on getting my activity from server");
  }

  const { items: myActivityItems, pagination }: ServerResponse =
    await response.json();

  if (myActivityItems.length === 0) {
    return { items: [], pagination };
  }

  // PREPARE CALL TO ALCHEMY FOR METADATA
  const tokens = myActivityItems.map(({ collection, tokenId }) => ({
    contractAddress: collection,
    tokenId,
  }));

  const metadata = await getNftsMetadataBatch(tokens);

  // @ts-ignore
  const ipfsImagesPromises: Array<any> = metadata.map((nftMetadata) => {
    if (nftMetadata.media?.[0]?.gateway) {
      return nftMetadata.media?.[0]?.gateway;
    } else {
      return getImage(nftMetadata.tokenUri.gateway);
    }
  });

  const images = await Promise.all(ipfsImagesPromises);

  const items: Array<MyActivityItem> = myActivityItems.map(
    (
      {
        collection,
        tokenId,
        state,
        buyer,
        bidderAddress,
        auctionEndTimestamp,
        bidPrice,
        category,
        soldPrice,
        soldTimestamp,
        sellPrice,
      },
      index
    ) => {
      const { date, status, wasSold } = determineMyActivityItemStatus(
        category,
        state,
        soldPrice,
        sellPrice,
        auctionEndTimestamp,
        buyer,
        address,
        soldTimestamp,
        bidderAddress
      );

      return {
        type: category,
        nfts: [
          {
            collection,
            tokenId,
            image: images[index],
            name: getCollectionNameByContractAddress(collection),
          },
        ],
        date,
        status,
        wasSold,
        soldPrice: soldPrice ? BigNumber.from(soldPrice) : null,
        latestBid: bidPrice.at(-1) ? BigNumber.from(bidPrice.at(-1)) : null,
      };
    }
  );

  const itemsAuctionedToClaim = items.filter(
    ({ type, status }) =>
      type === MarketItemType.LOAN_AUCTIONED &&
      status === MyActivityItemStatus.CLAIM
  );

  const loanDataCalls = new CallsGroupedByMethod(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    protocolDataProviderAbi,
    "getLoanDataByCollateral",
    []
  );

  itemsAuctionedToClaim.forEach(({ nfts: [{ collection, tokenId }] }) => {
    loanDataCalls.callsArguments.push([
      changeToWrapIfCrypropunks(collection),
      tokenId,
    ]);
  });

  const [loansDataByCollateral] = await multicall(loanDataCalls);

  loansDataByCollateral.forEach(
    ({ loanData: { currentAmount } }, index) =>
      (itemsAuctionedToClaim[index].currentAmount = currentAmount)
  );

  return {
    items,
    // items: items.map((item) => ({
    //   ...item,
    //   status: MyActivityItemStatus.WAITING_FOR_BOT_TIME,
    //   date: new Date(Date.now()),
    // })),
    pagination,
  };

  // return {
  //   items: [
  //     {
  //       type: MarketItemType.LOAN_LISTED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.ONGOING,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.ONGOING,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.BID_FAILED,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.CLAIM,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.CLAIMED,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.LOADING,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.LOST,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 35,
  //           image: "",
  //           name: "mockdata",
  //         },
  //       ],
  //       latestBid: BigNumber.from("1000000000000000"),
  //       date: new Date("10-10-2023"),
  //       status: MyActivityItemStatus.SOLD,
  //       buyer: "0x3705a823c23f7a94562295cA60Ec00617DAWEFWEFb",
  //       soldTimestamp: 1000000000000000,
  //       soldPrice: BigNumber.from("1000000000000000"),
  //       bidBorrowAmount: BigNumber.from("1000000000000000"),
  //       bidPrice: BigNumber.from("1000000000000000"),
  //       isBuyNowAvailable: true,
  //       isAuctionAvailable: true,
  //     },
  //   ],
  //   pagination: {
  //     offset: 10,
  //     limit: 10,
  //     total: 10,
  //   },
  // };
};
