import {Transition} from "@headlessui/react";
import classNames from "classnames";
import {type FC} from "react";
import {useAccount} from "wagmi";
import {useToggle} from "react-use";
import {PanelWallet} from "./PanelWallet";
import {IconMetamask, IconWalletConnect} from "../../../ui/icons";
import {CtaPrimary} from "../../../ui";
import {parseHashToRender} from "../../../../utils";
import {FaChevronDown} from "react-icons/fa";

interface Props {
  className?: string;
}

type Controller = (props: Props) => {
  className?: string;
  address?: string;
  connectorName?: "MetaMask" | "WalletConnect";
  isOpen: boolean;
  togglePanel: (nextValue?: boolean) => void;
};

const useController: Controller = (props) => {
  const { address, connector } = useAccount();
  const [isOpen, togglePanel] = useToggle(false);

  return {
    address: address,
    connectorName: connector?.name as any,
    isOpen,
    togglePanel,
    ...props,
  };
};

export const ButtonPanelWallet: FC<Props> = (props) => {
  const { address, className, connectorName, isOpen, togglePanel } =
    useController(props);
  return (
    <>
      <div className={classNames("relative z-50", [className])}>
        <CtaPrimary
          onClick={() => togglePanel()}
          className={classNames(
            "break-normal min-w-fit flex items-center justify-center relative z-10",
            [className]
          )}
        >
          <div className="relative w-6 h-6 mr-2 flex items-center justify-center">
            {connectorName === "MetaMask" && <IconMetamask />}
            {connectorName === "WalletConnect" && <IconWalletConnect />}
          </div>
          <span>{address ? parseHashToRender(address) : "..."}</span>
          <div
            className={classNames("w-4 h-4 shrink-0 ml-2", {
              "rotate-180 transform": isOpen,
            })}
          >
            <FaChevronDown className="w-full h-full" />
          </div>
        </CtaPrimary>
        <Transition
          show={isOpen}
          enterFrom="opacity-0"
          enter="transition-opacity duration-300"
          leave="transition-opacity duration-300"
          leaveTo="opacity-0"
          className="relative h-0 w-full"
        >
          <PanelWallet isOpen={isOpen} togglePanel={togglePanel} />
        </Transition>
      </div>
    </>
  );
};
