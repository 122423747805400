import { equalIgnoreCase } from "../../utils";
import { COLLECTIONS } from "../../../app.config";

export const getUBoundFromNftCollection = (
  collectionAddress: string
): string => {
  const collection = COLLECTIONS.find(({ address }) =>
    equalIgnoreCase(address, collectionAddress)
  );

  if (!collection) {
    throw new Error("collection not supported");
  }

  return collection.uBoundAddress;
};
