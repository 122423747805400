import { BigNumber } from "ethers";

export class HealthFactorItem {
  collectionAddress: string;
  image?: string;
  name: string;
  tokenId: string;
  valuation: BigNumber;
  currentBorrowAmount: BigNumber;
  healthFactor: number | null;

  constructor(
    collectionAddress: string,
    name: string,
    tokenId: string,
    valuation: BigNumber,
    currentBorrowAmount: BigNumber,
    healthFactor: number | null,
    image?: string
  ) {
    this.collectionAddress = collectionAddress;
    this.image = image;
    this.name = name;
    this.tokenId = tokenId;
    this.valuation = valuation;
    this.currentBorrowAmount = currentBorrowAmount;
    this.healthFactor = healthFactor;
  }
}
