import {FC, ForwardedRef, forwardRef} from "react";
import {Anchor} from "../../ui";
import classNames from "classnames";
import type {SimpleLink} from "../../../types/structures";

interface Props extends SimpleLink<string> {
  className?: string;
}

export const NavigationElement: FC<Props> = forwardRef(
  function NavigationElement(props, ref: ForwardedRef<HTMLLIElement>) {
    const { id, children, href, target, rel, title, className } = props;
    return (
      <li
        ref={ref}
        className={classNames(
          `cursor-pointer text-sm font-semibold md:text-base transition-all duration-300 hover:text-white text-gray-300`,
          [className]
        )}
      >
        <Anchor
          className="px-3 py-2 md:px-6 h-full w-full grid place-content-center"
          id={id}
          href={href}
          target={target}
          title={title}
          rel={rel}
        >
          {children}
        </Anchor>
      </li>
    );
  }
);
