import { useEffect, useRef, useState } from "react";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";

export type BigNumberInputProps = {
  decimals: number;
  value: string;
  onChange: (value: string) => void;
  renderInput?: (
    props: React.HTMLProps<HTMLInputElement>
  ) => React.ReactElement;
  autofocus?: boolean;
  placeholder?: string;
  max?: string;
  min?: string;
  name?: string;
  className?: string;
  readOnly?: boolean;
};

export function BigNumberInput({
  decimals = 18,
  value,
  onChange,
  renderInput,
  autofocus,
  readOnly,
  placeholder = "0.00",
  max,
  min,
  ...extraProps
}: BigNumberInputProps) {
  const inputRef = useRef<any>(null);

  const [inputValue, setInputvalue] = useState("");

  const digits = 18;

  // update current value
  useEffect(() => {
    if (!value) {
      setInputvalue("");
    } else {
      let parseInputValue;

      try {
        parseInputValue = parseUnits(inputValue || "0", digits);
      } catch {
        // do nothing
      }

      if (!parseInputValue || !parseInputValue.eq(value)) {
        setInputvalue(formatUnits(value, digits));
      }
    }
  }, [value, digits, inputValue]);

  useEffect(() => {
    if (!renderInput && autofocus && inputRef) {
      const node = inputRef.current as HTMLInputElement;
      node.focus();
    }
  }, [autofocus, inputRef]);

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.currentTarget;

    const dotIndex = value.indexOf(".");

    if (dotIndex > -1) value = value.slice(0, dotIndex + decimals + 1);

    if (value === "") {
      onChange(value);
      setInputvalue(value);
      return;
    }

    let newValue: BigNumber;
    try {
      newValue = parseUnits(value, digits);
    } catch (e) {
      // don't update the input on invalid values
      return;
    }

    const invalidValue = (min && newValue.lt(min)) || (max && newValue.gt(max));
    if (invalidValue) {
      return;
    }

    setInputvalue(value);
    onChange(newValue.toString());
  };

  const inputProps = {
    placeholder,
    onChange: updateValue,
    type: "text",
    value: inputValue,
  };

  return renderInput ? (
    renderInput({ ...inputProps, ...extraProps })
  ) : (
    <input {...inputProps} {...extraProps} readOnly={readOnly} ref={inputRef} />
  );
}
