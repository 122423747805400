import classNames from "classnames";
import {ComponentProps, type ElementType} from "react";

type Component = <As extends ElementType = "button">(
  props: ComponentProps<As> & {
    as?: As;
    size?: "xs" | "sm" | "lg";
  }
) => JSX.Element;

export const DepositedStamp: Component = (props) => {
  const { children, as, className, size = "sm", ...restProps } = props;
  const Component = as ?? "button";

  return (
    <Component
      {...restProps}
      className={classNames(
        `bg-secondary absolute border-solid border-2 border-green-400 text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus-visible:border-white
        font-bold rounded-full active:contrast-125
        box-border block text-center break-words transition-colors duration-75`,
        {
          "text-2xs md:text-xs px-3 py-1.5": size === "xs",
          "text-xs md:text-sm px-3 max-3xs:px-2 py-2": size === "sm",
          "text-sm md:text-base px-3 max-3xs:px-2 py-2": size === "lg",
        },
        [className]
      )}
    >
      {children}
    </Component>
  );
};
