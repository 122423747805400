import { BigNumber } from "ethers";
import { equalIgnoreCase } from "./strings";

export const checkInputsAreEqual = (input1: any, input2: any): boolean => {
  if (input1 instanceof Array) {
    for (let i = 0; i < input1.length; i++) {
      if (!checkInputsAreEqual(input1[i], input2[i])) return false;
    }

    return true;
  } else if (BigNumber.isBigNumber(input1)) {
    return Number(input1) === Number(input2);
  } else if (!(input1 instanceof Object)) {
    if (typeof input1 === "string") {
      return equalIgnoreCase(input1, input2);
    }

    return input1 === input2;
  } else {
    return false;
  }
};
