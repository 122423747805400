import {
    TransactionReceipt
} from ".pnpm/@ethersproject+abstract-provider@5.7.0/node_modules/@ethersproject/abstract-provider/src.ts";
import {lookUpProvider} from "../logic/helpers";
import {validateTransactionHash} from "../utils";

export const getTransactionReceipt = async (
  transactionHash: string
): Promise<TransactionReceipt | null> => {
  validateTransactionHash(transactionHash);

  const provider = lookUpProvider();

  return provider.getTransactionReceipt(transactionHash);
};
