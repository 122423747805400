import {type FC, useState} from "react";
import classNames from "classnames";
import {RichText} from "../../ui";
import type {PortableText} from "../../../types/elements";
import {withAlert} from "./withAlert";
import {useTimeoutFn} from "react-use";
import {IoMdClose} from "react-icons/io";

interface Props {
  className?: string;
  isOpen: boolean;
  toggleAlert: (nextValue?: boolean) => void;
  timeout?: number;
  type: "success" | "error" | "warning" | "loading" | "info";
  content: PortableText;
}

type Controller = (props: Props) => Props & {
  pauseCountdown: () => void;
  resumeCountDown: () => void;
};

const useController: Controller = (props) => {
  const { timeout: _timeout = 5000, toggleAlert, ...restProps } = props;
  const [timeout, setTimeout] = useState(_timeout);
  const [timer, setTimer] = useState(Date.now);
  const [isReady, cancel, reset] = useTimeoutFn(() => {
    toggleAlert(false);
  }, timeout);

  const pauseCountdown = () => {
    setTimer((state) => Date.now() - state);
    cancel();
  };

  const resumeCountDown = () => {
    setTimeout((state) => state - timer);
    setTimer(Date.now);
    reset();
  };

  return { pauseCountdown, resumeCountDown, toggleAlert, ...restProps };
};

export const AlertNotification: FC<Props> = withAlert((props: Props) => {
  const {
    toggleAlert,
    className,
    type,
    content,
    pauseCountdown,
    resumeCountDown,
  } = useController(props);
  return (
    <>
      <div
        onMouseEnter={pauseCountdown}
        onMouseLeave={resumeCountDown}
        className={classNames(`relative w-[calc(100vw-24px-24px)] max-w-xl`, [
          className,
        ])}
      >
        <button
          className={classNames(
            `w-6 h-6 cursor-pointer absolute -right-2 -top-2 p-1 z-10
            border rounded-full bg-secondary text-white`,
            {
              "border-green-400": type === "success",
              "border-amber-500": type === "warning",
              "border-red-700": type === "error",
              "border-blue-500": type === "info",
              "border-blue-600": type === "loading",
            }
          )}
          onClick={() => {
            toggleAlert(false);
          }}
        >
          <IoMdClose className="w-full h-full" />
        </button>
        <div
          className={classNames(
            `w-full h-full overflow-hidden relative xs:px-3 px-2.5 py-2 xs:py-2.5 rounded-2xl
          border-2 bg-secondary element-shadow`,
            {
              "border-green-400": type === "success",
              "border-amber-500": type === "warning",
              "border-red-700": type === "error",
              "border-blue-500": type === "info",
              "border-blue-600": type === "loading",
            }
          )}
        >
          <div className="xs:text-sm text-xs max-3xs:text-2xs font-medium block text-center text-white relative z-10">
            <RichText value={content} />
          </div>
        </div>
      </div>
    </>
  );
});
