import {
  TransactionHistoryDto,
  TransactionHistoryItem,
} from "../types/marketplace";
import { ConnectionError } from "../errors/errors";
import { getCollectionNameByContractAddress } from "./helpers";
import { Pagination } from "../types";
import { NftMetadata, getImage, getNftsMetadataBatch } from "../utils";
import { BigNumber } from "ethers";

type ServerResponse = {
  items: Array<TransactionHistoryDto>;
  pagination: Pagination;
};

export async function retrieveTransactionsHistory(
  offset: number,
  limit: number
): Promise<{
  items: TransactionHistoryItem[];
  pagination: Pagination;
}> {
  const response = await fetch(
    `api/unlockd-api/protocol/marketplace/history?offset=${offset}&limit=${limit}`
  );

  if (!response.ok) {
    throw new ConnectionError(
      "failed on fetching transactions history from server"
    );
  }

  const { items: itemsFromServer, pagination }: ServerResponse =
    await response.json();

  if (itemsFromServer.length === 0) {
    return { items: [], pagination };
  }

  const metadata = await getNftsMetadataBatch(
    itemsFromServer.map(({ collection, tokenId }) => ({
      contractAddress: collection,
      tokenId,
    }))
  );

  const ipfsImagesPromises: Array<any> = metadata.map((nftMetadata) => {
    if (nftMetadata.media?.[0]?.gateway) {
      return nftMetadata.media?.[0]?.gateway;
    } else {
      return getImage(nftMetadata.tokenUri.gateway);
    }
  });

  const images = await Promise.all(ipfsImagesPromises);

  const items = itemsFromServer.map(
    (
      {
        category,
        debtId,
        buyer,
        soldPrice,
        soldTimestamp,
        collection,
        tokenId,
      },
      index
    ) => ({
      nfts: [
        {
          collection,
          tokenId,
          name: getCollectionNameByContractAddress(collection),
          image: images[index],
        },
      ],
      type: category,
      id: debtId,
      buyer,
      price: soldPrice ? BigNumber.from(soldPrice) : null,
      date: soldTimestamp ? new Date(parseInt(soldTimestamp)) : null,
    })
  );

  return {
    items,
    pagination,
  };

  // return {
  //   items: [
  //     {
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       nfts: [
  //         {
  //           collection: "0x3705a823c23f7a94562295cA60Ec00617DA6823b",
  //           tokenId: 20,
  //           name: "Unlockd Mock BAYC",
  //           image:
  //             "https://ipfs.io/ipfs/QmPbxeGcXhYQQNgsC6a36dDyYUcHgMLnGKnF8pVFmGsvqi",
  //         },
  //       ],
  //       id: 1,
  //       price: BigNumber.from(1),
  //       buyer: "0xBCE85e9874AD6859D2dD4394B86142d683A2D5b7",
  //       date: new Date(Date.now()),
  //     },
  //   ],
  //   pagination: {
  //     offset: 0,
  //     limit: 10,
  //     total: 1,
  //   },
  // };
}
