import { callContract } from "../../utils";
import abi from "../../abis/unlockdProtocolDataProvider.json";
import { CONTRACT_ADDRESSES } from "../../../app.config";

export const getReserveData = async (): Promise<any> => {
  const reserveData = await callContract(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    abi,
    null,
    "getReserveData",
    [CONTRACT_ADDRESSES.weth]
  );

  return reserveData;
};
