import {
  MarketItemType,
  MyActivityItemStatus,
  MyActivityState,
} from "../../types";
import { equalIgnoreCase } from "../../utils";

export const determineMyActivityItemStatus = (
  category: MarketItemType,
  state: MyActivityState,
  soldPrice: string,
  sellPrice: string,
  auctionEndTimestamp: string,
  buyer: string | null,
  address: string,
  soldTimestamp: string | null,
  bidderAddresses: string[]
): { date: Date | null; status: MyActivityItemStatus; wasSold: boolean } => {
  let status: MyActivityItemStatus;

  let date: Date | null;

  const wasSold: boolean =
    (category === MarketItemType.LOAN_AUCTIONED && state === "boughtOut") ||
    (category === MarketItemType.LOAN_LISTED && soldPrice === sellPrice);

  if (state === "redeemed") {
    status = MyActivityItemStatus.REDEEMED;

    date = new Date(parseFloat(auctionEndTimestamp));
  } else if (state === "canceled") {
    status = MyActivityItemStatus.CANCELLED;

    date = new Date(parseFloat(auctionEndTimestamp));
  } else if (state === "boughtOut" && equalIgnoreCase(buyer!, address)) {
    status = MyActivityItemStatus.CLAIMED;

    date = new Date(parseInt(soldTimestamp!));
  } else if (!wasSold) {
    date = new Date(parseFloat(auctionEndTimestamp));

    if (
      bidderAddresses.length > 0 &&
      equalIgnoreCase(bidderAddresses!.at(-1)!, address)
    ) {
      if (state === "sold") {
        status = MyActivityItemStatus.CLAIMED;
      } else if (parseFloat(auctionEndTimestamp) > Date.now()) {
        status = MyActivityItemStatus.ONGOING;
      } else if (
        parseFloat(auctionEndTimestamp) + 1000 * 60 * 20 >
        Date.now()
      ) {
        status = MyActivityItemStatus.WAITING_FOR_BOT_TIME;
      } else {
        status = MyActivityItemStatus.CLAIM;
      }
    } else {
      if (parseFloat(auctionEndTimestamp) < Date.now()) {
        status = MyActivityItemStatus.LOST;
      } else {
        status = MyActivityItemStatus.BID_FAILED;
      }
    }
  } else {
    status = MyActivityItemStatus.SOLD;

    date = soldTimestamp ? new Date(parseInt(soldTimestamp!)) : null;
  }
  return { date, status, wasSold };
};
