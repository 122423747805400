import {useState, type FC} from "react";
import {useAccount, useDisconnect, useNetwork} from "wagmi";
import {Anchor, Divider} from "../../../ui";
import {BsBoxArrowInLeft, BsBoxArrowUpRight} from "react-icons/bs";
import {IoCopyOutline} from "react-icons/io5";
import {usePanelWalletStatics} from "../../../../components/statics";
import type {PanelWalletStatics} from "../../../../types";
import {useCopyToClipboard} from "react-use";
import classNames from "classnames";
import Script from "next/script";

interface Props {
  className?: string;
  isOpen: boolean;
  togglePanel: (nextValue?: boolean) => void;
}
declare global {
  interface Window {
    hashmail: any;
  }
}

type Controller = (props: Props) => Props & {
  statics: PanelWalletStatics;
  chainName: string;
  isNetworkSupported: boolean;
  address: string;
  disconnect: () => void;
  copyAddressToClipboard: () => void;
};

const useController: Controller = (props) => {
  const statics = usePanelWalletStatics();
  const { chain, chains } = useNetwork();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const [, copyToClipboard] = useCopyToClipboard();

  const copyAddressToClipboard = () => copyToClipboard(address!);

  const isNetworkSupported = chain?.id === chains[0].id;

  return {
    statics,
    chainName: chain?.name || "unknown chain",
    isNetworkSupported: isNetworkSupported,
    address: address || "",
    disconnect: disconnect,
    copyAddressToClipboard: copyAddressToClipboard,
    ...props,
  };
};

export const PanelWallet: FC<Props> = (props) => {
  const {
    chainName,
    isNetworkSupported,
    className,
    isOpen,
    address,
    togglePanel,
    disconnect,
    copyAddressToClipboard,
    statics,
  } = useController(props);
  const [isHashmailLoaded, setIsHashmailLoaded] = useState<boolean>(false);

  const disconnectWallet = () => {
    window.hashmail.disconnect()
    disconnect();
  }
  const { networkLabel, copyAddress, viewExplorer, discconectWallet } = statics;

  return (
    <>
    <Script
        src="/static/hashmail.js"
        onLoad={() => {
          setIsHashmailLoaded(true);
        }}
      />
      <div
        className="fixed top-0 right-0 w-screen h-screen"
        onClick={() => {
          togglePanel(false);
        }}
      ></div>
      <ul
        className="bg-secondary w-full top-0 left-0 absolute z-30 translate-y-7
      rounded-lg border border-white border-glow-sm overflow-hidden"
      >
        <li className="bg-secondary px-3 py-4">
          <span className=" ml-1.5 mb-2 block text-2xs md:text-xs text-white font-normal">
            {networkLabel}
          </span>
          <div
            className="flex items-center text-sm md:text-md
            text-white font-bold bg-secondary w-full"
          >
            <div className="relative w-6 h-6 mr-2 flex items-center justify-center">
              <div
                className={classNames(
                  "w-3 h-3 rounded-full transition-colors duration-300",
                  {
                    "bg-green-400": isNetworkSupported,
                    "bg-red-600": !isNetworkSupported,
                  }
                )}
              ></div>
            </div>
            <span>{chainName}</span>
          </div>
        </li>
        <Divider className="mx-auto w-52" />
        <li className="flex items-center bg-secondary">
          <button
            onClick={() => copyAddressToClipboard()}
            className="flex items-center text-sm md:text-md px-3 py-2 mt-2 text-white font-normal w-full
          transition-colors duration-300 hover:bg-slate-700 focus-visible:bg-slate-700 active:bg-slate-600"
          >
            <div className="relative w-5 h-5 mr-2 flex items-center justify-center">
              <IoCopyOutline className="w-full h-full -translate-x-0.5" />
            </div>
            <span>{copyAddress}</span>
          </button>
        </li>
        <li className="flex items-center bg-secondary">
          <Anchor
            href={`https://${
              chainName === "Goerli" ? chainName + "." : ""
            }etherscan.io/address/${address}`}
            target="_blank"
            title={viewExplorer}
            rel="noopener noreferrer"
            className="flex items-center text-sm md:text-md px-3 py-2 text-white font-normal w-full
          cursor-pointer transition-colors duration-300 hover:bg-slate-700 focus-visible:bg-slate-700 active:bg-slate-600"
          >
            <div className="relative w-5 h-5 mr-2 flex items-center justify-center">
              <BsBoxArrowUpRight className="w-full h-full pr-0.5" />
            </div>
            <span>{viewExplorer}</span>
          </Anchor>
        </li>
        <li className="flex items-center bg-secondary">
          <button
            className="flex items-center text-sm md:text-md px-3 py-2 mb-2 text-white font-normal w-full
            transition-colors duration-300 hover:bg-slate-700 focus-visible:bg-slate-700 active:bg-slate-600"
            onClick={disconnectWallet}
          >
            <div className="relative w-5 h-5 mr-2 flex items-center justify-center">
              <BsBoxArrowInLeft className="w-full h-full" />
            </div>
            <span>{discconectWallet}</span>
          </button>
        </li>
      </ul>
    </>
  );
};
