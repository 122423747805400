import { BigNumber, ethers } from "ethers";
import { percentMul, wadDiv } from "../../utils/DSMath";

export const calculateHealthFactor = (
  nftValuation: BigNumber,
  currentBorrowAmount: BigNumber,
  liquidationThreshold: BigNumber,
  variation?: BigNumber
): number => {
  if (variation && variation.eq(0)) return 0;

  const nftValuationThreshold: BigNumber = percentMul(
    nftValuation,
    liquidationThreshold
  );

  return parseFloat(
    ethers.utils.formatEther(
      wadDiv(
        nftValuationThreshold,
        variation ? variation.add(currentBorrowAmount) : currentBorrowAmount
      )
    )
  );
};
