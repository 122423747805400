import {getNftDebtData} from "./helpers/getNftDebtData";

export const isLoanFullyRepaid = async (
  collectionAddress: string,
  tokenId: number
) => {
  const { totalDebt } = await getNftDebtData(collectionAddress, tokenId);

  return totalDebt.isZero();
};
