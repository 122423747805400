import {ContractInterface} from "ethers";
import {BytesLike, Interface, Result} from "ethers/lib/utils";

export const decodeTransactionData = (
  abi: ContractInterface,
  eventName: string,
  data: BytesLike,
  topics?: string[]
): Result => {
  // @ts-ignore
  const iface = new Interface(abi);

  const decodedData = iface.decodeEventLog(eventName, data, topics);

  return decodedData;
};
