import { ContractInterface, ethers, providers, Signer } from "ethers";
import { lookUpProvider } from "../logic/helpers";
import { TxnOptionsType } from "../types";
import { isGnosisSafeSmartAccount } from "./isGnosisSafeSmartAccount";
import { handleSafeTransaction } from "./handleSafeTransaction";

export const callContract = async (
  contractAddress: string,
  abi: ContractInterface,
  signerOrProvider: Signer | providers.Provider | null,
  method: string,
  args?: any[],
  options?: TxnOptionsType
): Promise<{ hash: string }> => {
  let isGnosisSafe = false;
  let signer: Signer;

  let provider: providers.Provider;

  if (signerOrProvider) {
    // @ts-ignore
    provider = signerOrProvider;
  } else {
    provider = lookUpProvider();
  }

  const contract = new ethers.Contract(contractAddress, abi, provider);
  const estimation = parseInt(
    await (
      await contract.estimateGas[method](...(args || []), options || {})
    ).toString()
  );
  const gasLimit = Math.round(parseInt(estimation.toString()) * 2);
  if (options) options.gasLimit = gasLimit;

  // @ts-ignore
  if (signerOrProvider?._isSigner) {
    // @ts-ignore
    signer = signerOrProvider;

    isGnosisSafe = isGnosisSafeSmartAccount(signer);
  }

  if (!isGnosisSafe) {
    return contract[method](...(args || []), options || { gasLimit: gasLimit });
  } else {
    return handleSafeTransaction(
      signer!,
      contract,
      method,
      args || [],
      gasLimit,
      options
    );
  }
};
