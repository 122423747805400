import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import { CONTRACT_ADDRESSES } from "../../../app.config";
import abi from "../../abis/lendpoolConfigurator.json";
import { Log } from "../../types";
import { BigNumber } from "ethers";

type NftConfigChangedEventData = {
  address: string;
  tokenId: BigNumber;
  ltv: BigNumber;
  liquidationThreshold: BigNumber;
  liquidationBonus: BigNumber;
};

type NftConfigChangedEventDataParsed = {
  address: string;
  tokenId: number;
  ltv: number;
  liquidationThreshold: number;
  liquidationBonus: number;
};

export const setValuationListener = (
  collectionAddress: string,
  tokenId: number,
  callback: (data?: NftConfigChangedEventDataParsed) => void
): (() => void) => {
  const unlistener = listenContractEvent(
    getEventSignature(abi, "NftConfigurationChanged"),
    CONTRACT_ADDRESSES.lendpoolConfigurator,
    [collectionAddress, tokenId],
    ({ data, topics }: Log) => {
      // @ts-ignore
      const valuationData: NftConfigChangedEventData = decodeTransactionData(
        abi,
        "NftConfigurationChanged",
        data,
        topics
      );

      const valuationDataParsed: NftConfigChangedEventDataParsed = {
        ...valuationData,
        tokenId: Number(valuationData.tokenId),
        ltv: Number(valuationData.ltv),
        liquidationThreshold: Number(valuationData.liquidationThreshold),
        liquidationBonus: Number(valuationData.liquidationBonus),
      };

      callback(valuationDataParsed);
    }
  );

  return unlistener;
};
