import { CHAIN } from "../../app.config";

var raleon: any;

export const registerRaleonEvent = (eventName: string, data: string) => {
  // @ts-ignore
  if (CHAIN === "mainnet" && raleon !== undefined) {
    console.debug(
      `%c ${eventName} Event Registered`,
      "background: blue; color: white"
    );

    raleon.registerEvent(eventName, data);
  }
};
