import { Interface, Result } from "ethers/lib/utils";
import {
  Call,
  CallsGroupedByMethod,
  SingleCall,
  _CallsGroupedByMethod,
  _SingleCall,
} from "../types";
import { aggregateCallContract } from "./aggregateCallContract";

export const multicall = async (
  ...callsGrouped: Array<CallsGroupedByMethod | SingleCall>
): Promise<Array<Array<Result> | Result>> => {
  const calls: Array<Call> = [];

  callsGrouped.forEach((element) => {
    // @ts-ignore
    const contractInterface = new Interface(element.abi);

    if (element instanceof CallsGroupedByMethod) {
      element.callsArguments.forEach((callArguments) => {
        const callData = contractInterface.encodeFunctionData(
          element.method,
          callArguments
        );

        calls.push({
          target: element.contractAddress,
          callData,
        });
      });
    } else {
      const callData = contractInterface.encodeFunctionData(
        element.method,
        element.args
      );

      calls.push({
        target: element.contractAddress,
        callData,
      });
    }
  });

  const result = await aggregateCallContract(calls);

  const { returnData } = result;

  const decodedResults: Array<Array<Result> | Result> = [];

  let index = 0;

  callsGrouped.forEach((element) => {
    // @ts-ignore
    const contractInterface = new Interface(element.abi);

    if (element instanceof CallsGroupedByMethod) {
      const decodedResultsWithTheSameMethod: Array<any> = [];

      element.callsArguments.forEach((callArguments) => {
        const callResult = contractInterface.decodeFunctionResult(
          element.method,
          returnData[index++]
        );

        decodedResultsWithTheSameMethod.push(callResult);
      });

      decodedResults.push(decodedResultsWithTheSameMethod);
    } else {
      const callResult = contractInterface.decodeFunctionResult(
        element.method,
        returnData[index++]
      );

      decodedResults.push(callResult);
    }
  });

  return decodedResults;
};
