import { BigNumber } from "ethers";

export enum LoanStatus {
  TO_REDEEM,
  TO_REPAY,
  ONLY_REPAY,
  TO_BORROW,
  NOT_AVAILABLE,
  ERROR,
}

export interface NftBorrowData {
  collection: string;
  tokenId: number;
  name: string;
  tokenUri: string;
  isDeposited: boolean;
  loanId?: number;
  amountSelectedToBorrow: BigNumber;
  apy: number;
  valuation?: BigNumber;
  ltv?: string;
  availableToBorrow?: BigNumber;
  liquidationThreshold?: number;
  liquidationPrice?: BigNumber;
  currentBorrowAmount?: BigNumber;
  healthFactor?: number;
  redeemEnd?: number;
  status?: LoanStatus;
  isListed?: boolean;
}
