import { callContract } from "../../utils";
import abi from "../../abis/lendpoolUnlockd.json";
import { CONTRACT_ADDRESSES } from "../../../app.config";

export const getNftDebtData = async (
  collectionAddress: string,
  tokenId: number
): Promise<any> => {
  const reserveData = await callContract(
    CONTRACT_ADDRESSES.lendpool,
    abi,
    null,
    "getNftDebtData",
    [collectionAddress, tokenId]
  );

  return reserveData;
};
