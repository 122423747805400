import { BigNumber } from "ethers";

export const calculateAvailableToBorrow = (
  valuation: BigNumber,
  ltv: number,
  debt?: BigNumber
): BigNumber => {
  const maximumAmount = valuation.mul(ltv).div(10000);

  return debt ? maximumAmount.sub(debt) : maximumAmount;
};
