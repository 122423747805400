import { type FC } from "react";
import Link from "next/link";
import classNames from "classnames";
import { IconEthereum } from "../../ui/icons";
import { CtaSecondary } from "../../ui";
import { BigNumber } from "ethers";
import { formatWeiToEth } from "../../../utils";

interface Props {
  totalDebtOfUser?: BigNumber;
  nftsDepositedByUser?: number;
  borrowApy?: number;
  className?: string;
}

export const LoansBox: FC<Props> = (props) => {
  const { className, totalDebtOfUser, nftsDepositedByUser, borrowApy } = props;

  return (
    <>
      <div
        className={classNames(
          "h-ull flex flex-col justify-between gap-9 p-8 bg-secondary rounded-xl shadow-lighting ",
          [[className]]
        )}
      >
        <div className="flex flex-col gap-7">
          <h3 className="font-bold text-xl">My Loans</h3>
          <div className="h-full flex flex-col gap-5">
            <div className="flex justify-between w-full">
              <span>NFTs collateralized</span>
              <p>{nftsDepositedByUser}</p>
            </div>
            <div className="flex justify-between w-full">
              <span>Amount borrowed</span>
              <div className="flex items-center gap-1">
                <p>
                  {totalDebtOfUser &&
                    (totalDebtOfUser.isZero()
                      ? "0"
                      : formatWeiToEth(totalDebtOfUser, 2))}
                </p>
                <div className="w-4 h-4 bg-white rounded-full -mt-0.5">
                  <IconEthereum />
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <span>Interest rate</span>
              <p>{borrowApy && (borrowApy * 100).toFixed(2)} %</p>
            </div>
          </div>
        </div>
        <Link legacyBehavior href="/borrow">
          <a>
            <CtaSecondary className="mx-auto w-40">Borrow</CtaSecondary>
          </a>
        </Link>
      </div>
    </>
  );
};
