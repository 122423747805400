import {hexlify, hexZeroPad} from "ethers/lib/utils";

export const parseArgHexWithZeroPad = (arg: string | number): string => {
  if (typeof arg === "string" && arg.startsWith("0x"))
    return hexZeroPad(arg, 32);
  else if (typeof arg === "number") {
    return hexZeroPad(hexlify(arg), 32);
  } else {
    return hexZeroPad(hexlify(parseInt(arg)), 32);
  }
};
