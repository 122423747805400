import { BigNumber } from "ethers";
import { isCryptopunks } from "./isCryptopunks";

export const getBorrowArguments = (
  amount: BigNumber,
  collectionAddress: string,
  tokenIdOrIndex: number,
  walletAddress: string
): Array<any> => {
  if (isCryptopunks(collectionAddress)) {
    return [amount, tokenIdOrIndex, walletAddress, 0];
  } else {
    return [amount, collectionAddress, tokenIdOrIndex, walletAddress, 0];
  }
};
