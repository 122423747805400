import { SingleCall, _CallsGroupedByMethod } from "../types";
import nftAbi from "../abis/customERC721.json";
import debtTokenAbi from "../abis/debtToken.json";
import abiDataProvider from "../abis/unlockdProtocolDataProvider.json";
import abiLendPool from "../abis/lendpoolUnlockd.json";
import { isCryptopunks } from "./helpers";
import { callContract } from "../utils";
import { BigNumber } from "ethers";
import { isPunkForSale } from "./isPunkForSale";
import { multicall } from "../utils/multicall";
import { UINT_256_MAX } from "../constants";
import { CONTRACT_ADDRESSES, PUNKS_COLLECTION } from "../../app.config";

// valuation -> deppends the address
// approveCollection -> deppends offer for sale or approve for all

export const retrieveApprovalsAndValuation = async (
  walletAddress: string,
  collectionAddress: string,
  tokenId: number,
  isDeposited: boolean
): Promise<{
  isCollectionApproved: boolean;
  isValuationApproved: boolean;
  debtTokenAllowance: BigNumber;
}> => {
  // console.log('retrieveApprovalsAndValuation', walletAddress, collectionAddress, tokenId, isDeposited);

  const collectionAddressToCheckValuation = isCryptopunks(collectionAddress)
    ? PUNKS_COLLECTION.wrapAddress!
    : collectionAddress;

  const gateway = isCryptopunks(collectionAddress)
    ? CONTRACT_ADDRESSES.punkGateway
    : CONTRACT_ADDRESSES.wethGateway;

  const debtTokenBorrowAllowanceCall = new SingleCall(
    CONTRACT_ADDRESSES.debtToken,
    debtTokenAbi,
    "borrowAllowance",
    [walletAddress, gateway]
  );

  const nftConfigurationDataCall = new SingleCall(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    abiDataProvider,
    "getNftConfigurationDataByTokenId",
    [collectionAddressToCheckValuation, tokenId]
  );

  const timeframeCall = new SingleCall(
    CONTRACT_ADDRESSES.lendpool,
    abiLendPool,
    "getTimeframe",
    []
  );

  const [
    //@ts-ignore
    [debtTokenAllowance],
    //@ts-ignore
    [nftConfigurationData],
    //@ts-ignore
    [timeframe],
  ] = await multicall(
    debtTokenBorrowAllowanceCall,
    nftConfigurationDataCall,
    timeframeCall
  );

  const currentTimestamp = Math.floor(Date.now() / 1000); //we want to compare it in seconds!!

  const isValuationApproved = nftConfigurationData.configTimestamp
    .add(timeframe)
    .lt(currentTimestamp)
    ? false
    : true;

  let isCollectionApproved: boolean;

  if (!isCryptopunks(collectionAddress)) {
    isCollectionApproved = await callContract(
      collectionAddress,
      nftAbi,
      null,
      "isApprovedForAll",
      [walletAddress, CONTRACT_ADDRESSES.wethGateway]
    );
  } else {
    if (isDeposited) {
      isCollectionApproved = true;
    } else {
      isCollectionApproved = await isPunkForSale(
        collectionAddress,
        tokenId.toString()
      );
    }
  }

  return {
    isCollectionApproved: !!isCollectionApproved,
    debtTokenAllowance: isCryptopunks(collectionAddress)
      ? BigNumber.from(UINT_256_MAX)
      : debtTokenAllowance,
    isValuationApproved,
  };
};
