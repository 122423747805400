import classNames from "classnames";
import { type FC } from "react";
import { HealthFactorCircle } from "../commons";
import { IconEthereum } from "../ui/icons";

type Props = {
  className?: string;
  title?: string;
  value: string;
  icon?: "eth" | "health-factor";
  offerMessage?: string;
};

export const DataItem: FC<Props> = (props) => {
  const { title, value, icon, className, offerMessage } = props;

  return (
    <div className={classNames("flex flex-col", className)}>
      <h4 className="font-bold text-xs">{title}</h4>
      {icon ? (
        <div className="flex items-center">
          <p>{value}</p>
          {icon === "eth" && (
            <div className="ml-1 w-4 h-4 bg-white rounded-full">
              <IconEthereum />
            </div>
          )}
          {icon === "health-factor" && (
            <HealthFactorCircle
              className="ml-1"
              healthFactor={parseFloat(value)}
            />
          )}
        </div>
      ) : (
        <p>{value}</p>
      )}
      {offerMessage && (
        <p className="text-[8px] text-[#8DF85B] font-bold">{offerMessage}</p>
      )}
    </div>
  );
};
