import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { truncate } from "./string-numbers";

export const formatWeiToEth = (
  wei: BigNumber | string,
  decimals = 4
): string => {
  const eth = formatEther(wei);

  return truncate(eth, decimals);
};
