import {type ComponentPropsWithRef, type FC, forwardRef} from "react";
import classNames from "classnames";
import Link from "next/link";

type Props = ComponentPropsWithRef<"a"> & {};

export const Anchor: FC<Props> = forwardRef(function Anchor(
  { children, href, className, ...props },
  ref
): JSX.Element {
  return (
    <Link legacyBehavior href={href || "/"}>
      <a ref={ref} {...props} className={classNames("", [className])}>
        {children}
      </a>
    </Link>
  );
});
