import { getImage, getNftsMetadataBatch } from "../../utils";

export const retrieveImagesForNftsList = async (
  nfts: Array<{ collectionAddress: string; tokenId: string }>
): Promise<Array<string>> => {
  const tokens = nfts.map(({ collectionAddress, tokenId }) => {
    return {
      contractAddress: collectionAddress,
      tokenId: parseInt(tokenId),
    };
  });

  const metadata = await getNftsMetadataBatch(tokens);

  const ipfsImagesPromises: Array<any> = metadata.map((nftMetadata) => {
    if (nftMetadata.media?.[0]?.gateway) {
      return nftMetadata.media?.[0]?.gateway;
    } else {
      return getImage(nftMetadata.tokenUri.gateway);
    }
  });

  const images = await Promise.all(ipfsImagesPromises);

  return images;
};
