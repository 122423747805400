import { callContract } from "../utils";
import { BigNumber, Signer } from "ethers";
import {
  changeToWrapIfCrypropunks,
  getAbiToInteractWith,
  getClaimArguments,
  getContractAddressToInteractWith,
} from "./helpers";

export const claimAuctionWon = async (
  signer: Signer,
  collectionAddress: string,
  tokenId: number,
  borrowAmount: BigNumber,
  bidPrice: BigNumber
) => {
  const amount = borrowAmount.lte(bidPrice)
    ? 0
    : borrowAmount.sub(bidPrice).mul(1001).div(1000);

  const transaction = await callContract(
    getContractAddressToInteractWith(collectionAddress),
    getAbiToInteractWith(collectionAddress),
    signer,
    "liquidateETH",
    getClaimArguments(changeToWrapIfCrypropunks(collectionAddress), tokenId),
    { value: amount }
  );

  return transaction.hash;
};
