import { BigNumber } from "ethers";
import { CONTRACT_ADDRESSES } from "../../../app.config";
import {
  CallsGroupedByMethod,
  MarketItem,
  MarketItemAuctionStatus,
  MarketItemType,
  SingleCall,
} from "../../types";
import { determineAuctionStatus } from "./determineAuctionStatus";
import { getCollectionNameByContractAddress } from "./getCollectionNameByContractAddress";
import { multicall } from "../../utils/multicall";
import unlockdAbi from "../../abis/unlockdProtocolDataProvider.json";
import lendpoolAbi from "../../abis/lendpoolUnlockd.json";
import { calculateAvailableToBorrow } from "./calculateAvailableToBorrow";
import { changeToWrapIfCrypropunks } from "./changeToWrapIfCryptopunks";
import { MarketItemPrePopulated } from "../retrieveMarketplaceItems";

export const populateLoansAuctioned = async (
  items: Array<MarketItemPrePopulated>,
  address: string
): Promise<Array<MarketItem>> => {
  const loanDataByCollateralCalls = new CallsGroupedByMethod(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    unlockdAbi,
    "getLoanDataByCollateral",
    []
  );

  const bidDeltaCall = new SingleCall(
    CONTRACT_ADDRESSES.lendpool,
    lendpoolAbi,
    "getBidDelta",
    []
  );

  items.forEach((item) => {
    const { collection, tokenId } = item;

    loanDataByCollateralCalls.callsArguments.push([
      changeToWrapIfCrypropunks(collection),
      tokenId,
    ]);
  });

  const [loanDatas, [bidDelta]] = await multicall(
    loanDataByCollateralCalls,
    bidDeltaCall
  );

  const itemsToReturn: Array<MarketItem> = items.map((items, index) => {
    const {
      category,
      debtId,
      collection,
      tokenId,
      auctionEndTimestamp,
      redeemEndTimestamp,
      bidPrice: bids,
      valuation: _valuation,
      sellPrice,
      image,
      ltv,
      reservoirBid,
      healthFactor,
    } = items;

    const valuation = BigNumber.from(_valuation);

    // @ts-ignore
    const { loanData } = loanDatas![index];

    const {
      bidderAddress,
      bidPrice,
      // @ts-ignore
      borrower,
      currentAmount,
    } = loanData;

    const debt = BigNumber.from(currentAmount);

    const biddingEnd = auctionEndTimestamp;

    const redeemEnd = redeemEndTimestamp ? redeemEndTimestamp : undefined;

    const auctionStatus = determineAuctionStatus(
      MarketItemType.LOAN_AUCTIONED,
      borrower,
      address,
      biddingEnd,
      bidderAddress,
      bids && bids.length > 0,
      redeemEnd
    );

    const buyNowPrice = debt.gt(valuation)
      ? debt.add(BigNumber.from(10000000000000))
      : valuation;

    return {
      type: category,
      nfts: [
        {
          collection,
          tokenId,
          image: image!,
          name: getCollectionNameByContractAddress(collection),
        },
      ],
      id: debtId,
      valuation,
      debt,
      healthFactor: parseFloat(healthFactor),
      isBuyNowAvailable:
        !valuation.lt(debt) &&
        ((auctionStatus !== MarketItemAuctionStatus.BID_FINISHED &&
          auctionStatus !== MarketItemAuctionStatus.REDEEM_FINISHED) ||
          bids.length > 0),
      isAuctionAvailable: true,
      buyNowPrice,
      availableToBorrow: calculateAvailableToBorrow(valuation, ltv, debt),
      owner: borrower,
      bidder: bidderAddress,
      biddingEnd,
      // biddingEnd: Date.now() - 1000 * 60 * 19.5,
      redeemEnd,
      latestBid: bidPrice,
      minBid: !bidPrice.isZero()
        ? bidPrice.mul(101).div(100)
        : debt.mul(bidDelta).div(10000).mul(10001).div(10000),
      isModalOpenAndRedeemed: false,
      auctionStatus,
      // auctionStatus: MarketItemAuctionStatus.WAITING_FOR_BOT_TIME,
      reservoirBid,
    };
  });

  return itemsToReturn;
};
