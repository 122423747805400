import { BigNumber, Signer } from "ethers";
import { callContract } from "../utils";
import {
  adjustAddressIfIsCryptopunks,
  getAbiToInteractWith,
  getContractAddressToInteractWith,
  getRepayArguments,
} from "./helpers";

export const repay = async (
  signer: Signer,
  collectionAddress: string,
  tokenId: number,
  amount: BigNumber
) => {
  const transaction = await callContract(
    getContractAddressToInteractWith(collectionAddress),
    getAbiToInteractWith(collectionAddress),
    signer,
    "repayETH",
    getRepayArguments(
      adjustAddressIfIsCryptopunks(collectionAddress),
      tokenId,
      amount
    ),

    { value: amount }
  );

  return transaction.hash;
};
