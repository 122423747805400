import { providers } from "ethers";
import { CHAIN, ALCHEMY, METAMASK } from "../../../app.config";

export const lookUpProvider = (): providers.Provider => {
  if (
    window?.ethereum &&
    // @ts-ignore
    window.ethereum?.networkVersion == METAMASK.chainId!
  ) {
    // @ts-ignore
    return new providers.Web3Provider(window.ethereum);
  } else {
    return new providers.AlchemyProvider(
      CHAIN,
      typeof window === "undefined"
        ? process.env.SERVER_ALCHEMY_KEY
        : ALCHEMY.apiKey
    );
  }
};
