import { Contract } from "ethers";
import { checkInputsAreEqual } from "./checkInputsAreEqual";

export const areDataAndArgsEqual = (
  contract: Contract,
  method: string,
  args: Array<any>,
  data: string
): boolean => {
  try {
    const decodedData = contract.interface.decodeFunctionData(method, data);

    if (decodedData.length) {
      for (let i = 0; i < decodedData.length; i++) {
        if (!checkInputsAreEqual(decodedData[i], args![i])) {
          return false;
        }
      }
    }

    return true;
  } catch (err) {
    return false;
  }
};
