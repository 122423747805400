import { type FC } from "react";
import classNames from "classnames";
import Link from "next/link";
import { CtaSecondary } from "../../ui";
import { formatWeiToEth } from "../../../utils";
import { BigNumber } from "ethers";
import { IconEthereum } from "../../ui/icons";

interface Props {
  className?: string;
  apy?: number | null;
  supply?: BigNumber;
  interestEarned?: BigNumber;
}

export const EarnBox: FC<Props> = (props) => {
  const { className, apy, supply, interestEarned } = props;

  return (
    <>
      <div
        className={classNames(
          "h-full flex flex-col justify-between gap-9 p-8 bg-secondary rounded-xl shadow-lighting ",
          [[className]]
        )}
      >
        <div className="flex flex-col gap-7">
          <h3 className="font-bold text-xl">My Earnings</h3>
          <div className="h-full flex flex-col gap-5">
            <div className="flex justify-between w-full">
              <span>Funds supplied</span>
              <div className="flex items-center gap-1">
                <p>
                  {supply && (supply.isZero() ? "0" : formatWeiToEth(supply))}
                </p>
                <div className="w-4 h-4 bg-white rounded-full -mt-0.5">
                  <IconEthereum />
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <span>Interest earned</span>
              <div className="flex items-center gap-1">
                <p>
                  {interestEarned &&
                    (interestEarned.isZero()
                      ? "0"
                      : formatWeiToEth(interestEarned))}
                </p>
                <div className="w-4 h-4 bg-white rounded-full -mt-0.5">
                  <IconEthereum />
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <span>Lending APY</span>
              <p>{apy && (apy * 100).toFixed(2)} % </p>
            </div>
          </div>
        </div>

        <Link legacyBehavior href="/earn">
          <a>
            <CtaSecondary className="mx-auto w-40">Earn</CtaSecondary>
          </a>
        </Link>
      </div>
    </>
  );
};
