var _ = require("lodash");

/**
 * Manages session or local storage with a JSON structure.
 *
 * @example
 * ```js
 * const storage = Storage.get(localStorage);
 *
 * const txts = storage.get('accounts.something.history')
 *
 * txts.push({ id: '...'})
 *
 * storage.set('accounts.something.history', txts)
 * ```
 */
export class Storage {
  target: globalThis.Storage;

  static __session: Storage | null = null;
  static __local: Storage | null = null;

  static get SESSION() {
    return this.__session
      ? this.__session
      : (this.__session = new Storage(sessionStorage));
  }

  static get LOCAL() {
    return this.__local
      ? this.__local
      : (this.__local = new Storage(localStorage));
  }

  constructor(target: globalThis.Storage) {
    this.target = target;
  }

  __retrieve() {
    return this.target.store ? JSON.parse(this.target.store) : {};
  }

  __persist(store: object) {
    this.target.store = JSON.stringify(store);
  }

  set(path: string, value: any) {
    const store = this.__retrieve();

    _.set(store, path, value);

    this.__persist(store);
  }

  get(path: string, defaultValue?: any) {
    const store = this.__retrieve();

    return _.get(store, path, defaultValue);
  }
}
