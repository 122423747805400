import { UnexpectedError } from "../errors/errors";
import {
  MarketItem,
  Pagination,
  MarketItemType,
  Filter,
  MarketItemDto,
  CallsGroupedByMethod,
} from "../types";
import { equalIgnoreCase, getImage, getNftsMetadataBatch } from "../utils";
import {
  changeToWrapIfCrypropunks,
  isCryptopunks,
  populateLoansAuctioned,
  populateLoansListed,
} from "./helpers";
import {
  COLLECTIONS,
  CONTRACT_ADDRESSES,
  PUNKS_COLLECTION,
} from "../../app.config";
import { getReservoirData } from "./getReservoirData";
import { BigNumber } from "ethers";
import lendpoolAbi from "../abis/lendpoolUnlockd.json";
import { multicall } from "../utils/multicall";

type ServerResponse = {
  items: Array<MarketItemDto>;
  pagination: {
    offset: number;
    limit: number;
    total: number;
  };
};

export interface MarketItemPrePopulated extends MarketItemDto {
  reservoirBid?: BigNumber;
  image?: string;
  liquidationThreshold?: BigNumber;
}

export function retrieveMarketplaceItems(
  address: string,
  offset: number,
  limit: number,
  filter: Filter,
  callback: (
    error: Error | null,
    items?: MarketItem[],
    pagination?: Pagination
  ) => void
): { stop: () => void } {
  let hasToContinue = true;

  const stop = () => {
    hasToContinue = false;
  };

  (async () => {
    try {
      let url = `/api/unlockd-api/protocol/marketplace?offset=${offset}&limit=${limit}&wallet=${address.toLowerCase()}&orderBy=${
        filter.orderBy
      }&orderType=${filter.sort}`;

      filter.types.forEach((type) => (url += `&types[]=${type}`));

      if (!filter.allCollectionsSelected) {
        url += `&collection=${COLLECTIONS[
          filter.collectionSelected!
        ].address.toLowerCase()}`;
      }

      const listedResponse = await fetch(url);

      if (!listedResponse.ok) {
        throw new UnexpectedError("failed fetching listed items");
      }

      const { items: itemsFromServer, pagination }: ServerResponse =
        await listedResponse.json();

      if (itemsFromServer.length === 0) {
        return callback(null, [], pagination);
      }

      const items: Array<MarketItemPrePopulated> = [...itemsFromServer];

      const reservoirBidPromises: Array<Promise<BigNumber | null> | null> = [];

      const nftMetadataBatchInput: Array<{
        contractAddress: string;
        tokenId: number;
      }> = [];

      const collateralDataCalls = new CallsGroupedByMethod(
        CONTRACT_ADDRESSES.lendpool,
        lendpoolAbi,
        "getNftCollateralData",
        []
      );

      items.forEach(({ collection, tokenId, category, sellType }) => {
        if (
          category === MarketItemType.LOAN_AUCTIONED ||
          sellType === "mixed" ||
          sellType === "auction"
        ) {
          reservoirBidPromises.push(
            getReservoirData(
              isCryptopunks(collection)
                ? PUNKS_COLLECTION.wrapAddress!
                : collection,
              tokenId
            )
          );
        } else {
          reservoirBidPromises.push(null);
        }

        nftMetadataBatchInput.push({
          contractAddress: changeToWrapIfCrypropunks(collection),
          tokenId,
        });

        collateralDataCalls.callsArguments.push([
          changeToWrapIfCrypropunks(collection),
          tokenId,
          CONTRACT_ADDRESSES.weth,
        ]);
      });

      const [reservoirBids, metadata, [collateralData]] = await Promise.all([
        Promise.all(reservoirBidPromises),
        getNftsMetadataBatch(nftMetadataBatchInput),
        multicall(collateralDataCalls),
      ]);

      const ipfsImagesPromises: Array<any> = metadata.map((nftMetadata) => {
        if (nftMetadata.media?.[0]?.gateway) {
          return nftMetadata.media?.[0]?.gateway;
        } else {
          return getImage(nftMetadata.tokenUri.gateway);
        }
      });

      const images: Array<string> = await Promise.all(ipfsImagesPromises);

      items.forEach((item, index) => {
        if (reservoirBids[index]) {
          // @ts-ignore
          item.reservoirBid = reservoirBids[index];
        }

        item.image = images[index];

        item.liquidationThreshold = collateralData![index].liquidationThreshold;
      });

      const loanListedItems = items.filter(
        ({ category }) => category === MarketItemType.LOAN_LISTED
      );
      const loanAuctionedItems = items.filter(
        ({ category }) => category === MarketItemType.LOAN_AUCTIONED
      );

      const loanListedItemsPopulated: Array<MarketItem> = [];

      if (loanListedItems.length > 0) {
        loanListedItemsPopulated.push(
          ...(await populateLoansListed(loanListedItems, address))
        );
      }

      const loanAucionedItemsPopulated: Array<MarketItem> = [];

      if (loanAuctionedItems.length > 0) {
        loanAucionedItemsPopulated.push(
          ...(await populateLoansAuctioned(loanAuctionedItems, address))
        );
      }

      const itemsToReturn: Array<MarketItem> = items.map(
        ({ collection, tokenId, category }) => {
          if (category === MarketItemType.LOAN_AUCTIONED) {
            return loanAucionedItemsPopulated.find(
              ({ nfts: [{ collection: _collection, tokenId: _tokenId }] }) =>
                equalIgnoreCase(collection, _collection) && tokenId === _tokenId
            )!;
          } else {
            return loanListedItemsPopulated.find(
              ({ nfts: [{ collection: _collection, tokenId: _tokenId }] }) =>
                equalIgnoreCase(collection, _collection) && tokenId === _tokenId
            )!;
          }
        }
      );
      if (hasToContinue) {
        callback(null, itemsToReturn, pagination);
      }
    } catch (err) {
      callback(err as Error);
    }
  })();

  return { stop };

  // return {
  //   // items: auctions,
  //   // hardcoded for testing
  //   items: [
  //     {
  //       bidBorrowAmount: BigNumber.from(0),
  //       bidder: "0x0000000000000000000000000000000000000000",
  //       biddingEnd: Date.now() + 10000000000,
  //       nfts: [
  //         {
  //           collection: "0x9278420bf7548970799c56ef9a0b081862515330",
  //           tokenId: 53,
  //           name: "Unlockd Mock BAYC",
  //           image:
  //             "https://nft-cdn.alchemy.com/eth-goerli/87cb3f02ad5a0ada7bfb09bb42ac0363",
  //         },
  //       ],
  //       debt: BigNumber.from("0x4579fc4c99b225"),

  //       isModalOpenAndRedeemed: false,
  //       latestBid: BigNumber.from("100000000000000"),
  //       loanId: 12,
  //       minBid: BigNumber.from("0x457bc39ed5d1ee"),
  //       healthFactor: 0.08,
  //       owner: "0x65CBCb7258baED3939d7aF9618434a65445fb5a0",
  //       redeemEnd: Date.now() + 10000000000,
  //       auctionStatus: MarketItemAuctionStatus.TO_BID,
  //       isAuctionAvailable: true,
  //       isBuyNowAvailable: true,
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       valuation: BigNumber.from("0x09a08eef0e1a7800"),
  //       buyNowPrice: BigNumber.from("0x667bc39ed5d1ee"),
  //     },
  //     {
  //       bidBorrowAmount: BigNumber.from(0),
  //       bidder: "0x0000000000000000000000000000000000000000",
  //       biddingEnd: Date.now() + 10000000000,
  //       nfts: [
  //         {
  //           collection: "0x9278420bf7548970799c56ef9a0b081862515330",
  //           tokenId: 55,
  //           name: "Unlockd Mock BAYC",
  //           image:
  //             "https://nft-cdn.alchemy.com/eth-goerli/87cb3f02ad5a0ada7bfb09bb42ac0363",
  //         },
  //       ],
  //       debt: BigNumber.from("0x4579fc4c99b225"),
  //       isModalOpenAndRedeemed: false,
  //       latestBid: BigNumber.from(0),
  //       loanId: 12,
  //       minBid: BigNumber.from("0x02a08eef0e1a7800"),
  //       healthFactor: 0.08,
  //       owner: "0x65CBCb7258baED3939d7aF9618434a65445fb5a0",
  //       redeemEnd: Date.now() + 10000000000,
  //       auctionStatus: MarketItemAuctionStatus.TO_FIRST_BID,
  //       isAuctionAvailable: true,
  //       isBuyNowAvailable: true,
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       valuation: BigNumber.from("0x09a08eef0e1a7800"),
  //       buyNowPrice: BigNumber.from("0x667bc39ed5d1ee"),
  //     },
  //     {
  //       bidBorrowAmount: BigNumber.from(0),
  //       bidder: "0x0000000000000000000000000000000000000000",
  //       biddingEnd: Date.now() + 10000000000,
  //       nfts: [
  //         {
  //           collection: "0x9278420bf7548970799c56ef9a0b081862515330",
  //           tokenId: 54,
  //           name: "Unlockd Mock BAYC",
  //           image:
  //             "https://nft-cdn.alchemy.com/eth-goerli/87cb3f02ad5a0ada7bfb09bb42ac0363",
  //         },
  //       ],
  //       debt: BigNumber.from("0x4579fc4c99b225"),
  //       isModalOpenAndRedeemed: false,
  //       latestBid: BigNumber.from("0x667bc39ed5d1ee"),
  //       loanId: 12,
  //       minBid: BigNumber.from("0x457bc39ed5d1ee"),
  //       healthFactor: 0.08,
  //       owner: "0x65CBCb7258baED3939d7aF9618434a65445fb5a0",
  //       redeemEnd: Date.now() + 10000000000,
  //       auctionStatus: MarketItemAuctionStatus.TO_BID,
  //       isAuctionAvailable: true,
  //       isBuyNowAvailable: false,
  //       type: MarketItemType.LOAN_LISTED,
  //       valuation: BigNumber.from("0x09a08eef0e1a7800"),
  //       availableToBorrow: BigNumber.from("0x02a08eef0e1a7800"),
  //     },
  //     {
  //       nfts: [
  //         {
  //           collection: "0x9278420bf7548970799c56ef9a0b081862515330",
  //           tokenId: 777,
  //           name: "Unlockd Mock BAYC",
  //           image:
  //             "https://nft-cdn.alchemy.com/eth-goerli/87cb3f02ad5a0ada7bfb09bb42ac0363",
  //         },
  //       ],
  //       debt: BigNumber.from("0x4579fc4c99b225"),
  //       buyNowPrice: BigNumber.from("0x667bc39ed5d1ee"),
  //       isModalOpenAndRedeemed: false,
  //       loanId: 12,
  //       healthFactor: 0.08,
  //       owner: "0x65CBCb7258baED3939d7aF9618434a65445fb5a0",
  //       isAuctionAvailable: false,
  //       isBuyNowAvailable: true,
  //       type: MarketItemType.LOAN_LISTED,
  //       valuation: BigNumber.from("0x09a08eef0e1a7800"),
  //       availableToBorrow: BigNumber.from("0x99a08eef0e1a7800"),
  //     },
  //     {
  //       bidBorrowAmount: BigNumber.from(0),
  //       bidder: "0x0000000000000000000000000000000000000000",
  //       biddingEnd: Date.now() + 10000000000,
  //       nfts: [
  //         {
  //           collection: "0x9278420bf7548970799c56ef9a0b081862515330",
  //           tokenId: 999,
  //           name: "Unlockd Mock BAYC",
  //           image:
  //             "https://nft-cdn.alchemy.com/eth-goerli/87cb3f02ad5a0ada7bfb09bb42ac0363",
  //         },
  //       ],
  //       debt: BigNumber.from("0x4579fc4c99b225"),
  //       isModalOpenAndRedeemed: false,
  //       latestBid: BigNumber.from("0x667bc39ed5d1ee"),
  //       loanId: 12,
  //       minBid: BigNumber.from("0x02a08eef0e1a7800"),
  //       healthFactor: 0.08,
  //       owner: "0x65CBCb7258baED3939d7aF9618434a65445fb5a0",
  //       redeemEnd: Date.now() + 10000000000,
  //       auctionStatus: MarketItemAuctionStatus.BIDDED,
  //       isAuctionAvailable: true,
  //       isBuyNowAvailable: true,
  //       type: MarketItemType.LOAN_AUCTIONED,
  //       valuation: BigNumber.from("0x09a08eef0e1a7800"),
  //       buyNowPrice: BigNumber.from("0x667bc39ed5d1ee"),
  //     },
  //   ],
  //   pagination: {
  //     offset: 10,
  //     limit: 10,
  //     total: 1,
  //   },
  // };
}
