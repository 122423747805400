export const parseCompleteDateToRender = (date: Date): string => {
  const day = date.getDate();
  const dayToRender = day < 10 ? "0" + day : day;

  const month = date.getMonth() + 1;
  const monthToRender = month < 10 ? "0" + month : month;

  const yearToRender = date.getFullYear();

  const hours = date.getHours();
  const hoursToRender = hours < 10 ? "0" + hours : hours;

  const minutes = date.getMinutes();
  const minutesToRender = minutes < 10 ? "0" + minutes : minutes;

  return `${dayToRender}/${monthToRender}/${yearToRender} ${hoursToRender}:${minutesToRender}`;
};
