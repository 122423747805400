import {equalIgnoreCase} from "../../utils";
import {Market} from "../../types";
import {COLLECTIONS} from "../../../app.config";

export const isMarketSupported = (
  collectionAddress: string,
  market: Market
): boolean => {
  const collection = COLLECTIONS.find(({ address, uBoundAddress }) => {
    return (
      equalIgnoreCase(address, collectionAddress) ||
      equalIgnoreCase(uBoundAddress, collectionAddress)
    );
  });

  if (!collection) {
    throw new Error(
      "failed on getting collection address name. Contract not supported"
    );
  }

  return collection.markets.includes(market);
};
