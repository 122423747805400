import type {ComponentPropsWithRef, FC, ForwardedRef} from "react";
import {createElement, forwardRef} from "react";
import {PortableText} from "@portabletext/react";
import {PortableText as IPortableText} from "../../types/elements";

interface Props extends ComponentPropsWithRef<"div"> {
  value: IPortableText;
  className?: string;
}

export const RichText: FC<Props> = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const { value, className, ...rest } = props;
    return (
      <div className={className} ref={ref}>
        <PortableText
          {...rest}
          value={value}
          components={{
            block: ({ value }) => (
              <p>
                {value.children.map((child) => {
                  if (child._type === "span" && child.text === "")
                    return createElement("br", {
                      key: child._key,
                    });
                  return createElement(
                    child._type,
                    { key: child._key },
                    child.text
                  );
                })}
              </p>
            ),
          }}
        />
      </div>
    );
  }
);
