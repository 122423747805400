import { CONTRACT_ADDRESSES } from "../../../app.config";
import {
  Log,
  BidOnLoanListedEventData,
  BidOnLoanListedParsed,
} from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import abi from "../../abis/debtMarket.json";

export const setBidOnLoanListedListener = (
  callback: (dataDecoded: BidOnLoanListedParsed) => void
): (() => void) => {
  const unlistener = listenContractEvent(
    getEventSignature(abi, "BidPlaced"),
    CONTRACT_ADDRESSES.debtMarket,
    [],
    ({ data, topics }: Log) => {
      //@ts-ignore
      const dataDecoded: BidOnLoanListedEventData = decodeTransactionData(
        abi,
        "BidPlaced",
        data,
        topics
      );

      const dataToReturn: BidOnLoanListedParsed = {
        ...dataDecoded,
        tokenId: Number(dataDecoded.tokenId),
        debtId: Number(dataDecoded.debtId),
      };

      callback(dataToReturn);
    },
    false
  );

  return unlistener;
};
