import { Signer } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/lendpoolUnlockd.json";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const triggerUserCollateral = async (
  collectionAddress: string,
  tokenId: number,
  configFee: number,
  signer: Signer
): Promise<string> => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.lendpool,
    abi,
    signer,
    "approveValuation",
    [collectionAddress, tokenId],
    { value: configFee }
  );

  return transaction.hash;
};
