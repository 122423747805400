import { BigNumber } from "ethers";

export enum MarketItemAuctionStatus {
  TO_REPAY,
  TO_REDEEM,
  TO_CLAIM,
  TO_FIRST_BID,
  TO_BID,
  BIDDED,
  REDEEM_FINISHED,
  WAITING_FOR_BOT_TIME,
  BID_FINISHED,
  LOADING_CLAIM,
  CLAIMED,
  NOT_AVAILABLE,
}

export enum MarketItemType {
  // lendpool
  LOAN_AUCTIONED = "loan-auctioned",
  // debtMarket
  LOAN_LISTED = "debt-sell",
}

export type MarketItem = {
  type: MarketItemType;
  nfts: Array<{
    collection: string;
    tokenId: number;
    name: string;
    image: string;
  }>;
  id: number;
  owner: string;
  valuation: BigNumber;
  debt: BigNumber;
  healthFactor: number;
  isBuyNowAvailable: boolean;
  isAuctionAvailable: boolean;
  buyNowPrice?: BigNumber;
  availableToBorrow?: BigNumber;
  auctionStatus?: MarketItemAuctionStatus;
  biddingEnd?: number;
  redeemEnd?: number;
  bidder?: string;
  latestBid?: BigNumber;
  minBid?: BigNumber;
  bidBorrowAmount?: BigNumber;
  isModalOpenAndRedeemed?: boolean;
  isOwnDebt?: boolean;
  reservoirBid?: BigNumber;
};

export interface MarketItemDto {
  category: MarketItemType;
  sellType: "fixed-price" | "auction" | "mixed";
  bidderAddress: Array<string> | null;
  bidPrice: Array<string>;
  collection: string;
  debt: string;
  debtId: number;
  debtor: string;
  ltv: number;
  sellPrice: string;
  state: "new" | "active" | "auctioned";
  startBiddingPrice: string;
  auctionEndTimestamp: number;
  redeemEndTimestamp: number | null;
  tokenId: number;
  valuation: string;
  healthFactor: string;
}
