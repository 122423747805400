import { callContract } from "../utils";
import abi from "../abis/cryptopunksMarket.json";
import { Signer } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const offerPunkForSale = async (
  collectionAddress: string,
  punkIndex: number,
  signer: Signer
): Promise<string> => {
  const { hash } = await callContract(
    collectionAddress,
    abi,
    signer,
    "offerPunkForSaleToAddress",
    [punkIndex, 0, CONTRACT_ADDRESSES.punkGateway]
  );

  return hash;
};
