import { type FC, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import {
  setBidOnLoanAuctionedListener,
  setBuyLoanAuctionedListener,
  setBuyNowListener,
  setCancelListListener,
  setRedeemListener,
  setRepayListener,
  setBidOnLoanListedListener,
} from "../../logic/listeners";
import { getMarketplaceDiscount, retrieveMarketplaceItems } from "../../logic";
import { MarketItemCard, Filters } from "..";
import {
  MarketItem,
  Filter,
  MarketItemType,
  Pagination,
  Discount,
} from "../../types";
import classNames from "classnames";
import { useAlertNotification } from "../providers";
import { CtaSecondary } from "../ui";
import { BigNumber } from "ethers";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  determineAuctionStatus,
  getDebtById,
  handleErrors,
  isCryptopunks,
  sortAuctionsByStatusAndRemainTime,
} from "../../logic/helpers";
import { equalIgnoreCase } from "../../utils";
import { PUNKS_COLLECTION } from "../../../app.config";

type Props = {
  className?: string;
  isWrapPunkApproved?: boolean;
};

const LIMIT = 9;

const FILTER_DEFAULT_VALUE: Filter = {
  types: [MarketItemType.LOAN_AUCTIONED, MarketItemType.LOAN_LISTED],
  orderBy: "bidPrice",
  sort: "ASC",
  allCollectionsSelected: true,
};

const context: {
  address?: string;
  loanOpenInModal?: {
    collection: string;
    tokenId: number;
    type: MarketItemType;
  };
} = {};

export const BuyNfts: FC<Props> = (props) => {
  const { className, isWrapPunkApproved } = props;
  const { address } = useAccount();
  const [error, setError] = useState<Error | null>(null);
  const [filter, setFilter] = useState<Filter>(FILTER_DEFAULT_VALUE);
  const [logicHandler, setLogicHandler] = useState<
    { stop: () => void } | undefined
  >();
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();
  const [loanOpenInModal, setLoanOpenInModal] = useState<{
    collection: string;
    tokenId: number;
    type: MarketItemType;
  }>();
  const [loanToClose, setLoanToClose] = useState<{
    collection: string;
    tokenId: number;
    type: MarketItemType;
  }>();
  const [discount, setDiscount] = useState<Discount>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Array<MarketItem>>([]);
  const [pagination, setPagination] = useState<Pagination>();

  const handleFilterChange = (newFilter: Filter) => setFilter(newFilter);

  context.address = address;
  context.loanOpenInModal = loanOpenInModal;

  useEffect(() => {
    if (error && !isAlertNotificationOpen) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          setDiscount(await getMarketplaceDiscount(address));
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  useEffect(() => {
    if (logicHandler) {
      logicHandler.stop();
    }

    if (address) {
      if (filter.types.length > 0) {
        (async () => {
          setLoading(true);
          setItems([]);

          try {
            const logicHandler = retrieveMarketplaceItems(
              address,
              0,
              LIMIT,
              filter,
              (err, itemsFromLogic, pagination) => {
                if (err) {
                  setError(handleErrors(err));
                } else {
                  setItems(itemsFromLogic!);
                  setPagination(pagination!);
                  setLoading(false);
                  setLogicHandler(undefined);
                }
              }
            );

            setLogicHandler(logicHandler);
          } catch (err) {
            setError(handleErrors(err));
          }
        })();
      } else {
        setItems([]);
        setPagination(undefined);
      }
    }
  }, [address, filter]);

  useEffect(() => {
    const bidOnLoanListedUnlistener = setBidOnLoanListedListener((data) => {
      const { nftAsset, tokenId, bidderAddress, bidPrice } = data;

      const collection = isCryptopunks(nftAsset)
        ? PUNKS_COLLECTION.address!
        : nftAsset;

      handleBidPlaced(
        collection,
        tokenId,
        MarketItemType.LOAN_LISTED,
        bidderAddress,
        bidPrice
      );
    });

    const bidOnLoanAuctionedUnlistener = setBidOnLoanAuctionedListener(
      (data) => {
        const { bidder, price, nftAsset, nftTokenId } = data;

        const collection = isCryptopunks(nftAsset)
          ? PUNKS_COLLECTION.address!
          : nftAsset;

        handleBidPlaced(
          collection,
          nftTokenId,
          MarketItemType.LOAN_AUCTIONED,
          bidder,
          price
        );
      }
    );

    const redeemUnlistener = setRedeemListener((data) => {
      const { nftAsset, nftTokenId } = data;

      const collection = isCryptopunks(nftAsset)
        ? PUNKS_COLLECTION.address!
        : nftAsset;

      handleRemoveItemFromList(
        collection,
        nftTokenId,
        MarketItemType.LOAN_AUCTIONED,
        true
      );
    });

    const repayUnlistener = setRepayListener((data) => {
      const { nftAsset, nftTokenId } = data;

      const collection = isCryptopunks(nftAsset)
        ? PUNKS_COLLECTION.address!
        : nftAsset;

      handleRemoveItemFromList(
        collection,
        nftTokenId,
        MarketItemType.LOAN_AUCTIONED,
        true
      );
    });

    const buyNowUnlistener = setBuyNowListener(async (data) => {
      const { debtId } = data;

      const { nftAsset, tokenId } = await getDebtById(debtId);

      const collection = isCryptopunks(nftAsset)
        ? PUNKS_COLLECTION.address!
        : nftAsset;

      handleRemoveItemFromList(
        collection,
        Number(tokenId),
        MarketItemType.LOAN_LISTED,
        false
      );
    });

    const buyoutUnlistener = setBuyLoanAuctionedListener((data) => {
      const { nftAsset, nftTokenId } = data;

      const collection = isCryptopunks(nftAsset)
        ? PUNKS_COLLECTION.address!
        : nftAsset;

      handleRemoveItemFromList(
        collection,
        nftTokenId,
        MarketItemType.LOAN_AUCTIONED,
        true
      );
    });

    const cancelListUnistener = setCancelListListener((data) => {
      const {
        marketListing: { nftAsset, tokenId },
      } = data;

      const collection = isCryptopunks(nftAsset)
        ? PUNKS_COLLECTION.address!
        : nftAsset;

      handleRemoveItemFromList(
        collection,
        tokenId,
        MarketItemType.LOAN_LISTED,
        false
      );
    });

    return () => {
      redeemUnlistener();
      bidOnLoanAuctionedUnlistener();
      repayUnlistener();
      bidOnLoanListedUnlistener();
      buyNowUnlistener();
      buyoutUnlistener();
      cancelListUnistener();
    };
  }, []);

  const handleSeeMore = async () => {
    if (address) {
      setLoading(true);

      try {
        const logicHandler = retrieveMarketplaceItems(
          context.address!,
          items.length,
          LIMIT,
          filter,
          (error, itemsFromLogic, pagination) => {
            if (error) {
              setError(handleErrors(error));
            } else {
              setLogicHandler(undefined);
              setItems((prevItems) => [...prevItems, ...itemsFromLogic!]);
              setPagination(pagination!);
              setLoading(false);
            }
          }
        );

        setLogicHandler(logicHandler);
      } catch (err) {
        setError(handleErrors(err));
      }
    }
  };

  const handleModalOpen = (
    collection: string,
    tokenId: number,
    type: MarketItemType
  ) => setLoanOpenInModal({ collection, tokenId, type });

  const handleModalClose = () => {
    setLoanOpenInModal(undefined);

    if (loanToClose) {
      handleRemoveItemFromList(
        loanToClose.collection,
        loanToClose.tokenId,
        loanToClose.type,
        false
      );
    }
  };

  const handleRemoveItemFromList = (
    collection: string,
    tokenId: number,
    type: MarketItemType,
    removeAll: boolean
  ) => {
    setItems((items) => {
      const itemsCopy = [...items];

      // GET THE INDEXES OF THE ELEMENTS TO REMOVE
      let indexes: number[] = [];

      items.forEach(
        (
          {
            type: _type,
            nfts: [{ collection: _collection, tokenId: _tokenId }],
          },
          index
        ) => {
          if (
            equalIgnoreCase(collection, _collection) &&
            tokenId === _tokenId &&
            (removeAll ? true : _type === type)
          ) {
            indexes.push(index);
          }
        }
      );

      if (indexes.length > 0) {
        if (context.loanOpenInModal) {
          // CHECK IF ONE OF THE ITEMS TO DELETE IS OPEN IN MODAL
          let itemFound = false;

          for (let i = 0; i < indexes.length && !itemFound; i++) {
            const {
              nfts: [{ collection, tokenId }],
              type,
            } = items[indexes[i]];

            if (
              equalIgnoreCase(context.loanOpenInModal.collection, collection) &&
              context.loanOpenInModal.tokenId === tokenId &&
              type === context.loanOpenInModal.type
            ) {
              // IF IT IS OPEN IN MODAL THE INDEX IS REMOVE FROM THE INDEXES ARRAY TO NOT REMOVE IT FROM ITEMS ARRAY
              // AND IS SETTED AS LOAN TO CLOSE WHEN THE MODAL IS CLOSED
              itemFound = true;
              indexes.splice(i, 1);

              setLoanToClose(context.loanOpenInModal);
            }
          }
        }

        // REMOVE ALL THE ITEMS FROM THE INDEXES ARRAY

        for (let i = 0; i < indexes.length; i++) {
          itemsCopy.splice(indexes[i], 1);

          if (indexes[i + 1]) indexes[i + 1] -= 1;
        }
        // CORRECT PAGINATION AFTER REMOVING
        if (indexes.length) handlePaginationChangeOnItemRemoved(indexes.length);
      }

      return itemsCopy;
    });
  };

  const handlePaginationChangeOnItemRemoved = (amount: number) => {
    setPagination((pagination) => {
      const paginationCopy = { ...pagination! };

      paginationCopy.total = paginationCopy.total - amount;

      return paginationCopy;
    });
  };

  const handleBidPlaced = (
    collection: string,
    tokenId: number,
    type: MarketItemType,
    bidder: string,
    bidPrice: BigNumber
  ) => {
    setItems((items) => {
      const index = items.findIndex(
        ({
          type: _type,
          nfts: [{ collection: _collection, tokenId: _tokenId }],
        }) =>
          equalIgnoreCase(collection, _collection) &&
          tokenId === _tokenId &&
          type === _type
      );
      if (index !== -1) {
        const itemToUpdate = { ...items[index] };

        itemToUpdate.bidder = bidder;
        itemToUpdate.latestBid = BigNumber.from(bidPrice);
        itemToUpdate.minBid =
          itemToUpdate.type === MarketItemType.LOAN_LISTED
            ? BigNumber.from(bidPrice).mul(102).div(100)
            : BigNumber.from(bidPrice).mul(101).div(100);
        itemToUpdate.auctionStatus = determineAuctionStatus(
          itemToUpdate.type,
          itemToUpdate.owner,
          context.address!,
          itemToUpdate.biddingEnd!,
          itemToUpdate.bidder,
          true,
          itemToUpdate.redeemEnd
        );
        const itemsCopy = [...items];

        itemsCopy[index] = itemToUpdate;
        return itemsCopy;
      } else {
        return items;
      }
    });
  };

  return (
    <div className="w-full flex flex-col gap-8">
      <div className="w-full flex pl-8 gap-20">
        <Filters filter={filter} onChange={handleFilterChange} />

        <div className="w-full flex justify-center items-center">
          {items.length === 0 && !isLoading ? (
            <div className="flex justify-center py-32 text-lg font-semibold">
              {filter.types.length > 0
                ? "There are currently no items available"
                : "Please select at least one item type in the filters"}
            </div>
          ) : (
            <ul
              className={classNames(
                "flex flex-wrap mt-0 w-full gap-20 justify-start",
                className
              )}
            >
              {items.map((item, index) => {
                let buyNowPriceDiscounted: BigNumber | undefined;

                const discountToApply =
                  item.type === MarketItemType.LOAN_AUCTIONED
                    ? discount!.loanAuctioned
                    : discount!.loanListed;

                if (item.isBuyNowAvailable) {
                  const priceWithDiscount = item
                    .buyNowPrice!.mul(100 - discountToApply)
                    .div(100);

                  buyNowPriceDiscounted = priceWithDiscount.gt(item.debt)
                    ? priceWithDiscount
                    : item.debt;
                } else {
                  buyNowPriceDiscounted = item.buyNowPrice!;
                }

                return (
                  <MarketItemCard
                    key={`${item.nfts[0].collection}-${item.nfts[0].tokenId}-${item.type}`}
                    index={index}
                    marketItem={item}
                    onModalOpen={handleModalOpen}
                    onModalClose={handleModalClose}
                    isRemoved={
                      loanToClose !== undefined &&
                      equalIgnoreCase(
                        item.nfts[0].collection,
                        loanToClose.collection
                      ) &&
                      item.nfts[0].tokenId === loanToClose.tokenId
                    }
                    // discount={discount}
                    discount={discountToApply}
                    buyNowPrice={buyNowPriceDiscounted}
                    isWrapPunkApproved={isWrapPunkApproved}
                  />
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <div className="flex justify-center">
        {!isLoading && pagination && items.length < pagination.total && (
          <CtaSecondary className="mb-14 w-48" onClick={handleSeeMore}>
            See more
          </CtaSecondary>
        )}
      </div>
      {isLoading && (
        <div className="w-full flex mx-auto mt-8 justify-center items-center">
          <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
        </div>
      )}
    </div>
  );
};
