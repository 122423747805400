import { BytesLike } from "ethers";
import abi from "../../abis/debtMarket.json";
import { decodeTransactionData } from "../../utils";

export const decodeBidOnLoanListedLogData = (
  data: BytesLike,
  topics: string[]
) => {
  return decodeTransactionData(abi, "BidPlaced", data, topics);
};
