import classNames from "classnames";
import {ComponentProps, type ElementType} from "react";

type Component = <As extends ElementType = "button">(
    props: ComponentProps<As> & {
        as?: As;
        size?: "xs" | "sm" | "lg";
    }
) => JSX.Element;

export const CtaWhite: Component = (props) => {
    const { children, as, className, size = "sm", ...restProps } = props;
    const Component = as ?? "button";

    const styles = props.disabled
        ? "bg-[#9A9A9A] text-[#545454] border-[#9A9A9A]"
        : "bg-white border-white text-primary hover:border-white focus-visible:border-white";

    return (
        <Component
            {...restProps}
            className={classNames(
                styles,
                `border-2 
        active:contrast-125 font-bold rounded-full
        box-border block text-center break-words transition-colors duration-75`,
                {
                    "text-2xs md:text-xs px-3 py-1.5": size === "xs",
                    "text-xs md:text-sm px-3 max-3xs:px-2 py-2": size === "sm",
                    "text-sm md:text-base px-3 max-3xs:px-2 py-2": size === "lg",
                },
                [className]
            )}
        >
            {children}
        </Component>
    );
};
