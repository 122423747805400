import { Discount, SingleCall } from "../types";
import abi from "../abis/lockeyManager.json";
import { multicall } from "../utils/multicall";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { isLockeysHolder } from "./helpers";

export const getMarketplaceDiscount = async (
  address: string
): Promise<Discount> => {
  const discounts = {
    loanAuctioned: 0,
    loanListed: 0,
  };

  if (await isLockeysHolder(address)) {
    const loanAuctionedDiscountCall: SingleCall = {
      abi,
      contractAddress: CONTRACT_ADDRESSES.lockeyManager,
      method: "getLockeyDiscountPercentage",
      args: [],
    };

    const loanListedDiscountCall: SingleCall = {
      abi,
      contractAddress: CONTRACT_ADDRESSES.lockeyManager,
      method: "getLockeyDiscountPercentageOnDebtMarket",
      args: [],
    };

    const [loanAuctionedValue, loanListedValue] = await multicall(
      loanAuctionedDiscountCall,
      loanListedDiscountCall
    );

    discounts.loanAuctioned = (10000 - Number(loanAuctionedValue)) / 100;
    discounts.loanListed = (10000 - Number(loanListedValue)) / 100;
  }

  return discounts;
};
