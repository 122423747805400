import { CONTRACT_ADDRESSES } from "../../../app.config";
import { callContract } from "../../utils";
import abi from "../../abis/customERC721.json";

export const isLockeysHolder = async (address: string): Promise<boolean> => {
  const balance = await callContract(
    CONTRACT_ADDRESSES.theLockeys,
    abi,
    null,
    "balanceOf",
    [address]
  );

  return Number(balance) !== 0;
};
