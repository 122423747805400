import { CONTRACT_ADDRESSES } from "../../../app.config";
import { BuyNowEventData, BuyNowEventDataParsed, Log } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import abi from "../../abis/debtMarket.json";

export const setBuyNowListener = (
  callback: (dataDecoded: BuyNowEventDataParsed) => void,
  topics?: { from: string; to: string }
): (() => void) => {
  const unlistener = listenContractEvent(
    getEventSignature(abi, "DebtSold"),
    CONTRACT_ADDRESSES.debtMarket,
    topics ? [topics.from, topics.to] : [],
    ({ data, topics }: Log) => {
      // @ts-ignore
      const dataDecoded: BuyNowEventData = decodeTransactionData(
        abi,
        "DebtSold",
        data,
        topics
      );

      const dataParsed = {
        ...dataDecoded,
        debtId: Number(dataDecoded.debtId),
      };

      callback(dataParsed);
    },
    false
  );

  return unlistener;
};
