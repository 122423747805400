import {BigNumber} from "ethers";
import {formatEther} from "ethers/lib/utils";
import {percentMul, wadDiv} from "../../utils";

export const calculateHealthFactorRepay = (
  nftValuation: BigNumber,
  currentBorrowAmount: BigNumber,
  liquidationThreshold: BigNumber,
  amountToRepay: BigNumber = BigNumber.from(0)
): number => {
  const newBorrowAmount = currentBorrowAmount.sub(amountToRepay);

  if (newBorrowAmount.isZero()) {
    return 0;
  } else {
    const nftValuationThreshold: BigNumber = percentMul(
      nftValuation,
      liquidationThreshold
    );

    const hf = parseFloat(
      formatEther(wadDiv(nftValuationThreshold, newBorrowAmount))
    );

    // const hf = nftValuationThreshold.div(newBorrowAmount);

    return hf;
  }
};
