import { BigNumber, Signer } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/wethGateway.json";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { setSafeMultiSigTxListener } from "./listeners";

export const supply = async (
  address: string,
  signer: Signer,
  amount: BigNumber
) => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.wethGateway,
    abi,
    signer,
    "depositETH",
    [address, 0],
    { value: amount }
  );

  return transaction.hash;
};
