import { MarketItemAuctionStatus, MarketItemType } from "../../types";
import { equalIgnoreCase } from "../../utils";

export const determineAuctionStatus = (
  type: MarketItemType,
  owner: string,
  addressFromWallet: string,
  biddingEnd: number,
  latestBidder: string | undefined,
  hasBids: boolean,
  redeemEnd?: number
): MarketItemAuctionStatus => {
  let status: MarketItemAuctionStatus;

  if (equalIgnoreCase(owner, addressFromWallet)) {
    if (type === MarketItemType.LOAN_AUCTIONED) {
      if (redeemEnd && Date.now() - redeemEnd < 0) {
        if (!hasBids) {
          status = MarketItemAuctionStatus.TO_REPAY;
        } else {
          status = MarketItemAuctionStatus.TO_REDEEM;
        }
      } else {
        status = MarketItemAuctionStatus.REDEEM_FINISHED;
      }
    } else {
      status = MarketItemAuctionStatus.NOT_AVAILABLE;
    }
  } else if (!latestBidder && Date.now() - biddingEnd < 0) {
    status = MarketItemAuctionStatus.TO_FIRST_BID;
  } else if (latestBidder && equalIgnoreCase(latestBidder, addressFromWallet)) {
    if (Date.now() - biddingEnd < 0) {
      status = MarketItemAuctionStatus.BIDDED;
    } else if (
      type === MarketItemType.LOAN_AUCTIONED &&
      Date.now() - biddingEnd < 1000 * 60 * 20
    ) {
      status = MarketItemAuctionStatus.WAITING_FOR_BOT_TIME;
    } else {
      status = MarketItemAuctionStatus.TO_CLAIM;
    }
  } else if (Date.now() - biddingEnd < 0) {
    status = MarketItemAuctionStatus.TO_BID;
  } else {
    status = MarketItemAuctionStatus.BID_FINISHED;
  }

  return status;
};
