import { FC, useState } from "react";
import Image from "next/image";
import { ModalNotifications } from "../modals";
import { useWeb3ModalGuard } from "../modals/shared";
import { useToggle } from "react-use";

type Props = {
  className?: string;
};

export const NotificationButton: FC<Props> = (props) => {
  const [isOpen, toggleModal] = useToggle(false);
  const launcherModal = useWeb3ModalGuard(isOpen, toggleModal);

  const handleOnNotificationClick = () => launcherModal();

  return (
    <>
      <button onClick={handleOnNotificationClick}>
        <Image
          src="/assets/images/notification.png"
          alt="notifications"
          width="20"
          height="21"
        />
      </button>
      {isOpen && (
        <ModalNotifications isOpen={isOpen} toggleModal={toggleModal} />
      )}
    </>
  );
};
