import {Storage} from "../../utils";

export const getNftImageFromCache = (
  collectionAddress: string,
  tokenId: string
): string => {
  const image = Storage.LOCAL.get(
    `nfts.${collectionAddress.toLowerCase()}.id-${tokenId}.image`,
    ""
  );

  return image;
};
