import { BigNumber } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/unlockdProtocolDataProvider.json";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const getProtocolLiquidity = async (
  assetAddress: string
): Promise<BigNumber> => {
  const { availableLiquidity } = await callContract(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    abi,
    null,
    "getReserveData",
    [assetAddress]
  );

  return availableLiquidity;
};
