import { type FC, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { CtaPrimary, CtaSecondary, RichText } from "../../ui";
import {
  SwitchChainError,
  UserRejectedRequestError,
  useSwitchNetwork,
} from "wagmi";
import { ErrorMessage, withModal } from "./shared";
import { useModalSwitchNetworkStatics } from "../../statics";
import type { ModalSwitchNetworkStatics, PortableText } from "../../../types";
import { CHAIN } from "../../../../app.config";

interface Props {
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
}

type Controller = (props: Props) => {
  statics: Omit<ModalSwitchNetworkStatics, "feedbacks">;
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
  onActionButton: () => void;
  errorMessage?: string;
};

const useController: Controller = (props) => {
  const { toggleModal, ...restProps } = props;
  const statics = useModalSwitchNetworkStatics();
  const { feedbacks, ...restStatics } = statics;
  const [errorMessage, setErrorMessage] = useState<string>();
  const { chains, switchNetwork } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
    onSuccess() {
      // metamask not change chainID at the same time that this event, need 1.5s more
      setTimeout(() => {
        toggleModal(false);
      }, 1500);
    },
    onError(error) {
      if (error instanceof UserRejectedRequestError)
        return setErrorMessage(feedbacks.userRejectedRequestError);
      if (error instanceof SwitchChainError)
        return setErrorMessage(feedbacks.pendingSwitchTransactionError);
      setErrorMessage(feedbacks.unknownError);
    },
  });

  const onActionButton = () => {
    switchNetwork?.(chains[0]?.id);
  };

  return {
    statics: restStatics,
    onActionButton,
    toggleModal,
    errorMessage,
    ...restProps,
  };
};

export const ModalSwitchNetwork: FC<Props> = withModal((props) => {
  const { statics, toggleModal, onActionButton, errorMessage } =
    useController(props);
  const { title, dismissButton, successButton } = statics;

  return (
    <>
      <div
        className="bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] w-96 overflow-hidden
           border-2 rounded-3xl xs:px-6 px-5 pb-6 pt-8 xs:pb-8"
      >
        <button
          className="w-10 h-10 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5 text-white"
          onClick={() => toggleModal(false)}
        >
          <IoMdClose className="w-full h-full" />
        </button>
        <h5 className="xs:mb-8 mb-5 text-center text-base xs:text-lg text-white font-bold">
          {title}
        </h5>
        <div className="w-80 text-center text-xs xs:text-sm max-w-full xs:mb-8 mb-5">
          <RichText value={legalText} className="text-white" />
        </div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <div className="max-w-full xs:flex xs:flex-row-reverse justify-center items-center">
          <CtaPrimary
            className="max-xs:w-full max-xs:mb-4"
            onClick={() => onActionButton()}
          >
            {successButton}
          </CtaPrimary>
          <CtaSecondary
            className="xs:mr-4 max-xs:w-full"
            onClick={() => toggleModal(false)}
          >
            {dismissButton}
          </CtaSecondary>
        </div>
      </div>
    </>
  );
});

const legalText: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "block-0-child-0",
        _type: "span",
        text: `To be able to mint, please switch to ${
          CHAIN === "goerli" ? "Goerli" : "Ethereum"
        } network`,
      },
    ],
  },
];
