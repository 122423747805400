import { type FC } from "react";
import {
  mainnet,
  goerli,
  configureChains,
  createClient,
  WagmiConfig,
} from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { infuraProvider } from "wagmi/providers/infura";
import { CHAIN, ALCHEMY, INFURA, WALLET_CONNECT } from "../../app.config";

const { provider, webSocketProvider, chains } = configureChains(
  // @ts-ignore
  [CHAIN === "mainnet" ? mainnet : goerli],
  [
    alchemyProvider({
      apiKey:
        typeof window === "undefined"
          ? process.env.SERVER_ALCHEMY_KEY!
          : ALCHEMY.apiKey,
    }),
    infuraProvider({ apiKey: INFURA.apiKey }),
  ]
);
const client = createClient({
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        showQrModal: true,
        projectId: WALLET_CONNECT.projectId,
      },
    }),
  ],
  provider,
  webSocketProvider,
  autoConnect: true,
});

export const WagmiSetup: FC = ({ children }) => {
  return (
    <>
      <WagmiConfig client={client}>{children}</WagmiConfig>
    </>
  );
};
