import { callContract } from "../../utils";
import lendpoolAbi from "../../abis/lendpoolUnlockd.json";
import { BigNumber } from "ethers";
import { CONTRACT_ADDRESSES } from "../../../app.config";

export const getReserveNormalizedVariableDebt =
  async (): Promise<BigNumber> => {
    const reserveVariableDebt = await callContract(
      CONTRACT_ADDRESSES.lendpool,
      lendpoolAbi,
      null,
      "getReserveNormalizedVariableDebt",
      [CONTRACT_ADDRESSES.weth]
    );

    return reserveVariableDebt;
  };
