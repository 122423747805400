import classNames from "classnames";
import type {ComponentProps, ElementType} from "react";

type Component = <As extends ElementType = "button">(
  props: ComponentProps<As> & {
    as?: As;
    size?: "xs" | "sm" | "lg";
  }
) => JSX.Element;

export const CtaDisabled: Component = (props) => {
  const { children, as, className, size = "sm", ...restProps } = props;
  const Component = as ?? "button";

  return (
    <Component
      {...restProps}
      className={classNames(
        `bg-[#2D2B41] border-2 border-[#9A9A9A] text-[#9A9A9A]
        font-bold rounded-full
        box-border block text-center break-words`,
        {
          "text-2xs md:text-xs px-3 py-1.5": size === "xs",
          "text-xs md:text-sm px-3 max-3xs:px-2 py-2": size === "sm",
          "text-sm md:text-base px-3 max-3xs:px-2 py-2": size === "lg",
        },
        [className]
      )}
      disabled={true}
    >
      {children}
    </Component>
  );
};
