import { type FC, FormEvent, useEffect, useState } from "react";
import { ErrorMessage, Input, withModal } from "./shared";
import { IoMdClose } from "react-icons/io";
import { IconEthereum } from "../../ui/icons";
import { CtaPrimary, CtaSecondary } from "../../ui";
import { useAccount, useSigner } from "wagmi";
import { useAlertNotification } from "../../providers";
import {
  formatWeiToEth,
  getBalanceOfAddress,
  waitForTransactionReceipt,
} from "../../../utils";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  TransactionError,
  transactionErrorMessage,
} from "../../../errors/errors";
import { getLoanDataToRedeem, redeem } from "../../../logic";
import { AiOutlineArrowUp, AiOutlineLoading3Quarters } from "react-icons/ai";
import { HealthFactorCircle } from "../HealthFactorCircle";
import Link from "next/link";
import { BigNumber } from "ethers";
import {
  calculateHealthFactorRepay,
  handleErrors,
  changeToWrapIfCrypropunks,
} from "../../../logic/helpers";
import { approveInWalletLiteral } from "../../../literals";

type Props = {
  className?: string;
  isOpen: boolean;
  collectionAddress: string;
  tokenId: number;
  debt: BigNumber;
  valuation: BigNumber;
  toggleModal: (nextValue: boolean) => void;
};

type redeemData = {
  liquidationThreshold: BigNumber;
  maxRedeem: BigNumber;
  minRedeem: BigNumber;
  bidFine: BigNumber;
};

enum Status {
  TO_REDEEM,
  LOADING,
  SUCCESS,
}

export const ModalRedeem: FC<Props> = withModal((props) => {
  const { toggleModal, collectionAddress, tokenId, debt, valuation } = props;
  const { address } = useAccount();
  const [balance, setBalance] = useState<BigNumber>();
  const [amount, setAmount] = useState<BigNumber>(BigNumber.from(0));
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<Status>(Status.TO_REDEEM);
  const [redeemData, setRedeemData] = useState<redeemData>();
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();
  const { data: signer } = useSigner();
  const [healthFactor, setHealthFactor] = useState<number>();
  const [max, setMax] = useState<BigNumber>();

  const minRedeem = redeemData
    ? formatWeiToEth(redeemData.minRedeem)
    : "-.----";
  const maxRedeem = redeemData
    ? formatWeiToEth(redeemData.maxRedeem)
    : "-.----";

  useEffect(() => {
    (async () => {
      try {
        const data = await getLoanDataToRedeem(
          changeToWrapIfCrypropunks(collectionAddress),
          tokenId
        );

        setRedeemData(data);

        const hf = calculateHealthFactorRepay(
          valuation,
          debt,
          data.liquidationThreshold
        );

        setHealthFactor(hf);
      } catch (err) {
        setError(handleErrors(err));
      }
    })();
  }, []);

  useEffect(() => {
    if (redeemData) {
      setMax(
        redeemData.maxRedeem.lt(balance!) ? redeemData.maxRedeem : balance!
      );
    }
  }, [redeemData]);

  useEffect(() => {
    if (redeemData) {
      let hf;

      if (amount && amount.gt(debt)) {
        hf = calculateHealthFactorRepay(
          valuation,
          debt,
          redeemData.liquidationThreshold
        );
      } else {
        hf = calculateHealthFactorRepay(
          valuation,
          debt,
          redeemData.liquidationThreshold,
          amount
        );
      }

      setHealthFactor(hf);
    }
  }, [amount]);

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(
        error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError ||
        error instanceof TransactionError
      )
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    } else if (error instanceof TransactionError) {
      openAlertNotification("error", transactionErrorMessage, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const balanceRetrieved = await getBalanceOfAddress(address);

          setBalance(balanceRetrieved);
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  const handleAmountChange = (amountSelected: BigNumber): void => {
    setAmount(amountSelected);

    if (amountSelected.gt(balance!)) {
      setError(
        new LimitMaxError(
          `amount ${formatWeiToEth(
            amountSelected
          )} is greater than balance of address: ${formatWeiToEth(balance!)}`
        )
      );
    } else if (redeemData && amountSelected.gt(redeemData.maxRedeem)) {
      setError(
        new LimitMaxError(
          `amount ${formatWeiToEth(
            amountSelected
          )} is greater than max allowed (${formatWeiToEth(
            redeemData.maxRedeem
          )})`
        )
      );
    } else if (
      redeemData &&
      BigNumber.from(amountSelected).lt(redeemData.minRedeem)
    ) {
      setError(
        new LimitMinError(
          `amount ${formatWeiToEth(
            amountSelected
          )} is lower than the minimum required (${formatWeiToEth(
            redeemData.minRedeem
          )})`
        )
      );
    } else {
      if (error) setError(null);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!amount.isZero() && signer && address && redeemData?.bidFine) {
      (async () => {
        try {
          openAlertNotification("info", approveInWalletLiteral, 50000);

          const transactionHash = await redeem(
            signer,
            collectionAddress,
            tokenId,
            amount,
            redeemData.bidFine
          );

          closeAlertNotification();
          setStatus(Status.LOADING);

          waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
            if (receipt.status == 1) {
              setStatus(Status.SUCCESS);
            } else if (receipt.status === 0) {
              setError(
                new TransactionError(
                  `transaction with hash ${receipt.transactionHash} failed`
                )
              );

              setStatus(Status.TO_REDEEM);
            }
          });
        } catch (err) {
          closeAlertNotification();
          setError(handleErrors(err));
        }
      })();
    }
  };

  return (
    <form
      className="bg-secondary relative border-glow-sm max-w-5xl overflow-hidden border-2 rounded-3xl xs:px-12 px-10 pb-6 pt-10 xs:pb-8 text-white flex flex-col items-center"
      onSubmit={handleSubmit}
    >
      <button
        type="button"
        className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
        onClick={() => toggleModal(false)}
      >
        <IoMdClose className="w-full h-full" />
      </button>
      <div className="w-full flex">
        <h3 className="xs:mb-5 text-xl text-center xs:text-2xl font-bold">
          Redeem ETH
        </h3>
        <div className="w-6 h-6 bg-white rounded-full ml-2">
          <IconEthereum />
        </div>
      </div>
      {status === Status.TO_REDEEM && (
        <>
          <div className="w-full flex flex-wrap justify-between">
            <div className="w-1/3 flex flex-col">
              <h3 className="font-bold text-xs">Min redeem</h3>
              <div className="flex items-center">
                <p>{minRedeem}</p>
                <div className="bg-white ml-2 w-4 h-4 rounded-full">
                  <IconEthereum />
                </div>
              </div>
            </div>
            <div className="w-1/3 flex flex-col">
              <h3 className="font-bold text-xs">Max redeem</h3>
              <div className="flex items-center">
                <p>{maxRedeem}</p>
                <div className="bg-white ml-2 w-4 h-4 rounded-full">
                  <IconEthereum />
                </div>
              </div>
            </div>
            <div className="w-1/3 flex flex-col">
              <h3 className="font-bold text-xs">Bid fine</h3>
              <div className="flex items-center">
                <p>
                  {redeemData
                    ? formatWeiToEth(redeemData.bidFine, 5)
                    : "#.####"}
                </p>
                <div className="bg-white ml-2 w-4 h-4 rounded-full">
                  <IconEthereum />
                </div>
              </div>
            </div>
            <div className="mt-6 w-1/3 flex flex-col">
              <h3 className="font-bold text-xs">Debt</h3>
              <div className="flex items-center">
                <p>{formatWeiToEth(debt)}</p>
                <div className="bg-white ml-2 w-4 h-4 rounded-full">
                  <IconEthereum />
                </div>
              </div>
            </div>
          </div>
          <Input
            className="mt-5 w-[560px]"
            balance={balance!}
            max={max}
            nameInput="redeem"
            onChange={handleAmountChange}
            value={redeemData?.minRedeem}
          />
          <div className="mt-5 flex flex-col items-center gap-[6px]">
            <div className="flex items-center gap-1">
              <p className="font-bold">Health Factor</p>
              <p>{healthFactor?.toFixed(2) || ""}</p>
              {healthFactor && (
                <HealthFactorCircle healthFactor={healthFactor} />
              )}
            </div>
            <div>
              <p>You can redeem up to 90% of the total borrowing amount.</p>
              <p>You can fully repay from My Loans to claim your NFT back.</p>
            </div>
          </div>
        </>
      )}

      {status === Status.LOADING && (
        <>
          <div className="w-full flex flex-col items-center">
            <p className="mt-5 font-bold">Don't close this screen.</p>
            <p className="mt-3">Your redeem should be confirmed</p>
            <p>in the blockchain shortly.</p>
          </div>
          <div className="flex justify-center items-center my-10">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        </>
      )}

      {(error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError) && (
        <ErrorMessage className="mt-4 px-4">{error.message}</ErrorMessage>
      )}

      {(status === Status.TO_REDEEM || status === Status.LOADING) && (
        <div className="mt-10 flex justify-between gap-4">
          <CtaSecondary className="w-40" onClick={() => toggleModal(false)}>
            Back
          </CtaSecondary>
          <CtaPrimary className="w-40" disabled={status === Status.LOADING}>
            {status === Status.TO_REDEEM ? "Redeem" : "Loading..."}
          </CtaPrimary>
        </div>
      )}

      {status === Status.SUCCESS && (
        <>
          <div className="mt-5 flex w-96 flex-col items-center justify-center mx-auto xs:mb-8 mb-5">
            <div className="border-white border-2 rounded-full mb-4">
              <AiOutlineArrowUp className="w-8 h-8 m-4" />
            </div>
            <span>Transaction submitted</span>
          </div>
          <div className="flex justify-center gap-5 w-full">
            <Link legacyBehavior href="/borrow">
              <CtaPrimary
                className="w-full"
                type="button"
                onClick={() => toggleModal(false)}
              >
                Go to my loans
              </CtaPrimary>
            </Link>
          </div>
        </>
      )}
    </form>
  );
});
