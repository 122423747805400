import { BigNumber } from "ethers";
import { isCryptopunks } from "./isCryptopunks";

export const getRepayArguments = (
  collectionAddress: string,
  tokenIdOrPunkIndex: number,
  amount: BigNumber
): Array<any> => {
  if (isCryptopunks(collectionAddress)) {
    return [tokenIdOrPunkIndex, amount];
  } else {
    return [collectionAddress, tokenIdOrPunkIndex, amount];
  }
};
