import { CONTRACT_ADDRESSES } from "../../../app.config";
import { Log, RedeemEventDataParsed } from "../../types";
import { RedeemEventData } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import abi from "../../abis/lendpoolUnlockd.json";

export const setRedeemListener = (
  callback: (dataDecoded: RedeemEventDataParsed) => void,
  address?: string
): (() => void) => {
  const unlistener = listenContractEvent(
    // @ts-ignore
    getEventSignature(abi, "Redeem"),
    CONTRACT_ADDRESSES.lendpool,
    address ? [, , address] : [],
    ({ data, topics }: Log) => {
      // @ts-ignore
      const dataDecoded: RedeemEventData = decodeTransactionData(
        abi,
        "Redeem",
        data,
        topics
      );

      const dataParsed: RedeemEventDataParsed = {
        ...dataDecoded,
        nftTokenId: Number(dataDecoded.nftTokenId),
        loanId: Number(dataDecoded.loanId),
      };

      callback(dataParsed);
    },
    false
  );

  return unlistener;
};
