import { callContract } from "../../utils";
import abi from "../../abis/unlockdProtocolDataProvider.json";
import { BigNumber } from "ethers";
import { CONTRACT_ADDRESSES } from "../../../app.config";

export const getNFTXPrice = async (
  collectionAddress: string,
  tokenId: number,
  reserveAddress: string
): Promise<BigNumber> => {
  const nftxPrice = await callContract(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    abi,
    null,
    "getNFTXPrice",
    [collectionAddress, tokenId, reserveAddress]
  );

  return nftxPrice;
};
