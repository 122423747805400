import { BigNumber, Signer } from "ethers";
import { callContract } from "../utils";
import abi from "../abis/weth.json";
import { UINT_256_MAX } from "../constants";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const approveUnlockdToManageTokens = async (
  signer: Signer
): Promise<string> => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.uWeth,
    abi,
    signer,
    "approve",
    [CONTRACT_ADDRESSES.wethGateway, BigNumber.from(UINT_256_MAX)]
  );

  return transaction.hash;
};
