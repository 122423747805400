import { CONTRACT_ADDRESSES, EVENTS_TOPICS } from "../../../app.config";
import {
  CancelListEventData,
  CancelListEventDataParsed,
  Log,
} from "../../types";
import { decodeTransactionData, listenContractEvent } from "../../utils";
import abi from "../../abis/debtMarket.json";

export const setCancelListListener = (
  callback: (dataDecoded: CancelListEventDataParsed) => void
): (() => void) => {
  const unlistener = listenContractEvent(
    EVENTS_TOPICS.debtListingCanceled,
    CONTRACT_ADDRESSES.debtMarket,
    [],
    ({ data, topics }: Log) => {
      // @ts-ignore
      const dataDecoded: CancelListEventData = decodeTransactionData(
        abi,
        "DebtListingCanceled",
        data,
        topics
      );

      const dataParsed: CancelListEventDataParsed = {
        ...dataDecoded,
        debtId: Number(dataDecoded.debtId),
        totalByCollection: Number(dataDecoded.totalByCollection),
        totalByUserAndCollection: Number(dataDecoded.totalByUserAndCollection),
        marketListing: {
          ...dataDecoded.marketListing,
          debtId: Number(dataDecoded.marketListing.debtId),
          tokenId: Number(dataDecoded.marketListing.tokenId),
          auctionEndTimestamp: Number(
            dataDecoded.marketListing.auctionEndTimestamp
          ),
        },
      };

      callback(dataParsed);
    },
    false
  );

  return unlistener;
};
