import React, {
  type FC,
  type FormEvent,
  useEffect,
  useState,
  useMemo,
} from "react";
import { IoMdClose } from "react-icons/io";
import { Anchor, CtaPrimary, CtaSecondary, RichText } from "../../ui";
import { ErrorMessage, Input, withModal } from "./shared";
import { IconEthereum } from "../../ui/icons";
import {
  AiFillCloseCircle,
  AiOutlineCheck,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { PortableText } from "../../../types/elements";
import {
  LimitMaxError,
  LimitMinError,
  TransactionError,
} from "../../../errors/errors";
import {
  approveUnlockdToManageTokens,
  getAmountOfTokensApprovedToManage,
  getProtocolLiquidity,
  withdraw,
} from "../../../logic";
import { handleErrors } from "../../../logic/helpers";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { formatWeiToEth, waitForTransactionReceipt } from "../../../utils";
import { useAlertNotification } from "../../providers";
import { BigNumber } from "ethers";
import { UINT_256_MAX } from "../../../constants";
import { CONTRACT_ADDRESSES } from "../../../../app.config";
import { approveInWalletLiteral } from "../../../literals";

enum Status {
  TO_APPROVE,
  LOADING_APPROVE,
  TO_WITHDRAW,
  LOADING_WITHDRAW,
  LOADING,
  SUCCESS,
  ERROR,
}

interface Props {
  isOpen: boolean;
  className?: string;
  toggleModal: (nextValue?: boolean) => void;
  deposit: BigNumber;
  balance: BigNumber;
  apy?: number;
  onApproved: () => void;
  onWithdraw: () => void;
}

export const ModalWithdraw: FC<Props> = withModal((props) => {
  const { toggleModal, deposit, apy, balance, onApproved, onWithdraw } = props;

  const [isMaxAmountSelected, setMaxAmountSelected] = useState<boolean>(false);
  const [error, setError] = useState<Error | null | undefined>(null);
  const [amount, setAmount] = useState<BigNumber>();
  const { chains } = useNetwork();
  const chainName = chains[0]?.name;
  const [transaction, setTransaction] = useState<any>();
  const [amountApproved, setAmountApproved] = useState<BigNumber>();
  const [status, setStatus] = useState<Status>(Status.TO_APPROVE);
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();

  const { primaryButtonText, isPrimaryButtonDisabled } = useMemo(
    () => ({
      primaryButtonText:
        status === Status.LOADING_APPROVE || status === Status.LOADING_WITHDRAW
          ? "Loading..."
          : status === Status.TO_APPROVE
          ? "Approve"
          : "Withdraw",
      isPrimaryButtonDisabled:
        deposit?.isZero() ||
        status === Status.LOADING_APPROVE ||
        status === Status.LOADING_WITHDRAW ||
        status === Status.LOADING,
    }),
    [status]
  );

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(error instanceof LimitMinError || error instanceof LimitMaxError)
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  useEffect(() => {
    if (amount !== undefined && amountApproved && amountApproved.lt(amount)) {
      setStatus(Status.TO_APPROVE);
    } else {
      setStatus(Status.TO_WITHDRAW);
    }
  }, [amountApproved, amount]);

  useEffect(() => {
    if (signer) {
      callGetAmountOfTokensApprovedToManage();
    }
  }, [signer]);

  const callGetAmountOfTokensApprovedToManage = async (): Promise<void> => {
    if (address) {
      try {
        const amountApprovedResponse = await getAmountOfTokensApprovedToManage(
          address
        );
        setAmountApproved(BigNumber.from(amountApprovedResponse));
      } catch (err) {
        setError(handleErrors(err));
      }
    }
  };

  const handleAmountChange = (amountSelected: BigNumber): void => {
    setAmount(amountSelected);
    if (!amountSelected.eq(deposit) && isMaxAmountSelected) {
      setMaxAmountSelected(false);
    }
    if (amountSelected.gt(deposit)) {
      setError(
        new LimitMaxError(
          `amount ${formatWeiToEth(
            amountSelected
          )} is greater than deposit amount: ${formatWeiToEth(deposit)}`
        )
      );
    } else if (amountSelected.lt(BigNumber.from(0))) {
      setError(
        new LimitMinError(`amount ${amountSelected} is lower than zero`)
      );
    } else {
      if (error) setError(null);
    }
  };

  const isEnoughLiquidity = async (): Promise<boolean> => {
    const liquidity = await getProtocolLiquidity(CONTRACT_ADDRESSES.weth);

    if (liquidity.isZero()) {
      openAlertNotification("info", richTextNoLiquidity, 5000);

      return false;
    } else if (liquidity.lt(amount!)) {
      openAlertNotification(
        "info",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: `There is not enough liquidity at this moment. The maximum amount you can withdraw is ${formatWeiToEth(
                  liquidity,
                  18
                )}.
                  This is temporary, try again later.
                  However, be aware that the less liquid the protocol is, the higher the APY`,
              },
            ],
          },
        ],
        5000
      );

      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    if (status === Status.TO_WITHDRAW) {
      if (amount && address && signer) {
        try {
          if (await isEnoughLiquidity()) {
            openAlertNotification("info", approveInWalletLiteral, 50000);

            const transactionHash = await withdraw(
              signer,
              isMaxAmountSelected ? BigNumber.from(UINT_256_MAX) : amount,
              address
            );

            closeAlertNotification();
            setStatus(Status.LOADING_WITHDRAW);

            waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
              if (receipt.status === 1) {
                onWithdraw();

                setStatus(Status.SUCCESS);
                setTransaction(transactionHash);
              } else if (receipt.status === 0) {
                setError(
                  new TransactionError(
                    `transaction with hash ${receipt.transactionHash} failed`
                  )
                );
                setStatus(Status.TO_WITHDRAW);
              }
            });
          }
        } catch (err) {
          setError(handleErrors(err));
          closeAlertNotification();
        }
      }
    } else if (status === Status.TO_APPROVE) {
      if (signer && amount) {
        try {
          openAlertNotification("info", approveInWalletLiteral, 50000);

          const transactionHash = await approveUnlockdToManageTokens(signer);

          onApproved();
          closeAlertNotification();
          setStatus(Status.LOADING_APPROVE);

          waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
            if (receipt.status == 1) {
              openAlertNotification("success", richTextApproved, 5000);

              callGetAmountOfTokensApprovedToManage();
            } else if (receipt.status === 0) {
              setError(
                new TransactionError(
                  `transaction with hash ${receipt.transactionHash} failed`
                )
              );
              setStatus(Status.TO_APPROVE);
            }
          });
        } catch (err) {
          closeAlertNotification();
          setError(handleErrors(err));
        }
      }
    }
  };

  const handleMaxAmountSelected = () => setMaxAmountSelected(true);

  return (
    <>
      <form
        className="bg-secondary relative border-glow-sm max-w-5xl overflow-hidden
           border-2 rounded-3xl xs:px-12 px-8 pb-6 pt-8 xs:pb-8 text-white"
        onSubmit={handleSubmit}
      >
        <button
          className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
          onClick={() => toggleModal(false)}
          type="button"
        >
          <IoMdClose className="w-full h-full" />
        </button>
        <div className="flex">
          <h5 className="xs:mb-5 text-center text-xl xs:text-2xl font-bold">
            Withdraw ETH
          </h5>
          <div className="w-6 h-6 mt-1 bg-white rounded-full p-0.5 ml-2">
            <IconEthereum />
          </div>
        </div>
        <div className="w-50 text-xs xs:text-sm max-w-full xs:mb-3 mb-3">
          {(status === Status.TO_APPROVE || status === Status.TO_WITHDRAW) && (
            <>
              <RichText value={richTextValuePending} className="text-lg mb-5" />
              <p className="text-xl">
                APY:
                <span className="font-bold ml-3">
                  {apy ? `${(apy * 100).toFixed(4)}%` : "0%"}
                </span>
              </p>
              <p className="flex  gap-x-1 text-xl mt-5 mb-8 items-center">
                Current deposit:
                <span className="flex font-bold gap-x-1 items-center ml-2">
                  {deposit && formatWeiToEth(deposit)}
                  <div className="bg-white w-4 h-4 rounded-full">
                    <IconEthereum />
                  </div>
                </span>
              </p>
            </>
          )}
          {status === Status.LOADING_APPROVE && (
            <div>
              <p className="flex justify-center mb-4">
                Don't close this screen.
              </p>
              <p className="flex justify-center mb-4">
                Your approval should be confirmed in the blockchain shortly.
              </p>
            </div>
          )}
          {status === Status.LOADING_WITHDRAW && (
            <div>
              <p className="flex justify-center mb-4">
                Don't close this screen.
              </p>
              <p className="flex justify-center mb-4">
                Your withdrawal should be confirmed in the blockchain shortly.
              </p>
            </div>
          )}
          {status === Status.ERROR && (
            <RichText value={richTextValueError} className="mb-6" />
          )}
        </div>
        {(status === Status.TO_APPROVE || status === Status.TO_WITHDRAW) && (
          <Input
            max={deposit}
            onMax={handleMaxAmountSelected}
            balance={balance}
            className="xs:mb-10 mb-10"
            nameInput="totalSupply"
            defaultValue={amount}
            decimals={18}
            onChange={handleAmountChange}
          />
        )}
        {(status === Status.LOADING_APPROVE ||
          status === Status.LOADING_WITHDRAW) && (
          <div className="flex justify-center items-center my-10 py-4">
            <AiOutlineLoading3Quarters className="animate-spin w-8 h-8" />
          </div>
        )}
        {status === Status.SUCCESS && (
          <div className="flex w-96 flex-col items-center justify-center mx-auto xs:mb-8 mb-5">
            <div className="border-white border-4 rounded-full mb-4">
              <AiOutlineCheck className="w-16 h-16 m-4" />
            </div>
            <span className="mb-6">Transaction submitted</span>
            <CtaSecondary>
              <Anchor
                href={`https://${
                  chainName === "Goerli" ? chainName + "." : ""
                }etherscan.io/tx/${transaction}`}
                target="_blank"
                title="Etherscan"
                rel="noopener noreferrer"
                className="font-bold px-5"
              >
                See your transaction on Etherscan
              </Anchor>
            </CtaSecondary>
          </div>
        )}
        {status === Status.ERROR && (
          <div className="flex items-center justify-center mx-auto xs:mb-8 mb-5">
            <AiFillCloseCircle className="w-28 h-28" />
          </div>
        )}
        {(error instanceof LimitMinError || error instanceof LimitMaxError) && (
          <ErrorMessage>{error.message}</ErrorMessage>
        )}

        {status === Status.SUCCESS ? (
          <div className="flex justify-center gap-5 w-full mt-6">
            <CtaPrimary
              onClick={() => toggleModal(false)}
              className="w-full"
              type="button"
            >
              Close
            </CtaPrimary>
          </div>
        ) : (
          <div className="flex justify-center gap-5 w-full mt-6">
            <CtaSecondary
              onClick={() => toggleModal(false)}
              className="px-11"
              type="button"
            >
              Cancel
            </CtaSecondary>
            <CtaPrimary
              className="px-11"
              type="submit"
              disabled={isPrimaryButtonDisabled}
            >
              {primaryButtonText}
            </CtaPrimary>
          </div>
        )}
      </form>
    </>
  );
});

const richTextValuePending: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Withdraw previously deposited tokens from the liquidity pool.",
      },
    ],
  },
];

const richTextApproved: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Approved. Now you can withdraw!",
      },
    ],
  },
];

const richTextValueError: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "You can try again, or check the Transaction ID.",
      },
    ],
  },
];

const richTextNoLiquidity: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "There is no liquidity available temporarily. Try again later. However, be aware that the less liquid the protocol is, the higher the APY",
      },
    ],
  },
];
