import { callContract } from "../utils";
import abi from "../abis/weth.json";
import { BigNumber } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const getAmountOfTokensApprovedToManage = async (
  address: string
): Promise<BigNumber> => {
  const result = await callContract(
    CONTRACT_ADDRESSES.uWeth,
    abi,
    null,
    "allowance",
    [address, CONTRACT_ADDRESSES.wethGateway]
  );

  return result;
};
