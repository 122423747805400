import classNames from "classnames";
import {type FC} from "react";
import {useAccount} from "wagmi";
import {withCSR} from "../../ui";
import {ButtonConnectWallet} from "./ButtonConnectWallet";
import {ButtonPanelWallet} from "./ButtonPanelWallet";

interface Props {
  className: string;
}

type Controller = (props: Props) => Props & { isConnected: boolean };

const useController: Controller = (props) => {
  const { isConnected } = useAccount();

  return {
    isConnected,
    ...props,
  };
};

export const Wallet: FC<Props> = withCSR((props: Props) => {
  const { className, isConnected } = useController(props);
  return (
    <>
      {!isConnected && (
        <ButtonConnectWallet className={classNames("w-60", [className])} />
      )}
      {isConnected && (
        <ButtonPanelWallet className={classNames("w-60", [className])} />
      )}
    </>
  );
});
