import { alchemy } from "../clients";
import { GetNftsForOwnerOptions, OwnedNft } from "alchemy-sdk";

export const retrieveOwnerNfts = async (
  address: string,
  options: GetNftsForOwnerOptions,
  allTokens: boolean = false
): Promise<Array<OwnedNft>> => {
  const nftsToRetrieve: Array<OwnedNft> = [];

  const contractAddressesArrayOfArrays: Array<Array<string>> = [];

  options?.contractAddresses?.forEach((address, index) => {
    const arrayIndex = Math.floor(index / 20);

    if (contractAddressesArrayOfArrays[arrayIndex]) {
      contractAddressesArrayOfArrays[arrayIndex].push(address);
    } else {
      contractAddressesArrayOfArrays[arrayIndex] = [address];
    }
  });

  return (async function call(
    contractAddressesIndex
  ): Promise<Array<OwnedNft>> {
    const optionsCorrected: GetNftsForOwnerOptions = {
      ...options,
    };

    if (contractAddressesArrayOfArrays.length) {
      optionsCorrected.contractAddresses =
        contractAddressesArrayOfArrays[contractAddressesIndex];
    }

    const { ownedNfts, pageKey } = await alchemy().nft.getNftsForOwner(
      address,
      optionsCorrected
    );

    nftsToRetrieve.push(...ownedNfts);

    if (allTokens && pageKey) {
      options.pageKey = pageKey;

      return call(contractAddressesIndex);
    } else if (
      contractAddressesIndex <
      contractAddressesArrayOfArrays.length - 1
    ) {
      return call(contractAddressesIndex + 1);
    }

    return nftsToRetrieve;
  })(0);
};
