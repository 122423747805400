import { getContractErrorCode, isContractError } from "../../utils";
import {
  contractErrorsMap,
  metamaskErrorsMap,
  TransactionError,
  UnexpectedError,
  UserDeniedTransactionSignatureError,
} from "../../errors";

export const handleErrors = (error: any): Error => {
  console.error(error);
  if (isContractError(error)) {
    const errorCode = getContractErrorCode(error);

    if (contractErrorsMap[errorCode]?.eng) {
      return new Error(contractErrorsMap[errorCode].eng);
    }
  } else if (error.code && metamaskErrorsMap[error.code]?.eng) {
    return new Error(metamaskErrorsMap[error.code].eng);
  } else if (
    error instanceof TransactionError ||
    error instanceof UserDeniedTransactionSignatureError
  ) {
    return error;
  }

  return new UnexpectedError("unexpected error");
};
