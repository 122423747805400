import { isCryptopunks } from "./isCryptopunks";

export const getPlaceABidArguments = (
  collectionAddress: string,
  tokenId: string,
  address: string
): Array<any> => {
  if (isCryptopunks(collectionAddress)) {
    return [tokenId, address];
  } else {
    return [collectionAddress, tokenId, address];
  }
};
