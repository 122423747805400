import { BigNumber, Contract, Signer } from "ethers";
import { setSafeExSuccessListener } from "../logic/listeners";
import { getSafeTransactionData } from "./getSafeTransactionData";
import { areDataAndArgsEqual } from "./areDataAndArgsEqual";
import {
  UnexpectedError,
  UserDeniedTransactionSignatureError,
} from "../errors";
import { TxnOptionsType } from "../types";
import { getSafeNewNonce } from "./getSafeNewNonce";

export const handleSafeTransaction = async (
  signer: Signer,
  contract: Contract,
  method: string,
  args: Array<any>,
  gasLimit: number,
  options?: TxnOptionsType
): Promise<{ hash: string }> => {
  const safeAddress = await signer!.getAddress();

  const nonce = await getSafeNewNonce(safeAddress);

  const resultObj: {
    error: Error | null;
    transaction: { hash: string } | null;
  } = { error: null, transaction: null };

  let unlistener: () => void;

  new Promise<{ hash: string }>(async (resolve, reject) => {
    unlistener = await (async function setListener(): Promise<() => void> {
      return setSafeExSuccessListener(safeAddress, async (data) => {
        const safeTx = await getSafeTransactionData(data.txHash, true);

        if (safeTx.nonce === nonce) {
          if (!safeTx.data) {
            reject(
              new UserDeniedTransactionSignatureError(
                "user denied transaction signature"
              )
            );
          } else if (
            areDataAndArgsEqual(contract, method, args, safeTx.data) &&
            (!options ||
              options.value === undefined ||
              options.value.toString() === safeTx.value)
          ) {
            resolve({ hash: safeTx.transactionHash! });
          }
        }

        reject(new UnexpectedError("failend handling safe transaction"));
      });
    })();
  })
    .then((transaction) => (resultObj.transaction = transaction))
    .catch((error) => (resultObj.error = error as Error));

  contract[method](...(args || []), options || { gasLimit: gasLimit }).catch(
    (error: Error) => {
      unlistener?.();

      resultObj.error = error;
    }
  );

  return new Promise<{ hash: string }>((resolve, reject) => {
    (function call() {
      setTimeout(() => {
        if (resultObj.error) {
          reject(resultObj.error);
        } else if (resultObj.transaction) {
          resolve(resultObj.transaction);
        } else {
          call();
        }
      }, 500);
    })();
  });
};
