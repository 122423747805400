import {isHexString} from "ethers/lib/utils";
import {FormatError} from "../../errors/errors";

export const validateAddress = (address: string): void => {
  if (
    !(address.startsWith("0x") && address.length === 42 && isHexString(address))
  ) {
    throw new FormatError("invalid address");
  }
};
