import { Disclosure, Transition } from "@headlessui/react";
import { NavigationElement } from "./NavigationElement";
import classNames from "classnames";
import Image from "next/image";
import { type FC } from "react";
import { useHeaderStatics } from "../../statics/header";
import { Anchor, Divider } from "../../ui";
import { useRouter } from "next/router";
import { Wallet } from "../wallet";
import { NotificationButton } from "./NotificationButton";

interface Props {
  className?: string;
}

export const Header: FC<Props> = (props) => {
  const statics = useHeaderStatics();
  const route = useRouter();
  const { className } = props;

  const { logo, navigationElements } = statics;

  return (
    <>
      <Disclosure
        as="header"
        className={classNames(`sticky w-full top-[-1px] mt-[-1px] h-20 z-50`, [
          className,
        ])}
      >
        <div className="flex h-full bg-secondary">
          <nav className="flex flex-row w-full h-full">
            <Anchor
              href={logo.href}
              className="px-3 py-2 md:px-6 h-full grid place-content-center"
              title={logo.title}
              rel={logo.rel}
              target={logo.target}
            >
              <div className="relative w-36 translate-y-0.5">
                <Image
                  layout="intrinsic"
                  width={1400}
                  height={300}
                  alt={logo.children.alt}
                  src={logo.children.asset}
                />
              </div>
            </Anchor>
            <ul className="z-50 flex items-center">
              {navigationElements.map((item, i) => (
                <Transition
                  unmount={false}
                  show={route.asPath.includes(item.href)}
                  appear={route.asPath.includes(item.href)}
                  as={NavigationElement}
                  key={item.id + i.toString()}
                  href={item.href}
                  target={item.target}
                  rel={item.rel}
                  title={item.title}
                  className="h-full"
                  enterFrom="!text-white"
                  enterTo="!text-white scale-110 text-shadow"
                >
                  {item.children}
                </Transition>
              ))}
            </ul>
          </nav>
          <div className="w-20 h-20 mt-[5px] -mr-[10px] flex items-center">
            <NotificationButton />
          </div>
          <Wallet className="h-fit self-center mr-6" />
        </div>

        <Divider className="border-glow-shadow-lg border-t-2 -m-0.5" />
      </Disclosure>
    </>
  );
};
