import Image from "next/image";
import {type FC} from "react";
import classNames from "classnames";
import {CtaPrimary} from "../ui";
import {useWeb3ModalGuard} from "./modals/shared";

export const ConnectWalletScreen: FC<{ className?: string }> = (props) => {
  const { className } = props;
  const launcher = useWeb3ModalGuard();

  return (
    <section
      className={classNames(
        `relative w-[650px] bg-secondary rounded-2xl border-glow-shadow-lg pb-12 pt-5 flex flex-col justify-center items-center`,
        [className]
      )}
    >
      <div className="ml-4 w-56 h-60">
        <Image
          src="/assets/images/logo-unlock-rotated.png"
          width={224}
          height={240}
        />
      </div>
      <h3 className="text-2xl font-bold mb-2">Please connect your wallet</h3>
      <p className="text-xs">
        Please connect your wallet to see your supplies, borrowings, and open
        positions.
      </p>
      <CtaPrimary className="mt-10 w-40" onClick={launcher}>
        Connect wallet
      </CtaPrimary>
    </section>
  );
};
