import classNames from "classnames";
import {type FC} from "react";

interface Props {
  className?: string;
}

export const FaucetSubHeader: FC<Props> = (props) => {
  const { className } = props;

  return (
    <>
      <section className={classNames(`mt-14 mb-8`, [className])}>
        <h2 className="text-center w-full text-xl font-semibold md:text-3xl xs:text-2xl mt-b xs:mb-3">
          NFT Faucet
        </h2>
        {/* <p className="max-w-full mx-auto text-center text-sm font-medium md:text-base">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p> */}
      </section>
    </>
  );
};
