import { SAFE_BASE_URL } from "../../app.config";
import { UnexpectedError } from "../errors";

type SafeTransactionResponse = {
  safe: string;
  to: string;
  value: string;
  data: string;
  operation: number;
  gasToken: string;
  safeTxGas: number;
  baseGas: number;
  gasPrice: string;
  refundReceiver: string;
  nonce: number;
  executionDate: string;
  submissionDate: string;
  modified: string;
  blockNumber: number;
  transactionHash: string | null;
  safeTxHash: string;
  executor: string;
  isExecuted: boolean;
  isSuccessful: boolean;
  ethGasPrice: string;
  maxFeePerGas: string;
  maxPriorityFeePerGas: string;
  gasUsed: number;
  fee: number;
  origin: string;
  dataDecoded: string;
  confirmationsRequired: number;
  trusted: boolean;
  signatures: string;
};

export const getSafeTransactionData = async (
  safeTxHash: string,
  recursive: boolean = false
): Promise<SafeTransactionResponse> => {
  return (async function call(tries) {
    const safeTxResponse = await fetch(
      `${SAFE_BASE_URL}/v1/multisig-transactions/${safeTxHash}`
    );

    if (!safeTxResponse.ok) {
      throw new UnexpectedError("failed fetching transaction from gnosis safe");
    }

    const safeTx: SafeTransactionResponse = await safeTxResponse.json();

    if (safeTx.transactionHash) {
      return safeTx;
    } else if (recursive && tries < 3) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          resolve(call(++tries));
        }, 3000);
      });
    } else {
      throw new UnexpectedError(
        "failed on getting transaction hash from gnosis"
      );
    }
  })(0);
};
