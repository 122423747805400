import { MarketItemAuctionStatus } from "../../types";

export const sortAuctionsByStatusAndRemainTime = (
  auctions: Array<any>
): Array<any> => {
  return auctions.sort((a, b) => {
    if (b.status < a.status) {
      return 1;
    } else if (
      b.status === MarketItemAuctionStatus.TO_REDEEM &&
      b.redeemEnd < a.redeemEnd
    ) {
      return 1;
    } else if (
      (b.status === MarketItemAuctionStatus.TO_BID ||
        b.status === MarketItemAuctionStatus.BIDDED) &&
      b.biddingEnd < a.biddingEnd
    ) {
      return 1;
    } else {
      return -1;
    }
  });

  //   return auctions.sort((a, b) => {
  //     if (b.status === AuctionStatus.TO_REDEEM) {
  //       return 1;
  //     } else if (
  //       b.status === AuctionStatus.TO_CLAIM &&
  //       a.status !== AuctionStatus.TO_REDEEM
  //     ) {
  //       return 1;
  //     } else if (
  //       b.status === AuctionStatus.TO_BID &&
  //       a.status !== AuctionStatus.TO_REDEEM &&
  //       a.status !== AuctionStatus.TO_CLAIM
  //     ) {
  //       return 1;
  //     } else if (
  //       b.status === AuctionStatus.BIDDED &&
  //       a.status !== AuctionStatus.TO_REDEEM &&
  //       a.status !== AuctionStatus.TO_CLAIM &&
  //       a.status !== AuctionStatus.TO_BID
  //     ) {
  //       return 1;
  //     } else if (
  //       b.status === AuctionStatus.REDEEM_FINISHED &&
  //       (a.status === AuctionStatus.REDEEM_FINISHED ||
  //         a.status === AuctionStatus.BID_FINISHED)
  //     ) {
  //       return 1;
  //     } else {
  //       return -1;
  //     }
  //   });
};
