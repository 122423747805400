import { createContext, type FC, useContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { retrieveLockeysHolderType } from "../../utils";
import { LockeysHolderType } from "../../types";
import { useToggle, useUpdateEffect } from "react-use";
import { PortableText } from "../../types/elements";
import { AlertNotification } from "../index";
import { ConnectionError } from "../../errors/errors";
import { useGlobalProviderStatics } from "../statics";
import Script from "next/script";

type IContext = Partial<{
  lockeysHolderType: LockeysHolderType | null;
  alertNotification: [
    boolean,
    (
      type: "error" | "success" | "warning" | "loading",
      content: PortableText,
      timeout: number
    ) => void,
    () => void
  ];
}>;
declare global {
  interface Window {
    hashmail: any;
  }
}
const Context = createContext<IContext>({});

export const GlobalProvider: FC = ({ children }) => {
  const { feedbacks } = useGlobalProviderStatics();
  const [lockeysHolderType, setLockeysHolderType] =
    useState<LockeysHolderType | null>(null);
  const { address } = useAccount();
  const [isAlertNotificationOpen, toggleAlertNotification] = useToggle(false);
  const [typeAlertNotification, setTypeAlertNotification] = useState<
    "error" | "success" | "warning" | "loading"
  >("error");
  const [contentAlertNotification, setContentAlertNotification] =
    useState<PortableText>([]);
  const [timeoutAlertNotification, setTimeoutAlertNotification] =
    useState<number>();
  const [error, setError] = useState<Error | null>(null);
  const [isHashmailLoaded, setIsHashmailLoaded] = useState<boolean>(false);

  const openAlertNotification = (
    type: typeof typeAlertNotification,
    content: typeof contentAlertNotification,
    timeout: typeof timeoutAlertNotification
  ) => {
    setTypeAlertNotification(type);
    setContentAlertNotification(content);
    setTimeoutAlertNotification(timeout);
    toggleAlertNotification(true);
  };

  const closeAlertNotification = () => {
    toggleAlertNotification(false);
  };

  useUpdateEffect(() => {
    if (!isAlertNotificationOpen) {
      const timer = setTimeout(() => {
        setTypeAlertNotification("error");
        setContentAlertNotification([]);
        setTimeoutAlertNotification(undefined);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isAlertNotificationOpen]);

  useEffect(() => {
    if (error) {
      console.error(error)
      setTypeAlertNotification("error");
      setContentAlertNotification(feedbacks.unexpectedError);
      setTimeoutAlertNotification(30000);
      toggleAlertNotification(true);
    }
  }, [error]);

  useEffect(() => {
    let counterCall = 0;
    if (address) {
      (async function call() {
        try {
          const lockeysHolderTypeRetrieved = await retrieveLockeysHolderType(
            address
          );

          setLockeysHolderType(lockeysHolderTypeRetrieved);
        } catch (err) {
          console.error(err)
          counterCall++;
          if (counterCall < 3) {
            call();
          } else {
            setError(new ConnectionError("Unexpected error. Please try again"));
          }
        }
      })();
    }
  }, [address]);

  useEffect(() => {
    if (address && isHashmailLoaded) window.hashmail.identify(address);
  }, [address, isHashmailLoaded]);

  return (
    <>
      <Context.Provider
        value={{
          lockeysHolderType,
          alertNotification: [
            isAlertNotificationOpen,
            openAlertNotification,
            closeAlertNotification,
          ],
        }}
      >
        {children}
      </Context.Provider>
      <AlertNotification
        isOpen={isAlertNotificationOpen}
        toggleAlert={toggleAlertNotification}
        type={typeAlertNotification}
        content={contentAlertNotification}
        timeout={timeoutAlertNotification}
      />

      <Script
        src="/static/hashmail.js"
        onLoad={() => {
          setIsHashmailLoaded(true);
        }}
      />
    </>
  );
};

export const useLockeysHolderType = () => {
  const { lockeysHolderType } = useContext(Context);

  return lockeysHolderType;
};

export const useGlobalAlertNotification = () => {
  const { alertNotification } = useContext(Context);

  return alertNotification!;
};
