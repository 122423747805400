import genericAbi from "./src/abis/customERC721.json";
import { ContractInterface } from "ethers";

type Collection = {
  name: string;
  address: string;
  uBoundAddress: string;
  abi: ContractInterface;
  reserve: string;
  genericImage: string;
  wrapAddress?: string;
  baseTokenUri?: string;
};

export const PUNKS_COLLECTION: Collection = {
  name: "Cryptopunks",
  address: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
  uBoundAddress: "0x9A9D7e6ad774D49f83eeC5C38095a85f9cB36653",
  abi: genericAbi,
  reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  genericImage:
    "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/CryptoPunks.png",
  wrapAddress: "0xb7F7F6C52F2e2fdb1963Eab30438024864c313F6",
};

export const MOONBIRDS_COLLECTION: Collection = {
  name: "Moonbirds",
  address: "0x23581767a106ae21c074b2276D25e5C3e136a68b",
  uBoundAddress: "0x8E9Fb7A1E7bEAa26e0A2330531B34f9Bb76d7F80",
  abi: genericAbi,
  reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  genericImage:
    "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Moonbirds.png",
  baseTokenUri: "https://live---metadata-5covpqijaa-uc.a.run.app/metadata",
};

export const COLLECTIONS: Array<Collection> = [
  PUNKS_COLLECTION,
  MOONBIRDS_COLLECTION,
  {
    name: "BAYC",
    address: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
    uBoundAddress: "0xDB9Ef1a7C45bC0471C9fbd24b43b21934dAD1D2f",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/BAYC.png",
  },
  {
    name: "MAYC",
    address: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
    uBoundAddress: "0x2715dA5f2Ac78c3e28424E3094FBC749f90d64EB",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/MAYC.png",
  },
  {
    name: "Otherdeed",
    address: "0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258",
    uBoundAddress: "0x516757088fE52804977479859939ef2b58D019f1",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Otherdeed.png",
  },
  {
    name: "Azuki",
    address: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
    uBoundAddress: "0x0BC06CE312799CCFc8D6a47d5fED0c816c2f0364",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Azuki.png",
  },
  {
    name: "Doodles",
    address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
    uBoundAddress: "0x27BbAf86A2fa4329154d51851EEE5A8BC4755922",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Doodles.png",
  },
  {
    name: "World of Women",
    address: "0xe785E82358879F061BC3dcAC6f0444462D4b5330",
    uBoundAddress: "0xD943aaa9596931d0A6b836de014C224CED18d8f7",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/World_of_Women.png",
  },
  {
    name: "Meebits",
    address: "0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7",
    uBoundAddress: "0xf8E9C57f5612720aAB83F06DcFa86fF6351939B4",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Meebits.png",
  },
  {
    name: "mfers",
    address: "0x79FCDEF22feeD20eDDacbB2587640e45491b757f",
    uBoundAddress: "0x251425AaDcDac52f2e76cb67E2e3CD0Ee549533A",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/mfers.png",
  },
  {
    name: "Clone X",
    address: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
    uBoundAddress: "0x61d475d8D5d6a895eAf9b842E5BB3332201740A7",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/CLONE_X.png",
  },
  {
    name: "Pudgy Penguins",
    address: "0xBd3531dA5CF5857e7CfAA92426877b022e612cf8",
    uBoundAddress: "0xc53EF2544Bbedce8c79f3596c62dD1c45bd0D955",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Pudgy_Penguins.png",
  },
  {
    name: "Cool Cats",
    address: "0x1A92f7381B9F03921564a437210bB9396471050C",
    uBoundAddress: "0x198aC8788db9D5884d8Ab7D5aad91887D4Ff2137",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Cool_Cats.png",
  },
  {
    name: "Decentraland",
    address: "0xF87E31492Faf9A91B02Ee0dEAAd50d51d56D5d4d",
    uBoundAddress: "0x3787FE991B0706875ED0CbEd18836A4B8D778f4f",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Decentraland.png",
  },
  {
    name: "3Landers",
    address: "0xb4d06d46A8285F4EC79Fd294F78a881799d8cEd9",
    uBoundAddress: "0x4AB51145Ea5855a34304Bc470E8568aBbCC4F011",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/3Landers.png",
  },
  {
    name: "Cyberbrokers",
    address: "0x892848074ddeA461A15f337250Da3ce55580CA85",
    uBoundAddress: "0x153b584863297328601347fFcD2a8f90eDc82Ba4",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/CyberBrokers.png",
  },
  {
    name: "My Pet Hooligan",
    address: "0x09233d553058c2F42ba751C87816a8E9FaE7Ef10",
    uBoundAddress: "0x3f11D91DB5e20E9a358F5D264739D25FB63ef70C",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://gateway.pinata.cloud/ipfs/QmULY7REhrxaJRhtqf2qv8BxaKYeRjJ4sk4EDMvAmB2Vdy/1.png",
  },
  {
    name: "Avarik Saga",
    address: "0x127E479Ac59A1EA76AfdEDf830fEcc2909aA4cAE",
    uBoundAddress: "0x0C0D3fB374F0A132EBa63B45516c58Ab6D8F1637",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Avarik_Saga.png",
  },
  {
    name: "Sipherian Flash",
    address: "0x09E0dF4aE51111CA27d6B85708CFB3f1F7cAE982",
    uBoundAddress: "0xf62B554b1B39FAa673750849bc75B461541ab4bC",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://sipherstorage.s3.ap-southeast-1.amazonaws.com/imgs-neko/neko_007890_default.png",
  },
  {
    name: "Sipherian Surge",
    address: "0x9c57D0278199c931Cf149cc769f37Bb7847091e7",
    uBoundAddress: "0x543EDDCa2628884458756D37855fF0Ea79ecAFf4",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/SIPHER.png",
  },
  {
    name: "GalaxyKats",
    address: "0x71C4658ACc7b53EE814A29cE31100fF85Ca23cA7",
    uBoundAddress: "0x3D8d88F23CC4Af9b909535067D5F283aEE50849e",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/GalaXY_Kats.png",
  },
  {
    name: "Heroes of Mavia",
    address: "0x4a537F61ef574153664c0Dbc8c8F4B900cacBE5d",
    uBoundAddress: "0x9F9a5F1c07B72A3dDEd5f9EbBb1ABB3E5401D8B1",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Mavia_Land.png",
  },
  {
    name: "The Sandbox",
    address: "0x5CC5B05a8A13E3fBDB0BB9FcCd98D38e50F90c38",
    uBoundAddress: "0x0B7Ac67D6838AfAC24dC3D93E76e227B4Df28F5f",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/The_Sandbox.png",
  },
  {
    name: "Space Doodles",
    address: "0x620b70123fB810F6C653DA7644b5dD0b6312e4D8",
    uBoundAddress: "0xdA79058f80b61C2f4e6644a9c4F83E89E4DeC67B",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Space_Doodles.png",
  },
  {
    name: "Cyberkongz",
    address: "0x57a204AA1042f6E66DD7730813f4024114d74f37",
    uBoundAddress: "0x3A50962CF859d54b665522969C64ddcaF8ded845",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/CyberKongz.png",
  },
  {
    name: "Arcadians",
    address: "0xc3C8A1E1cE5386258176400541922c414e1B35Fd",
    uBoundAddress: "0x399625f8b164bdF56A54C1B8dB031EC22b67aBBa",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeianownu2pqjnbqed3v7ygn2gga3bp3jogmclw7wdz2s53fqtwnxya.ipfs.nftstorage.link/Arcadians.png",
  },
  {
    name: "DeGods",
    address: "0x8821BeE2ba0dF28761AffF119D66390D594CD280",
    uBoundAddress: "0xF21Fe329a0D492bd129fdCeAF783a7F0a19103Ec",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage: "https://metadata.degods.com/g/1-s3-male.png",
  },
  {
    name: "Milady",
    address: "0x5Af0D9827E0c53E4799BB226655A1de152A425a5",
    uBoundAddress: "0x909dB9b1Cd4dFC0d8c50f3011C072b2f26e18384",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage: "https://www.miladymaker.net/milady/1.png",
  },
  {
    name: "Nouns",
    address: "0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03",
    uBoundAddress: "0x6310BD718C34574C6F9fB499C6De29eF1a0d820c",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://nft-cdn.alchemy.com/eth-mainnet/98c7ba1cb2c011776f2932cad1c48ab6",
  },
  {
    name: "Redacted Remilio Babies",
    address: "0xD3D9ddd0CF0A5F0BFB8f7fcEAe075DF687eAEBaB",
    uBoundAddress: "0x736103B390FA4D0009c4893A61E7063EEDdF9806",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage: "https://remilio.org/remilio/1.png",
  },
  {
    name: "Otherdeed Expanded",
    address: "0x790B2cF29Ed4F310bf7641f013C65D4560d28371",
    uBoundAddress: "0xBCb86CD2dF3A4565fe1319F0cF19F44e675f34EF",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://assets.otherside.xyz/otherdeeds/96ad825867364361055d7a967e46f30298e8a05ae6c3556d63847f2b8ef6bb96.jpg",
  },
  {
    name: "Koda",
    address: "0xE012Baf811CF9c05c408e879C399960D1f305903",
    uBoundAddress: "0xb8Cfa4A093731c4a6B5Ccd018b3461C59F5F4830",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://assets.otherside.xyz/kodas/63960f98d58826ab11c47679d347d5c9734544b900a1613fe67fff276868e45c.png",
  },
  {
    name: "HV-MTL",
    address: "0x4b15a9c28034dC83db40CD810001427d3BD7163D",
    uBoundAddress: "0x373eFF0628Ae8EC74D4e45E7827ce9B99CdCac75",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage: "https://assets.hv-mtl.com/evo1/1_legendary_evo1.png",
  },
  {
    name: "Parallel Avatars",
    address: "0x0Fc3DD8C37880a297166BEd57759974A157f0E74",
    uBoundAddress: "0x6CDD39C65D1261147BCE54c9C1D0a78aCB464e63",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://nftmedia.parallelnft.com/paralel-avatars/llppl/eddbd3fc371eb703c531783ee85c94a78bcfc0160b30818a21156ceb87fb6cfd.png",
  },
  {
    name: "Pixelmon",
    address: "0x32973908FaeE0Bf825A343000fE412ebE56F802A",
    uBoundAddress: "0x377A24A0F19F8f975a4B857Dead3B5DE0D1DCA18",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage: "https://pixelmon.club/images/pixelmon/Egg.jpg",
  },
  {
    name: "HOPE",
    address: "0xC4973de5eE925b8219f1E74559FB217A8e355EcF",
    uBoundAddress: "0x970018A95EB00ee013e7dC16AC2A86cEeB35a0c4",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://bafybeiadogk3khjwptabubdehq3d2yxd6x6q3srkiido266cj6qbacu2v4.ipfs.nftstorage.link/1.webp?ext=webp",
  },
  {
    name: "Mavia Land",
    address: "0x4a537F61ef574153664c0Dbc8c8F4B900cacBE5d",
    uBoundAddress: "0x9F9a5F1c07B72A3dDEd5f9EbBb1ABB3E5401D8B1",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage: "https://nft.mavia.com/1645235307611-common-grey.jpg",
  },
  {
    name: "0N1 Force",
    address: "0x3bf2922f4520a8BA0c2eFC3D2a1539678DaD5e9D",
    uBoundAddress: "0x166Ed08a66F79c861909999279E60a85098258CD",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://ipfs.io/ipfs/QmcoavNZq2jyZGe2Zi4nanQqzU9hRPxunHAo8pgYZ5fSep/0001.png",
  },
  {
    name: "Captainz",
    address: "0x769272677faB02575E84945F03Eca517ACc544Cc",
    uBoundAddress: "0x353d339d73D2b8627E28ad694971272a21F81F97",
    abi: genericAbi,
    reserve: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    genericImage:
      "https://captainz-api.memeland.com/images/7798a383-d096-4392-9640-78e333007248/1.png",
  },
];

export const EVENTS_TOPICS = {
  debtListingCanceled:
    "0x19b7c7aba882d9b069a894c7b9e9eb9c1e1a4a1050b252f29fa1f0b31fab4214",
};

export const CONTRACT_ADDRESSES = {
  punkGateway: "0x2540b104310EFa795DE0a9247Ba3942b71385251",
  wethGateway: "0x66CD472E4404D0C62d7068b4D69e28D5Ed6C32c2",
  theLockeysMainnet: "0x9a29a9DBC70eA932637216A2BF9EbE7E60023798",
  theLockeys: "0x9a29a9DBC70eA932637216A2BF9EbE7E60023798",
  uWeth: "0x302Ceac15678E3238D19a0C4714533701169C217",
  lendpool: "0xFC7f036a8FB66D031675239413F9179A82932a53",
  weth: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  unlockdProtocolDataProvider: "0xE9175c391190A600a66BA29B8770B82320f6481d",
  lendpoolAddressesProvider: "0xE6cd031FB0D9A79FD8CD3035B9228F78ff459B07",
  uiPoolDataProvider: "0xA7Cb07CD8BC197Fb3623A121FFF854f300a4195c",
  nftOracle: "0xed1a46EBE904dD6562f5F20A515009752Fb5bAdE",
  lendpoolLoan: "0x00da789CEBF4A0da965FbcB9e4d72F1A15c67Eb6",
  lendpoolConfigurator: "0x82CefdbdFe6c4A868962E7B78A3CE54f952FE209",
  debtToken: "0xd11cF5512A775591b3258D976af51D3A70A6660e",
  multicall: "0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441",
  pushChannel: "0x9fa5b8F350c08d7F9802cE9D7db0f322946e9C80",
  yearn: "0xa258C4606Ca8206D8aA700cE2143D7db854D168c",
  debtMarket: "0x9E148E7750BEcFEEFBC1816bc9413850B844FC84",
  lockeyManager: "0xFcf344175C046459b0fAdf10722A093281F5D13E",
  reservoirAdapter: "0x24cc0adc04708390e098d22e9f0B2472A57A453c",
};

export const FUNCTIONALITIES = [
  {
    id: "faucet",
    enabled: false,
  },
  {
    id: "debt-market",
    enabled: true,
  },
];

export const ALCHEMY = {
  apiKey: "gF3bmnWVhJb6mZEScsFcnCuWYZy2kFp2",
  apiUrl: "https://eth-mainnet.g.alchemy.com/nft/v2",
};

export const INFURA = {
  apiKey: "ced8c51067c74b86964b5d61e18d980d",
};

export const CHAIN = "mainnet";

export const METAMASK = {
  chainId: 1,
};

export const WALLET_CONNECT = {
  projectId: "ec934ada88527af0e297054305451159",
};

export const TOKEN_ID_RANGES_TEAM = "51/75|1453/1459|1983/1983|668/668";
export const TOKEN_ID_RANGES_KEY_SUPPORTER = "76/590";
export const TOKEN_ID_RANGES_ONE_ON_ONE = "1/50";
export const DATE_TO_FAUCET_TEAM = "12/01/2022";
export const DATE_TO_FAUCET_ONE_ON_ONE = "12/06/2022";
export const DATE_TO_FAUCET_KEY_SUPPORTER = "12/06/2022";
export const DATE_TO_FAUCET_HOLDER = "12/06/2022";
export const DATE_TO_FAUCET_NON_HOLDER = "12/15/2022";
export const DEFAULT_LIQ_THRESHOLD_VALUE = 8500;
export const PUSH_CHANNEL = "0x937e65AA5bdE5D9a22eE41033Fff0127a30ACc47";
export const RALEON_ORG_ID = 25;
export const SAFE_BASE_URL = "https://safe-transaction-mainnet.safe.global/api";

export const YEARN = {
  apiUrl: "https://api.yearn.finance/v1/chains/1",
};
