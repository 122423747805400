import { BigNumber } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";
import uTokenAbi from "../abis/uToken.json";
import { callContract } from "../utils";

export const getDepositBalance = async (
  address: string
): Promise<BigNumber> => {
  const depositBalance = await callContract(
    CONTRACT_ADDRESSES.uWeth,
    uTokenAbi,
    null,
    "balanceOf",
    [address]
  );
  return depositBalance;
};
