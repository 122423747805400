import {AmountPanelStatics} from "../../types";

export const useAmountPanelStatics = () => {
  const data: AmountPanelStatics = {
    id: "amounts",
    content: { 
        amountTitle: "Amount",
        ETHTitle: "ETH",
        MAXTitle: "MAX",
        healthFactorTitle: "Health Factor",
        feedbacks: {
        demoText: [
            {
            _key: "1",
            _type: "block",
            children: [
                {
                _key: "1",
                _type: "span",
                text: "demotext : SE ESTA PROCESANDO",
                },
            ],
            },
        ],
        },
    },
  };

  return data;
};
