import type { Web3ModalGuardStatics } from "../../../../types";
import { LockeysHolderType } from "../../../../types";
import {
  DATE_TO_FAUCET_TEAM,
  DATE_TO_FAUCET_ONE_ON_ONE,
  DATE_TO_FAUCET_KEY_SUPPORTER,
  DATE_TO_FAUCET_HOLDER,
  DATE_TO_FAUCET_NON_HOLDER
} from "../../../../../app.config"

export const useWeb3ModalGuardStatics = () => {
  const data: Web3ModalGuardStatics = {
    feedbacks: {
      unexpectedChangeAddress: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "chlid-1",
              _type: "span",
              text: "No puedes cambiar de address o desconectarte en mitad de un proceso!",
            },
          ],
        },
      ],
      unexpectedChangeNetwork: [
        {
          _key: "block-0",
          _type: "block",
          children: [
            {
              _key: "chlid-1",
              _type: "span",
              text: "No puedes cambiar de network en mitad de un proceso!",
            },
          ],
        },
      ],
    },
  };

  data.feedbacks[`dappNotAvailable${LockeysHolderType.TEAM}`] = [
    {
      _key: "block-0",
      _type: "block",
      children: [
        {
          _key: "chlid-1",
          _type: "span",
          text: `For team lockeys holders dapp will be available from ${DATE_TO_FAUCET_TEAM}`,
        },
      ],
    },
  ];

  data.feedbacks[`dappNotAvailable${LockeysHolderType.ONE_ON_ONE}`] = [
    {
      _key: "block-0",
      _type: "block",
      children: [
        {
          _key: "chlid-1",
          _type: "span",
          text: `For 1/1 lockeys holders dapp will be available from ${DATE_TO_FAUCET_ONE_ON_ONE}`,
        },
      ],
    },
  ];

  data.feedbacks[`dappNotAvailable${LockeysHolderType.KEY_SUPPORTER}`] = [
    {
      _key: "block-0",
      _type: "block",
      children: [
        {
          _key: "chlid-1",
          _type: "span",
          text: `For key supporters lockeys holders dapp will be available from ${DATE_TO_FAUCET_KEY_SUPPORTER}`,
        },
      ],
    },
  ];

  data.feedbacks[`dappNotAvailable${LockeysHolderType.HOLDER}`] = [
    {
      _key: "block-0",
      _type: "block",
      children: [
        {
          _key: "chlid-1",
          _type: "span",
          text: `For lockeys holders dapp will be available from ${DATE_TO_FAUCET_HOLDER}`,
        },
      ],
    },
  ];

  data.feedbacks[`dappNotAvailable${LockeysHolderType.NON_HOLDER}`] = [
    {
      _key: "block-0",
      _type: "block",
      children: [
        {
          _key: "chlid-1",
          _type: "span",
          text: `For non lockeys holders dapp will be available from ${DATE_TO_FAUCET_NON_HOLDER}`,
        },
      ],
    },
  ];

  return data;
};
