import {equalIgnoreCase} from "../../utils";
import {COLLECTIONS} from "../../../app.config";

const boundCollections = COLLECTIONS.reduce((collections:Array<string>, {uBoundAddress}) => {
  collections.push(uBoundAddress)

  return collections
}, [])
export const isNftDeposited = (collectionAddress: string): boolean => {
  return boundCollections.some((address) =>
    equalIgnoreCase(address, collectionAddress)
  );
};
