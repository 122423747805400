import { retrieveOwnerNfts } from "../utils";
import {
  getNftCollectionAddressFromUBound,
  isNftDeposited,
  retrieveNftsData,
} from "./helpers";
import { NftBorrowData } from "../types";
import { COLLECTIONS } from "../../app.config";

const totalListOfContracts = COLLECTIONS.reduce(
  (acc: Array<string>, { address, uBoundAddress }) => {
    acc.push(address, uBoundAddress);

    return acc;
  },
  []
);

type RetrieveUserNftsLoop = (
  address: string,
  pageSize: number,
  callback: (
    error: Error | null,
    nfts?: Array<NftBorrowData>,
    hasMoreLoansToLoad?: boolean
  ) => void
) => Promise<{ stopLoop: () => void }>;

export const retrieveUserNftsLoop: RetrieveUserNftsLoop = async (
  address,
  pageSize,
  callback
) => {
  let hasToContinue = true;

  const stopLoop = () => {
    hasToContinue = false;
  };

  const nftsList = await retrieveOwnerNfts(
    address,
    {
      contractAddresses: totalListOfContracts,
      pageSize: 100,
    },
    true
  );

  // TEST (comment after testing)
  // nftsList.push(
  //   {
  //     contract: {
  //       address: "0x3aFE908110e5c5275Bc96a9e42DB1B322590bDa4",
  //       name: "Cryptopunks",
  //     },
  //     tokenId: "220",
  //     tokenUri: {
  //       gateway: "https://images.wrappedpunks.com/images/punks/220.png",
  //     },
  //     media: [
  //       {
  //         gateway: "https://images.wrappedpunks.com/images/punks/220.png",
  //       },
  //     ],
  //   },
  // );

  const nftsListWithCompareFlag = nftsList.map((nft) => {
    return {
      ...nft,
      isDeposited: isNftDeposited(nft.contract.address),
      tokenId: Number(nft.tokenId),
    };
  });

  nftsListWithCompareFlag.sort((a, b) => {
    if (b.isDeposited && !a.isDeposited) {
      return -1;
    } else {
      return 1;
    }
  });

  (async function call(lastIndex) {
    const top =
      lastIndex + pageSize > nftsListWithCompareFlag.length
        ? nftsListWithCompareFlag.length
        : lastIndex + pageSize;

    const nftDatas = [];

    for (let i = lastIndex; i < top; i++) {
      const isDeposited = nftsListWithCompareFlag[i].isDeposited;

      const collectionAddress = isDeposited
        ? getNftCollectionAddressFromUBound(
            nftsListWithCompareFlag[i].contract.address
          )
        : nftsListWithCompareFlag[i].contract.address;

      nftDatas.push({
        collectionAddress,
        tokenId: nftsListWithCompareFlag[i].tokenId,
        isDeposited,
        collectionName: nftsListWithCompareFlag[i].contract.name!,
        tokenUri: nftsListWithCompareFlag[i].tokenUri?.gateway!,
        image: nftsListWithCompareFlag[i].media[0]?.gateway,
      });
    }

    try {
      const nfts = await retrieveNftsData(nftDatas);

      if (hasToContinue) {
        const hasMoreLoansToLoad = lastIndex + pageSize < nftsList.length;

        callback(null, nfts, hasMoreLoansToLoad);

        if (hasMoreLoansToLoad) {
          call(lastIndex + pageSize);
        }
      }
    } catch (err) {
      callback(err as Error);
    }
  })(0);

  return { stopLoop };
};
