import { CONTRACT_ADDRESSES } from "../../../app.config";
import {
  LoanAuctionedEventData,
  LoanAuctionEventDataParsed,
  Log,
} from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import abi from "../../abis/lendpoolLoan.json";

export const setBidOnLoanAuctionedListener = (
  callback: (dataDecoded: LoanAuctionEventDataParsed) => void
): (() => void) => {
  const unlistener = listenContractEvent(
    getEventSignature(abi, "LoanAuctioned"),
    CONTRACT_ADDRESSES.lendpoolLoan,
    [],
    ({ data, topics }: Log) => {
      //@ts-ignore
      const dataDecoded: LoanAuctionedEventData = decodeTransactionData(
        abi,
        "LoanAuctioned",
        data,
        topics
      );

      const dataParsed: LoanAuctionEventDataParsed = {
        ...dataDecoded,
        nftTokenId: Number(dataDecoded.nftTokenId),
        loanId: Number(dataDecoded.loanId),
        borrowIndex: Number(dataDecoded.borrowIndex),
      };

      callback(dataParsed);
    },
    false
  );

  return unlistener;
};
