import { BigNumber, Signer } from "ethers";
import { callContract } from "../utils";
import {
  getAbiToInteractWith,
  getBorrowArguments,
  getContractAddressToInteractWith,
} from "./helpers";

export const borrow = async (
  amount: BigNumber,
  collectionAddress: string,
  tokenId: number,
  onBehalfOf: string,
  signer: Signer
) => {
  const collectionAddressAddressToInteractWith = getContractAddressToInteractWith(collectionAddress)
  const abiToInteractWith = getAbiToInteractWith(collectionAddress)
  const callArguments = getBorrowArguments(amount, collectionAddress, tokenId, onBehalfOf)

  //console.log('borrow', collectionAddressAddressToInteractWith, abiToInteractWith, callArguments)

  const transaction = await callContract(
    collectionAddressAddressToInteractWith,
    abiToInteractWith,
    signer,
    "borrowETH",
    callArguments
  );

  // DEPRECATED -> WAS SOLVED WITH EVENT LISTENERS
  // Storage.LOCAL.set(`account.${onBehalfOf}.borrow`, {
  //   amount: Number(amount).toString(),
  //   collectionAddress,
  //   tokenId: tokenId.toString(),
  // });

  return transaction.hash;
};
