import { BigNumber } from "ethers";
import { COLLECTIONS } from "../../app.config";
import { UnexpectedError } from "../errors";
import { CollectionData, SingleCall } from "../types";
import { multicall } from "../utils/multicall";
import { isCryptopunks } from "./helpers";

type ResponseFromServer = Array<{
  collection: string;
  tvl: string;
  nfts: string;
  minimumVal: string;
  maximumVal: string;
}>;

export const getCollectionsData = async (): Promise<Array<CollectionData>> => {
  const collectionsCalls: Array<SingleCall> = [];

  const body: { collections: Array<string> } = { collections: [] };

  COLLECTIONS.forEach(({ address, abi, uBoundAddress, wrapAddress }) => {
    collectionsCalls.push(
      new SingleCall(
        isCryptopunks(address) ? wrapAddress! : address,
        abi,
        "balanceOf",
        [uBoundAddress]
      )
    );

    body.collections.push(address.toLowerCase());
  });

  const [[...collectionsResults], response] = await Promise.all([
    multicall(...collectionsCalls),
    fetch("api/unlockd-api/protocol/dashboard/collections", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    }),
  ]);

  if (!response.ok) {
    throw new UnexpectedError(
      "failed on fetching collections data from server"
    );
  }

  const collectionsDataFromServer: ResponseFromServer = await response.json();

  const collectionsToReturn: Array<CollectionData> = [];

  COLLECTIONS.forEach(({ address, name, genericImage }, index) => {
    const collectionDataFromServer = collectionsDataFromServer.find(
      ({ collection }) => collection === address.toLowerCase()
    );

    let tvl: string = "0",
      minimumVal: string = "0",
      maximumVal: string = "0";

    if (collectionDataFromServer) {
      tvl = collectionDataFromServer.tvl;
      minimumVal = collectionDataFromServer.minimumVal;
      maximumVal = collectionDataFromServer.maximumVal;
    }

    collectionsToReturn.push({
      address,
      totalNftsLocked: Number(collectionsResults[index]),
      tvl,
      minimumVal,
      maximumVal,
      name,
      image: genericImage,
    });
  });

  return collectionsToReturn.sort((a, b) => parseInt(b.tvl) - parseInt(a.tvl));
};
