import { callContract } from "../utils";
import { BigNumber, Signer } from "ethers";
import {
  changeToWrapIfCrypropunks,
  getAbiToInteractWith,
  getContractAddressToInteractWith,
  getPlaceABidArguments,
} from "./helpers";

export const placeABid = async (
  signer: Signer,
  collectionAddress: string,
  tokenId: string,
  address: string,
  amount: BigNumber
) => {
  const collection = getContractAddressToInteractWith(collectionAddress);
  const abi = getAbiToInteractWith(collectionAddress);
  const bidArguments = getPlaceABidArguments(
    changeToWrapIfCrypropunks(collectionAddress),
    tokenId,
    address
  );

  const transaction = await callContract(
    collection,
    abi,
    signer,
    "auctionETH",
    bidArguments,
    { value: amount }
  );

  return transaction.hash;
};
