import { callContract } from "../utils";
import { Signer } from "ethers";
import abi from "../abis/debtMarket.json";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const claimLoanListedAuction = async (
  signer: Signer,
  collection: string,
  tokenId: number,
  onBehalfOf: string
) => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.debtMarket,
    abi,
    signer,
    "claim",
    [collection, tokenId, onBehalfOf]
  );

  return transaction.hash;
};
