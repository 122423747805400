import {PortableText} from "../types";

export class CustomError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AddressError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class ConnectionError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class LimitMinError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class LimitMaxError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class GasLimitError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class InsufficientFundsError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class TransactionError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class FormatError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class UserDeniedTransactionSignatureError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class ConfigError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

export class UnexpectedError extends CustomError {
  constructor(message: string) {
    super(message);
  }
}

/**
 * Provides a way to wrap multiple errors in one. Useful for cases where
 * different exceptions must be handled all together and report them in one
 * single instance.
 *
 * @example
 * ```js
 * const authError = new AuthError('wrong credentials')
 * //...
 * const timeoutError = new TimeoutError('user inaction took more than 30s')
 * //
 * throw new MultipleError('login failures', [authError, timeoutError])
 * ```
 */
export class MultipleError extends CustomError {
  errors: Array<Error>;

  /**
   * Constructs an instance of MultipleError
   *
   * @param message {string}
   * @param errors {Array<Error>}
   */
  constructor(message: string, errors: Array<Error>) {
    super(message);

    this.errors = errors;
  }
}

export const transactionErrorMessage: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your transaction has failed. Please try again.",
      },
    ],
  },
];
