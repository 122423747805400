import { type FC } from "react";

export const HealthFactorNavBar: FC = () => {
  return (
    <>
      <nav className="max-w-max mx-auto bg-secondary rounded-xl flex justify-evenly gap-20 px-10 my-10 border-4 border-transparent hover:border-indigo-600">
        <a href="#" className="p-2 flex items-center">
          Loans in auction
        </a>
        <a
          href="#"
          className="rounded-xl p-2 border-4 font-bold border-slate-600 hover:border-indigo-600 flex items-center"
        >
          Health Factor list
        </a>
        <a href="#" className="p-2 flex items-center">
          Auction history
        </a>
        <a href="#" className="p-2 flex items-center">
          My auctions
        </a>
      </nav>
    </>
  );
};
