import { SAFE_BASE_URL } from "../../app.config";
import { UnexpectedError } from "../errors";

type SafeAllTransactionsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<{
    nonce: number;
  }>;
};

export const getSafeNewNonce = async (safeAddress: string): Promise<number> => {
  const gnosisTransactionsResponse = await fetch(
    `${SAFE_BASE_URL}/v1/safes/${safeAddress}/all-transactions/?executed=false&queued=true`
  );

  if (!gnosisTransactionsResponse.ok) {
    throw new UnexpectedError(
      "failed fetching all-transactons data from safe gnosis"
    );
  }

  const data: SafeAllTransactionsResponse =
    await gnosisTransactionsResponse.json();

  if (!data.results.length) {
    return 0;
  }

  return data.results[0].nonce + 1;
};
