import { callContract } from "../utils";
import nftAbi from "../abis/customERC721.json";
import { isCryptopunks } from "./helpers";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const getIsApprovedForAll = async (
  walletAddress: string,
  collectionAddress: string
): Promise<boolean> => {
  const gateway = isCryptopunks(collectionAddress)
    ? CONTRACT_ADDRESSES.punkGateway
    : CONTRACT_ADDRESSES.wethGateway;

  const isCollectionApproved = await callContract(
    collectionAddress,
    nftAbi,
    null,
    "isApprovedForAll",
    [walletAddress, gateway]
  );

  return isCollectionApproved;
};
