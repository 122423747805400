import {type FC, useEffect, useState} from "react";
import {useTransactionsStatics} from "../../statics/transactions";
import classNames from "classnames";
import {useAccount} from "wagmi";
import {Transaction} from "./Transaction";
import {WithCSR} from "../../ui";
import {useAlertNotification} from "../../providers";
import {handleErrors, retrieveMintHistoryOfAddress,} from "../../../logic/helpers";
import {Storage} from "../../../utils/Storage";
import {equalIgnoreCase, waitForTransactionReceipt} from "../../../utils";
import {ITransaction} from "../../../types";
import {TransactionError} from "../../../errors/errors";

interface Props {
  className?: string;
  pendingTxHash: string | null;
}

export const Transactions: FC<Props> = (props) => {
  const statics = useTransactionsStatics();
  const { className, pendingTxHash: pendingTxFromProps } = props;
  const {
    content: { statusTitle, timeTitle, transactionTitle },
  } = statics;
  const { address } = useAccount();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [pendingTransaction, setPendingTransaction] = useState<ITransaction>();
  const [error, setError] = useState<Error | null>(null);
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();

  useEffect(() => {
    if (error) {
      if (!isAlertNotificationOpen) {
        openAlertNotification("error", statics.feedbacks.unexpectedError, 5000);
      }
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const historicalTransactions = await retrieveMintHistoryOfAddress(
            address
          );

          setTransactions(historicalTransactions);

          const storage = Storage.LOCAL;

          const storedTx: string = storage.get(
            `account.${address}.transactionPending`,
            ""
          );

          if (storedTx) {
            const isStoredTxCompleted = historicalTransactions.find(
              (transaction) => equalIgnoreCase(transaction.hash, storedTx)
            );

            if (isStoredTxCompleted) {
              storage.set(`account.${address}.transactionPending`, "");

              setPendingTransaction(undefined);
            } else {
              setPendingTransaction({ hash: storedTx });
            }
          }
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  useEffect(() => {
    if (pendingTxFromProps) {
      const storage = Storage.LOCAL;

      storage.set(`account.${address}.transactionPending`, pendingTxFromProps);

      setPendingTransaction({ hash: pendingTxFromProps });
    }
  }, [pendingTxFromProps]);

  useEffect(() => {}, [pendingTransaction]);

  useEffect(() => {
    if (pendingTransaction) {
      try {
        waitForTransactionReceipt(pendingTransaction.hash, 5000, (receipt) => {
          if (receipt.status == 1) {
            setTransactions((transactions) => {
              const transactionsCopy = [...transactions];

              transactionsCopy.unshift({
                hash: receipt.transactionHash,
                status: receipt.status,
                date: new Date(Date.now()),
              });

              return transactionsCopy;
            });
          } else if (receipt.status === 0) {
            setError(
              new TransactionError(
                `transaction with hash ${receipt.transactionHash} failed`
              )
            );
          }
          const storage = Storage.LOCAL;

          storage.set(`account.${address}.transactionPending`, "");

          setPendingTransaction(undefined);
        });
      } catch (err) {
        setError(handleErrors(err));
      }
    }
  }, [pendingTransaction]);

  return (
    <>
      <section
        className={classNames(
          "max-w-4xl mx-auto mb-5 xs:px-6 px-4 pt-8 pb-8 shadow-lg rounded-b-xl bg-secondary element-shadow",
          [className]
        )}
      >
        <div className="grid grid-cols-[calc(100%-144px-144px),144px,144px] child:flex child:items-center">
          <div className="w-full">
            <span className="font-bold text-sm">{transactionTitle}</span>
          </div>
          <div className="">
            <span className="font-bold text-sm">{statusTitle}</span>
          </div>
          <div className="">
            <span className="font-bold text-sm">{timeTitle}</span>
          </div>
          <WithCSR>
            {pendingTransaction && (
              <Transaction
                key={pendingTransaction.hash}
                transaction={pendingTransaction}
              />
            )}
            {transactions?.length ? (
              transactions.map((transaction) => (
                <Transaction key={transaction.hash} transaction={transaction} />
              ))
            ) : !pendingTransaction ? (
              <Transaction />
            ) : (
              <></>
            )}
          </WithCSR>
        </div>
      </section>
    </>
  );
};
