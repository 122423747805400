import classNames from "classnames";
import {type FC} from "react";

interface Props {
  className?: string;
}

type Controller = (props: Props) => Props & { totalMintedCount?: number };

const useController: Controller = (props) => {
  return {
    ...props,
  };
};

export const EmptyOfLimit: FC<Props> = (props) => {
  const { className } = useController(props);

  return (
    <>
      <div className={classNames(`max-w-lg w-full`, [className])}>
        <div className="h-6 rounded-full border border-dashed mb-1 xs:mb-1 overflow-hidden relative">
          <div className="absolute h-full rounded-full"></div>
          <div className="max-w-full h-full absolute rounded-full"></div>
        </div>
      </div>
    </>
  );
};
