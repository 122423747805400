import { HealthFactorItem } from "../types/marketplace";
import { BigNumber } from "ethers";

import lendpoolAbi from "../abis/lendpoolUnlockd.json";
import { ConnectionError } from "../errors/errors";
import {
  changeToWrapIfCrypropunks,
  getCollectionNameByContractAddress,
} from "./helpers";
import { CallsGroupedByMethod, Pagination } from "../types";
import { multicall } from "../utils/multicall";
import { CONTRACT_ADDRESSES } from "../../app.config";

type ServerResponse = {
  items: Array<{
    collection: string;
    tokenId: number;
    valuation: string;
    healthFactor: string | null;
  }>;
  pagination: {
    offset: number;
    limit: number;
    total: number;
  };
};

export async function retrieveHealthFactorList(
  offset: number,
  limit: number
): Promise<{
  items: HealthFactorItem[];
  pagination: Pagination;
}> {
  const response = await fetch(
    `api/unlockd-api/protocol/health-factor?offset=${offset}&limit=${limit}`
  );

  if (!response.ok) {
    throw new ConnectionError(
      "failed on fetching healthFactor List from server"
    );
  }

  const { items, pagination }: ServerResponse = await response.json();

  if (items.length === 0) {
    return { items: [], pagination };
  }

  const nftsDebtDataCalls = new CallsGroupedByMethod(
    CONTRACT_ADDRESSES.lendpool,
    lendpoolAbi,
    "getNftDebtData",
    []
  );

  for (let i = 0; i < items.length; i++) {
    const { collection, tokenId } = items[i];

    nftsDebtDataCalls.callsArguments.push([
      changeToWrapIfCrypropunks(collection),
      tokenId,
    ]);
  }

  const nftsDebtData = await multicall(nftsDebtDataCalls);

  const healthFactorList: Array<HealthFactorItem> = items.map((item, index) => {
    const {
      collection,
      tokenId,
      valuation: valuationFromServer,
      healthFactor: healthFactorFromServer,
    } = item;

    const { totalDebt } = nftsDebtData![0][index];
    const valuation = BigNumber.from(valuationFromServer);

    const healthFactor = healthFactorFromServer
      ? Number(Number(healthFactorFromServer).toFixed(2))
      : null;

    return new HealthFactorItem(
      collection,
      getCollectionNameByContractAddress(collection),
      tokenId.toString(),
      valuation,
      totalDebt,
      healthFactor
    );
  });

  return {
    items: healthFactorList,
    pagination,
  };
}
