import { type FC, FormEvent, useEffect, useState } from "react";
import { ErrorMessage, Input, withModal } from "./shared";
import { IoMdClose } from "react-icons/io";
import { IconEthereum } from "../../ui/icons";
import { Anchor, CtaPrimary, CtaSecondary } from "../../ui";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { useAlertNotification } from "../../providers";
import {
  formatWeiToEth,
  getBalanceOfAddress,
  waitForTransactionReceipt,
} from "../../../utils";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  TransactionError,
  transactionErrorMessage,
} from "../../../errors/errors";
import { approveCollection, getIsApprovedForAll, repay } from "../../../logic";
import { AiOutlineArrowUp, AiOutlineLoading3Quarters } from "react-icons/ai";
import { HealthFactorCircle } from "../HealthFactorCircle";
import { getLoanDataToRepay } from "../../../logic/getLoanDataToRepay";
import { BigNumber } from "ethers";
import { PortableText } from "../../../types";
import {
  calculateHealthFactor,
  changeToWrapIfCrypropunks,
  handleErrors,
  isCryptopunks,
} from "../../../logic/helpers";
import { PUNKS_COLLECTION } from "../../../../app.config";
import { approveInWalletLiteral } from "../../../literals";

type Props = {
  className?: string;
  isOpen: boolean;
  collectionAddress: string;
  tokenId: number;
  currentBorrowAmount: BigNumber;
  valuation: BigNumber;
  apy?: number;
  liquidationThreshold?: string;
  toggleModal: (nextValue: boolean) => void;
};

enum Status {
  TO_APPROVE,
  LOADING_APPROVE,
  TO_REPAY,
  LOADING_REPAY,
  SUCCESS,
}

export const ModalRepay: FC<Props> = withModal((props) => {
  const {
    toggleModal,
    collectionAddress,
    tokenId,
    apy: apyFromProps,
    valuation,
    liquidationThreshold: liquidationThresholdFromProps,
    currentBorrowAmount,
  } = props;
  const { address } = useAccount();
  const { chains } = useNetwork();
  const chainName = chains[0]?.name;
  const [transaction, setTransaction] = useState<any>();
  const [balance, setBalance] = useState<BigNumber>();
  const [amount, setAmount] = useState<BigNumber>();
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<Status>(Status.TO_REPAY);
  const [apy, setApy] = useState<number>(apyFromProps);
  const [liquidationThreshold, setLiquidationThreshold] = useState<string>(
    liquidationThresholdFromProps
  );
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();
  const { data: signer } = useSigner();
  const [healthFactor, setHealthFactor] = useState<number>();
  const [max, setMax] = useState<BigNumber>();
  const [isCollectionApproved, setIsCollectionApproved] =
    useState<boolean>(false);

  useEffect(() => {
    if (balance) {
      setMax(
        currentBorrowAmount.mul(100002).div(100000).lt(balance!)
          ? currentBorrowAmount.mul(100002).div(100000)
          : balance!
      );
    }
  }, [balance]);

  useEffect(() => {
    (async () => {
      if (!apy || !liquidationThreshold) {
        try {
          const { apy, liquidationThreshold } = await getLoanDataToRepay(
            changeToWrapIfCrypropunks(collectionAddress),
            tokenId
          );

          setApy(apy);
          setLiquidationThreshold(liquidationThreshold);
        } catch (err) {
          setError(handleErrors(err));
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (address && isCryptopunks(collectionAddress)) {
      (async () => {
        setIsCollectionApproved(
          await getIsApprovedForAll(address, PUNKS_COLLECTION.wrapAddress!)
        );
      })();
    }
  }, [address]);

  useEffect(() => {
    if (liquidationThreshold) {
      const hf = calculateHealthFactor(
        valuation,
        currentBorrowAmount,
        BigNumber.from(liquidationThreshold),
        amount && amount.lte(currentBorrowAmount)
          ? BigNumber.from("0").sub(amount!)
          : undefined
      );

      setHealthFactor(hf);
    }
  }, [amount, liquidationThreshold]);

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(
        error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError ||
        error instanceof TransactionError
      )
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    } else if (error instanceof TransactionError) {
      openAlertNotification("error", transactionErrorMessage, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const balanceRetrieved = await getBalanceOfAddress(address);

          setBalance(balanceRetrieved);
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  const handleAmountChange = (amountSelected: BigNumber): void => {
    setAmount(amountSelected);

    if (amountSelected.gt(balance!)) {
      setError(
        new LimitMaxError(
          `amount ${formatWeiToEth(
            amountSelected
          )} is greater than balance of address: ${formatWeiToEth(balance!)}`
        )
      );
    } else if (max && amountSelected.gt(max)) {
      setError(
        new LimitMaxError(
          `amount ${formatWeiToEth(
            amountSelected
          )} is greater than max allowed (${formatWeiToEth(
            currentBorrowAmount
          )})`
        )
      );
    } else if (amountSelected.isNegative()) {
      setError(
        new LimitMinError(
          `amount ${formatWeiToEth(amountSelected)} is lower than zero`
        )
      );
    } else {
      if (error) setError(null);
    }

    if (
      isCryptopunks(collectionAddress) &&
      !isCollectionApproved &&
      max &&
      amountSelected.eq(max)
    ) {
      setStatus(Status.TO_APPROVE);
    } else if (status === Status.TO_APPROVE) {
      setStatus(Status.TO_REPAY);
    }
  };

  const handleRepay = async () => {
    const transactionHash = await repay(
      signer!,
      collectionAddress,
      tokenId,
      amount!
    );

    closeAlertNotification();
    setStatus(Status.LOADING_REPAY);

    waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
      if (receipt.status == 1) {
        const literal =
          max && amount && max.eq(amount)
            ? successCompleteRepayLiteral
            : successPartialRepayLiteral;

        openAlertNotification("success", literal, 5000);

        setStatus(Status.SUCCESS);
        setTransaction(transactionHash);
      } else if (receipt.status === 0) {
        setError(
          new TransactionError(
            `transaction with hash ${receipt.transactionHash} failed`
          )
        );

        openAlertNotification("error", transactionErrorMessage, 5000);

        setStatus(Status.TO_REPAY);
      }
    });
  };

  const handleApprove = async () => {
    const transactionHash = await approveCollection(
      PUNKS_COLLECTION.wrapAddress!,
      signer!
    );

    setStatus(Status.LOADING_APPROVE);

    openAlertNotification("info", approvalLoadingLiteral, 5000);

    waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
      if (receipt.status == 1) {
        openAlertNotification("success", successApprovalLiteral, 5000);

        setStatus(Status.TO_REPAY);
        setIsCollectionApproved(true);
      } else if (receipt.status === 0) {
        setError(
          new TransactionError(
            `transaction with hash ${receipt.transactionHash} failed`
          )
        );

        openAlertNotification("error", transactionErrorMessage, 5000);

        setStatus(Status.TO_APPROVE);
      }
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (amount && signer && address) {
      try {
        openAlertNotification("info", approveInWalletLiteral, 50000);

        if (status === Status.TO_REPAY) {
          handleRepay();
        } else if (status === Status.TO_APPROVE) {
          handleApprove();
        }
      } catch (err) {
        closeAlertNotification();
        setError(handleErrors(err));
      }
    }
  };

  return (
    <form
      className="bg-secondary relative border-glow-sm max-w-5xl overflow-hidden border-2 rounded-3xl xs:px-12 px-10 pb-6 pt-10 xs:pb-8 text-white flex flex-col items-center"
      onSubmit={handleSubmit}
    >
      <button
        type="button"
        className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
        onClick={() => toggleModal(false)}
      >
        <IoMdClose className="w-full h-full" />
      </button>
      <div className="w-full flex">
        <h3 className="xs:mb-5 text-xl text-center xs:text-2xl font-bold">
          Repay ETH
        </h3>
        <div className="w-6 h-6 bg-white mt-1 rounded-full ml-2">
          <IconEthereum />
        </div>
      </div>
      {(status === Status.TO_REPAY || status === Status.TO_APPROVE) && (
        <>
          <div className="w-full flex flex-wrap">
            <div className="w-1/3 flex flex-col">
              <h3 className="font-bold text-xs">Available to repay</h3>
              <div className="flex items-center group">
                <div className="hidden group-hover:block z-10 max-w-max -ml-1 -mt-14 bg-secondary text-white border border-white text-center text-xs rounded-lg py-2 absolute  px-3 pointer-events-none">
                  {max && formatWeiToEth(max, 18)}
                </div>
                <p>{formatWeiToEth(currentBorrowAmount)}</p>
                <div className="bg-white ml-2 w-4 h-4 rounded-full">
                  <IconEthereum />
                </div>
              </div>
            </div>
            <div className="w-1/3 flex flex-col">
              <h3 className="font-bold text-xs">Variable interest</h3>
              <div className="flex items-center">
                <p>{apy ? (apy * 100).toFixed(2) : "--.--"}%</p>
              </div>
            </div>
          </div>
          <Input
            className="mt-5 w-[560px]"
            balance={balance!}
            max={max}
            nameInput="repay"
            onChange={handleAmountChange}
            defaultValue={amount}
          />
          <div className="mt-5 flex items-center gap-[6px]">
            <p className="font-bold">Health Factor</p>
            <p>{healthFactor?.toFixed(2) || "-.--"}</p>
            {!!healthFactor && (
              <HealthFactorCircle healthFactor={healthFactor} />
            )}
          </div>
          <div className="mt-5 flex items-center gap-[6px]">
            <p>To fully repay your debt, press MAX button</p>
          </div>
        </>
      )}
      {(status === Status.LOADING_REPAY ||
        status === Status.LOADING_APPROVE) && (
        <>
          <div className="w-full flex flex-col items-center">
            <p className="mt-5 font-bold">Don't close this screen.</p>
            <p className="mt-3">
              {status === Status.LOADING_REPAY
                ? "Your repay should be confirmed"
                : "Your approve should be confirmed"}
            </p>
            <p>in the blockchain shortly.</p>
          </div>
          <div className="flex justify-center items-center my-10">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        </>
      )}
      {(error instanceof LimitMinError ||
        error instanceof LimitMaxError ||
        error instanceof GasLimitError) && (
        <ErrorMessage className="mt-4 px-4">{error.message}</ErrorMessage>
      )}
      {(status === Status.TO_REPAY ||
        status === Status.LOADING_REPAY ||
        status === Status.TO_APPROVE) && (
        <div className="mt-10 flex justify-between gap-4">
          <CtaSecondary className="w-40" onClick={() => toggleModal(false)}>
            Back
          </CtaSecondary>
          <CtaPrimary
            className="w-40"
            disabled={status === Status.LOADING_REPAY}
          >
            {status === Status.TO_REPAY
              ? "Repay"
              : status === Status.TO_APPROVE
              ? "Approve"
              : "Loading..."}
          </CtaPrimary>
        </div>
      )}
      {status === Status.SUCCESS && (
        <>
          <div className="mt-5 flex w-96 flex-col items-center justify-center mx-auto xs:mb-8 mb-5">
            <div className="border-white border-2 rounded-full mb-4">
              <AiOutlineArrowUp className="w-8 h-8 m-4" />
            </div>
            <span className="mb-6">Transaction submitted</span>
            <CtaSecondary>
              <Anchor
                href={`https://${
                  chainName === "Goerli" ? chainName + "." : ""
                }etherscan.io/tx/${transaction}`}
                target="_blank"
                title="Etherscan"
                rel="noopener noreferrer"
                className="font-bold px-5"
              >
                See your transaction on Etherscan
              </Anchor>
            </CtaSecondary>
          </div>
          <div className="flex justify-center gap-5 w-full">
            <CtaPrimary
              className="w-full"
              type="button"
              onClick={() => toggleModal(false)}
            >
              Close{" "}
            </CtaPrimary>
          </div>
        </>
      )}
    </form>
  );
});

const successCompleteRepayLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your loan has been succesfully repaid.",
      },
    ],
  },
  {
    _key: "block-3",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your NFT has been returned to your wallet.",
      },
    ],
  },
];

const successPartialRepayLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your loan has been succesfully repaid.",
      },
    ],
  },
];

const successApprovalLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your transaction is approved. Now you can repay.",
      },
    ],
  },
];

const approvalLoadingLiteral: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Wait a bit, your approval is being processed…",
      },
    ],
  },
];
