import { type FC, useState } from "react";
import classNames from "classnames";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { COLLECTIONS } from "../../../app.config";
import { Filter, MarketItemType } from "../../types";
import { Info } from "../commons";

interface Props {
  className?: string;
  onChange: (newFilter: Filter) => void;
  filter: Filter;
}

export const Filters: FC<Props> = (props) => {
  const { className, filter, onChange } = props;

  const [orderExpand, setOrderExpand] = useState(false);
  const [collectionExpand, setCollectionExpand] = useState(false);
  const orderExpandClass = orderExpand ? "display" : "hidden";
  const optionsClass = `${orderExpandClass} mb-6`;
  const collectionExpandClass = collectionExpand ? "display" : "hidden";
  const collectionClass = `${collectionExpandClass} mb-6`;
  const nftsWithDebtDescription =
    "Buying a loan, you are buying the NFT with its debt.";
  const nftsDescription =
    "NFTs in liquidation are the ones that have a health factor below 1.00, which means the protocol is going to liquidate the NFT in order to take back the debt. Buyers can acquire the NFT at a discounted price ";

  const handleToggleLoanListed = (filterType: MarketItemType) => {
    const newFilterTypes = [...filter.types];

    const index = newFilterTypes.indexOf(filterType);

    if (index !== -1) {
      newFilterTypes.splice(index, 1);
    } else {
      newFilterTypes.push(filterType);
    }

    onChange({ ...filter, types: newFilterTypes });
  };

  const handleOrderBy = (event: any) =>
    onChange({ ...filter, orderBy: event.target.value });

  const handleSort = (event: any) =>
    onChange({ ...filter, sort: event.target.value });

  const handleCollectionSelected = (event: any) => {
    const { value } = event.target;

    value === "all-collections"
      ? onChange({
          ...filter,
          collectionSelected: undefined,
          allCollectionsSelected: true,
        })
      : onChange({
          ...filter,
          allCollectionsSelected: false,
          collectionSelected: parseInt(value),
        });
  };

  return (
    <>
      <div
        className={classNames(
          "w-64 max-h-max shrink-0 grow-0 p-8 bg-secondary rounded-xl shadow-lighting",
          [className]
        )}
      >
        <h3 className="font-bold text-xl">Filters</h3>
        <div className="w-full">
          <div className="my-6">
            <span className="text-lg">Show</span>
            <div className="flex mt-6 text-sm items-center">
              <input
                className="w-3 h-3 border border-white rounded-sm checked:bg-tertiary"
                id="nfts"
                type="checkbox"
                value="nfts"
                checked={filter.types.includes(MarketItemType.LOAN_AUCTIONED)}
                // onChange={handleNFTCheckboxChange}
                onClick={() =>
                  handleToggleLoanListed(MarketItemType.LOAN_AUCTIONED)
                }
              />
              <span className="ml-1.5">NFTs</span>
              <Info
                className="w-48"
                description={nftsDescription}
                direction="right"
              />
            </div>
            <div className="flex mt-3.5 text-sm items-center">
              <input
                className="w-3 h-3 border border-white rounded-sm checked:bg-quaternary"
                id="debtNFTs"
                type="checkbox"
                value="debtNFTs"
                checked={filter.types.includes(MarketItemType.LOAN_LISTED)}
                // onChange={handleNFTCheckboxChange}
                onClick={() =>
                  handleToggleLoanListed(MarketItemType.LOAN_LISTED)
                }
              />
              <span className="ml-1.5">NFTs with Debt</span>
              <div className="ml-1">
                <Info
                  className="w-48"
                  description={nftsWithDebtDescription}
                  direction="right"
                />
              </div>
            </div>
          </div>
          <div
            className="py-4 text-md relative font-medium cursor-pointer"
            onClick={() => setOrderExpand(!orderExpand)}
          >
            <div className="w-full">
              <span className="py-2">Order By</span>
              <div className="h-0.5 border border-white mt-2"></div>
            </div>
            <button
              aria-label="options-expander"
              className="text-md absolute right-0 top-5 focus:outline-none"
            >
              {orderExpand ? (
                <AiOutlineUp className="w-4 h-4" />
              ) : (
                <AiOutlineDown className="w-4 h-4" />
              )}
            </button>
          </div>
          <div className={optionsClass}>
            <div className="mb-2">
              <div className="items-center mb-2">
                <div className="flex items-center mb-4 cursor-pointer">
                  <input
                    className="w-4 h-4 text-primary border border-white rounded-full checked:bg-primary cursor-pointer"
                    id="default-radio-1"
                    type="radio"
                    onClick={handleOrderBy}
                    value="bidPrice"
                    name="order-by"
                    checked={filter.orderBy === "bidPrice"}
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ml-2 text-sm font-medium cursor-pointer"
                  >
                    Bid Price
                  </label>
                </div>
                <div className="flex items-center cursor-pointer">
                  <input
                    checked={filter.orderBy === "debt"}
                    id="default-radio-2"
                    type="radio"
                    onClick={handleOrderBy}
                    value="debt"
                    name="order-by"
                    className="w-4 h-4 text-primary border border-white rounded-full checked:bg-primary cursor-pointer"
                  />
                  <label
                    htmlFor="default-radio-2"
                    className="ml-2 text-sm font-medium cursor-pointer"
                  >
                    Debt
                  </label>
                </div>
              </div>
              <div className="h-0.5 border border-white mt-2"></div>
            </div>
            <div>
              <div className="flex items-center mb-4 cursor-pointer">
                <input
                  className="w-4 h-4 text-primary border border-white rounded-full checked:bg-primary cursor-pointer"
                  id="default-radio-3"
                  type="radio"
                  value="ASC"
                  checked={filter.sort === "ASC"}
                  name="sort"
                  onClick={handleSort}
                />
                <label
                  htmlFor="default-radio-3"
                  className="ml-2 text-sm font-medium cursor-pointer"
                >
                  Ascending
                </label>
              </div>
              <div className="flex items-center cursor-pointer">
                <input
                  className="w-4 h-4 text-primary border border-white rounded-full checked:bg-primary cursor-pointer"
                  checked={filter.sort === "DESC"}
                  id="default-radio-4"
                  type="radio"
                  value="DESC"
                  onClick={handleSort}
                  name="sort"
                />
                <label
                  htmlFor="default-radio-4"
                  className="ml-2 text-sm font-medium cursor-pointer"
                >
                  Descending
                </label>
              </div>
              <div className="h-0.5 border border-white mt-2"></div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div
            className="py-4 text-md relative font-medium cursor-pointer"
            onClick={() => setCollectionExpand(!collectionExpand)}
          >
            <div className="w-full">
              <span className="py-2">Collections</span>
              <div className="h-0.5 border border-white mt-2"></div>
            </div>
            <button
              aria-label="options-expander"
              className="text-md absolute right-0 top-5 focus:outline-none"
            >
              {collectionExpand ? (
                <AiOutlineUp className="w-4 h-4" />
              ) : (
                <AiOutlineDown className="w-4 h-4" />
              )}
            </button>
          </div>
          <div className={collectionClass}>
            <div className="mb-2">
              <div className="flex items-center cursor-pointer">
                <input
                  className="w-4 h-4 border border-white rounded-full checked:bg-primary cursor-pointer"
                  checked={filter.allCollectionsSelected}
                  onClick={handleCollectionSelected}
                  id="default-radio-5"
                  type="radio"
                  value="all-collections"
                  name="collections"
                />
                <label
                  htmlFor="default-radio-5"
                  className="ml-2 text-sm font-medium cursor-pointer"
                >
                  All collections
                </label>
              </div>
            </div>
            {COLLECTIONS.map(({ address, name }, index) => (
              <div className="mb-2" key={address}>
                <div className="flex items-center cursor-pointer">
                  <input
                    checked={filter.collectionSelected === index}
                    onClick={handleCollectionSelected}
                    key={address}
                    id={address}
                    type="radio"
                    value={index}
                    name="collections"
                    className="w-4 h-4 text-secondary border border-white rounded-full checked:bg-primary cursor-pointer"
                  />
                  <label
                    htmlFor={address}
                    className="ml-2 text-sm font-medium cursor-pointer"
                  >
                    {name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
