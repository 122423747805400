import { callContract } from "../utils";
import abi from "../abis/customERC721.json";
import { PUNKS_COLLECTION, CONTRACT_ADDRESSES } from "../../app.config";

export const isPunkGatewayApprovedForWrappedPunks = async (
  address: string
): Promise<boolean> => {
  const isApproved = await callContract(
    PUNKS_COLLECTION.wrapAddress!,
    abi,
    null,
    "isApprovedForAll",
    [address, CONTRACT_ADDRESSES.punkGateway]
  );

  return isApproved;
};
