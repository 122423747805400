import { BigNumber, Signer } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { callContract } from "../utils";
import abi from "../abis/weth.json";

export const approveLendpoolOnWeth = async (
  signer: Signer,
  amount: BigNumber
): Promise<string> => {
  const transaction = await callContract(
    CONTRACT_ADDRESSES.weth,
    abi,
    signer,
    "approve",
    [CONTRACT_ADDRESSES.lendpool, amount]
  );

  return transaction.hash;
};
