import { UnexpectedError } from "../../errors";

export const calculateAuctionEndTimestamp = (lapse: string): number => {
  const now = Math.floor(Date.now() / 1000);

  const oneDay = 24 * 60 * 60;

  switch (lapse) {
    case "1d":
      return oneDay + now;
    case "3d":
      return 3 * oneDay + now;
    case "7d":
      return 7 * oneDay + now;
    case "28d":
      return 28 * oneDay + now;
    case "1m":
      return 30 * oneDay + now;
    case "3m":
      return 3 * 30 * oneDay + now;
    case "6m":
      return 6 * 30 * oneDay + now;
  }

  throw new UnexpectedError("invalid lapse value provided");
};
