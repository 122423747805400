import { CONTRACT_ADDRESSES } from "../../../app.config";
import { Log } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEventV2,
} from "../../utils";
import abi from "../../abis/reservoirAdapter.json";
import { BigNumber } from "ethers";

type LiquidatedReservoirData = {
  nftAsset: string;
  tokenId: BigNumber;
  loanId: BigNumber;
  borrowAmount: BigNumber;
  liquidatedAmount: BigNumber;
  remainAmount: BigNumber;
  extraDebtAmount: BigNumber;
};

export type LiquidatedReservoirDataParsed = {
  nftAsset: string;
  tokenId: number;
  loanId: number;
  borrowAmount: BigNumber;
  liquidatedAmount: BigNumber;
  remainAmount: BigNumber;
  extraDebtAmount: BigNumber;
};

export const setLiquidatedReservoirListener = async (
  callback: (dataDecoded: LiquidatedReservoirDataParsed) => void
): Promise<() => void> => {
  const unlistener = listenContractEventV2(
    getEventSignature(abi, "LiquidatedReservoir"),
    CONTRACT_ADDRESSES.reservoirAdapter,
    [],
    ({ data, topics }: Log) => {
      // @ts-ignore
      const dataDecoded: LiquidatedReservoirData = decodeTransactionData(
        abi,
        "LiquidatedReservoir",
        data,
        topics
      );

      const dataParsed: LiquidatedReservoirDataParsed = {
        ...dataDecoded,
        loanId: Number(dataDecoded.loanId),
        tokenId: Number(dataDecoded.tokenId),
      };

      callback(dataParsed);
    },
    false
  );

  return unlistener;
};
