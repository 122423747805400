import { type FC, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useAlertNotification } from "../providers";
import { retrieveMyActivity } from "../../logic/retrieveMyActivity";
import { MyActivityItem } from "../../types";
import { MyActiviyItemComponent } from "./MyActivityItemComponent";
import { CtaPrimary, CtaSecondary, CtaWhite } from "../ui";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { handleErrors, isCryptopunks } from "../../logic/helpers";
import { useToggle } from "react-use";
import ModalClaim from "../commons/modals/ModalClaim";

const LIMIT = 100;

type Props = {
  isWrapPunkApproved?: boolean;
};

export const MyActivity: FC<Props> = (props) => {
  const { isWrapPunkApproved } = props;
  const [items, setItems] = useState<Array<MyActivityItem>>([]);
  const { address } = useAccount();
  const [isModalClaimOpen, toggleModalClaim] = useToggle(false);
  const [error, setError] = useState<Error | null>(null);
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<{
    offset: number;
    limit: number;
    total: number;
  }>();

  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    if (error && !isAlertNotificationOpen) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          const { items: itemsFromLogic, pagination } =
            await retrieveMyActivity(address, page * LIMIT, LIMIT);

          setItems((items) => {
            const itemsCopy = [...items];

            return itemsCopy.concat(itemsFromLogic);
          });

          setPagination(pagination);

          setLoading(false);
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address, page]);

  useEffect(() => {
    return () => setItems([]);
  }, [address]);

  const handleSeeMore = () => {
    setPage(page + 1);
    setLoading(true);
  };

  return (
    <>
      <div className="overflow-x-auto max-w-max mx-auto p-4 relative bg-secondary rounded-xl border-4 border-transparent flex flex-col items-center">
        <table className="w-full text-base text-left text-white">
          <thead className="text-md">
            <tr>
              <th scope="col" className="py-3 px-8 min-w-[300px]">
                Collection
              </th>
              <th scope="col" className="py-3 px-8 min-w-[200px]">
                Token ID
              </th>
              <th scope="col" className="py-3 px-8 min-w-[180px]">
                Price
              </th>
              <th scope="col" className="py-3 px-8 min-w-[200px]">
                Date
              </th>
              <th scope="col" className="py-3 px-8">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <MyActiviyItemComponent
                key={item.nfts[0].collection + item.nfts[0].tokenId}
                className={index !== items.length - 1 ? "border-b" : ""}
                item={item}
                isWrapPunkApproved={
                  isCryptopunks(item.nfts[0].collection)
                    ? isWrapPunkApproved
                    : undefined
                }
              />
            ))}
            {!items.length && !isLoading && (
              <tr className="bg-secondary hover:bg-primary/10 text-base font-medium">
                <td scope="row" className="flex py-3 px-8 font-medium">
                  -
                </td>
                <td className="py-4 px-8 font-normal">-</td>
                <td className="py-4 px-8 font-normal">-</td>
                <td className="py-4 px-8 font-normal">-</td>
                <td className="py-4 px-8">-</td>
              </tr>
            )}
          </tbody>
        </table>
        {!isLoading && !!pagination && items.length < pagination.total && (
          <CtaSecondary className="mt-10 mb-8 w-48" onClick={handleSeeMore}>
            See more
          </CtaSecondary>
        )}
        {isLoading && (
          <div className="w-full flex mx-auto mt-10 mb-8 justify-center items-center">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        )}
      </div>
      {isModalClaimOpen && (
        <ModalClaim isOpen={isModalClaimOpen} toggleModal={toggleModalClaim} />
      )}
    </>
  );
};
