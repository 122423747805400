import { ConnectionError } from "../errors/errors";

const DEFAULT_IMAGE =
  "https://i.seadn.io/gae/9ZLCKS7w0XtwLpq7QVMRgY4Eakg5CQaLa52HFcf4HKksqOxRKc_ybis58FWnjHcNKf5MHc_Iw_JAP_7WPMJFLBLOkhFhBd4HU0InJw";

export const getImage = async (tokenUri: string): Promise<string> => {
  try {
    const response = await fetch(tokenUri);

    if (!response.ok) {
      throw new ConnectionError("failed on fetching ipfs image");
    }

    const { image }: { image: string } = await response.json();

    return image.startsWith("ipfs")
      ? "https://ipfs.io/ipfs/" + image.substring(7)
      : image;
  } catch (error) {
    return DEFAULT_IMAGE;
  }
};
