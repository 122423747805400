import {EarnPanelStatics} from "../../types/earn";

export const useEarnPanelStatics = () => {
  const data: EarnPanelStatics = {
    id: "supplies",
    content: {
      tokenTitle: "Tokens",
      balanceTitle: "Wallet Balance", 
      depositedTitle: "Deposited",
      apyTitle: "APY",
      monthlyYieldTitle: "Monthly yield",
      feedbacks: {
        demoText: [
          {
            _key: "1",
            _type: "block",
            children: [
              {
                _key: "1",
                _type: "span",
                text: "demotext : SE ESTA PROCESANDO",
              },
            ],
          },
        ],
      },
    },
  };

  return data;
};
