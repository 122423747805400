import { BorrowEventData, BorrowEventDataParesed, Log } from "../../types";
import {
  decodeTransactionData,
  getEventSignature,
  listenContractEvent,
} from "../../utils";
import abi from "../../abis/lendpoolUnlockd.json";
import { CONTRACT_ADDRESSES } from "../../../app.config";

export const setBorrowConfirmedListener = (
  address: string,
  callback: (borrowData: BorrowEventDataParesed) => void
): (() => void) => {
  const unlistener = listenContractEvent(
    // @ts-ignore
    getEventSignature(abi, "Borrow"),
    CONTRACT_ADDRESSES.lendpool,
    [, address],
    ({ data, topics }: Log) => {
      // @ts-ignore
      const borrowData: BorrowEventData = decodeTransactionData(
        abi,
        "Borrow",
        data,
        topics
      );

      const borrowDataParsed: BorrowEventDataParesed = {
        ...borrowData,
        nftTokenId: Number(borrowData.nftTokenId),
        loanId: Number(borrowData.loanId),
      };

      callback(borrowDataParsed);
    },
    false
  );

  return unlistener;
};
