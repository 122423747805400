import {BigNumber} from "ethers";
import {lookUpProvider} from "../logic/helpers";

export const getBalanceOfAddress = async (
  address: string
): Promise<BigNumber> => {
  const provider = lookUpProvider();

  const balance = provider.getBalance(address);

  return balance;
};
