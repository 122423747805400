import { FC, useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { NftBorrowData } from "../../../../types";
import { FUNCTIONALITIES } from "../../../../../app.config";
import Link from "next/link";

const isFaucetEnabled = FUNCTIONALITIES.some(
  ({ id, enabled }) => id === "faucet" && enabled
);

interface Props {
  className?: string;
  myNfts: NftBorrowData[];
  isLoading: boolean;
}

export const NftImages: FC<Props> = (props) => {
  const { myNfts, isLoading } = props;
  const [quantity, setQuantity] = useState<number>(1);
  const itemsPerRow = 14;

  const handleSeeAll = () => setQuantity(quantity + 1);
  const handleSeeLess = () => setQuantity(quantity - 1);

  return (
    <>
      <div className="w-full flex justify-start items-center">
        {!isLoading && myNfts.length === 0 ? (
          <div className="w-full h-60 flex flex-col justify-center items-center">
            <p>You don't have any supported NFT.</p>
            <p>
              Check the list of supported NFT collections{" "}
              <Link
                legacyBehavior
                href="https://docs.unlockd.finance/protocol-mechanics/nft-appraisal/supported-collections"
              >
                <a className="text-primary font-bold" target="_blank">
                  here
                </a>
              </Link>
              .
            </p>
            {isFaucetEnabled ? "Please get it from the Faucet." : ""}
          </div>
        ) : (
          <div className="max-w-max w-full flex flex-wrap pt-8 gap-8 justify-start mb-11">
            {myNfts.slice(0, quantity * itemsPerRow).map((nft, i) =>
              nft.isDeposited ? (
                <div key={i} className="w-fit h-40 grid">
                  <div className="col-start-1 row-start-1 z-20 flex justify-end">
                    <div className="h-fit w-fit mt-2 mr-2 px-3 bg-gradient-to-r from-gray-500 to-gray-700 rounded-xl border-2 border-green-400 font-semibold">
                      Deposited
                    </div>
                  </div>
                  <img
                    className="w-max h-40 col-start-1 row-start-1 z-10 rounded-lg grayscale"
                    src={nft.tokenUri}
                  />
                </div>
              ) : (
                <div key={i} className="w-max h-40">
                  <img className="rounded-lg w-max h-40" src={nft.tokenUri} />
                </div>
              )
            )}
            {myNfts.length !== 0 &&
              myNfts.length < 3 &&
              new Array(3 - myNfts.length).map((el, i) => (
                <div
                  key={i}
                  className="border-2 border-dashed rounded-lg h-40 w-40"
                ></div>
              ))}
          </div>
        )}
      </div>
      {myNfts.length > quantity * itemsPerRow ? (
        <div onClick={handleSeeAll} className="flex items-center">
          <h3 className="font-bold">See more</h3>
          <AiOutlineUp className="w-5 h-5 ml-2" />
        </div>
      ) : null}
      {isLoading ? null : (
        <>
          {myNfts.length > itemsPerRow ? (
            <>
              {myNfts.length < quantity * itemsPerRow && (
                <div onClick={handleSeeLess} className="flex items-center">
                  <h3 className="font-bold">See less</h3>
                  <AiOutlineDown className="w-5 h-5 ml-2" />
                </div>
              )}
            </>
          ) : null}
        </>
      )}
      {myNfts.length !== 0 &&
        myNfts.length < 3 &&
        new Array(3 - myNfts.length).map((el, i) => (
          <div
            key={i}
            className="border-2 border-dashed rounded-lg h-40 w-40"
          ></div>
        ))}
    </>
  );
};
