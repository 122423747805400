import {
  calculateApyBorrow,
  calculateApyEarn,
  calculateApyEarnGoerli,
  equalIgnoreCase,
} from "../utils";
import wethAbi from "../abis/uToken.json";
import debtTokenAbi from "../abis/debtToken.json";
import protocolDataProviderAbi from "../abis/unlockdProtocolDataProvider.json";
import { DashboardGeneralData, SingleCall } from "../types";
import { CHAIN, COLLECTIONS, CONTRACT_ADDRESSES } from "../../app.config";
import { ConnectionError } from "../errors";
import { PEP } from "../constants";
import { multicall } from "../utils/multicall";
import { getYearnApy } from "./helpers/getYearnApy";

export const getDashboardGeneralData =
  async (): Promise<DashboardGeneralData> => {
    const totalSupplyUTokenCall = new SingleCall(
      CONTRACT_ADDRESSES.uWeth,
      wethAbi,
      "totalSupply",
      []
    );

    const totalSupplyDebtTokenCall = new SingleCall(
      CONTRACT_ADDRESSES.debtToken,
      debtTokenAbi,
      "totalSupply",
      []
    );

    const reserveDataCall = new SingleCall(
      CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
      protocolDataProviderAbi,
      "getReserveData",
      [CONTRACT_ADDRESSES.weth]
    );

    const collectionsCalls: Array<SingleCall> = COLLECTIONS.map(
      ({ address, abi, uBoundAddress }) => {
        return new SingleCall(address, abi, "balanceOf", [uBoundAddress]);
      }
    );

    if (CHAIN === "mainnet") {
      const [
        totalValueResponse,
        [
          //@ts-ignore
          [totalSupplyUToken],
          //@ts-ignore
          [totalSupplyDebtToken],
          //@ts-ignore
          { variableBorrowIndex, liquidityRate, variableBorrowRate },
          //@ts-ignore
          ...collectionsResults
        ],
      ] = await Promise.all([
        fetch(`api/unlockd-api/protocol/dashboard/total-value`),
        multicall(
          totalSupplyUTokenCall,
          totalSupplyDebtTokenCall,
          reserveDataCall,
          ...collectionsCalls
        ),
      ]);

      if (!totalValueResponse.ok) {
        throw new ConnectionError("failed fetching dashboard data from server");
      }

      const { total: totalNftsValue } = await totalValueResponse.json();

      const utilizationRate =
        (totalSupplyDebtToken.div(PEP).toString() /
          totalSupplyUToken.div(PEP).toString()) *
        100;
      const utilizationString = (
        Math.round(utilizationRate * 100) / 100
      ).toString();
      const nftsDeposited = collectionsResults.reduce((acc, result) => {
        return acc + Number(result![0]);
      }, 0);

      const borrowApy = calculateApyBorrow(Number(variableBorrowRate));

      let yearnApy: number | null;

      try {
        yearnApy = await getYearnApy();
      } catch (err) {
        yearnApy = null;
      }

      let earnApy: number | null = null;

      if (yearnApy) {
        earnApy = calculateApyEarn(
          liquidityRate,
          utilizationRate / 100,
          yearnApy,
          borrowApy
        );
      }

      return {
        totalValueLocked: totalSupplyUToken,
        utilizationRate: utilizationString,
        nftsDeposited,
        borrowApy,
        earnApy,
        totalNftsValue,
      };
    } else {
      const [
        response,
        [
          //@ts-ignore
          [totalSupplyUToken],
          //@ts-ignore
          [totalSupplyDebtToken],
          //@ts-ignore
          { variableBorrowIndex, liquidityRate, variableBorrowRate },
          //@ts-ignore
          ...collectionsResults
        ],
      ] = await Promise.all([
        fetch(`api/unlockd-api/protocol/dashboard/total-value`),
        multicall(
          totalSupplyUTokenCall,
          totalSupplyDebtTokenCall,
          reserveDataCall,
          ...collectionsCalls
        ),
      ]);

      if (!response.ok) {
        throw new ConnectionError("failed fetching dashboard data from server");
      }

      const { total: totalNftsValue } = await response.json();

      const utilizationRate =
        (totalSupplyDebtToken.div(PEP).toString() /
          totalSupplyUToken.div(PEP).toString()) *
        100;
      const utilizationString = (
        Math.round(utilizationRate * 100) / 100
      ).toString();
      const nftsDeposited = collectionsResults.reduce((acc, result) => {
        return acc + Number(result![0]);
      }, 0);

      const borrowApy = calculateApyBorrow(Number(variableBorrowRate));

      const earnApy = calculateApyEarnGoerli(liquidityRate);

      return {
        totalValueLocked: totalSupplyUToken,
        utilizationRate: utilizationString,
        nftsDeposited,
        borrowApy,
        earnApy,
        totalNftsValue,
      };
    }
  };
