import {Transition} from "@headlessui/react";
import {type FC} from "react";

type Hoc = (Component: FC<any>) => FC<any>;

export const withAlert: Hoc = (Component) => {
  return ({ isOpen, toggleAlert, ...restProps }) => (
    <>
      <Transition
        show={isOpen}
        enterFrom="opacity-0"
        enter="transition-opacity duration-300"
        leave="transition-opacity duration-300"
        leaveTo="opacity-0"
        className="fixed top-0 left-0 translate-y-24 w-screen h-0 z-50"
      >
        <div className="w-fit mx-auto">
          <Component isOpen={isOpen} toggleAlert={toggleAlert} {...restProps} />
        </div>
      </Transition>
    </>
  );
};
