import {BigNumber} from "ethers";
import {HALF_PERCENT, PERCENTAGE_FACTOR, RAY, WAD} from "../constants";

export const percentMul = (
  value: BigNumber,
  percentage: BigNumber
): BigNumber => {
  if (value.eq(0) || percentage.eq(0)) {
    return BigNumber.from(0);
  }
  return value.mul(percentage).add(HALF_PERCENT).div(PERCENTAGE_FACTOR);
};

export const wadDiv = (a: BigNumber, b: BigNumber): BigNumber => {
  const halfB = b.div(2);

  const aMulWAD = a.mul(WAD.toString());

  return aMulWAD.add(halfB).div(b);
};

export const rayMul = (a: BigNumber, b: BigNumber): BigNumber => {
  const rayBN = BigNumber.from(RAY);

  const halfRay = rayBN.div(2);

  return halfRay.add(a.mul(b)).div(rayBN);
};

export const rayDiv = (a: BigNumber, b: BigNumber): BigNumber => {
  const halfB = b.div(2);

  return a.mul(RAY).add(halfB).div(b);
};
