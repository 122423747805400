import { Signer } from "ethers";

export const isGnosisSafeSmartAccount = (signer: Signer) => {
  const WC_GNOSIS_SAFE_URL = "https://apps-portal.safe.global";

  return (
    // @ts-ignore
    signer?.provider?.provider?.connector?._peerMeta?.url === WC_GNOSIS_SAFE_URL
  );
};
