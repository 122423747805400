import { CONTRACT_ADDRESSES } from "../../../app.config";
import { callContract } from "../../utils";
import abi from "../../abis/debtMarket.json";
import { BigNumber } from "ethers";

export const getDebtById = async (
  debtId: number
): Promise<{
  auctionEndTimestamp: BigNumber;
  bidPrice: BigNumber;
  bidderAddress: string;
  debtId: BigNumber;
  debtor: string;
  nftAsset: string;
  reserveAsset: string;
  scaledAmount: BigNumber;
  sellPrice: BigNumber;
  sellType: number;
  startBiddingPrice: BigNumber;
  state: number;
  tokenId: BigNumber;
}> => {
  return await callContract(
    CONTRACT_ADDRESSES.debtMarket,
    abi,
    null,
    "getDebt",
    [debtId]
  );
};
