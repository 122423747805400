import { BigNumber, Signer } from "ethers";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { callContract } from "../utils";
import abi from "../abis/wethGateway.json";
import { isCryptopunks } from "./helpers";

export const buyLoanAuctioned = async (
  signer: Signer,
  collection: string,
  tokenId: number,
  buyAmount: BigNumber,
  onBehalfOf: string
): Promise<string> => {
  const isPunk = isCryptopunks(collection);

  const contract = isPunk
    ? CONTRACT_ADDRESSES.punkGateway
    : CONTRACT_ADDRESSES.wethGateway;

  const args = isPunk
    ? [tokenId, onBehalfOf]
    : [collection, tokenId, onBehalfOf];

  const transaction = await callContract(
    contract,
    abi,
    signer,
    "buyoutETH",
    args,
    {
      value: buyAmount,
    }
  );

  return transaction.hash;
};
