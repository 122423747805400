export const parseMsToRenderMinAndSec = (ms: number) => {
  const minutes = Math.floor(((ms % 86400000) % 3600000) / 60000);

  const seconds = Math.floor((((ms % 86400000) % 3600000) % 60000) / 1000);

  const minutesToRender = minutes < 10 ? `0${minutes}` : minutes;
  const secondsToRender = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutesToRender}:${secondsToRender}`;
};
