import { Alchemy, Network } from "alchemy-sdk";
import { CHAIN, ALCHEMY } from '../../app.config'

const getNetwork = () => {
  switch (CHAIN) {
    case "goerli":
      return Network.ETH_GOERLI;
    case "mainnet":
      return Network.ETH_MAINNET;
  }
};

const settings = {
  apiKey: typeof window === 'undefined' ? process.env.SERVER_ALCHEMY_KEY : ALCHEMY.apiKey,
  network: getNetwork(),
};

export function alchemy() {
  // @ts-ignore
  if (!alchemy.cache) {
    console.log('HEY init alchemy')
    // @ts-ignore
    alchemy.cache = new Alchemy(settings);
  }
  // @ts-ignore
  return alchemy.cache
}

// temporary setting to be remove when the dapp is deploy on mainnet
// reminder: retrieveLockeysHolderType file has to be modified also at these time
const settingsMainnet = {
  apiKey: typeof window === 'undefined' ? process.env.SERVER_ALCHEMY_KEY : ALCHEMY.apiKey,
  network: Network.ETH_MAINNET,
};

//@ts-ignore
alchemy.TEMP_MAINNET = function () {
  // @ts-ignore
  if (!alchemy.TEMP_MAINNET.cache) {
    console.log('HEY init TEMP alchemy')
    // @ts-ignore
    alchemy.TEMP_MAINNET.cache = new Alchemy(settingsMainnet);
  }
  // @ts-ignore
  return alchemy.TEMP_MAINNET.cache
}
