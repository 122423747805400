import { type FC, useEffect, useState } from "react";
import { ProtocolMetrics } from "./ProtocolMetrics";
import { EarnBox } from "./EarnBox";
import { LoansBox } from "./LoansBox";
import { AuctionsBox } from "./AuctionsBox";
import { getDashboardGeneralData, getDashboardUserData } from "../../../logic";
import { BigNumber } from "ethers";
import { handleErrors } from "../../../logic/helpers";
import { useToggle } from "react-use";
import { useAccount } from "wagmi";
import { useAlertNotification } from "../../providers";
import { DashboardGeneralData, DashboardUserData } from "../../../types";
import { CollectionsPanel } from "./CollectionsPanel";

interface Props {
  className?: string;
}

export const DashboardPage: FC<Props> = (props) => {
  const { className } = props;

  const { address } = useAccount();
  const [dashboardGeneralData, setDashboardGeneralData] =
    useState<DashboardGeneralData>();
  const [dashboardUserData, setDashboardUserData] =
    useState<DashboardUserData>();
  const [error, setError] = useState<Error | null>(null);
  const [isAlertNotificationOpen, openAlertNotification] =
    useAlertNotification();

  useEffect(() => {
    if (error && !isAlertNotificationOpen) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    }
  }, [error]);

  useEffect(() => {
    (async () => {
      try {
        setDashboardGeneralData(await getDashboardGeneralData());
      } catch (err) {
        console.log(error);
        setError(handleErrors(err));
      }
    })();
  }, []);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          setDashboardUserData(await getDashboardUserData(address));
        } catch (err) {
          setError(handleErrors(err));
        }
      })();
    }
  }, [address]);

  return (
    <div className="flex flex-col gap-10">
      <ProtocolMetrics
        className="w-full"
        valueLocked={dashboardGeneralData?.totalValueLocked}
        nftsDeposited={dashboardGeneralData?.nftsDeposited}
        utilizationRate={dashboardGeneralData?.utilizationRate}
        totalNftsValue={dashboardGeneralData?.totalNftsValue}
      />
      <div className="flex w-full max-h-max justify-center gap-10">
        <LoansBox
          className="w-1/3"
          nftsDepositedByUser={dashboardUserData?.nftsDeposited}
          totalDebtOfUser={dashboardUserData?.totalDebt}
          borrowApy={dashboardGeneralData?.borrowApy}
        />
        <EarnBox
          className="w-1/3"
          apy={dashboardGeneralData?.earnApy}
          supply={dashboardUserData?.totalSupplied}
          interestEarned={dashboardUserData?.interestEarned}
        />

        <AuctionsBox
          className="w-1/3"
          claimedAuctions={dashboardUserData?.claimedAuctions}
          onGoingAuctions={dashboardUserData?.onGoingAuctions}
        />
      </div>
      <CollectionsPanel className="w-full" />
    </div>
  );
};
