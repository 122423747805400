import unlockdDataProviderAbi from "../abis/unlockdProtocolDataProvider.json";
import uiDataProviderAbi from "../abis/uiPoolDataProvider.json";
import nftOracleAbi from "../abis/nftOracle.json";
import lendpoolUnlockdAbi from "../abis/lendpoolUnlockd.json";
import { BigNumber } from "ethers";
import { SingleCall } from "../types";
import { multicall } from "../utils/multicall";
import { CONTRACT_ADDRESSES } from "../../app.config";
import { percentMul, wadDiv } from "../utils";

export const getLoanDataToRedeem = async (
  collectionAddress: string,
  tokenId: number
): Promise<{
  maxRedeem: BigNumber;
  minRedeem: BigNumber;
  bidFine: BigNumber;
  liquidationThreshold: BigNumber;
}> => {
  const getSimpleLoansDataCall = new SingleCall(
    CONTRACT_ADDRESSES.uiPoolDataProvider,
    uiDataProviderAbi,
    "getSimpleLoansData",
    [
      CONTRACT_ADDRESSES.lendpoolAddressesProvider,
      [collectionAddress],
      [tokenId],
    ]
  );

  const getNftConfigurationDataByTokenIdCall = new SingleCall(
    CONTRACT_ADDRESSES.unlockdProtocolDataProvider,
    unlockdDataProviderAbi,
    "getNftConfigurationDataByTokenId",
    [collectionAddress, tokenId]
  );

  const getNFTPriceCall = new SingleCall(
    CONTRACT_ADDRESSES.nftOracle,
    nftOracleAbi,
    "getNFTPrice",
    [collectionAddress, tokenId]
  );

  const getSafeHealthFactorCall = new SingleCall(
    CONTRACT_ADDRESSES.lendpool,
    lendpoolUnlockdAbi,
    "getSafeHealthFactor",
    []
  );

  // @ts-ignore
  const [
    [[simpleLoansData]],
    nftConfigurationData,
    [nftPrice],
    safeHF,
  ]: Array<any> = await multicall(
    getSimpleLoansDataCall,
    getNftConfigurationDataByTokenIdCall,
    getNFTPriceCall,
    getSafeHealthFactorCall
  );

  // @ts-ignore
  const { liquidationThreshold }: { liquidationThreshold: BigNumber } =
    nftConfigurationData.configData;
  const { bidBorrowAmount }: { bidBorrowAmount: BigNumber } = simpleLoansData;

  // @ts-ignore
  const minRedeem: BigNumber = bidBorrowAmount.sub(
    //@ts-ignore
    percentMul(nftPrice, liquidationThreshold).div(safeHF / 1000000000000000000)
  );

  const maxRedeem = bidBorrowAmount.sub(bidBorrowAmount.sub(minRedeem).div(2));

  // console.log("nftPrice", nftPrice);

  return {
    minRedeem: nftPrice.lt(minRedeem)
      ? nftPrice.mul(1001).div(1000)
      : minRedeem.mul(1001).div(1000),
    maxRedeem,
    bidFine: simpleLoansData.bidFine.mul(1001).div(1000),
    liquidationThreshold: nftConfigurationData.configData.liquidationThreshold,
  };
};
