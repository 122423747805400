import { type FC } from "react";
import classNames from "classnames";
import { CtaPrimary, CtaSecondary } from "../../../ui";
import { NftImages } from "./NftImages";
import Link from "next/link";
import { NftBorrowData } from "../../../../types";
import { useWeb3ModalGuard } from "../../modals/shared";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FUNCTIONALITIES } from "../../../../../app.config";
import { useAccount } from "wagmi";
import { registerRaleonEvent } from "../../../../logic";

const isFaucetEnabled = FUNCTIONALITIES.some(
  ({ id, enabled }) => id === "faucet" && enabled
);

interface Props {
  className?: string;
  myNfts: NftBorrowData[];
  onDepositClick?: () => void;
  isLoading: boolean;
}

export const MyNfts: FC<Props> = (props) => {
  const { className, myNfts: nfts, onDepositClick, isLoading } = props;
  const { address } = useAccount();

  const launcherBorrow = useWeb3ModalGuard(undefined, onDepositClick);

  const handleDeposit = () => {
    registerRaleonEvent("deposit-clicked", address!);
    launcherBorrow();
  };

  return (
    <>
      <section
        className={classNames(
          "max-w-7xl mx-auto mb-11 pt-8 px-8 md:space-y-0 text-white bg-secondary rounded-xl shadow-lighting",
          [className]
        )}
      >
        <h1 className="font-bold text-xl mb-4">My supported NFTs</h1>
        {isFaucetEnabled && (
          <h2>
            Every wallet can own a maximum of 3 NFTs from the Faucet to operate
            in the Testnet.
          </h2>
        )}

        <NftImages myNfts={nfts} isLoading={isLoading} />

        {isLoading && (
          <div className="w-full h-40 flex justify-center items-center">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        )}

        <div className="flex flex-col gap-4 w-max mx-auto pb-8 pt-10">
          <CtaPrimary
            className="px-11"
            onClick={handleDeposit}
            disabled={isLoading || !nfts.length}
          >
            Deposit NFT
          </CtaPrimary>

          {isFaucetEnabled &&
            (nfts.length > 0 ? (
              <CtaSecondary>
                <Link legacyBehavior href="/faucet">
                  <a>Go to Faucet</a>
                </Link>
              </CtaSecondary>
            ) : (
              <CtaPrimary className="px-11">
                <Link legacyBehavior href="/faucet">
                  <a>Go to Faucet</a>
                </Link>
              </CtaPrimary>
            ))}
        </div>
      </section>
    </>
  );
};
