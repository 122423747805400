import {type FC} from "react";

interface Props {
  className?: string;
}

export const ScreenMessage: FC<Props> = () => {
  return (
    <>
      <section className="h-screen mx-auto mb-5 xs:px-6 px-4 pt-8 pb-8 shadow-lg rounded-t-xl bg-secondary element-shadow">
        <div className="relative m-1">
          <img
            src="./assets/images/logo-unlockd.png"
            alt="Unlockd"
            className="w-40 mb-8"
          />
          <h1 className="text-white text-xl font-bold mb-6">We are sorry...</h1>
          <p className="mb-4">We are still working on the dapp to make it compatible with smartphones.</p>
          <p className="mb-4">We will announce it soon.</p>
          <p className="mb-4">Please, access this dapp from your desktop instead.</p>
          <p className="mb-4">You can follow us on Twitter: <a href="https://mobile.twitter.com/unlockd_finance">@Unlockd_Finance</a></p>
        </div>
      </section>
    </>
  );
};
