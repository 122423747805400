import {type FC, useEffect, useState} from "react";
import type {ITransaction, PortableText} from "../../../types";
import classNames from "classnames";
import {useAccount, useNetwork, useSigner} from "wagmi";
import {parseDateToRender, parseHashToRender} from "../../../utils";
import {IconEthereum} from "../../ui/icons";
import {RiCheckboxCircleFill, RiCloseCircleFill} from "react-icons/ri";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {Anchor} from "../../ui";
import {useAlertNotification} from "../../providers";
import {handleErrors} from "../../../logic/helpers";

interface Props {
  className?: string;
  transaction?: ITransaction;
}

type Controller = (props: Props) => Props & {
  chainName: string;
};

const useController: Controller = (props) => {
  const { transaction } = props;
  const { chains } = useNetwork();
  const [isOpenAlertNotification, openAlertNotification] =
    useAlertNotification();
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const [error, setError] = useState<Error | null | undefined>(null);

  useEffect(() => {
    if (address && signer) {
      try {
        if (transaction ? transaction.status === undefined : "") {
          openAlertNotification("loading", richTextMintSuccess, 10000);
        }
      } catch (err) {
        setError(handleErrors(err));
      }
    }
  }, [signer]);

  return {
    chainName: chains[0]?.name,
    ...props,
  };
};

export const Transaction: FC<Props> = (props) => {
  const { className, transaction, chainName } = useController(props);

  return (
    <>
      <div className={classNames("w-full pt-4 ", [className])}>
        {transaction ? (
          <>
            <div className="w-7 h-7 bg-white rounded-full p-0.5 mr-2">
              <IconEthereum />
            </div>
            <Anchor
              href={`https://${
                chainName === "Goerli" ? chainName + "." : ""
              }etherscan.io/tx/${transaction.hash}`}
              target="_blank"
              title="Etherscan"
              rel="noopener noreferrer"
              className="underline-offset-4 hover:underline cursor-pointer text-sm"
            >
              {parseHashToRender(transaction.hash, {
                initLength: 4,
                endLength: 24,
              })}
            </Anchor>
          </>
        ) : (
          <span className="text-sm">-</span>
        )}
      </div>
      <div className="pt-4">
        {transaction ? (
          <div className="w-7 h-7 ml-2.5">
            {transaction.status === 0 && (
              <RiCloseCircleFill className="text-red-600 w-full h-full" />
            )}
            {transaction.status === 1 && (
              <RiCheckboxCircleFill className="text-green-400 w-full h-full" />
            )}
            {transaction.status === undefined && (
              <AiOutlineLoading3Quarters className="animate-spin w-full h-full" />
            )}
          </div>
        ) : (
          <span className="text-sm">-</span>
        )}
      </div>
      <div className="pt-4">
        {transaction ? (
          <span className="text-sm">
            {!transaction.date ? "" : parseDateToRender(transaction.date)}
          </span>
        ) : (
          <span className="text-sm">-</span>
        )}
      </div>
    </>
  );
};

const richTextMintSuccess: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your petition is being processed. You can check the transaction status in the panel below.",
      },
    ],
  },
];
