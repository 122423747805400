import { createContext, type FC, useContext, useState } from "react";
import { useToggle, useUpdateEffect } from "react-use";
import {
  AlertNotification,
  ModalConnectWallets,
  ModalSign,
  ModalSwitchNetwork,
} from "../commons";
import type { PortableText } from "../../types/elements";

type IContext = Partial<{
  modalConnectWallets: [boolean, (nextValue?: boolean) => void];
  modalSign: [boolean, (nextValue?: boolean) => void];
  modalSwitchNetwork: [boolean, (nextValue?: boolean) => void];
  alertNotification: [
    boolean,
    (
      type: "error" | "success" | "warning" | "loading" | "info",
      content: PortableText,
      timeout: number
    ) => void,
    () => void
  ];
}>;

const Context = createContext<IContext>({});

export const FunctionalProvider: FC = ({ children }) => {
  // Modals states
  const [isModalConnectWalletsOpen, toggleModalConnectWallets] =
    useToggle(false);
  const [isModalSignOpen, toggleModalSign] = useToggle(false);
  const [isModalSwitchNetworkOpen, toggleModalSwitchNetwork] = useToggle(false);

  // alert logic
  const [isAlertNotificationOpen, toggleAlertNotification] = useToggle(false);
  const [typeAlertNotification, setTypeAlertNotification] = useState<
    "error" | "success" | "warning" | "loading" | "info"
  >("error");
  const [contentAlertNotification, setContentAlertNotification] =
    useState<PortableText>([]);
  const [timeoutAlertNotification, setTimeoutAlertNotification] =
    useState<number>();

  const openAlertNotification = (
    type: typeof typeAlertNotification,
    content: typeof contentAlertNotification,
    timeout: typeof timeoutAlertNotification
  ) => {
    setTypeAlertNotification(type);
    setContentAlertNotification(content);
    setTimeoutAlertNotification(timeout);
    toggleAlertNotification(true);
  };

  const closeAlertNotification = () => {
    toggleAlertNotification(false);
  };

  useUpdateEffect(() => {
    if (!isAlertNotificationOpen) {
      const timer = setTimeout(() => {
        setTypeAlertNotification("error");
        setContentAlertNotification([]);
        setTimeoutAlertNotification(undefined);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isAlertNotificationOpen]);

  // Provider and renders of components
  return (
    <>
      <Context.Provider
        value={{
          modalConnectWallets: [
            isModalConnectWalletsOpen,
            toggleModalConnectWallets,
          ],
          modalSign: [isModalSignOpen, toggleModalSign],
          modalSwitchNetwork: [
            isModalSwitchNetworkOpen,
            toggleModalSwitchNetwork,
          ],
          alertNotification: [
            isAlertNotificationOpen,
            openAlertNotification,
            closeAlertNotification,
          ],
        }}
      >
        {children}
      </Context.Provider>
      <ModalConnectWallets
        isOpen={isModalConnectWalletsOpen}
        toggleModal={toggleModalConnectWallets}
      />
      <ModalSign isOpen={isModalSignOpen} toggleModal={toggleModalSign} />
      <ModalSwitchNetwork
        isOpen={isModalSwitchNetworkOpen}
        toggleModal={toggleModalSwitchNetwork}
      />
      <AlertNotification
        isOpen={isAlertNotificationOpen}
        toggleAlert={toggleAlertNotification}
        type={typeAlertNotification}
        content={contentAlertNotification}
        timeout={timeoutAlertNotification}
      />
    </>
  );
};

// helpers hooks for managment context values
export const useModalConnectWallets = () => {
  const { modalConnectWallets } = useContext(Context);

  return modalConnectWallets!;
};

export const useModalSign = () => {
  const { modalSign } = useContext(Context);

  return modalSign!;
};

export const useModalSwitchNetwork = () => {
  const { modalSwitchNetwork } = useContext(Context);

  return modalSwitchNetwork!;
};

export const useAlertNotification = () => {
  const { alertNotification } = useContext(Context);

  return alertNotification!;
};
