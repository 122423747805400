import { callContract, validateAddress, validateRange } from "../utils";
import abi from "../abis/unlockdMockBayc.json";
import { Signer } from "ethers";
import { MILADY_COLLECTION } from "../../app.config";

export const mintFaucet = async (
  signer: Signer,
  address: string,
  amount: number
): Promise<string> => {
  validateAddress(address);
  validateRange(amount, 1, 3, "mint amount");

  const tx = await callContract(
    MILADY_COLLECTION.address,
    abi,
    signer,
    "mint",
    [address, amount]
  );

  return tx.hash;
};
