import { callContract, equalIgnoreCase } from "../utils";
import abi from "../abis/cryptopunksMarket.json";
import { CONTRACT_ADDRESSES } from "../../app.config";

export const isPunkForSale = async (
  collectionAddress: string,
  punkIndex: string
): Promise<boolean> => {
  const { isForSale, onlySellTo } = await callContract(
    collectionAddress,
    abi,
    null,
    "punksOfferedForSale",
    [punkIndex]
  );

  return (
    isForSale && equalIgnoreCase(onlySellTo, CONTRACT_ADDRESSES.punkGateway)
  );
};
