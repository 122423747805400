export const contractErrorsMap: {
  [key: string]: {
    eng: string;
  };
} = {
  "100": {
    eng: "The caller must be the pool admin",
  },
  "101": {
    eng: "Caller not address provider",
  },
  "102": {
    eng: "Invalid from balance after transfer",
  },
  "103": {
    eng: "Invalid to balance after transfer",
  },
  "104": {
    eng: "Caller not on behalf of or in whitelist",
  },
  "105": {
    eng: "Caller not pool liquidator",
  },
  "106": {
    eng: "Invalid zero address",
  },
  "107": {
    eng: "Caller not ltv manager",
  },
  "108": {
    eng: "Caller not price manager",
  },
  "200": {
    eng: "Math multiplication overflow",
  },
  "201": {
    eng: "Math additon overflow",
  },
  "202": {
    eng: "Math division by zero",
  },
  "301": {
    eng: "Invalid amount",
  },
  "302": {
    eng: "Auction requires an active reserve",
  },
  "303": {
    eng: "Action cannot be performde because the reserve is frozen",
  },
  "304": {
    eng: "User cannot withdraw more than the available balance",
  },
  "305": {
    eng: "Borrowing not enabled",
  },
  "306": {
    eng: "Collateral balance error",
  },
  "307": {
    eng: "Health factor is less than the liquidation threshold",
  },
  "308": {
    eng: "Collateral cannot cover this new borrow",
  },
  "309": {
    eng: "For repayment of stable debt, the user needs to have stable debt",
  },
  "310": {
    eng: "No active nft",
  },
  "311": {
    eng: "Nft frozen",
  },
  "312": {
    eng: "User did not borrow the specified currency",
  },
  "313": {
    eng: "Invalid health factor",
  },
  "314": {
    eng: "Invalid on behalf of address",
  },
  "315": {
    eng: "Invalid target address",
  },
  "316": {
    eng: "Invalid reserve address",
  },
  "317": {
    eng: "Specified loan not borrowed by the user",
  },
  "318": {
    eng: "Specified reserve not borrowed by the user",
  },
  "319": {
    eng: "Health factor higher than liquidation threshold",
  },
  "320": {
    eng: "Timeframe exceeded",
  },
  "321": {
    eng: "Value exceed treasury balance",
  },
  "400": {
    eng: "The caller of the function is not the lending pool configurator",
  },
  "401": {
    eng: "Pool is paused",
  },
  "402": {
    eng: "No more serves allowed",
  },
  "403": {
    eng: "Not contract",
  },
  "404": {
    eng: "Borrow not exceed liquidation threshold. Loan is not in auction",
  },
  "405": {
    eng: "Borrow is exceed liquidation price",
  },
  "406": {
    eng: "No more nfts allowed",
  },
  "407": {
    eng: "Invalid user nft amount",
  },
  "408": {
    eng: "Inconsistent params",
  },
  "409": {
    eng: "Nft is not used as collateral",
  },
  "410": {
    eng: "Caller must be an uToken",
  },
  "411": {
    eng: "Invalid nft amount",
  },
  "412": {
    eng: "Nft has used as collateral",
  },
  "413": {
    eng: "Delegate call failed",
  },
  "414": {
    eng: "Amount less than extra debt",
  },
  "415": {
    eng: "Amount less than minimum redeem value",
  },
  "416": {
    eng: "Amount greater than maximum redeem value",
  },
  "417": {
    eng: "Nft token id exceed max limit",
  },
  "418": {
    eng: "Nft supply num exceed max limit",
  },
  "419": {
    eng: "Caller not lendpool liquidator or gatewat",
  },
  "420": {
    eng: "Consecutive bids not allowed",
  },
  "421": {
    eng: "Invalid overflow value",
  },
  "422": {
    eng: "Caller not nft holder",
  },
  "423": {
    eng: "Nft not allowed to sell",
  },
  "424": {
    eng: "Not enough liquidity to borrow. Please try with less amount or try again later",
  },
  "425": {
    eng: "Collection not supported",
  },
  "426": {
    eng: "Message value different from configuration fee",
  },
  "427": {
    eng: "Invalid safe health factor",
  },
  "428": {
    eng: "Amount less than debt",
  },
  "429": {
    eng: "Amount different from required buyout price",
  },
  "430": {
    eng: "Caller not debt token manager",
  },
  "480": {
    eng: "Loan cannot be repaid. Invalid loan state.",
  },
  "481": {
    eng: "Invalid loan amount",
  },
  "482": {
    eng: "Invalid taken amount",
  },
  "483": {
    eng: "Amount overflow",
  },
  "484": {
    eng: "Bid price less than liquidation price",
  },
  "485": {
    eng: "Bid price is less than highest price",
  },
  "486": {
    eng: "Time for redeem has finished",
  },
  "487": {
    eng: "Bid user is not the same",
  },
  "488": {
    eng: "Bid repay amount not enough",
  },
  "489": {
    eng: "Auction has finished",
  },
  "490": {
    eng: "Auction duration has finished yet",
  },
  "491": {
    eng: "Bid price is less than borrow",
  },
  "492": {
    eng: "Invalid bidder address",
  },
  "493": {
    eng: "Amount less than bid fine",
  },
  "494": {
    eng: "Invalid bid fine",
  },
  "495": {
    eng: "Bid price is less than minimum required",
  },
  "496": {
    eng: "Bid not buyout price",
  },
  "497": {
    eng: "Buyout duration has ended",
  },
  "498": {
    eng: "Buyout price less than borrow",
  },
  "499": {
    eng: "Caller must be market adapter",
  },
  "500": {
    eng: "Caller must be lendpool",
  },
  "501": {
    eng: "Invalid mint amount",
  },
  "502": {
    eng: "Invalid burn amount",
  },
  "503": {
    eng: "Borrow allowance not enough",
  },
  "504": {
    eng: "Caller must be debt market",
  },
  "601": {
    eng: "Reserve has already been initialized",
  },
  "602": {
    eng: "Liquidity index overflow uint",
  },
  "603": {
    eng: "Variable borrow index overflow uint",
  },
  "604": {
    eng: "Liquidity rate overflows uint",
  },
  "605": {
    eng: "Variable borrow rate overflows uint",
  },
  "700": {
    eng: "Wrong liquidity  of the reserve",
  },
  "701": {
    eng: "Invalid risk parameters for the reserve",
  },
  "702": {
    eng: "The caller must be the emergency admin",
  },
  "703": {
    eng: "Invalid uNft address",
  },
  "704": {
    eng: "Invalid loan address",
  },
  "705": {
    eng: "Nft liquidity not zero",
  },
  "706": {
    eng: "Nft assets and token ids mismatch",
  },
  "707": {
    eng: "Fee percentage too high",
  },
  "708": {
    eng: "Invalid LTV manager address",
  },
  "709": {
    eng: "Inconsistent parameters",
  },
  "710": {
    eng: "Invalid safe health factor",
  },
  "730": {
    eng: "Invalid ltv",
  },
  "731": {
    eng: "invalid liquidation threshold",
  },
  "732": {
    eng: "Invalid liquidation bonus",
  },
  "733": {
    eng: "Invalid decimals",
  },
  "734": {
    eng: "Invalid reserve factor",
  },
  "735": {
    eng: "Invalid redeem duration",
  },
  "736": {
    eng: "Invalid auction duration",
  },
  "737": {
    eng: "Invalid redeem fine",
  },
  "738": {
    eng: "Invalid redeem threshold",
  },
  "739": {
    eng: "Invalid min bind fine",
  },
  "740": {
    eng: "Invalid max bid fine",
  },
  "741": {
    eng: "Invalid max config timestamp",
  },
  "760": {
    eng: "Provider not registered",
  },
  "761": {
    eng: "Invalid address provider id",
  },
  "800": {
    eng: "Nftx invalid vaults length",
  },
  "900": {
    eng: "Invalid pricem address",
  },
  "1000": {
    eng: "Caller not the owner",
  },
  "1001": {
    eng: "Debt should exist",
  },
  "1002": {
    eng: "Invalid amount",
  },
  "1003": {
    eng: "Fail on send eth",
  },
  "1004": {
    eng: "Debt should be not sold",
  },
  "1005": {
    eng: "Debt already exists",
  },
  "1006": {
    eng: "Loan should exist",
  },
  "1007": {
    eng: "Auction already ended",
  },
  "1008": {
    eng: "Bid price higher than sell price",
  },
  "1009": {
    eng: "Bid price less than previous bid",
  },
  "1010": {
    eng: "Invalid sell type",
  },
  "1011": {
    eng: "Auction not already ended",
  },
  "1012": {
    eng: "Invalid claim receiver",
  },
  "1013": {
    eng: "Amount different from sell price",
  },
  "1014": {
    eng: "Bid price less than min bid price",
  },
  "1015": {
    eng: "Borrowed amount diverged",
  },
  "1016": {
    eng: "Invalid authorized address",
  },
  "1017": {
    eng: "Caller not the owner or authorized",
  },
};
