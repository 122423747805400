import { type FC, useEffect, useState } from "react";
import { useToggle, useUpdateEffect } from "react-use";
import { useAccount, useSigner } from "wagmi";
import { TransactionError, transactionErrorMessage } from "../../errors/errors";
import { useAlertNotification } from "../providers";
import {
  approvePunkGatewayForAllInWrappedPunks,
  claimAuctionWon,
  claimLoanListedAuction,
} from "../../logic";
import {
  MarketItem,
  MarketItemAuctionStatus,
  MarketItemType,
  PortableText,
} from "../../types";
import { CtaPrimary, CtaRed } from "../ui";
import { IconEthereum } from "../ui/icons";
import {
  equalIgnoreCase,
  formatWeiToEth,
  parseMsToRender,
  parseMsToRenderMinAndSec,
  waitForTransactionReceipt,
} from "../../utils";
import { ModalBid, ModalRedeem, ModalRepay } from "../commons";
import { useWeb3ModalGuard } from "../commons/modals/shared";
import {
  changeToWrapIfCrypropunks,
  determineAuctionStatus,
  handleErrors,
  isCryptopunks,
} from "../../logic/helpers";
import classNames from "classnames";
import { DataItem } from "./DataItem";
import { ModalBuyNow } from "../commons/modals/ModalBuyNow";
import { BigNumber } from "ethers";
import { approveInWalletLiteral } from "../../literals";
import { setLiquidatedReservoirListener } from "../../logic/listeners/setLiquidatedReservoirListener";

type Props = {
  marketItem: MarketItem;
  isRemoved: boolean;
  onModalOpen: (
    collection: string,
    tokenId: number,
    type: MarketItemType
  ) => void;
  onModalClose: () => void;
  index: number;
  discount: number;
  buyNowPrice: BigNumber;
  isWrapPunkApproved?: boolean;
};

enum PunkStatus {
  TO_APPROVE,
  LOADING_APPROVE,
  APPROVED,
}

export const MarketItemCard: FC<Props> = (props) => {
  const {
    onModalOpen,
    onModalClose,
    isRemoved,
    marketItem: {
      latestBid,
      redeemEnd,
      biddingEnd,
      healthFactor,
      type,
      valuation,
      minBid,
      debt,
      nfts: [{ collection, tokenId, name, image }],
      auctionStatus: auctionStatusFromProps,
      bidBorrowAmount,
      isBuyNowAvailable: isBuyNowAvailableFromProps,
      isAuctionAvailable,
      availableToBorrow,
      isOwnDebt,
      reservoirBid,
      owner,
      bidder,
    },
    marketItem,
    buyNowPrice,
    discount,
    isWrapPunkApproved,
  } = props;

  // const getBuyNowPrice = () => debt > valuation ? debt : valuation

  const { address } = useAccount();
  const { data: signer } = useSigner();
  const [time, setTime] = useState<number | null>();
  const [auctionStatus, setAuctionStatus] = useState<
    MarketItemAuctionStatus | undefined
  >(auctionStatusFromProps);
  const [intervalId, setIntervalId] = useState<any>();
  const [claimTime, setClaimTime] = useState<number | null>();
  const [claimIntervalId, setClaimIntervalId] = useState<any>();
  const [isOpenModalBid, toggleModalBid] = useToggle(false);
  const [isOpenModalRedeem, toggleModalRedeem] = useToggle(false);
  const [isOpenModalRepay, toggleModalRepay] = useToggle(false);
  const [isOpenModalBuyout, toggleModalBuyout] = useToggle(false);
  const [isBuyNowAvailable, setBuyNowAvailable] = useState<boolean>(
    isBuyNowAvailableFromProps
  );
  const [error, setError] = useState<Error | null>(null);
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();
  const [isAuctionButtonDisabled, setAuctionButtonDisabled] =
    useState<boolean>(true);
  const [timeToFinishTitle, setTimeToFinishTitle] = useState<string>();
  const [auctionButtonText, setAuctionButtonText] = useState<string>();
  const [hasAuctionButtonEthIcon, setHasAuctionButtonEthIcon] =
    useState<boolean>(false);
  const [ctaRedText, setCtaRedText] = useState<string>();
  const launcherModalBid = useWeb3ModalGuard(isOpenModalBid, toggleModalBid);
  const launcherModalRedeem = useWeb3ModalGuard(
    isOpenModalRedeem,
    toggleModalRedeem
  );
  const launcherModalRepay = useWeb3ModalGuard(
    isOpenModalRepay,
    toggleModalRepay
  );
  const launchModalBuyout = useWeb3ModalGuard(
    isOpenModalBuyout,
    toggleModalBuyout
  );
  const launcherClaim = useWeb3ModalGuard(undefined, handleClaim);
  const [punkApprovalStatus, setPunkApprovalStatus] = useState<PunkStatus>();

  const timeToRender = time
    ? parseMsToRender(time)
    : time === null
    ? "Finished"
    : "";

  const claimTimeToRender = claimTime && parseMsToRenderMinAndSec(claimTime);

  const cardTypeTitle =
    type === MarketItemType.LOAN_AUCTIONED ? "NFT" : "NFT with Debt";
  const cardTitleBorder =
    type === MarketItemType.LOAN_AUCTIONED
      ? "border-tertiary"
      : "border-quaternary";

  let offerMessage: string | undefined;

  if (isAuctionAvailable) {
    let percentage: number;

    if (type === MarketItemType.LOAN_AUCTIONED) {
      percentage = (1 - Number(minBid) / Number(buyNowPrice)) * 100;
    } else {
      percentage =
        (1 - (Number(minBid) + Number(debt)) / Number(valuation)) * 100;
    }

    if (percentage > 0) {
      offerMessage = `${percentage.toFixed()}% Underpriced`;
    }
  }

  // useEffect(() => {
  //   (async () => {
  //     const data = await getReservoirData(collection, tokenId);
  //   })();
  // }, []);

  useEffect(() => {
    if (
      isCryptopunks(collection) &&
      isWrapPunkApproved !== undefined &&
      (auctionStatus === MarketItemAuctionStatus.TO_CLAIM ||
        auctionStatus === MarketItemAuctionStatus.WAITING_FOR_BOT_TIME)
    ) {
      setPunkApprovalStatus(
        isWrapPunkApproved ? PunkStatus.APPROVED : PunkStatus.TO_APPROVE
      );
    }
  }, [isWrapPunkApproved]);

  useEffect(() => {
    if (punkApprovalStatus) {
      if (punkApprovalStatus === PunkStatus.LOADING_APPROVE) {
        setAuctionButtonText("Loading...");
        setAuctionButtonDisabled(true);
      } else if (auctionStatus === MarketItemAuctionStatus.TO_CLAIM) {
        if (punkApprovalStatus === PunkStatus.APPROVED) {
          setAuctionButtonText("Claim");
          setAuctionButtonDisabled(false);
        } else {
          setAuctionButtonText("Approve to claim");
          setAuctionButtonDisabled(false);
        }
      }
    }
  }, [punkApprovalStatus]);

  useEffect(() => {
    if (isAuctionAvailable) {
      setAuctionButtonDisabled(
        auctionStatusFromProps !== MarketItemAuctionStatus.TO_CLAIM &&
          auctionStatusFromProps !== MarketItemAuctionStatus.TO_BID &&
          auctionStatusFromProps !== MarketItemAuctionStatus.TO_FIRST_BID
      );

      setTimeToFinishTitle(
        auctionStatusFromProps === MarketItemAuctionStatus.TO_REDEEM
          ? "Redeem end"
          : "Bidding ends"
      );

      if (auctionStatusFromProps === MarketItemAuctionStatus.TO_FIRST_BID) {
        setAuctionButtonText(`Place first bid - ${formatWeiToEth(minBid!, 2)}`);
        setHasAuctionButtonEthIcon(true);
      } else if (auctionStatusFromProps === MarketItemAuctionStatus.TO_BID) {
        setAuctionButtonText(`Place bid - ${formatWeiToEth(minBid!, 2)}`);
        setHasAuctionButtonEthIcon(true);
      } else if (
        auctionStatusFromProps === MarketItemAuctionStatus.TO_CLAIM ||
        auctionStatusFromProps === MarketItemAuctionStatus.WAITING_FOR_BOT_TIME
      ) {
        setHasAuctionButtonEthIcon(false);

        if (
          isCryptopunks(collection) &&
          punkApprovalStatus !== PunkStatus.APPROVED
        ) {
          setAuctionButtonText("Approve to Claim");
        } else {
          setAuctionButtonText("Claim");
        }
      } else if (auctionStatusFromProps === MarketItemAuctionStatus.BIDDED) {
        setAuctionButtonText("Bid placed");
        setHasAuctionButtonEthIcon(false);
      } else if (
        auctionStatusFromProps === MarketItemAuctionStatus.BID_FINISHED
      ) {
        setAuctionButtonText("Auction finished");
        setHasAuctionButtonEthIcon(false);
      } else if (
        auctionStatusFromProps === MarketItemAuctionStatus.REDEEM_FINISHED
      ) {
        setAuctionButtonText("Redeem finished");
        setHasAuctionButtonEthIcon(false);
      } else if (auctionStatusFromProps === MarketItemAuctionStatus.TO_REPAY) {
        setCtaRedText("Repay");
        setHasAuctionButtonEthIcon(false);
      } else if (auctionStatusFromProps === MarketItemAuctionStatus.TO_REDEEM) {
        setCtaRedText("Redeem");
        setHasAuctionButtonEthIcon(false);
      }
    }
  }, [auctionStatusFromProps]);

  useUpdateEffect(() => {
    if (auctionStatus === MarketItemAuctionStatus.LOADING_CLAIM) {
      setAuctionButtonText("Loading...");
      setAuctionButtonDisabled(true);
    } else if (auctionStatus === MarketItemAuctionStatus.CLAIMED) {
      setAuctionButtonText("Claimed");
      setAuctionButtonDisabled(true);
    } else if (auctionStatus === MarketItemAuctionStatus.TO_CLAIM) {
      if (!punkApprovalStatus || punkApprovalStatus === PunkStatus.APPROVED) {
        setAuctionButtonText("Claim");
        setAuctionButtonDisabled(false);
      } else {
        setAuctionButtonText("Approve to claim");
        setAuctionButtonDisabled(false);
      }
    } else if (auctionStatus === MarketItemAuctionStatus.WAITING_FOR_BOT_TIME) {
      setAuctionButtonText("Claim");
      setAuctionButtonDisabled(true);
      setClaimIntervalProcess();
    }
  }, [auctionStatus]);

  useEffect(() => {
    if (
      error &&
      !isAlertNotificationOpen &&
      !(error instanceof TransactionError)
    ) {
      openAlertNotification(
        "error",
        [
          {
            _key: "block-0",
            _type: "block",
            children: [
              {
                _key: "child-0",
                _type: "span",
                text: error.message,
              },
            ],
          },
        ],
        5000
      );
    } else if (error instanceof TransactionError) {
      openAlertNotification("error", transactionErrorMessage, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (isAuctionAvailable) {
      clearInterval(intervalId);

      const duration =
        auctionStatus === MarketItemAuctionStatus.TO_REDEEM ||
        auctionStatus === MarketItemAuctionStatus.REDEEM_FINISHED
          ? redeemEnd
          : biddingEnd;

      const id = setInterval(() => {
        setTime((time) => {
          if (time !== undefined && time! < 1000) {
            clearInterval(id);
            latestBid && setBuyNowAvailable(false);

            setAuctionStatus(
              determineAuctionStatus(
                type,
                owner,
                address!,
                biddingEnd!,
                bidder,
                !!latestBid,
                redeemEnd
              )
            );

            return null;
          } else if (time === undefined || time! >= 1000) {
            if (duration! - Date.now() > 1000) {
              return duration! - Date.now();
            } else {
              return null;
            }
          }
        });
      }, 1000);

      setIntervalId(id);
    }

    let reservoirUnlistener: Promise<() => void>;

    if (auctionStatus === MarketItemAuctionStatus.WAITING_FOR_BOT_TIME) {
      const { id, reservoirUnlistener: _reservoirUnlistener } =
        setClaimIntervalProcess();
      setClaimIntervalId(id);

      reservoirUnlistener = _reservoirUnlistener;
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(claimIntervalId);

      if (reservoirUnlistener) {
        (async () => {
          const unlistener = await reservoirUnlistener;

          unlistener();
        })();
      }
    };
  }, [address]);

  useUpdateEffect(() => {
    if (!isOpenModalBid) {
      onModalClose();
    }
  }, [isOpenModalBid]);

  useUpdateEffect(() => {
    if (!isOpenModalRedeem) {
      onModalClose();
    }
  }, [isOpenModalRedeem]);

  useUpdateEffect(() => {
    if (!isOpenModalRepay) {
      onModalClose();
    }
  }, [isOpenModalRepay]);

  useUpdateEffect(() => {
    if (!isOpenModalBuyout) {
      onModalClose();
    }
  }, [isOpenModalBuyout]);

  const handleBid = () => {
    onModalOpen(collection, tokenId, type);

    launcherModalBid();
  };

  const handleRedeemOrRepayButton = () => {
    onModalOpen(collection, tokenId, type);

    if (auctionStatus === MarketItemAuctionStatus.TO_REPAY) {
      launcherModalRepay();
    } else if (auctionStatus === MarketItemAuctionStatus.TO_REDEEM) {
      launcherModalRedeem();
    }
  };

  const handleBuyout = () => {
    onModalOpen(collection, tokenId, type);

    launchModalBuyout();
  };

  async function handleClaim() {
    if (signer) {
      try {
        let transactionHash: string;

        openAlertNotification("info", approveInWalletLiteral, 50000);

        if (type === MarketItemType.LOAN_AUCTIONED) {
          transactionHash = await claimAuctionWon(
            signer,
            collection,
            tokenId,
            debt,
            latestBid!
          );
        } else {
          transactionHash = await claimLoanListedAuction(
            signer,
            changeToWrapIfCrypropunks(collection),
            tokenId,
            address!
          );
        }

        closeAlertNotification();
        setAuctionStatus(MarketItemAuctionStatus.LOADING_CLAIM);

        if (!isAlertNotificationOpen) {
          openAlertNotification("loading", feedbackLoading, 3000);
        }

        waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
          if (receipt.status == 1) {
            setAuctionStatus(MarketItemAuctionStatus.CLAIMED);

            if (!isAlertNotificationOpen) {
              openAlertNotification("success", feedbackClaimed, 3000);
            }
          } else if (receipt.status === 0) {
            setError(
              new TransactionError(
                `transaction with hash ${receipt.transactionHash} failed`
              )
            );

            setAuctionStatus(MarketItemAuctionStatus.TO_CLAIM);
          }
        });
      } catch (err) {
        closeAlertNotification();
        setError(handleErrors(err));
      }
    }
  }

  const setClaimIntervalProcess = () => {
    const reservoirUnlistener = setLiquidatedReservoirListener(
      ({ nftAsset, tokenId: _tokenId }) => {
        if (equalIgnoreCase(nftAsset, collection) && tokenId === tokenId) {
          setAuctionStatus(MarketItemAuctionStatus.BID_FINISHED);
        }
      }
    );

    const id = setInterval(() => {
      setClaimTime((claimTime: any) => {
        if (claimTime !== undefined && claimTime! < 1000) {
          clearInterval(id);
          setAuctionStatus((currentStatus) =>
            currentStatus !== MarketItemAuctionStatus.BID_FINISHED
              ? MarketItemAuctionStatus.TO_CLAIM
              : currentStatus
          );
          (async () => {
            const unlistener = await reservoirUnlistener;
            unlistener();
          })();
        } else if (claimTime === undefined || claimTime! >= 1000) {
          const claimTimeEnd = biddingEnd! + 1000 * 60 * 20;

          if (claimTimeEnd - Date.now() > 1000) {
            return claimTimeEnd - Date.now();
          } else {
            return null;
          }
        }
      });
    }, 1000);
    return { id, reservoirUnlistener };
  };

  const handleAuctionButton = () => {
    if (
      auctionStatus === MarketItemAuctionStatus.TO_BID ||
      auctionStatus === MarketItemAuctionStatus.TO_FIRST_BID
    ) {
      handleBid();
    } else if (auctionStatus === MarketItemAuctionStatus.TO_CLAIM) {
      if (punkApprovalStatus === PunkStatus.TO_APPROVE) {
        handleApprovePunk();
      } else {
        launcherClaim();
      }
    }
  };

  const handleApprovePunk = async (): Promise<void> => {
    try {
      const transactionHash: string =
        await approvePunkGatewayForAllInWrappedPunks(signer!);

      setPunkApprovalStatus(PunkStatus.LOADING_APPROVE);

      openAlertNotification("loading", richTextValueLoadingApprove, 5000);

      waitForTransactionReceipt(transactionHash, 5000, (receipt) => {
        if (receipt.status == 1) {
          openAlertNotification("success", richTextApprovedAll, 5000);

          setPunkApprovalStatus(PunkStatus.APPROVED);
        } else if (receipt.status === 0) {
          setError(
            new TransactionError(
              `transaction with hash ${receipt.transactionHash} failed`
            )
          );
          setPunkApprovalStatus(PunkStatus.TO_APPROVE);
        }
      });
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  return (
    <>
      <li className="bg-secondary w-[300px] p-5 rounded-2xl flex flex-col items-center shadow-lighting">
        <div
          className={classNames(
            "absolute -mt-7 font-bold text-xs min-w-[70px] px-3 py-0.5 border-2 rounded-full bg-[#312958] flex justify-center items-center",
            cardTitleBorder
          )}
        >
          {cardTypeTitle}
        </div>
        <div className="w-64 h-64 rounded-2xl mb-16">
          <div className="flex justify-between mb-4 items-center">
            <div className="w-[70%]">
              <h3 className="text-sm">Collection</h3>
              <p className="font-bold text-md">{name}</p>
            </div>
            <div>
              <h3 className="text-sm">Token ID</h3>
              <p className="font-bold text-lg"># {tokenId}</p>
            </div>
          </div>
          <div className="h-64 flex justify-center">
            <img className="h-64 w-fit rounded-2xl" src={image} />
          </div>
        </div>
        <div className="w-full h-[170px] pt-4 grid grid-cols-2 grid-rows-3 gap-y-4">
          <DataItem value={formatWeiToEth(debt)} title="Debt" icon="eth" />

          {isAuctionAvailable ? (
            <DataItem
              className="items-end"
              value={timeToRender || "--:--:--:--"}
              title={timeToFinishTitle}
            />
          ) : (
            <DataItem
              className="items-end"
              value={healthFactor.toFixed(2)}
              title="Health Factor"
              icon="health-factor"
            />
          )}

          <DataItem
            value={formatWeiToEth(valuation)}
            title="Valuation"
            icon="eth"
          />

          {isAuctionAvailable ? (
            auctionStatus === MarketItemAuctionStatus.TO_FIRST_BID ? (
              <DataItem
                className="items-end"
                value={formatWeiToEth(minBid!, 2)}
                title="Minimum Bid"
                icon="eth"
                offerMessage={offerMessage}
              />
            ) : (
              <DataItem
                className="items-end"
                value={latestBid ? formatWeiToEth(latestBid) : "0"}
                title="Latest Bid"
                icon="eth"
                offerMessage={offerMessage}
              />
            )
          ) : (
            <DataItem
              className="items-end"
              value={formatWeiToEth(availableToBorrow!, 2)}
              title="Av. to borrow"
              icon="eth"
            />
          )}

          {isAuctionAvailable && (
            <DataItem
              value={healthFactor.toFixed(2)}
              title="Health Factor"
              icon="health-factor"
            />
          )}

          {reservoirBid && (
            <DataItem
              className="items-end"
              value={formatWeiToEth(reservoirBid, 4)}
              title="External bids"
              icon="eth"
            />
          )}
        </div>

        <div
          className={classNames(
            "w-full min-h-[130px] flex flex-col items-center gap-4",
            auctionStatus === MarketItemAuctionStatus.WAITING_FOR_BOT_TIME
              ? "justify-between"
              : "justify-end"
          )}
        >
          {!isOwnDebt && (
            <>
              {
                /*isBuyNowAvailable &&
                auctionStatus !== MarketItemAuctionStatus.TO_REDEEM &&
                auctionStatus !== MarketItemAuctionStatus.TO_REPAY &&
                auctionStatus !== MarketItemAuctionStatus.CLAIMED &&
                auctionStatus !== MarketItemAuctionStatus.TO_CLAIM &&
                auctionStatus !== MarketItemAuctionStatus.LOADING_CLAIM &&*/ <CtaPrimary
                  className="w-60 flex items-center justify-center gap-1"
                  onClick={handleBuyout}
                >
                  Buy now - {formatWeiToEth(buyNowPrice!, 2)}
                  <div className="ml-1 w-4 h-4 bg-white rounded-full">
                    <IconEthereum />
                  </div>
                </CtaPrimary>
              }

              {auctionStatus ===
                MarketItemAuctionStatus.WAITING_FOR_BOT_TIME && (
                <div className="w-3/5 justify-self-start">
                  <p className="text-2xs text-center">
                    This auction has ended. We are checking the external bids to
                    validate the winner, once the countdown ends:
                  </p>
                  <p className="w-full text-xl text-center font-bold">
                    {claimTimeToRender}
                  </p>
                </div>
              )}

              {isAuctionAvailable &&
                (auctionStatus === MarketItemAuctionStatus.TO_REDEEM ||
                auctionStatus === MarketItemAuctionStatus.TO_REPAY ? (
                  <CtaRed
                    className="w-60"
                    disabled={!time}
                    onClick={handleRedeemOrRepayButton}
                  >
                    {ctaRedText}
                  </CtaRed>
                ) : (
                  <CtaPrimary
                    className="w-60 flex items-center justify-center gap-1"
                    disabled={isAuctionButtonDisabled}
                    onClick={handleAuctionButton}
                  >
                    {auctionButtonText}
                    {hasAuctionButtonEthIcon && (
                      <div className="ml-1 w-4 h-4 bg-white rounded-full">
                        <IconEthereum />
                      </div>
                    )}
                  </CtaPrimary>
                ))}
            </>
          )}
        </div>

        {auctionStatus === MarketItemAuctionStatus.TO_FIRST_BID && (
          <p className="mt-2 text-[8px] font-semibold text-[#FFDC83]">
            &gt;&gt; Get your bid + 2.5% of its value if you get overbidded
            &lt;&lt;
          </p>
        )}
      </li>

      {isOpenModalBid && (
        <ModalBid
          marketItem={marketItem}
          toggleModal={toggleModalBid}
          isOpen={isOpenModalBid}
          isRemoved={isRemoved}
        />
      )}
      {isOpenModalRedeem && (
        <ModalRedeem
          isOpen={isOpenModalRedeem}
          toggleModal={toggleModalRedeem}
          collectionAddress={collection}
          tokenId={tokenId}
          debt={debt}
          valuation={valuation}
        />
      )}
      {isOpenModalRepay && (
        <ModalRepay
          isOpen={isOpenModalRepay}
          toggleModal={toggleModalRepay}
          collectionAddress={collection}
          tokenId={tokenId}
          currentBorrowAmount={debt}
          valuation={valuation}
        />
      )}
      {isOpenModalBuyout && (
        <ModalBuyNow
          marketItem={marketItem}
          toggleModal={toggleModalBuyout}
          isOpen={isOpenModalBuyout}
          isRemoved={isRemoved}
          buyNowPrice={buyNowPrice}
          discount={discount}
        />
      )}
    </>
  );
};

// THIS VARIABLES ARE DECLARE ALSO IN MYAUCTIONITEMCOMPONENT
// IF HAVE TO CHANGE IT, ALSO DO IT ON THAT FILE
const feedbackLoading: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your claim transaction is being processed. Please wait.",
      },
    ],
  },
];

const feedbackClaimed: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "NFT claimed successfully. You can check it in your wallet.",
      },
    ],
  },
];

const richTextValueLoadingApprove: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "Your petition is being processed. Please wait.",
      },
    ],
  },
];

const richTextApprovedAll: PortableText = [
  {
    _key: "block-0",
    _type: "block",
    children: [
      {
        _key: "child-0",
        _type: "span",
        text: "NFT approved. You can now proceed with claiming.",
      },
    ],
  },
];
