import {Storage} from "../../utils";

export const setNftImageOnCache = (
  collectionAddress: string,
  tokenId: string,
  image: string
): void => {
  Storage.LOCAL.set(
    `nfts.${collectionAddress.toLowerCase()}.id-${tokenId}.image`,
    image
  );
};
