export const metamaskErrorsMap: {
  [key: string]: {
    eng: string;
  };
} = {
  INSUFFICIENT_FUNDS: {
    eng: "Insufficient funds for gas * price + value",
  },
  "-32000": {
    eng: "User denied transaction signature",
  },
  "4001": {
    eng: "User denied transaction signature",
  },
};
